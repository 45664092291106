import { CCard, CContainer, CRow, CSmartTable } from "@coreui/react-pro"
import { AppliedFilters, Divider, RoleCheck, SearchTable } from "../../components";
import { Util } from "../../../core/util/util";
import { Pagination } from "../../../core/interfaces";
import { useContext, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { User } from "../../../core/models/db/user";
import { LoaderContext } from "../../components/loader/context/LoaderContext";
import { Item } from "@coreui/react-pro/dist/components/table/types";
import { useCheckTable, useUser } from "../../../core/hooks";
import { LoginService } from "../../../core/services/login/login.service";
import { useActionBarStore, usePaginationStore } from "../../../core/store";
import { TablePagination } from "@mui/material";
import { BASE_ROUTE } from "../../../core/constants/route_name";


// const INIT_PAGINATION: Pagination = { page: 0, itemsPerPage: 10 };
// const INIT_ALL_ROW: Pagination = { page: 0, itemsPerPage: 0 };

const INIT_PAGINATION: Pagination = { page: 0, itemsPerPage: 10, filters: {} };
const INIT_ALL_ROW: Pagination = { page: 0, itemsPerPage: 0, filters: {} };

const CLASS_REGULAR_ROW = "check-reg-" + Util.getUniqueId(1);
const CLASS_PRINCIPAL_ROW = "check-prin-" + Util.getUniqueId(1);


const UserPage = () => {

    const navigate = useNavigate();
    const { isShowActionBar, reset, loadActionBar } = useActionBarStore(state => state);

    const initialPaginationConfig = useMemo(() => ({
        page: 0,
        itemsPerPage: 10,
        filters: {},
        orderBy: null,
    }), []);

    const { pagination, setPagination, resetFilterRaws } = usePaginationStore();
    const [init, setInit] = useState<boolean>(false);

    useEffect(() => {
        setPagination(initialPaginationConfig);
        resetFilterRaws();
        setInit(true);
    }, [initialPaginationConfig]);


    const [identity, setIdentity] = useState(undefined);

    const { getUser, getAllIdUser } = useUser();

    const [body, setBody] = useState<User[]>([]);
    const [reload, setReload] = useState<boolean>(true);


    const { loading, handleLoading, changeStyle } = useContext(LoaderContext);


    const { selected, handleSingleCheck, cleanSelected } = useCheckTable(
        {
            body,
            // itemsPerPage: pagination.itemsPerPage,
            pagination: pagination,

            reasonForDesactivation: [],
            classToFindPrincipalRow: CLASS_PRINCIPAL_ROW,
            classToFindRegularRow: CLASS_REGULAR_ROW,
            getAllId: undefined
        });


    useEffect(() => {

        console.log("filters", pagination?.filters)
        if (!pagination || !init) return;

        initPage({
            ...pagination,
        })
    }, [!!init, pagination?.page, pagination?.itemsPerPage, JSON.stringify(pagination?.orderBy), JSON.stringify(pagination?.filters)]
    );

    useEffect(() => {
        cleanSelected();
    }, [pagination?.itemsPerPage, JSON.stringify(pagination?.filters)]);

    useEffect(() => {
        if (reset) {
            initPage(initialPaginationConfig);
            cleanSelected();
        }
    }, [reset]);


    const initPage = (paginationDefault = pagination) => {
        (async () => {
            // changeStyle({ backgroundColor: "#fff" })
            handleLoading(true);
            // Cargamos las acciones para el ActionBar

            // const token = await LoginService.getTokenAsync();
            const id = await LoginService.getIdentityAsync();
            setIdentity(id);
            // setToken(token)

            const response = await getUser({ ...paginationDefault, page: paginationDefault.page + 1 });
            console.log("response", response)
            if (response && response.ok) {
                let rows = response.item.rows;

                for (let i = 0; i < response.item.rows.length; i++) {

                    const row = rows[i];
                    rows[i].username = row?.username || '';
                    rows[i].email = row.email || '';

                    // rows[i].priv_role = rows[i]?.roles ? rows[i]?.roles.title : '';
                    // Hay que agregar estos dos para añadir los checks
                    rows[i].priv_check = "";
                    rows[i]._props = { className: CLASS_REGULAR_ROW };
                }

                setBody(rows);
                setPagination({
                    ...paginationDefault,
                    totalItems: response.item.pagination.totalItems,
                    totalPages: response.item.pagination.totalPages,
                })

                setTimeout(() => {
                    loadActionBar({ delete: id.role === "ROLE_ADMIN", table: "users", count: selected.length, items: selected });
                    handleLoading(false);
                }, 200)
            } else {
                console.warn("Ha ocurrido un problema al devolver los usuarios")
            }
        })()
    }

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPagination({
            ...pagination,
            page: newPage
        })
        setReload(true);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setPagination({
            ...pagination,
            page: 0,
            itemsPerPage: parseInt(event.target.value, 10)
        })
        setReload(true);
    };

    const goToUpdate = (item: User) => {
        console.log(item, identity)
        // if (identity && item.id === identity.idUser || identity.role === "ROLE_ADMIN") {
            navigate(`${BASE_ROUTE}/perfil/${item.id}/detalle`);
        // }
    }

    return (
        <CContainer>
            <CRow>
                <div className="pt-4 pb-5">
                    <span className="text-4xl" style={{ fontWeight: 500 }}>Usuarios</span>
                </div>

            </CRow>
            <CCard className="rounded-xl p-3">
                <CRow>
                    <SearchTable nameColumn={["email"]} label={["Email"]} />
                </CRow>
                <Divider color="" marginX={-16} />
                <CRow>
                    <AppliedFilters />
                </CRow>
                <Divider marginX={-16} />
                <CRow className="flex justify-center align-middle items-center">
                    <RoleCheck multiSelect={false} nameTable="role" nameColumn="title" label="Rol" />
                </CRow>
                <Divider marginX={-16} />
                <CRow>

                    {loading && (
                        <div className="flex justify-center items-center h-full mt-5">
                            <div className="flex justify-center items-center">
                                <div className="animate-spin rounded-full h-[32px] w-[32px] border-t-2 border-b-2 border-gray-900"></div>
                            </div>
                        </div>
                    )}

                    {/* LISTADO */}
                    {/* <pre>{JSON.stringify(itemsFiltered, null, 2)}</pre> */}
                    {body && body.length > 0 && pagination && pagination.totalPages && (


                        //   className={width < 780 ? 'table-responsive' : ''}
                        <div style={{ visibility: loading ? 'hidden' : 'visible' }}>

                            <CSmartTable
                                tableHeadProps={{
                                    color: "#dedede",

                                }}
                                tableProps={{

                                    responsive: "md",
                                    bordered: false,
                                    borderless: true,
                                    // striped: true,
                                    hover: true,
                                    // color: "dark",

                                }}
                                items={body}

                                columns={[
                                    {
                                        key: "priv_check",
                                        label: "",
                                        sorter: false,
                                        filter: false,
                                        _props: { className: CLASS_PRINCIPAL_ROW },
                                        _style: { backgroundColor: '#f1f1f1' },

                                    },
                                    {
                                        key: "username",
                                        label: "Usuario",
                                        _style: { backgroundColor: '#f1f1f1' },
                                    },
                                    {
                                        key: "email",
                                        label: "Email",
                                        _style: { backgroundColor: '#f1f1f1' },

                                    },


                                ]}

                                columnSorter={{
                                    external: true
                                }}

                                itemsPerPage={pagination.itemsPerPage}

                                clickableRows

                                onRowClick={(item: Item, index: number, columnName: string, event) => {
                                    let target = typeof event !== 'boolean' ? event.nativeEvent.target as HTMLElement : event;
                                    let value = typeof target === 'boolean' ? target : target.tagName;

                                    if (typeof target !== 'boolean' && value === "INPUT") {
                                        //    Clica al check

                                    } else if (typeof target !== 'boolean' && value === "TD") {
                                        //    Console ir al detalle
                                        if (selected && selected.length > 0) {
                                            handleSingleCheck(null, item.id, item);
                                        } else {
                                            goToUpdate(item as User)
                                        }
                                    }
                                }}

                                scopedColumns={{
                                    email: (item) => {
                                        let value = "No hay email registrado";
                                        try {
                                            if (item.email) {
                                                value = item.email;
                                            }
                                            return (
                                                <td> {value} </td>
                                            )
                                        } catch (e) {
                                            console.error(e)
                                            return (
                                                <td> {value}* </td>
                                            )
                                        }
                                    },


                                }}

                                onSelectAll={() => {
                                    // console.log("calling all of ids")
                                }}
                                onSelectedItemsChange={(items) => {
                                    // console.log("items that you selected", items)
                                }}


                                onSorterChange={(value) => {
                                    // console.log("what is value?", value)
                                    if (value.state === 0) {
                                        setPagination({
                                            ...pagination,
                                            //   relatedTableOrder: null,
                                            orderBy: null
                                        })
                                    } else if (value.column === "priv_role") {
                                        // setPagination({
                                        //   ...pagination,
                                        //   relatedTableOrder: "roles",
                                        //   order: value.state,
                                        //   orderBy: "title"
                                        // })
                                    } else if (value.column === "username") {
                                        setPagination({
                                            ...pagination,
                                            // relatedTableOrder: null,

                                            orderBy: {
                                                field: value.column,
                                                order: value.state as any,
                                                relation: "webAppAuth"
                                            }
                                        })

                                    } else {
                                        setPagination({
                                            ...pagination,
                                            // relatedTableOrder: null,

                                            orderBy: {
                                                field: value.column,
                                                order: value.state as any
                                            }
                                        })
                                    }

                                    setTimeout(() => {
                                        setReload(true);
                                    }, 100)

                                }}
                            />
                            {pagination && pagination.totalItems > 0 && (
                                <TablePagination
                                    component="div"
                                    count={pagination.totalItems}
                                    page={pagination.page}
                                    rowsPerPage={pagination.itemsPerPage}

                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    labelRowsPerPage="Registros por página"
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                />

                            )}

                        </div>
                    )}




                </CRow>
            </CCard>
        </CContainer >
    )
}


export default UserPage;

