import React, { useEffect, useRef } from 'react'
import { CSidebar, CSidebarHeader } from '@coreui/react-pro'
import { useUIStore } from '../../../../core/store'
import { RxCross2 } from "react-icons/rx";
import { LuSettings2 } from "react-icons/lu";
import Setting from '../../setting/Setting';


const AppAside = () => {

  const { asideShow, setUI } = useUIStore(state => state)

  const sidebarRef = useRef<HTMLDivElement>(null);

  const closeSidebar = () => setUI({ asideShow: false });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
        closeSidebar();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  return (
    <CSidebar
      ref={sidebarRef}
      className='sidebar-lateral'
      colorScheme="light"
      size="lg"
      overlaid
      placement="end"
      visible={asideShow}
      onVisibleChange={(visible) => {
        setUI({ asideShow: visible })
        // dispatch({ type: 'set', asideShow: visible })
      }}
      style={{ maxHeight: '100vh', overflowY: 'auto' }}
    >
      <CSidebarHeader className="mt-2 flex items-center justify-between bg-transparent">

        <div className='flex items-center text-2xl float-start'><LuSettings2 className='mr-2' /> <span>Configuración</span></div>
        <div
          className="float-end cursor-pointer text-2xl"
          onClick={() => {
            setUI({ asideShow: false })
          }}>
          <RxCross2 />
        </div>

      </CSidebarHeader>

      {/* Pantalla Configuración */}
      <Setting />
    </CSidebar>
  )
}

export default React.memo(AppAside)
