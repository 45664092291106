import { useEffect, useState } from "react";
import { Dropdown } from "../../dropdown/Dropdown";
import { RippleButton } from "../../ripple-button/RippleButton";


import { BASE_ROUTE, ROUTE_NAME } from "../../../../../core/constants/route_name";
import { useNotificationStore, usePaginationStore } from "../../../../../core/store";



import { Badge, Tooltip } from "@mui/material";
import clsx from "clsx";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { BsChatDots } from "react-icons/bs";
import { GiPayMoney } from "react-icons/gi";
import { HiOutlineEnvelopeOpen } from "react-icons/hi2";
import { IoMdNotificationsOutline } from "react-icons/io";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { RiForbidLine } from "react-icons/ri";
import { TbDiscountCheck, TbMessageOff } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../../../../../core/hooks";
import { DropdownItemNotification } from "../../../../../core/interfaces/dropdown/dropdown-item-notification";
import { NotificationRBC } from "../../../../../core/models/db";
import { DropItemNotification } from "../../dropdown/drop-item-notification/DropItemNotification";


export const NavButtonNotification = (props) => {
    const navigate = useNavigate();

    const {
        getNotification: getNoRevised,
        markAllAsRevised: mark,
        updateNotification
    } = useNotification();

    const {
        isUpdateList,

        lastNotificationRevised,
        setNotificationSelected,
        setAllRevised,
        setLastNotificationRevised,

    }
        = useNotificationStore(state => state)

    const [notifications, setNotifications] = useState<NotificationRBC[]>([]);


    const { addFilterRawExclusive } = usePaginationStore();

    // const [identity, setIdentity] = useState<Identity>(undefined);
    const [showModal, setShowModal] = useState<boolean>(undefined);

    const toggleShowModal = () => {
        setShowModal(prevShowModal => !prevShowModal);
    };

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const result = await getNoRevised({
                    page: 0,
                    itemsPerPage: 10000000,
                    filters: {
                        revised: {
                            value: 0
                        }
                    }
                });

                if (result && result.ok) {
                    setNotifications(result.item.rows);
                }
            } catch (error) {
                setNotifications([]);
                console.error(error);
            }
        };

        fetchNotifications();
        const tenMinutes = 600000;
        const intervalId = setInterval(fetchNotifications, tenMinutes);
        return () => clearInterval(intervalId);
    }, [!!isUpdateList]);


    useEffect(() => {
        if (lastNotificationRevised) {
            setNotifications(prev => prev.filter(x => x.id !== lastNotificationRevised.id));
        }
    },[lastNotificationRevised])

    const markAllAsRevised = async () => {
        try {
            if (!notifications || notifications.length === 0) return;

            const result = await mark();
            if (result && result.ok) {
                setAllRevised(true);
                setNotifications([]);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setTimeout(() => {
                // setShowModal(false);
            }, 200)
        }
    }


    const update = async (item: NotificationRBC) => {
        try {
            const itemRemoved = item;
            setNotifications(prev => prev.filter(x => x.id !== item.id));

            const result = await updateNotification({ id: item.id, revised: 1 });
            console.log(result)
            if (result && result.ok) {
                // Indicamos cual ha sido el ultimo actualizado
                // Esto es importante por si nos encontramos en la pagina de notificaciones
                // poder actualizar el item de la lista de notificaciones si está visible
                setLastNotificationRevised(itemRemoved);

            } else {
                setNotifications(prev => [...prev, itemRemoved]);
            }
        } catch (error) {
            setShowModal(false);
            console.error(error);
        }
    }

    const createNotificationIcon = (notificationType) => {
        let iconStyle = {};

        switch (notificationType) {
            case 'BAN':
                iconStyle = { color: '#f44336' }; // Rojo MUI error
                return <RiForbidLine size={22} style={iconStyle} />;
            case 'CONVERSATION':
                iconStyle = { color: '#3f51b5' }; // Azul MUI primary
                return <BsChatDots size={22} style={iconStyle} />;
            case 'LEAD':
                iconStyle = { color: '#4caf50' }; // Verde MUI success
                return <TbDiscountCheck size={22} style={iconStyle} />;
            case 'QUOTA_REACHED':
                iconStyle = { color: '#ff9800' }; // Amarillo MUI warning
                // return <GiPayMoney size={22} style={iconStyle} />;
                return <TbMessageOff size={22} style={iconStyle} />;
            case 'OTHER':
                iconStyle = { color: '#9e9e9e' }; // Gris MUI default
                return <AiOutlineExclamationCircle size={22} style={iconStyle} />;
            case 'ADMINISTRATION':
                iconStyle = { color: '#9c27b0' }; // Púrpura MUI secondary
                return <MdOutlineAdminPanelSettings size={22} style={iconStyle} />;
            default:
                return <HiOutlineEnvelopeOpen size={22} />; // Icono por defecto, sin color específico
        }
    };


    return (
        <>
            <div className="relative">
                <RippleButton rippleColor="rgba(135, 135, 135, 0.4)" className="rounded-full w-[45px] h-[45px]" onClick={(e) => toggleShowModal()}>

                    {/* <div
                        onClick={(e) => toggleShowModal()}
                        className="no-select"

                        style={{
                      
                            fontSize: 16,
                            borderRadius: '50%',
                            width: '40px',
                            height: '40px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            fontWeight: 'bold',
                        }}
                    >
                 
                    
                            <IoMdNotificationsOutline size={24} color="black" />
                        
                    </div> */}

                    <Badge
                        badgeContent={notifications?.length || 0}
                        color="error"
                        overlap="circular"
                        sx={{ "& .MuiBadge-badge": { fontSize: 9, height: 15, minWidth: 15 } }}
                    >
                        <IoMdNotificationsOutline size={23} color="black" />

                    </Badge>

                </RippleButton>
            </div>



            <Dropdown
                position={{ top: 60, right: 70 }}
                showModal={showModal}
                setShowModal={setShowModal}
            >

                <div className={`rounded-xl bg-white w-auto min-w-[400px] max-w-xs shadow-lg px-2 py-3`}>

                    <div className="flex flex-col items-start px-2 ">
                        {/* <div className="text-black font-semibold text-md break-words">{identity?.username}</div>
                        <div className="text-slate-400 text-sm break-words">{identity?.email}</div> */}

                        <div className="flex items-center justify-between text-black text-lg w-full">
                            <span>Notificaciones</span>
                            <RippleButton
                                onClick={markAllAsRevised}
                                rippleColor="rgba(135, 135, 135, 0.4)"
                                className="rounded-full w-[40px] h-[40px]">
                                <Tooltip title={`Marcar todos como leídos`}>
                                    <span>
                                        <HiOutlineEnvelopeOpen className="cursor-pointer text-black" size={20} />
                                    </span>
                                </Tooltip>
                            </RippleButton>
                        </div>

                    </div>

                    <div className={clsx(`border-b border-gray-300 py-1`, {
                        "shadow-sm": notifications?.length > 0
                    })}></div>
                    {/* <div className="py-1"></div> */}
                    <div className="hide-scrollbar overflow-y-auto max-h-[300px]">
                        {
                            notifications && notifications?.map((item, index) => {
                                let icon = createNotificationIcon(item.notificationType);
                                let o: DropdownItemNotification = {
                                    component: DropItemNotification,
                                    notification: item,
                                    name: item.title,
                                    icon: icon,
                                    to: BASE_ROUTE + ROUTE_NAME.notification,
                                }

                                const lastItem = index === notifications.length - 1;

                                return (
                                    <div key={index}>
                                        <DropItemNotification
                                            key={index}
                                            item={o}
                                            handleVisible={setShowModal}
                                            selectItem={(object) => {
                                                // TODO: Importante que tenga un tiempo de espera
                                                // para evitar bug
                                                setTimeout(() => {
                                                    addFilterRawExclusive({
                                                        nameTable: "",
                                                        nameColumn: "id",
                                                        title: item.id,
                                                        label: "id",
                                                        value: Number(item.id),
                                                    });
                                                }, 200)
                                                setNotificationSelected(object);
                                            }}

                                            removeItem={() => update(item)}
                                            lastItem={lastItem} />
                                    </div>
                                )
                            })
                        }

                        {!notifications || notifications?.length === 0 && (
                            <div className="flex justify-center items-center text-black text-sm h-[80px]">
                                <span>No hay nuevas notificaciones</span>
                            </div>
                        )}


                    </div>
                    <div className={clsx(`border-t border-gray-300 `, {
                        "shadow-top h-[4px]": notifications?.length > 0
                    })}></div>
                    <div className="py-1"></div>
                    <div className="flex justify-center items-center mt-2 text-black ">
                        <span className="text-sm font-semibold cursor-pointer hover:text-gray-600" onClick={() => {
                            setShowModal(false);
                            navigate(`${BASE_ROUTE}${ROUTE_NAME.notification}`)
                        }}>Ver todas las notificaciones</span>
                    </div>

                </div>


            </Dropdown >


        </>
    )
}


