import { CCardBody, CCardHeader, CCol, CContainer, CRow } from "@coreui/react-pro";
import { useState } from 'react';

import { Autocomplete, TextField, Typography } from '@mui/material';


import clsx from "clsx";
import { COLORS } from "../../../core/constants/colors";
import { DatePickerRange } from '../../components';
import { RippleButton } from "../../components/ui/ripple-button/RippleButton";

import { END_DATE_DEFAULT, START_DATE_DEFAULT } from "../../components/datePickerRange/custom-ranges";

import { useAnalytic } from "../../../core/hooks";
import { AnalyticUtil } from "../../../core/util/analytics-util/analytics-util";

import { Analytic1 } from "./Analytic1";
import Analytic2 from "./Analytic2";
import { Analytic3 } from "./Analytic3";
import { Analytic4 } from "./Analytic4";
import { ProgressWheel } from "../../components/@analitycs";
import Analytic5 from "./Analytic5";
import moment from "moment";


const optionsSocialMedia = [
    { title: 'Instagram', value: 'INSTAGRAM' },
    { title: 'Whatsapp', value: 'WHATSAPP' },
    { title: 'Facebook', value: 'FACEBOOK' },
    { title: 'Web', value: 'WEB' },
    { title: 'Telegram', value: 'TELEGRAM' },
];


const HomePage = () => {
    // const [dateRange, setDateRange] = useState<any>([
    //     START_DATE_DEFAULT.format("YYYY-MM-DD HH:mm:ss"),
    //     END_DATE_DEFAULT.format("YYYY-MM-DD HH:mm:ss")
    // ]);

    const [dateRange, setDateRange] = useState<{ startDate: any, endDate: any }>({
        // startDate: new Date(new Date(new Date().setDate(new Date().getDate() - 29)).setHours(0, 0, 0, 0)),
        // endDate: new Date(new Date().setHours(23, 59, 59, 999))
        startDate: START_DATE_DEFAULT.format("YYYY-MM-DD HH:mm:ss"),
        endDate: END_DATE_DEFAULT.format("YYYY-MM-DD HH:mm:ss")
    });


    const [disabled, setDisabled] = useState<boolean>(false);
    const [fistSearch, setFistSearch] = useState<boolean>(false);

    const [selectedSocialMedia, setSelectedSocialMedia] = useState([]);

    const [dataBarAndLine, setDataBarAndLine] = useState<any>([]);
    const [dataCounterMessage, setDataCounterMessage] = useState<any>([]);
    const [dataPie, setDataPie] = useState<any>([]);
    const [dataTreeMap, setDataTreeMap] = useState<any>(undefined);
    const [dataMonthlyMessages, setDataMonthlyMessages] = useState<any>(undefined);


    const [errorAnalytic1, setErrorAnalytic1] = useState<boolean>(false);
    const [errorAnalytic2, setErrorAnalytic2] = useState<boolean>(false);
    const [errorAnalytic3, setErrorAnalytic3] = useState<boolean>(false);
    const [errorAnalytic4, setErrorAnalytic4] = useState<boolean>(false);
    const [errorAnalytic5, setErrorAnalytic5] = useState<boolean>(false);

    const [loading1, setLoading1] = useState<boolean>(false);
    const [loading2, setLoading2] = useState<boolean>(false);
    const [loading3, setLoading3] = useState<boolean>(false);
    const [loading4, setLoading4] = useState<boolean>(false);
    const [loading5, setLoading5] = useState<boolean>(false);

    const {
        getAnalytics,
        getAnalyticsPerTime,
        getCounterMessagePerTime,
        getFlowPercentage,
        getMonthlyMessages
    } = useAnalytic();


    // Manejador para cambios en el Autocomplete
    const handleChangeSocialMedia = (event, newValue) => {
        setSelectedSocialMedia(newValue);
    };

    const analyticsPieChart = (date, platforms) => {
        setErrorAnalytic2(false);
        setLoading2(true);
        getAnalytics(date[0], date[1], platforms)
            .then((res) => {
                // console.log(res)
                if (res && res?.ok) {
                    if (res.item?.error) {
                        setErrorAnalytic2(true);
                    } else {
                        let result = AnalyticUtil.transformDataForPieChart(res.item?.analytics, selectedSocialMedia);
                        setDataPie(result);
                    }
                } else {
                    setErrorAnalytic2(true);
                    setDataPie([]);
                }
            })
            .catch((e) => {
                setDataPie([]);
                console.log(e)
            })
            .finally(() => {
                setLoading2(false);
            })
    }

    const analyticsPerTime = (date, platforms) => {
        setErrorAnalytic1(false);
        setLoading1(true);
        getAnalyticsPerTime(date[0], date[1], platforms)
            .then((res) => {
                // console.log(res)
                if (res && res?.ok) {
                    if (res.item?.error) {
                        setErrorAnalytic1(true);
                    } else {
                        let result = AnalyticUtil.clasificarConversacionesConRango(res.item, date[0], date[1])
                        // console.log("========================")
                        // console.log(result)
                        setDataBarAndLine(result);
                    }
                    // let result = AnalyticUtil.transformDataForBarAndLineChart(res.item);

                } else {
                    setErrorAnalytic1(true);
                    setDataBarAndLine([]);
                }
            })
            .catch((e) => {
                setErrorAnalytic1(true);
                setDataBarAndLine([]);
                console.log(e)
            }).finally(() => {
                setLoading1(false);
            })
    }


    const analyticsCounterMessagePerTime = (date, platforms) => {
        setErrorAnalytic3(false);
        setLoading3(true);
        getCounterMessagePerTime(date[0], date[1], platforms)
            .then((res) => {
                // console.log(res)
                if (res && res?.ok) {
                    if (res.item?.error) {
                        setErrorAnalytic3(true);
                    } else {
                        let result = AnalyticUtil.transformDataForBarChartAnalytic3(res?.item?.totals);
                        setDataCounterMessage(result);
                    }
                } else {
                    setErrorAnalytic3(true);
                    setDataCounterMessage([]);
                }
            })
            .catch((e) => {
                setErrorAnalytic3(true);
                setDataCounterMessage([]);
                console.log(e)
            }).finally(() => {
                setLoading3(false);
            })
    }

    const analyticsFlowPercentage = (date, platforms) => {
        setErrorAnalytic4(false);
        setLoading4(true);
        getFlowPercentage(date[0], date[1], platforms)
            .then((res) => {
                // console.log("NUEVO", res)
                if (res && res?.ok) {
                    if (res.item?.error) {
                        setErrorAnalytic4(true);
                    } else {
                        let result = AnalyticUtil.transformToTreeMapChartAnalytic4(res.item);
                        setDataTreeMap(result);
                    }
                } else {
                    setErrorAnalytic4(true);
                    setDataTreeMap({ nodes: [], links: [] });
                }
            })
            .catch((e) => {
                setErrorAnalytic4(true);
                setDataTreeMap({ nodes: [], links: [] });
                console.log(e)
            }).finally(() => {
                setLoading4(false);
            })
    }

    const analyticsMonthlyMessages = () => {
        setErrorAnalytic5(false);
        setLoading5(true);
        getMonthlyMessages()
            .then((res) => {
                // console.log(res)
                if (res && res?.ok) {
                    if (res.item?.error) {
                        setErrorAnalytic5(true);
                    } else {
                        setDataMonthlyMessages(res.item);
                    }
                } else {
                    setErrorAnalytic5(true);
                    setDataMonthlyMessages(undefined);

                }
            })
            .catch((e) => {
                setErrorAnalytic5(true);
                setDataMonthlyMessages(undefined);
                console.log(e)
            }).finally(() => {
                setLoading5(false);
            })
    }


    const search = () => {

        if (!fistSearch) {
            setFistSearch(true);
        }

        const startDate = moment(dateRange.startDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ') as any;
        const endDate = moment(dateRange.endDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ') as any;
        let range = [startDate, endDate];

        console.log(dateRange)

        const platforms = selectedSocialMedia.map((item) => item.value);

        analyticsPieChart(range, platforms);
        analyticsPerTime(range, platforms);
        analyticsCounterMessagePerTime(range, platforms);
        analyticsMonthlyMessages();
        analyticsFlowPercentage(range, platforms);


    }


    


    const handleDate = (startDate, endDate) => {
        setDateRange({ startDate, endDate })

        setDisabled(disabled => {
            if (startDate && endDate) {
                return false;
            } else {
                return true;
            }
        });
    };


    return (
        <CContainer>
            <CRow>
                <div className="pt-4 pb-5">
                    <span className="text-4xl" style={{ fontWeight: 500 }}>Dashboard</span>
                </div>
                {/* <pre>{JSON.stringify(selectedSocialMedia, null, 2)}</pre> */}

            </CRow>


            <CCardHeader className='mb-8'>
                <CRow>
                    <CCol xs={8} md={8} xl={8}>
                        <h4 id="title-page" className="card-title mb-0">

                        </h4>
                        {/* <div className="small text-medium-emphasis">Listado</div> */}
                    </CCol>
                    <CCol sm={10} className="d-none d-md-block">
                        {/* <Link to={'/dashboard/usuarios/crear'} >
                            <CButton color="primary" className="float-end"  >
                                <CIcon icon={cilPlus} />
                            </CButton>
                        </Link> */}
                        <div className="col-4 float-end me-3">
                        </div>
                    </CCol>
                </CRow>





                <div className="flex lg:flex-row items-center justify-around px-4 flex-col">
                    <CCol xs={12} md={6} className="flex flex-col justify-end min-h-[130px] mr-10">
                        <Typography variant="h6">Selecciona Redes Sociales</Typography>
                        <Autocomplete
                            multiple
                            id="tags-outlined"
                            options={optionsSocialMedia}
                            getOptionLabel={(option) => option.title}
                            filterSelectedOptions
                            onChange={handleChangeSocialMedia}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Redes Sociales"
                                    placeholder="Añadir"
                                />
                            )}
                        />
                    </CCol>

                    <CCol xs={12} md={6} className="flex flex-col justify-end min-h-[130px] ">
                        <Typography variant="h6">Selecciona una fecha</Typography>
                        <DatePickerRange defaultStartDate={dateRange?.startDate} defaultEndDate={dateRange?.endDate} handleDate={handleDate} />
                    </CCol>
                </div>




                <div className="w-full flex justify-center my-8">
                    <RippleButton className={clsx(`py-2 px-3 rounded-xl`, {
                    })}
                        style={{
                            backgroundColor: disabled ? COLORS.disabledColor : COLORS.principalColor,
                        }}
                        onClick={search}
                    >
                        <div className="flex items-center">
                            {/* <HiMagnifyingGlass className="mr-2"/> */}
                            <span>Buscar</span>
                        </div>
                    </RippleButton>
                </div>


            </CCardHeader>


            {fistSearch && (

                <CCardBody>

                    <div className="flex flex-col justify-between md:flex-row">
                        <Analytic2 loading={loading2} data={dataPie} error={errorAnalytic2} />

                        <div className="ml-0 flex-1 md:ml-4">
                            <Analytic1 loading={loading1} data={dataBarAndLine} error={errorAnalytic1} />
                        </div>
                    </div>


                    <div className="flex flex-col justify-between md:flex-row">
                        <div className="ml-0 flex-1">
                            <Analytic3 loading={loading3} error={errorAnalytic3} data={dataCounterMessage} />
                        </div>

                        <div className="ml-0 md:ml-4">
                            <Analytic5
                                loading={loading5}
                                error={errorAnalytic5}
                                totalInteractions={dataMonthlyMessages?.totalInteractions}
                                totalPurchasedMessages={dataMonthlyMessages?.totalPurchasedMessages}
                            />
                        </div>

                    </div>


                    <div className="w-full">
                        <Analytic4 loading={loading4} error={errorAnalytic4} data={dataTreeMap} />
                    </div>
                </CCardBody>
            )}





        </CContainer>
    );
};


export default HomePage;
