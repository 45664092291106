import axios from "axios";
import { Pagination } from "../../interfaces";


const apiUrl = process.env.REACT_APP_API_URL;


export class CRUDService<T> {
    constructor(private apiUrl: string, private route: string) { }

    add(body: T) {
        return axios.post(`${this.apiUrl}/api/bot/${this.route}/add`, body, { withCredentials: true });
    }

    getAll(pagination?: Pagination) {
        return axios.post(`${this.apiUrl}/api/bot/${this.route}`, { pagination }, { withCredentials: true });
    }

    getById(id: any) {
        return axios.get(`${this.apiUrl}/api/bot/${this.route}-${id}`, { withCredentials: true });
    }

    update(id: string | number, body: T) {
        return axios.post(`${this.apiUrl}/api/bot/${this.route}/update-${id}`, body, { withCredentials: true });
    }

    delete(idList: string[] | number[], action: boolean) {
        let params = { idList, action };
        return axios.post(`${this.apiUrl}/api/bot/${this.route}/delete`, params, { withCredentials: true });
    }
}
