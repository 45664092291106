import { CCard, CContainer, CRow, CSmartTable } from "@coreui/react-pro";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Pagination } from "../../../core/interfaces";
import { Util } from "../../../core/util/util";
import { AppliedFilters, Divider, NotificationTypeCheck, SearchTable } from "../../components";

import { Item } from "@coreui/react-pro/dist/components/table/types";
import { Chip, Stack, TablePagination } from "@mui/material";
import { HiOutlineEnvelope, HiOutlineEnvelopeOpen } from "react-icons/hi2";
import { BASE_ROUTE } from "../../../core/constants/route_name";
import { useNotification } from "../../../core/hooks";
import { NotificationRBC } from "../../../core/models/db";
import { LoginService } from "../../../core/services/login/login.service";
import { useActionBarStore, useNotificationStore, usePaginationStore } from "../../../core/store";
import { LoaderContext } from "../../components/loader/context/LoaderContext";

import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { RevisedNotificationCheck } from "../../components/table/multi-check-table/RevisedNotificationCheck";
import { RippleButton } from "../../components/ui/ripple-button/RippleButton";
import { NotificationTypeColumn } from "./components/NotificationTypeColumn";
import moment from "moment";

const INIT_PAGINATION: Pagination = { page: 0, itemsPerPage: 10, filters: {} };
const INIT_ALL_ROW: Pagination = { page: 0, itemsPerPage: 0, filters: {} };

const CLASS_REGULAR_ROW = "check-reg-" + Util.getUniqueId(1);
const CLASS_PRINCIPAL_ROW = "check-prin-" + Util.getUniqueId(1);




const NotificationPage = () => {

  const navigate = useNavigate();

  const { reset, loadActionBar } = useActionBarStore(state => state);

  const [identity, setIdentity] = useState(undefined);
  const { loading, handleLoading, changeStyle } = useContext(LoaderContext);


  const { getNotification, getAllId, updateNotification } = useNotification();

  const [body, setBody] = useState<NotificationRBC[]>([]);


  const {
    notificationSelected,
    lastNotificationRevised,
    allRevised,
    setLastNotificationRevised,
    setAllRevised,

    setNotificationSelected
  } = useNotificationStore(state => state);

  useEffect(() => {

    if (lastNotificationRevised) {

      setBody(
        bodyPrev => bodyPrev?.map((item: any) => {
          if (item.id === lastNotificationRevised?.id) {
            console.log("todo bien?")
            item.revised = true;
            item._props = { className: `${CLASS_REGULAR_ROW}` };
          }
          return item
        })
      );

      setLastNotificationRevised(undefined);
    }

    if (allRevised) {
      setBody(
        bodyPrev => bodyPrev?.map((item: any) => {
          item.revised = true;
          item._props = { className: `${CLASS_REGULAR_ROW}` };
          return item
        }
        )
      );
      setAllRevised(false);
    }



  }, [lastNotificationRevised, allRevised]);

  const initialPaginationConfig = useMemo(() => ({
    page: 0,
    itemsPerPage: 10,
    filters: notificationSelected ? { id: { value: notificationSelected.id } } : {},
    orderBy: null,
  }), []);

  const { pagination, setPagination, resetFilterRaws, addFilterRawExclusive } = usePaginationStore();

  const [init, setInit] = useState<boolean>(false);

  useEffect(() => {


    setPagination(initialPaginationConfig)
    if (!notificationSelected) {
      resetFilterRaws();
    } else {
      setNotificationSelected(undefined);
    }
    setInit(true);


  }, [initialPaginationConfig]);








  // const { selected, handleSingleCheck, cleanSelected } = useCheckTable(
  //   {
  //     body,
  //     // itemsPerPage: pagination.itemsPerPage,
  //     pagination: pagination,

  //     reasonForDesactivation: [],
  //     classToFindPrincipalRow: CLASS_PRINCIPAL_ROW,
  //     classToFindRegularRow: CLASS_REGULAR_ROW,
  //     getAllId: getAllId,
  //   });


  useEffect(() => {

    console.log("filters", pagination?.filters)
    if (!pagination || !init) return;

    resetDetails();


    initPage({
      ...pagination,
    })
  }, [!!init, pagination?.page, pagination?.itemsPerPage, JSON.stringify(pagination?.orderBy), JSON.stringify(pagination?.filters)]
  );

  useEffect(() => {
    // cleanSelected();

  }, [pagination?.itemsPerPage, JSON.stringify(pagination?.filters)]);



  useEffect(() => {
    if (reset) {
      resetDetails();

      initPage(initialPaginationConfig);
      // cleanSelected();
    }
  }, [reset]);


  const initPage = (paginationDefault = pagination) => {
    (async () => {
      // changeStyle({ backgroundColor: "#fff" })
      handleLoading(true);
      // Cargamos las acciones para el ActionBar

      // const token = await LoginService.getTokenAsync();
      const id = await LoginService.getIdentityAsync();
      setIdentity(id);
      // setToken(token)

      const response = await getNotification({ ...paginationDefault, page: paginationDefault.page + 1 });
      console.log("response", response)
      if (response && response.ok) {
        let rows = response.item.rows;

        for (let i = 0; i < response.item.rows.length; i++) {

          const row = rows[i];

          rows[i].title = row?.title || '';
          rows[i].email = row.email || '';

          // rows[i].priv_role = rows[i]?.roles ? rows[i]?.roles.title : '';
          // Hay que agregar estos dos para añadir los checks
          rows[i].priv_check = "";
          rows[i].show_details = "";
          rows[i]._props = { className: `${CLASS_REGULAR_ROW} ${row.revised ? '' : 'table-bg-notification'}` };

          rows[i]._cellProps = { title: { className: 'align-middle' } }

        }

        setBody(rows);
        setPagination({
          ...paginationDefault,
          totalItems: response.item.pagination.totalItems,
          totalPages: response.item.pagination.totalPages,
        })

        setTimeout(() => {
          // loadActionBar({ delete: id.role === "ROLE_ADMIN", table: "users", count: selected.length, items: selected });
          handleLoading(false);
        }, 200)
      } else {
        console.warn("Ha ocurrido un problema al devolver los usuarios")
      }
    })()
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPagination({
      ...pagination,
      page: newPage
    })
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPagination({
      ...pagination,
      page: 0,
      itemsPerPage: parseInt(event.target.value, 10)
    })
  };



  const [details, setDetails] = useState([])
  const toggleDetails = async (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);

    // Encuentra el ítem en el cuerpo por ID y actualiza su estado `revised`
    const itemIndex = body.findIndex(i => i.id === index);
    if (itemIndex !== -1 && body[itemIndex].revised == false) {
      const newBody: any = [...body];
      newBody[itemIndex].revised = true;
      // Actualiza la clase CSS también, si es necesario
      newBody[itemIndex]._props = { className: `${CLASS_REGULAR_ROW}` }; // Asegúrate de ajustar según tu lógica de clases
      setBody(newBody);

      // Llama a la función para actualizar la notificación como revisada
      await updateNotification({ id: index, revised: 1 });

      setLastNotificationRevised(body[itemIndex]);
    }
  };

  const resetDetails = () => {
    setDetails([])
  }

  return (
    <CContainer>
      <CRow>
        <div className="pt-4 pb-5">
          <span className="text-4xl" style={{ fontWeight: 500 }}>Notificaciones</span>
        </div>

      </CRow>
      <CCard className="rounded-xl p-3">
        <CRow>
          <SearchTable nameColumn={["email"]} label={["Email"]} />
        </CRow>
        <Divider color="" marginX={-16} />
        <CRow>
          <AppliedFilters />
        </CRow>
        <Divider marginX={-16} />
        <CRow >
          <div className="flex items-center">
            <NotificationTypeCheck multiSelect={true} nameTable="" nameColumn="notificationType" label="Tipo" />
            <RevisedNotificationCheck multiSelect={false} nameTable="" nameColumn="revised" label="Visto" />
          </div>
          {/* <RoleCheck multiSelect={false} nameTable="role" nameColumn="title" label="Rol" /> */}


        </CRow>
        <Divider marginX={-16} />
        <CRow>

          {loading && (
            <div className="flex justify-center items-center h-full mt-5">
              <div className="flex justify-center items-center">
                <div className="animate-spin rounded-full h-[32px] w-[32px] border-t-2 border-b-2 border-gray-900"></div>
              </div>
            </div>
          )}

          {/* LISTADO */}
          {/* <pre>{JSON.stringify(itemsFiltered, null, 2)}</pre> */}
          {body && body.length > 0 && pagination && pagination.totalPages && (

            //   className={width < 780 ? 'table-responsive' : ''}
            <div style={{ visibility: loading ? 'hidden' : 'visible' }}>


              <CSmartTable
                tableHeadProps={{
                  color: "#dedede",


                }}
                tableProps={{

                  responsive: "md",
                  bordered: false,
                  borderless: true,
                  // striped: true,
                  hover: true,
                  // color: "dark",

                }}
                items={body}

                columns={[
                  // {
                  //   key: "priv_check",
                  //   label: "",
                  //   sorter: false,
                  //   filter: false,
                  //   _props: { className: CLASS_PRINCIPAL_ROW },
                  //   _style: { backgroundColor: '#f1f1f1', width: '5%' },

                  // },
                  {
                    key: 'show_details',
                    label: '',
                    _style: { backgroundColor: '#f1f1f1', width: '70px' },
                    filter: false,
                    sorter: false,

                  },

                  {
                    key: "title",
                    label: "Asunto",
                    _style: { backgroundColor: '#f1f1f1' },
                  },
                  {
                    key: "notificationType",
                    label: "Tipo",
                    _style: {
                      backgroundColor: '#f1f1f1', width: '20%',
                    },
                  },
                  {
                    key: "revised",
                    label: "Revisado",
                    _style: { backgroundColor: '#f1f1f1', width: '14%', textAlign: 'center' },
                    sorter: false,

                  },
                  {
                    key: "createdDate",
                    label: "Creado",
                    _style: { backgroundColor: '#f1f1f1', width: '14%', textAlign: 'center' },
                    sorter: true,
                  },

                ]}

                columnSorter={{
                  external: true
                }}

                itemsPerPage={pagination.itemsPerPage}

                clickableRows

                onRowClick={(item: Item, index: number, columnName: string, event) => {
                  let target = typeof event !== 'boolean' ? event.nativeEvent.target as HTMLElement : event;
                  let value = typeof target === 'boolean' ? target : target.tagName;

                  if (typeof target !== 'boolean' && value === "INPUT") {
                    //    Clica al check

                  } else if (typeof target !== 'boolean' && value === "TD") {
                    //    Console ir al detalle
                    // if (selected && selected.length > 0) {
                    //   handleSingleCheck(null, item.id, item);
                    // } else {
                    // goToUpdate(item as NotificationRBC)
                    // }
                  }
                }}


                scopedColumns={{
                  notificationType: (item) => {
                    return (
                      <NotificationTypeColumn item={item} />
                    )
                  },
                  revised: (item) => {
                    return <td className="text-center align-middle">
                      <div className="flex justify-center items-center">
                        {item.revised ? <HiOutlineEnvelopeOpen size={16} /> : <HiOutlineEnvelope size={16} />}
                      </div>
                    </td>
                  },
                  createdDate: (item) => {
                    return (
                      <td className="text-center align-middle">
                        {moment(item.createdDate).format("DD/MM/YYYY HH:mm")}
                      </td>
                    )
                  },
                  show_details: (item) => {
                    return (
                      <td className="py-2 text-center align-middle">
                        <RippleButton
                          className="text-gray-400 rounded-2xl w-[29px] h-[29px]"
                          rippleColor="#757575"
                          onClick={async () => {
                            await toggleDetails(item.id);
                          }}
                        >
                          <span className="text-black">{details.includes(item.id) ? <IoEyeOutline size={20} /> : <IoEyeOffOutline size={20} />}</span>
                        </RippleButton>
                      </td>
                    )
                  },
                  details: (item, number) => {

                    return (
                      <>
                        <LeadDetails item={item} details={details} />
                        {/* {details.includes(item.id) && (
                          <>
                            <div className="px-2 py-4" dangerouslySetInnerHTML={{ __html: item.message.replace(/\n/g, '<br />') }}>
                            </div>

                            {item.leadJson && Object.keys(item.leadJson).length > 0 && (
                              <div></div>
                            )}
                          </> */}

                      </>
                      // <CCollapse visible={details.includes(item.id)}>
                      //   <div className="p-3">
                      //     {/* <h4>{item.title}</h4>
                      //       <p className="text-title">
                      //           {moment(item.createdDate).format("DD/MM/YYYY")}
                      //       </p> */}
                      //       asdasdasdasdasd
                      //     {userSocialMedia && (
                      //       <table style={{ width: '50%', borderCollapse: 'collapse' }}>
                      //         <tbody>
                      //           <tr>
                      //             {/* <td style={{ fontWeight: 'bold' }}>Título email</td> */}
                      //             <td style={{ fontWeight: 'bold' }}>Plataforma</td>
                      //             <td style={{ fontWeight: 'bold' }}>Usuario</td>
                      //           </tr>
                      //           <tr>
                      //             {/* {item && item.title &&
                      //                       <td style={{ color: '#999' }}>{item.title}</td>
                      //                   } */}
                      //             {userSocialMedia.platform &&
                      //               <td style={{ color: '#999' }}>{userSocialMedia.platform}</td>
                      //             }
                      //             {userSocialMedia.idUserPlatform &&
                      //               <td style={{ color: '#999' }}>{userSocialMedia.idUserPlatform}</td>
                      //             }
                      //           </tr>
                      //         </tbody>
                      //       </table>
                      //     )}

                      //     <div style={{ marginTop: 50 }}>
                      //       <div className="clearfix">
                      //         {/* <CustomerInformation jsonLead={notification.jsonLead} /> */}
                      //       </div>

                      //     </div>
                      //   </div>
                      // </CCollapse>
                    )
                  },


                }}

                onSelectAll={() => {
                  // console.log("calling all of ids")
                }}
                onSelectedItemsChange={(items) => {
                  // console.log("items that you selected", items)
                }}


                onSorterChange={(value) => {
                  // console.log("what is value?", value)
                  if (value.state === 0) {
                    setPagination({
                      ...pagination,
                      //   relatedTableOrder: null,
                      orderBy: null
                    })
                  } else if (value.column === "priv_role") {

                    // } 
                    // else if (value.column === "username") {
                    //   setPagination({
                    //     ...pagination,
                    //     // relatedTableOrder: null,

                    //     orderBy: {
                    //       field: value.column,
                    //       order: value.state as any,
                    //       relation: "webAppAuth"
                    //     }
                    //   })

                  } else {
                    setPagination({
                      ...pagination,
                      // relatedTableOrder: null,

                      orderBy: {
                        field: value.column,
                        order: value.state as any
                      }
                    })
                  }



                }}
              />
              {pagination && pagination.totalItems > 0 && (
                <TablePagination
                  component="div"
                  count={pagination.totalItems}
                  page={pagination.page}
                  rowsPerPage={pagination.itemsPerPage}

                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage="Registros por página"
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                />

              )}

            </div>
          )}




        </CRow>
      </CCard>
    </CContainer >
  )
}


const LeadDetails = ({ item, details }) => {
 
  const leadData = item?.leadJson || {};
  const keys = Object.keys(leadData).filter(key => key !== 'comment' && key !== 'project_idea');
  if (leadData.comment) {
    keys.push('comment');
  }
  if (leadData.project_idea) {
    keys.push('project_idea');
  }

  const renderValue = (key, value) => {
    if (key === 'phone') {
      return <a href={`tel:${value}`} className="text-white underline">{value}</a>;
    } else if (key === 'email') {
      return <a href={`mailto:${value}`} className="text-white underline">{value}</a>;
    } else {
      return value;
    }
  };

  return (
    <>
      {details.includes(item?.id) && (
        <>
          <div className="px-2 py-4" dangerouslySetInnerHTML={{ __html: item.message.replace(/\n/g, '<br />') }}></div>

          {item.leadJson && Object.keys(item.leadJson).length > 0 && (
            <div className="flex flex-wrap gap-2 mt-4 px-4 pb-4">
              {keys.map((key) => (
                <div key={key} className="bg-principal text-white px-3 py-1 rounded-md md:rounded-full flex flex-col md:flex-row">
                  <span className="font-bold mr-2">{key}:</span>
                  <span className="break-all">{renderValue(key, item.leadJson[key])}</span>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </>
  );
};


export default NotificationPage;

