import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { classNames } from '../../utils/classes-css';
import { useDragDrop } from './hooks/useDragDrop';
import { useAddFileInput } from './hooks/useAddFileInput';
import { FileItem } from './model/FileItem';
import { formatFileAllowedToExt, TypeAlloweds } from './util/format-types';
import { isImage, isVideo } from './util/validators';
import { FileService } from './services/file.service';
import { LoginService } from '../../../core/services/login/login.service';
import { UploadFile } from './model/UploadFile';

import { FileItemObservableService } from './services/rxJS/fileitem-observable.service';


import { Tooltip } from 'react-tooltip';
import CircularProgressWithLabel from '../../components_OLD/circularProgress/CircularProgress';
import { Util } from '../../../core/util/util';
import { cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { AxiosProgressEvent } from 'axios';

const MAX_BEFORE_TOOLTIP = 15;
// const apiUrl = process.env.REACT_APP_API_URL;

export interface DragDropProps {
    serviceAddFile?: (object: UploadFile, onUploadProgress: (progressEvent: AxiosProgressEvent) => void) => Promise<any>;
    apiUrl?: string;

    id: string;
    disabled: boolean;
    directoryNameList: string[];
    maxSizeMB: number;
    typeAllowed: TypeAlloweds[];
    valueDefault?: string;
    /** Avatar, un vídeo, un archivo... */
    isOnlyOneFile?: boolean;
    /** Si quieres mostrar la lista de archivos subidos en este componente */
    showFileList?: boolean,
    /** Dar estilo externo*/
    styleDD?: React.CSSProperties;
    /** Añadir clases externas */
    classDD?: string;


    specialCase?: boolean;


    handleFiles?: (value: any) => void;

}

export interface ErrorDragDropProps {
    size?: boolean;
    type?: boolean;
}


const DragDrop = forwardRef((props: DragDropProps, ref: React.Ref<any>) => {

    const { id, maxSizeMB, disabled, typeAllowed, directoryNameList,
        isOnlyOneFile = false,
        valueDefault,
        styleDD = {},
        classDD = "",
        showFileList = true,
        specialCase = false,


        handleFiles,
        serviceAddFile = FileService.add,
        apiUrl = process.env.REACT_APP_API_URL
    } = props;

    const isInitialMount = useRef(true); // Referencia para verificar si es la inicialización del componente


    const [error, setError] = useState<ErrorDragDropProps>({ size: false, type: false });
    const [loading, setLoading] = useState<boolean>(false);
    const [fileItemList, setFileItemList] = useState<FileItem[]>([]);
    const [uploadProgress, setUploadProgress] = useState(0);

    useEffect(() => {
        if (valueDefault && !Array.isArray(valueDefault)) {
            setFileItemList([
                {
                    id: "default-img",
                    pathRelative: valueDefault,
                    pathAbsolute: apiUrl + valueDefault,
                    name: Util.getFileName(valueDefault)
                }
            ])
        } else if (valueDefault === "") {
            setFileItemList([]);
        } else {
            console.error("El valor por defecto no existe o es un array", valueDefault)
        }

    }, [valueDefault]);

    useEffect(() => {
        (async () => {
            if (isInitialMount.current) {
                isInitialMount.current = false;
            } else {
                await handleUpload(fileItemList);

                if (!specialCase || !handleFiles) {
                    const fileObsService = FileItemObservableService.instance;
                    fileObsService.sendFileItemList({ id, fileItemList });
                } else if (specialCase && handleFiles) {
                    // NUEVO 16/10/23
                    // NUEVO NUEVO
                    handleFiles(fileItemList);
                }
            }
        })();
    }, [fileItemList])

    // Referencia externa

    // Ahora asignamos la función deleteFileItem a la ref:
    React.useImperativeHandle(ref, () => ({
        deleteFileItem
    }));


    /** Se manipula el error mostrado. */
    const handleError = (key, value: boolean) => {
        setError(error => { return { ...error, [key]: value } });
    }


    /** Se manipula el listado de archivos a añadir */
    const handleFileItem = (files: FileItem[]) => {
        if (isOnlyOneFile && files.length > 0) files = [files.pop()];
        setFileItemList(files);
    }

    const deleteFileItem = (e, inx: number, force = false) => {
        if (disabled && !force) return;

        let fileItemListAux = [...fileItemList];
        fileItemListAux.splice(inx, 1);
        setFileItemList(fileItemListAux);

        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

    }

    // const deleteFileItem

    const handleUpload = async (fileItemList: FileItem[]) => {

        try {
            if (fileItemList.length == 0) return;
            setLoading(true);
            for (const [i, fileItem] of fileItemList.entries()) {
                if (!fileItem.pathAbsolute) {
                    if (fileItemList.length - 1 === i) {
                        await addFile({ file: fileItem.file, directoryNameList }, fileItem);
                        setLoading(false);
                    } else {
                        addFile({ file: fileItem.file, directoryNameList }, fileItem);
                    }
                } else {
                    if (fileItemList.length - 1 === i) {
                        setLoading(false);
                    }
                }
            }
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    }

    const addFile = (uploadFile: UploadFile, fileItem: FileItem) => {
        return new Promise<any>(async (res, rej) => {
            // const token = await LoginService.getTokenAsync();
            // FileService.add(uploadFile,
            serviceAddFile(uploadFile,
                (progressEvent) => {
                    const percentLoaded = Math.round(
                        (progressEvent.loaded / progressEvent.total) * 100
                    );
                    setUploadProgress(percentLoaded);

                })
                .then(({ data }) => {
                    setUploadProgress(0);

                    if (data.ok) {
                        const pathRelative = `/${data.item[0].destination}/${data.item[0].filename}`
                        fileItem.pathRelative = pathRelative
                        fileItem.pathAbsolute = apiUrl + pathRelative
                        res("")
                    } else {
                        res(undefined)
                    }
                })
                .catch(e => {
                    rej(e)
                })
        })
    }


    const inputDragDrop = useRef<HTMLInputElement>(null);

    const {
        isOverDrop,
        handleDrop,
        handleDragOver,
        handleDragLeave,
    } = useDragDrop({ fileItemList, maxSizeMB, typeAllowed, handleError, handleFileItem });

    const {
        onAddFileInput
    } = useAddFileInput({
        reference: inputDragDrop,
        maxSizeMB,
        fileItemList,
        handleError,
        handleFileItem
    });


    // const test = () => {
    //     console.log(inputDragDrop.current.value)
    //     inputDragDrop.current.value = "";
    //     console.log(inputDragDrop.current.value)
    // }





    return (
        <div className={classNames("drop-zone-wp", classDD)} >
            {/* <pre>[TEST] Errores {JSON.stringify(error, null, 2)}</pre> */}
            {/* <pre>[TEST] Items añadidos {JSON.stringify(fileItemList, null, 2)}</pre> */}
            <input hidden multiple accept={typeAllowed.toString()} ref={inputDragDrop} type="file" onChange={(e) => onAddFileInput(e)} />
            <div
                className={classNames("drop-zone", isOverDrop ? "file-over" : null)}
                style={styleDD}
                // Disabled o si está activado el modo avatar no se puede cambiar si ya hay una imagen
                onDrop={(e) => !disabled && ((isOnlyOneFile && fileItemList.length === 0) || !isOnlyOneFile) ? handleDrop(e) : null}
                onDragOver={(e) => !disabled && ((isOnlyOneFile && fileItemList.length === 0) || !isOnlyOneFile) ? handleDragOver(e) : null}
                onDragLeave={(e) => !disabled && ((isOnlyOneFile && fileItemList.length === 0) || !isOnlyOneFile) ? handleDragLeave(e) : null}
                onClick={(e) => !disabled && ((isOnlyOneFile && fileItemList.length === 0) || !isOnlyOneFile) ? inputDragDrop.current.click() : null}
            >

                {loading && (
                    <div className="d-flex justify-content-center align-items-center"
                        style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
                        <CircularProgressWithLabel value={uploadProgress} />
                    </div>


                )}

                {/* Si solo se va a mostrar un archivo (una imagen o un vídeo) */}
                {(isOnlyOneFile && fileItemList.length && !loading)
                    ? isImage(fileItemList[0].name)
                        ?
                        (
                            <div>
                                <button className={classNames("btn btn-danger btn-delete btn-sm d-flex justify-content-center align-items-center btn-circle ", disabled && 'd-none')}
                                    onClick={(e) => deleteFileItem(e, 0)}>
                                    <CIcon icon={cilTrash} size='xl' />
                                </button>
                                <img src={fileItemList[0].pathAbsolute} alt="imagen" />
                            </div>
                        )
                        : isVideo(fileItemList[0].name)
                            ?
                            (
                                <div>
                                    <button className={classNames("btn btn-danger btn-delete btn-sm d-flex justify-content-center align-items-center btn-circle ", disabled && 'd-none')}
                                        onClick={(e) => deleteFileItem(e, 0)}>
                                        <CIcon icon={cilTrash} size='xl' />
                                    </button>
                                    <video className="img-fluid" src={fileItemList[0].pathAbsolute} controls></video>
                                </div>
                            )
                            :
                            // Casos que sean documentos
                            (
                                <div>
                                    <button className={classNames("btn btn-danger btn-delete btn-sm d-flex justify-content-center align-items-center btn-circle ", disabled && 'd-none')}
                                        onClick={(e) => deleteFileItem(e, 0)}>
                                        <CIcon icon={cilTrash} size='xl' />
                                    </button>
                                    <div>{fileItemList[0].name}</div>
                                </div>
                            )
                    : null
                }

                {/* No está cargando y son varios archivos o es un único archivo y no tiene elementos subidos */}
                {!loading && (!isOnlyOneFile || (fileItemList.length === 0 && isOnlyOneFile)) && (
                    <div>
                        <div className="my-3 text-dark text-center">
                            <span className="text-muted text-dd">Tamaño máximo</span> {maxSizeMB} <span className="text-muted text-dd">Mb</span>
                        </div>
                        <div className="my-3 text-dark text-center">
                            {formatFileAllowedToExt(typeAllowed).length > MAX_BEFORE_TOOLTIP
                                ? <span id="id-tooltip-types"><span className="text-muted text-dd">Tipos permitidos</span>
                                    {formatFileAllowedToExt(typeAllowed)
                                        .toString()
                                        .substring(0, MAX_BEFORE_TOOLTIP)}...</span>
                                : <span><span className="text-muted text-dd">Tipos permitidos</span> {formatFileAllowedToExt(typeAllowed).toString()}</span>
                            }

                        </div>

                    </div>
                )}

            </div>

            {/* Si son varios archivos */}
            {
                !isOnlyOneFile && showFileList && fileItemList.length > 0 && (
                    <div>
                        {fileItemList.map((fileitem, index) => {

                            return (
                                <div key={index}>
                                    <button className={classNames("btn btn-sm", disabled && 'd-none')}
                                        onClick={(event) => deleteFileItem(event, index)}>Eliminar</button>
                                    <a href={fileitem.pathAbsolute} target="_blank"
                                        className="btn btn-primary btn-download-pdf">
                                        <span className="mr-2">{fileitem.name} </span>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            width="20" height="20" viewBox="0 0 20 20">
                                            <g>
                                                <path fill="#ffffff"
                                                    d="M15.667 13.167v2.5h-10v-2.5H4v2.5a1.672 1.672 0 0 0 1.667 1.667h10a1.672 1.672 0 0 0 1.667-1.667v-2.5zm-.833-3.333l-1.176-1.176-2.158 2.15V4H9.833v6.808l-2.158-2.15L6.5 9.833 10.667 14z"
                                                    transform="translate(3.333 3.333) translate(-4 -4)" />
                                            </g>
                                        </svg>
                                    </a>

                                </div >
                            )
                        })}
                    </div >
                )
            }

            {/* Errores que pueden ser mostrados */}
            {error.size &&
                <div className='alert alert-danger' role='alert'>Uno de los archivos tiene un tamaño mayor del permitido. <div><strong>Tamaño máximo permitido {maxSizeMB} Mb</strong></div></div>}
            {error.type &&
                <div className='alert alert-danger' role='alert'>Uno de los archivos no es de un tipo permitido. <div><strong>Tipos permitidos {formatFileAllowedToExt(typeAllowed)}</strong></div></div>}


            <Tooltip style={{ width: 210 }} anchorSelect="#id-tooltip-types"
                content={formatFileAllowedToExt(typeAllowed).toString()} place="bottom" />
        </div >
    )
})


DragDrop.displayName = "DragDrop";
export default DragDrop
