import BotMessage from './BotMessage';
import { CardPayload } from './card-bot';
import { QuickReplyPayload } from './quickreply-bot';
import { TextPayload } from './text-bot';

export interface BotPayload {
    item: TextPayload | CardPayload | QuickReplyPayload;
    user: {
        userType: "bot" | "human";
    };
    notification: {
        notificationType: any,
        hour: string
    };
}

interface HandleBotPayload {
    botPayload: BotPayload[];
    rrss: "telegram" | "facebook" | "instagram" | "web" | "whatsapp";
}

const HandleBotConversationPayload = ({ botPayload, rrss }: HandleBotPayload) => {

    let parsedBotPayload = [];
    if (typeof botPayload === 'string') {
        try {
            const potentialArray = JSON.parse(botPayload);
            if (Array.isArray(potentialArray)) {
                parsedBotPayload = potentialArray;
            } else {
                console.error('botPayload es un string pero no un array JSON válido.');
                // Manejar el caso o asignar un valor predeterminado a parsedBotPayload si es necesario
            }
        } catch (e) {
            console.error('Error al parsear botPayload:', e);
            // Manejar el error o asignar un valor predeterminado a parsedBotPayload
        }
    } else if (Array.isArray(botPayload)) {
        parsedBotPayload = botPayload;
    } else {
        console.error('botPayload no es ni un string ni un array.');
        // Manejar este caso como se desee, parsedBotPayload ya está inicializado como array vacío
    }


    return (

        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            {parsedBotPayload.map((bot, i) => (
                <BotMessage key={i} {...bot} rrss={rrss.toLowerCase()} />
            ))}
        </div>
    )
};


export default HandleBotConversationPayload;
