import axios, { AxiosResponse } from 'axios';
import { Pagination } from '../../../interfaces';
import { BannedUser } from '../../../models/db';
import { CRUDService } from '../crud-service.service';
import { WebAllowed } from '../../../models/db/web-allowed/webAllowed';
import { ReasonForDeactivationProps } from '../../../hooks';


const apiUrl = process.env.REACT_APP_API_URL;
const nameRoute = 'webAllowed';

export class WebAllowedService extends CRUDService<WebAllowed> {

    constructor() {
        super(apiUrl, nameRoute);
    }

    getAllId(reasonForDesactivation: ReasonForDeactivationProps[]) {
        return axios.post(`${apiUrl}/api/bot/${nameRoute}-all-id`, { reasonForDesactivation }, { withCredentials: true });
    }

}
