import axios from "axios";
import { User } from "../../../models/db/user";
import { Pagination } from "../../../interfaces/pagination";
import { ReasonForDeactivationProps } from "../../../hooks/check-table/useCheckTable";


const apiUrl = process.env.REACT_APP_API_URL;

export class ConversationService {

    constructor() { }

    // static get(id: any) {
    //     return axios.get(`${apiUrl}/api/users/${id}`, { withCredentials: true });
    // }


    static getAllMinified(pagination?: Pagination) {
        return axios.post(`${apiUrl}/api/bot/conversations-minified`, { pagination }, { withCredentials: true });
    }

    static getAllCompleted(pagination?: Pagination) {
        return axios.post(`${apiUrl}/api/bot/conversations-completed`, { pagination }, { withCredentials: true });
    }

    static getById(id: any) {
        return axios.get(`${apiUrl}/api/bot/conversations-${id}`, { withCredentials: true });
    }

    static getByIdSession(idSession: any) {
        return axios.get(`${apiUrl}/api/bot/conversations/session-${idSession}`, { withCredentials: true });
    }

    // Analytics


    // static getAllId(token, reasonForDesactivation: ReasonForDeactivationProps[] = []) {
    //     let object = { reasonForDesactivation };

    //     return axios.post(`${apiUrl}/api/users/all-id`, object, { headers });
    // }



}
