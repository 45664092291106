// import { forwardRef, useState } from "react";
// import { DropdownItemCheck } from "../../../../../core/interfaces/dropdown/dropdown-item-check";
// import { CFormCheck } from "@coreui/react-pro";

// interface DropdownItemProps {
//     item: DropdownItemCheck;
//     multiSelect?: boolean;

//     handleVisible: (visible: boolean) => void;
//     handleSelection: (item: { title: string, value: any }, value: boolean) => any;
// }

// export const DropItemCheck = forwardRef<HTMLDivElement, DropdownItemProps>((
//     {
//         item,
//         multiSelect,
//         handleSelection,
//         handleVisible
//     }, ref) => {

//     const { title, value, checked } = item;

//     const [checkecLocal, setCheckedLocal] = useState<boolean>(checked);

//     // Controlador de clic que ignora los clics del botón derecho
//     const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {

//         const newValueCheck = !checkecLocal;
//         setCheckedLocal(newValueCheck);

//         handleSelection({ title, value }, newValueCheck);
//         // No se cierra por seleccionar un check
//         if (!multiSelect) {
//             // No hacer nada si es clic derecho
//             if (event.button === 2) {
//                 return;
//             }
//             // Cerrar el menú para clics que no son del botón derecho
//             handleVisible(false);
//         }

//     }

//     return (
//         <div ref={ref} onClick={handleClick} className="w-full cursor-pointer">
//             <div className="flex items-center text-black my-1 pl-3 pr-2 h-[40px] rounded-lg hover:bg-gray-100 transition duration-300 ease-in-out">
//                 <div className="flex items-center no-select">
//                     <CFormCheck style={{ width: 20, height: 20 }} checked={checked} onChange={() => { }} />
//                     {title && <span className="capitalize pl-2 pt-1">{title}</span>}
//                 </div>
//             </div>
//         </div>

//     );
// });

// DropItemCheck.displayName = "DropItemCheck";

import { forwardRef, useState } from "react";
import Checkbox from '@mui/material/Checkbox';
import { DropdownItemCheck } from "../../../../../core/interfaces/dropdown/dropdown-item-check";
import { FormControlLabel } from "@mui/material";

interface DropdownItemProps {
    item: DropdownItemCheck;
    multiSelect?: boolean;
    handleVisible: (visible: boolean) => void;
    handleSelection: (item: { title: string, value: any }, value: boolean) => any;
}

export const DropItemCheck = forwardRef<HTMLDivElement, DropdownItemProps>((
    {
        item,
        multiSelect,
        handleSelection,
        handleVisible
    }, ref) => {

    const { title, value, checked } = item;
    const [checkedLocal, setCheckedLocal] = useState<boolean>(checked);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        // No hacer nada si es clic derecho
        if (event.button === 2) {
            return;
        }

        const newValueCheck = !checkedLocal;
        setCheckedLocal(newValueCheck);

        handleSelection({ title, value }, newValueCheck);

        // No se cierra por seleccionar un check si es multiselect
        if (!multiSelect) {
            handleVisible(false);
        }
    };

    return (
        <div ref={ref} onClick={handleClick} className="w-full cursor-pointer">
            <div className="flex items-center text-black my-1 pl-5 pr-2 h-[40px] rounded-lg hover:bg-gray-100 transition duration-300 ease-in-out">
                <div className="flex items-center no-select">
                    <FormControlLabel
                        control={
                        
                            <Checkbox
                                checked={checkedLocal}
                                // onChange={(e: any) => handleClick(e.target.checked)}
                                color="primary"
                                inputProps={{ 'aria-label': 'controlled' }}
                                style={{ padding: 0 }} // Ajusta el padding según sea necesario
                            />
                        }
                        label=""
                    />

                    {title && <span className="capitalize pt-1">{title}</span>}
                </div>
            </div>
        </div >
    );
});

DropItemCheck.displayName = "DropItemCheck";
