import moment from 'moment';

export const customRangeShortcuts = [
    {
        label: 'Para hoy',
        dates: [moment().startOf('day'), moment().endOf('day')],
    },
    {
        label: 'Ayer',
        dates: [moment().subtract(1, 'day').startOf('day'), moment().subtract(1, 'day').endOf('day')],
    },
    {
        label: 'Últimos 7 días',
        dates: [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
    },
    {
        label: 'Últimos 30 días',
        dates: [moment().subtract(29, 'days').startOf('day'), moment().endOf('day')],
    },
    {
        label: 'Este mes',
        dates: [moment().startOf('month'), moment().endOf('month')],
    },
    {
        label: 'Anterior mes',
        dates: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    },
    {
        label: 'Último año',
        dates: [moment().subtract(1, 'year').startOf('day'), moment().endOf('day')],
    },
    {
        label: 'Este año',
        dates: [moment().startOf('year'), moment().endOf('year')],
    },
];


// Si necesitas obtener las fechas por defecto para "Este mes", puedes usar:
export const START_DATE_DEFAULT = customRangeShortcuts.find(r => r.label === 'Este mes').dates[0];
export const END_DATE_DEFAULT = customRangeShortcuts.find(r => r.label === 'Este mes').dates[1];
