import { Box, Button, Chip, Divider, List, ListItem } from "@mui/material";
import { customRangeShortcuts } from "../custom-ranges";
import { RippleButton } from "../../ui/ripple-button/RippleButton";

export const CustomRangeShortcuts = (props) => {
    const { setDateRange } = props;

    const shortcutActions = customRangeShortcuts;

    const handleClickShortcut = (dates) => {
        setDateRange(dates);
    };

    return (

        <Box

            sx={{
                gridRow: 2,
                gridColumn: 1,
                paddingTop: 4,
            }}
        >
            <List
                dense
                sx={(theme) => ({
                    display: 'flex flex-col',
                    px: theme.spacing(2),
                    '& .MuiListItem-root': {
                        pt: 0,
                        pl: 0,
                        pr: theme.spacing(1),
                    },
                })}
            >
                {shortcutActions.map((item) => {
                    return (
                        <ListItem key={item.label} onClick={() => handleClickShortcut(item.dates)}>
                            <span className="cursor-pointer"><Chip {...item}/></span>
                        </ListItem>
                    );
                })}
            </List>
            <Divider />
        </Box>
    );
}