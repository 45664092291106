// socket-io-client.service.js
import { io } from "socket.io-client";

export class SocketIoClientService {
    private static _instance: SocketIoClientService;
    private socket;
    private BACKEND_PLATAFORMA_URL = process.env.REACT_APP_API_URL;

    /** 600000 son 10min */
    private reconnectionDelay = 3000;

    private constructor() {
        // Conectar al backend de la plataforma como cliente
        this.socket = io(this.BACKEND_PLATAFORMA_URL, {
            reconnection: false, 
            // transports: ['websocket', 'polling'],

        });



        // Manejar eventos como 'connect', 'disconnect', etc.
        this.socket.on('connect', () => {
            this.reconnectionDelay = 3000; // Reiniciar el delay de reconexión
            console.log('Conectado al backend de la plataforma como cliente.', this.BACKEND_PLATAFORMA_URL)
        });
        // Manejar conexión fallida
        this.socket.on('connect_error', (error) => {
            console.log('Error de conexión:', error);
            this.attemptReconnect();  // Intenta reconectar
        });

        // Manejar desconexión
        this.socket.on('disconnect', () => {
            console.log('Desconectado del backend de la plataforma.', this.BACKEND_PLATAFORMA_URL);
            this.attemptReconnect();
        });

        // Manejar tiempo de espera de conexión
        this.socket.on('connect_timeout', (timeout) => {
            console.log('Tiempo de espera de conexión excedido:', timeout);
            this.attemptReconnect();  // Intenta reconectar
        });

    }

    private attemptReconnect() {
        setTimeout(() => {
            console.log('Intentando reconectar...');
            if (!this.socket.connected) {
                this.socket.connect();
                // Aumentar el delay de reconexión para el próximo intento
                this.reconnectionDelay = Math.min(this.reconnectionDelay * 2, 300000); // No exceder de 5min
            }
        }, this.reconnectionDelay);
    }


    public static get instance() {
        if (!this._instance) {
            this._instance = new SocketIoClientService();
        }
        return this._instance;
    }

    // Método para suscribirse a eventos del socket
    public on(eventName: string, callback: (...args: any[]) => void) {
        this.socket.on(eventName, callback);
    }


    // Método para desconectar el socket
    public disconnect() {
        if (this.socket) {
            this.socket.disconnect();
        }
    }
}
