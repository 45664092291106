import { CForm, CInputGroup } from '@coreui/react-pro'
import { useEffect, useRef } from 'react'
import { useTableSearch } from '../hook/useSearchTable';
import StyledCFormInput from './style-component/StyledCFormInput';
import { HiMagnifyingGlass } from "react-icons/hi2";
import { usePaginationStore } from '../../../../core/store';

interface SearchTableProps {
    // nameTable?: string[];
    nameColumn: string[];
    label: string[];

    disabled?: boolean;
    placeholder?: string;


}

export const SearchTable = (props: SearchTableProps) => {

    const { addFilterRaw } = usePaginationStore((state) => state);

    const {
        disabled,
        placeholder,

        nameColumn,
        label


    } = props;

    const {
        message,
        updated,

        handleChange,
        handleKeyDown,
        handleDelete } = useTableSearch({ multiSelect: false });

    const isInitialMount = useRef(true);

    useEffect(() => {
        console.log(message)
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            if (updated && updated.length) {
                let message = updated.pop();
                nameColumn.forEach((name, index) => {
                    addFilterRaw({
                        nameTable: '',
                        nameColumn: name,
                        label: label[index],
                        title: message,
                        value: message
                    })
                });

            }
        }
    }, [updated])


    return (
        <CForm>
            <CInputGroup className='flex items-center'>
                <HiMagnifyingGlass size={24} className='antialiased' />
                <StyledCFormInput
                    type="text"
                    id="searchtable"
                    placeholder={placeholder || "Buscador"}
                    value={message}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}

                    disabled={disabled}
                    readOnly={disabled}
                />
            </CInputGroup>
        </CForm>
    )
}

