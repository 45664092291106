
import { createContext } from "react";

interface AuthContextProps {
    auth: boolean;
    checkIdentity: () => Promise<any>;
    logout: () => void;
}


export const AuthContext = createContext({} as AuthContextProps)