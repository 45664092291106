
import { cilUser } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { BASE_ROUTE, ROUTE_NAME, ROUTE_NAME_ID, RouteName } from '../../../core/constants/route_name';
import CompanySelect from '../../components_OLD/companySelect/CompanySelect';
import { _companyBackupLSAdmin, companyBackupLS } from '../../../core/util/save-company-choosed';
import { useLocation, useNavigate } from 'react-router-dom';
import NavItemCustom from '../../components/ui/nav-item-custom/NavItemCustom';
import NavGroupCustom from '../../components/ui/nav-group-custom/NavGroupCustom';
import { NavItem } from '../../../core/interfaces/nav-item';

import { CiUser } from "react-icons/ci";
import { BsChatDots } from "react-icons/bs";
import { RiForbidLine } from "react-icons/ri";
import { MdOutlineWebAsset } from "react-icons/md";
import { BiNotification } from "react-icons/bi";
import { RxGear } from "react-icons/rx";
const useNavSideBar = () => {
    const navigate = useNavigate();
    const location = useLocation();

    // const { company: companyRedux } = useSelector((state: RootState) => state.dashboardStep);
    // const [company, setCompany] = useState(undefined)


    // useEffect(() => {
    //     (async () => {
    //         initPage();
    //     })();
    // }, [companyRedux])

    /**
     * Este fragmento es exclusivo para la ruta de la compañía
     */
    // const initPage = async () => {
    //     try {

    //         /**
    //          * TODO: Posible mejora para el futuro
    //          * En vez de coger el rol del identity para averiguar como capturar el id en el LS
    //          * Se podria hacer una petición al backend.
    //          * Esta petición solamente se haría cada vez que el usuario iniciara o clicase en F5
    //          */
    //         let identity = await LoginService.getIdentityAsync();

    //         let comp = !companyRedux
    //             ? identity.idRole === 1
    //                 ? await _companyBackupLSAdmin("get")
    //                 : await companyBackupLS("get")
    //             : companyRedux;
    //         // if (!comp) {
    //         //     navigate(BASE_ROUTE)
    //         // }
    //         setCompany(comp);

    //         const currentLocation = location.pathname;
    //         const currentCompanyLocation = BASE_ROUTE + ROUTE_NAME_ID(comp?.id, RouteName.companiaDetail);
    //         /**
    //          * Este código es necesario para cambiar el id de la ruta
    //          * después de que hayas elegido una compañía diferente en el select
    //          */
    //         if (currentLocation.includes("/dashboard/compania/")
    //             && currentLocation.includes("/detalle")
    //             && currentLocation !== BASE_ROUTE + currentCompanyLocation) {
    //             navigate(BASE_ROUTE + ROUTE_NAME_ID(comp?.id, RouteName.companiaDetail))
    //         }
    //         /**
    //          * Este código es necesario para cambiar el id de la ruta
    //          * después de que hayas elegido una compañía diferente en el select
    //          */
    //         if (currentLocation.includes("/dashboard/compania/")
    //             && currentLocation.includes("/documentos")
    //             && currentLocation !== BASE_ROUTE + currentCompanyLocation) {
    //             navigate(BASE_ROUTE + ROUTE_NAME_ID(comp?.id, RouteName.documentAccesses))
    //         }
    //         /**
    //         * Este código es necesario para cambiar el id de la ruta
    //         * después de que hayas elegido una compañía diferente en el select
    //         */
    //         if (currentLocation.includes("/dashboard/compania/")
    //             && currentLocation.includes("/facturacion")
    //             && currentLocation !== BASE_ROUTE + currentCompanyLocation) {
    //             navigate(BASE_ROUTE + ROUTE_NAME_ID(comp?.id, RouteName.billing))
    //         }

    //     } catch (e) {
    //         console.log("error bonito");
    //         console.error(e);
    //     }
    // }


    const navSideBar: NavItem[] = [
        // {
        //     component: CompanySelectAdmin,
        //     name: 'Select Company Admin',
        //     roles: ['ROLE_ADMIN'],
        //     icon: <CIcon icon={cilHome} customClassName="nav-icon" />,
        //     // badge: {
        //     //   color: 'info-gradient',
        //     //   text: 'NEW',
        //     // },
        //     // to: BASE_ROUTE + ROUTE_NAME.selectAdmin,
        //     to: BASE_ROUTE + ROUTE_NAME.home,
        // },
        // {
        //     component: CompanySelect,
        //     name: 'Select Company',
        //     roles: ['ROLE_ADMIN'],
        //     icon: <CIcon icon={cilHome} customClassName="nav-icon" />,
        //     // badge: {
        //     //   color: 'info-gradient',
        //     //   text: 'NEW',
        //     // },
        //     to: BASE_ROUTE + ROUTE_NAME.home,
        // },
        // {
        //     component: CNavItem,
        //     name: 'Dashboard',
        //     roles: ["ROLE_ADMIN", "ROLE_USER"],

        //     icon: <CIcon icon={cilHome} customClassName="nav-icon" />,
        //     // badge: {
        //     //   color: 'info-gradient',
        //     //   text: 'NEW',
        //     // },
        //     to: BASE_ROUTE + ROUTE_NAME.home,
        // },
        // {
        //     component: NavItemCustom,
        //     name: 'No hay na CUSTOM',
        //     roles: ["ROLE_ADMIN"],

        //     icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
        //     to: BASE_ROUTE + ROUTE_NAME.usuarios,
        // },
        {
            component: NavItemCustom,
            name: 'Dashboard',
            roles: ["ROLE_ADMIN"],
            // badge: {
            //     color: "#ffff",
            //     text: "alfredo"
            // },
            icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
            to: BASE_ROUTE + ROUTE_NAME.home,
        },
        {
            component: NavItemCustom,
            name: 'Usuarios',
            roles: ["ROLE_ADMIN", "ROLE_USER"],
            // badge: {
            //     color: "#ffff",
            //     text: "alfredo"
            // },
            icon: <CiUser className="nav-icon" />,
            to: BASE_ROUTE + ROUTE_NAME.usuarios,
        },
        {
            component: NavItemCustom,
            name: 'Chats',
            roles: ["ROLE_ADMIN", "ROLE_USER"],
            icon: <BsChatDots className="nav-icon" />,
            to: BASE_ROUTE + ROUTE_NAME.chats,
        },
        {
            component: NavItemCustom,
            name: 'Baneos',
            roles: ["ROLE_ADMIN"],
            icon: <RiForbidLine className="nav-icon" />,
            to: BASE_ROUTE + ROUTE_NAME.bannedUsers,
        },
        {
            component: NavItemCustom,
            name: 'URLs Permitidas',
            roles: ["ROLE_ADMIN"],
            icon: <MdOutlineWebAsset className="nav-icon" />,
            to: BASE_ROUTE + ROUTE_NAME.webAllowed,
        },
        {
            component: NavItemCustom,
            name: 'Notificaciones',
            roles: ["ROLE_ADMIN", "ROLE_USER"],
            icon: <BiNotification className="nav-icon" />,
            to: BASE_ROUTE + ROUTE_NAME.notification,
        },
        {
            component: NavItemCustom,
            name: 'Configuración',
            roles: ["ROLE_ADMIN", "ROLE_USER"],
            icon: <RxGear className="nav-icon" />,
            to: BASE_ROUTE + ROUTE_NAME.configuration,
        },
        // NavGroupCustom
        // {
        //     component: NavGroupCustom,
        //     name: 'Grupo',
        //     roles: ["ROLE_ADMIN"],
        //     icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
        //     to: '',
        //     items: [
        //         {
        //             component: NavItemCustom,
        //             name: 'No hay na hijooo',
        //             roles: ["ROLE_ADMIN"],
        //             badge: {
        //                 color: "#ffff",
        //                 text: "alfredo"
        //             },
        //             // icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
        //             to: BASE_ROUTE + ROUTE_NAME.usuarios,

        //         },
        //         {
        //             component: NavItemCustom,
        //             name: 'No hay na hijooo',
        //             roles: ["ROLE_ADMIN"],
        //             badge: {
        //                 color: "#ffff",
        //                 text: "alfredo"
        //             },
        //             // icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
        //             to: BASE_ROUTE + ROUTE_NAME.usuarios,

        //         },
        //         {
        //             component: NavItemCustom,
        //             name: 'No hay na hijooo',
        //             roles: ["ROLE_ADMIN"],
        //             badge: {
        //                 color: "#ffff",
        //                 text: "alfredo"
        //             },
        //             // icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
        //             to: BASE_ROUTE + ROUTE_NAME.usuarios,

        //         },
        //         {
        //             component: NavItemCustom,
        //             name: 'No hay na hijooo',
        //             roles: ["ROLE_ADMIN"],
        //             badge: {
        //                 color: "#ffff",
        //                 text: "alfredo"
        //             },
        //             // icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
        //             to: BASE_ROUTE + ROUTE_NAME.usuarios,

        //         }
        //     ]
        // },

        // {
        //     component: CNavItem,
        //     name: 'No hay na',
        //     roles: ["ROLE_ADMIN"],

        //     icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
        //     to: BASE_ROUTE + ROUTE_NAME.usuarios,
        // },
        // {
        //     component: CNavItem,
        //     name: 'No hay na',
        //     roles: ["ROLE_ADMIN"],

        //     icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
        //     to: BASE_ROUTE + ROUTE_NAME.usuarios,
        // },
        // {
        //     component: CNavItem,
        //     name: 'No hay na',
        //     roles: ["ROLE_ADMIN"],

        //     icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
        //     to: BASE_ROUTE + ROUTE_NAME.usuarios,
        // },
        // {
        //     component: CNavItem,
        //     name: 'No hay na',
        //     roles: ["ROLE_ADMIN"],

        //     icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
        //     to: BASE_ROUTE + ROUTE_NAME.usuarios,
        // },
        // {
        //     component: CNavItem,
        //     name: 'No hay na',
        //     roles: ["ROLE_ADMIN"],

        //     icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
        //     to: BASE_ROUTE + ROUTE_NAME.usuarios,
        // },
        // {
        //     component: CNavItem,
        //     name: 'No hay na',
        //     roles: ["ROLE_ADMIN"],

        //     icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
        //     to: BASE_ROUTE + ROUTE_NAME.usuarios,
        // },
        // {
        //     component: CNavItem,
        //     name: 'No hay na',
        //     roles: ["ROLE_ADMIN"],

        //     icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
        //     to: BASE_ROUTE + ROUTE_NAME.usuarios,
        // },
        // {
        //     component: CNavItem,
        //     name: 'No hay na',
        //     roles: ["ROLE_ADMIN"],

        //     icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
        //     to: BASE_ROUTE + ROUTE_NAME.usuarios,
        // },
        // {
        //     component: CNavGroup,
        //     name: 'No hay na',
        //     roles: ["ROLE_ADMIN"],

        //     icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
        //     to: '',
        //     items: [
        //         {
        //             component: CNavItem,
        //             name: 'Detalle',
        //             roles: ["ROLE_ADMIN"],
        //             to: BASE_ROUTE + ROUTE_NAME.usuarios,
        //         },
        //     ]
        // },

        //         documentAccesses
        // documentAccessesCrear
        // {
        //     component: CNavGroup,
        //     name: 'Empresa',
        //     roles: [1, 2],
        //     icon: <CIcon icon={cilBuilding} customClassName="nav-icon" />,
        //     to: '',
        //     items: [
        //         {
        //             component: CNavItem,
        //             name: 'Detalle',
        //             roles: [1, 2],
        //             to: BASE_ROUTE + ROUTE_NAME_ID(company?.id, RouteName.companiaDetail),
        //         },
        //         {
        //             component: CNavItem,
        //             name: 'Documentos',
        //             roles: [1, 2],
        //             // to: BASE_ROUTE + ROUTE_NAME.documentAccesses,
        //             to: BASE_ROUTE + ROUTE_NAME_ID(company?.id, RouteName.documentAccesses)
        //         },
        //     ]
        // },



        // BillingPage

        // {
        //     component: NavItemCustom,
        //     name: 'Salir',
        //     roles: ["ROLE_ADMIN", "ROLE_USER"],
        //     icon: <CIcon icon={cilExitToApp} customClassName="nav-icon" />,
        //     to: ROUTE_NAME.login,
        // },
        // {
        //     component: CNavItem,
        //     name: 'Salir',
        //     roles: ["ROLE_ADMIN", "ROLE_USER"],
        //     icon: <CIcon icon={cilExitToApp} customClassName="nav-icon" />,
        //     to: ROUTE_NAME.login,
        // },
    ]

    return navSideBar;
}

export default useNavSideBar;
