

interface DividerProps {
    color?: string;
    marginY?: number;
    marginX?: number;
}

export const Divider = ({ color = '#e0e0e0', marginY = 10, marginX = 0 }: DividerProps) => {
    return (
        <div className="border-b py-0" style={{
            borderColor: color, padding: '16px 0',
            marginTop: marginY,
            marginBottom: marginY,
            marginRight: marginX,
            marginLeft: marginX
        }}></div>
    )
}
