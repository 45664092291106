import axios from 'axios';
import { Pagination } from '../../../interfaces';
import { PushNotificationType } from '../../../models/db/push-notification-types';

const apiUrl = process.env.REACT_APP_API_URL;

export class PushNotificationTypeService {
    static add(body: PushNotificationType) {
        return axios.post(`${apiUrl}/api/pushNotificationType/add`, body, { withCredentials: true });
    }

    static getAll(pagination?: Pagination) {
        return axios.post(`${apiUrl}/api/pushNotificationTypes`, { pagination }, { withCredentials: true });
    }

    static update(id, body: PushNotificationType) {
        return axios.post(`${apiUrl}/api/pushNotificationType/${id}`, body, { withCredentials: true });
    }

    static delete(idList: string[] | number[], action: boolean) {
        let params = { list: idList, action };
        return axios.post(`${apiUrl}/api/pushNotificationType/delete`, params, { withCredentials: true });
    }
}
