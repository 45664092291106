
import { AnalyticService } from '../../../services/db/analytic/analytic.service';


export const useAnalytic = () => {


    const getAnalytics = (startDate: string, endDate: string, platforms: string[]) => {
        return new Promise<any>((res, rej) => {
            AnalyticService.getAnalytics(startDate, endDate, platforms)
                .then((item) => {

                    if (item && item.data && item.data?.ok) {
                        res(item.data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })
        })
    }

    const getAnalyticsPerTime = (startDate: string, endDate: string, platforms: string[]) => {
        return new Promise<any>((res, rej) => {
            AnalyticService.getAnalyticsPerTime(startDate, endDate, platforms)
                .then((item) => {

                    if (item && item.data && item.data?.ok) {
                        res(item.data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })
        })
    }


    const getCounterMessagePerTime = (startDate: string, endDate: string, platforms: string[]) => {
        return new Promise<any>((res, rej) => {
            AnalyticService.getCounterMessagePerTime(startDate, endDate, platforms)
                .then((item) => {

                    if (item && item.data && item.data?.ok) {
                        res(item.data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })
        })
    }


    const getFlowPercentage = (startDate: string, endDate: string, platforms: string[]) => {
        return new Promise<any>((res, rej) => {
            AnalyticService.getFlowPercentage(startDate, endDate, platforms)
                .then((item) => {

                    if (item && item.data && item.data?.ok) {
                        res(item.data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })
        })
    }

    const getMonthlyMessages = () => {
        return new Promise<any>((res, rej) => {
            AnalyticService.getMonthlyMessages()
                .then((item) => {

                    if (item && item.data && item.data?.ok) {
                        res(item.data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })
        })
    }


    return {
        getAnalytics,
        getAnalyticsPerTime,
        getCounterMessagePerTime,
        getFlowPercentage,
        getMonthlyMessages
    }
}