import React, { FC, ReactNode, useEffect, useState } from 'react'
import {  useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import { CBadge } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'

import { LoginService } from '../../../../core/services/login/login.service'
import { Badge, NavItem } from '../../../../core/interfaces'

interface AppSidebarNavProps {
  items: NavItem[]
}
export const AppSidebarNav: FC<AppSidebarNavProps> = ({ items }) => {


  const [identity, setIdentity] = useState(undefined);
  useEffect(() => {
    (async () => {
      let id = await LoginService.getIdentityAsync();
      setIdentity(id);
    })();
  }, []);


  // console.log("que es item", items)
  const location = useLocation();

  const navLink = (
    name: string | JSX.Element,
    icon: string | ReactNode,
    badge?: Badge,
  ) => {
    return (
      <>
        {icon && typeof icon === 'string' ? (
          <CIcon icon={icon} customClassName="nav-icon" />
        ) : (
          icon
        )}
        {name && name}
        {badge && (
          <CBadge color={badge.color} className="ms-auto">
            {badge.text}
          </CBadge>
        )}
      </>
    )
  }

  const navItem = (item: NavItem, index: number, identity) => {
    const { component, name, badge, icon, roles, to, ...rest } = item
    const Component = component;

    if (roles && !roles.includes(identity?.role)) {
      return null;
    }

  
    return (
      <Component
        key={index}
        name={name}
        icon={icon}
        badge={badge}
        to={to}
        roles={roles}
      />
    )
  }

  const navGroup = (item: NavItem, index: number) => {
    const { component, name, icon, to, roles, ...rest } = item
    const Component = component
    if (roles && !roles.includes(identity?.role)) {
      return null;
    }
    // console.log("soy un grupo", item)
  
    return (
      <Component
        key={index}
        name={name}
        icon={icon}
        visible={to && location.pathname.startsWith(to)}
        {...rest}
      >
        {item.items?.map((item: NavItem, index: number) =>
          item.items ? navGroup(item, index) : navItem(item, index, identity),
        )}
      </Component>
    )
  }

  return (
    <React.Fragment>
      <div>
        {items &&
          // items.map((item: NavItem, index: number) =>

          //   item.items ? navGroup(item, index) : navItem(item, index),
          // )}
          items.map((item: NavItem, index: number) => {
            return item.items ? navGroup(item, index) : navItem(item, index, identity)
          }


          )}
      </div>
    </React.Fragment>
  )
}


AppSidebarNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
}
