
import { CRow, CSmartTable } from "@coreui/react-pro";
import { Item } from "@coreui/react-pro/dist/components/table/types";
import { TablePagination } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { HiDotsVertical } from "react-icons/hi";
import { IoBanSharp, IoRemoveCircleOutline } from "react-icons/io5";
import { useBannedUser } from "../../../../core/hooks/db/banned-user/useBannedUser";
import { DropdownItem, Pagination } from "../../../../core/interfaces";
import { BannedUser } from "../../../../core/models/db";
import { User } from "../../../../core/models/db/user";
import { LoginService } from "../../../../core/services/login/login.service";
import { usePaginationStore, useSnackbarStore } from "../../../../core/store";
import { Util } from "../../../../core/util/util";
import { DropItemStandard, Dropdown } from "../../../components";
import { LoaderContext } from "../../../components/loader/context/LoaderContext";
import { RippleButton } from "../../../components/ui/ripple-button/RippleButton";



const CLASS_REGULAR_ROW = "check-reg-" + Util.getUniqueId(1);
const CLASS_PRINCIPAL_ROW = "check-prin-" + Util.getUniqueId(1);


interface BannedUserTemporaryListProps {
    handleModal: (bannedUser: BannedUser, typeActionModal: 'to-permanent' | 'remove-ban') => void;
    loading?: boolean;
    setLoading?: (loading: boolean) => void;
}


const BannedUserPermanentList = ({ handleModal, loading, setLoading }: BannedUserTemporaryListProps) => {

    const initialPaginationConfig: Pagination = useMemo(() => ({
        page: 0,
        itemsPerPage: 5,
        filters: {
            banType: {
                value: "PERMANENT",
                relation: ""
            }
        },
        orderBy: {
            field: "id",
            order: "desc",
            relation: ""
        }
    }), []);


    const { addSnackbar } = useSnackbarStore();
    const { pagination, setPagination, resetFilterRaws } = usePaginationStore();
    const [init, setInit] = useState<boolean>(false);
    const [dropdownItems, setDropdownItems] = useState<DropdownItem[]>([
        {
            component: DropItemStandard,
            name: "Quitar Ban",
            fn: undefined, // Se añadirá en el handleModal
            icon: <IoRemoveCircleOutline />

        },
    ]);

    const [activeDropdownId, setActiveDropdownId] = useState<number | null>(null);
    const [identity, setIdentity] = useState(undefined);

    const { getBannedUser } = useBannedUser();

    const [body, setBody] = useState<User[]>([]);




    /**
     * Inicio de la paginación
     */
    useEffect(() => {
        setPagination(initialPaginationConfig);
        // resetFilterRaws();
        setInit(true);
    }, [initialPaginationConfig]);


    /**
     * Cada vez que se cambie la paginación, se recargará la tabla
     */
    useEffect(() => {
        if (!pagination || !init) return;

        initPage({
            ...pagination,
        })
    }, [!!init, pagination?.page, pagination?.itemsPerPage, JSON.stringify(pagination?.orderBy), JSON.stringify(pagination?.filters)]);




    const initPage = (paginationDefault = pagination) => {
        (async () => {
            setLoading(true);
            // Cargamos las acciones para el ActionBar


            const id = await LoginService.getIdentityAsync();
            setIdentity(id);


            console.log("metro paginacion", paginationDefault, pagination)
            const response = await getBannedUser({ ...paginationDefault, page: paginationDefault.page + 1 });
            console.log("response", response)
            if (response && response.ok) {
                let rows = response.item.rows;

                for (let i = 0; i < response.item.rows.length; i++) {
                    
                    rows[i].idSession = response.item.rows[i].idSession;

                    const userSocialMedias: any = rows[i]?.userSocialMedias;
                    rows[i].idUserPlatform = userSocialMedias?.idUserPlatform ? userSocialMedias?.idUserPlatform : '';
                    rows[i].platform = rows[i]?.userSocialMedias?.platform ? rows[i]?.userSocialMedias?.platform : '';

                    // rows[i].priv_role = rows[i]?.roles ? rows[i]?.roles.title : '';
                    // Hay que agregar estos dos para añadir los checks
                    rows[i].priv_options = "";

                    // rows[i].priv_delete = "";
                    // rows[i].priv_view = "";
                    rows[i]._props = { className: CLASS_REGULAR_ROW, align: "middle" };
                }

                setBody(rows);
                setPagination({
                    ...paginationDefault,
                    totalItems: response.item.pagination.totalItems,
                    totalPages: response.item.pagination.totalPages,
                })

                setTimeout(() => {
                    setLoading(false);
                }, 200)
            } else {
                console.warn("Ha ocurrido un problema al devolver los usuarios")
            }
        })()
    }


    const handleDropdownClick = (id: number) => {
        // Esto cambiará el estado a la ID actual si está cerrado o a null si ya está abierto.
        setActiveDropdownId(prevActiveId => (prevActiveId === id ? null : id));
    };

    // const [details, setDetails] = useState([])
    // const toggleDetails = (index) => {
    //     const position = details.indexOf(index)
    //     let newDetails = details.slice()
    //     if (position !== -1) {
    //         newDetails.splice(position, 1)
    //     } else {
    //         newDetails = [...details, index]
    //     }
    //     setDetails(newDetails)
    // }

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPagination({
            ...pagination,
            page: newPage
        })
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setPagination({
            ...pagination,
            page: 0,
            itemsPerPage: parseInt(event.target.value, 10)
        })
    };

    return (


        <CRow>
            {loading && (
                <div className="flex justify-center items-center h-full">
                    <div className="flex justify-center items-center">
                        <div className="animate-spin rounded-full h-[32px] w-[32px] border-t-2 border-b-2 border-gray-900"></div>
                    </div>
                </div>
            )}
            {/* LISTADO */}
            {/* <pre>{JSON.stringify(itemsFiltered, null, 2)}</pre> */}
            {!loading && body && body.length > 0 && pagination && pagination.totalPages && (

                //   className={width < 780 ? 'table-responsive' : ''}
                <div>
                    <CSmartTable
                        tableHeadProps={{
                            color: "#dedede",

                        }}
                        tableProps={{

                            responsive: "md",
                            bordered: false,
                            borderless: true,
                            // striped: true,
                            hover: true,
                            // color: "dark",

                        }}
                        items={body}

                        columns={[
                            {
                                key: "idSession",
                                label: "Sesión",
                                _style: { backgroundColor: '#f1f1f1', width: '40%' },
                            },
                            {
                                key: "idUserPlatform",
                                label: "Usuario",
                                _style: { backgroundColor: '#f1f1f1', width: '20%' },
                            },
                            {
                                key: "platform",
                                label: "Plataforma",
                                _style: { backgroundColor: '#f1f1f1', width: '15%' },

                            },
                            {
                                key: "priv_options",
                                label: "",
                                sorter: false,
                                _style: { backgroundColor: '#f1f1f1', width: '10%' },
                                _props: { className: 'fw-semibold', align: "right" },

                            },

                            // {
                            //     key: 'show_details',
                            //     label: '',
                            //     _style: { width: '5%' },
                            //     filter: false,
                            //     sorter: false,
                            //     _props: { className: 'fw-semibold' },
                            // },

                        ]}

                        columnSorter={{
                            external: true
                        }}

                        // pagination={false}
                        // itemsPerPageSelect={false}
                        // itemsPerPageLabel={"Registros por página"}
                        itemsPerPage={pagination.itemsPerPage}

                        clickableRows

                        onRowClick={(item: Item, index: number, columnName: string, event) => {
                            let target = typeof event !== 'boolean' ? event.nativeEvent.target as HTMLElement : event;
                            let value = typeof target === 'boolean' ? target : target.tagName;

                            if (typeof target !== 'boolean' && value === "INPUT") {
                                //    Clica al check

                            }
                        }}

                        scopedColumns={{
                            idSession: (item) => {
                                const copiarAlPortapapeles = async (texto) => {
                                    try {
                                        await navigator.clipboard.writeText(texto);
                                        console.log("Texto copiado al portapapeles");
                                        addSnackbar({
                                            title: "Texto copiado",
                                            position: "bottom-left",
                                            color: "info",

                                        })
                                        // Aquí puedes agregar alguna notificación al usuario si lo deseas.
                                    } catch (error) {
                                        console.error("Error al copiar texto: ", error);
                                    }
                                };


                                return (
                                    <td>
                                        <div className="flex justify-start items-center">
                                            {item.idSession}
                                            <button onClick={() => copiarAlPortapapeles(item.idSession)} className="flex justify-start items-center">
                                                <RippleButton rippleColor="#757575" className="ml-2 text-gray-400 rounded-lg px-1">
                                                    <span className="text-xs text-gray-400">Copiar</span>
                                                </RippleButton>
                                            </button>

                                        </div>
                                    </td>
                                )
                            },
                            priv_options: (objectTable) => {
                                return (
                                    <td className="relative">
                                        <div className="flex justify-end items-center">
                                            <RippleButton
                                                className="text-gray-400 rounded-2xl  w-[29px] h-[29px]"
                                                rippleColor="#757575"
                                                onClick={() => handleDropdownClick(objectTable.id)}
                                            >
                                                <HiDotsVertical size={18} />
                                            </RippleButton>
                                        </div>
                                        <Dropdown
                                            showModal={activeDropdownId === objectTable.id}
                                            setShowModal={() => setActiveDropdownId(null)}
                                            position={{ top: 30, right: 20 }}
                                        >

                                            <div className={`rounded-xl bg-white w-auto min-w-[220px] max-w-xs shadow-md px-2 py-3`}>

                                                {
                                                    dropdownItems.map((item, index) => {
                                                        return (
                                                            <DropItemStandard
                                                                key={index}
                                                                item={{
                                                                    ...item,
                                                                    fn: () => handleModal(objectTable,
                                                                        item.name === "Pasar a Permanente" ? "to-permanent" : "remove-ban")
                                                                }}
                                                                handleVisible={setActiveDropdownId as any}
                                                            />
                                                        )
                                                    })
                                                }

                                            </div>
                                        </Dropdown>
                                    </td>
                                )
                            },

                            // email: (item) => {
                            //     let value = "No hay email registrado";
                            //     try {
                            //         if (item.email) {
                            //             value = item.email;
                            //         }
                            //         return (
                            //             <td> {value} </td>
                            //         )
                            //     } catch (e) {
                            //         console.error(e)
                            //         return (
                            //             <td> {value}* </td>
                            //         )
                            //     }
                            // },


                        }}

                        onSelectAll={() => {
                            // console.log("calling all of ids")
                        }}
                        onSelectedItemsChange={(items) => {
                            // console.log("items that you selected", items)
                        }}


                        onSorterChange={(value) => {
                            // console.log("what is value?", value)
                            if (value.state === 0) {
                                setPagination({
                                    ...pagination,
                                    //   relatedTableOrder: null,
                                    orderBy: null
                                })

                            } else if (value.column === "idUserPlatform") {
                                setPagination({
                                    ...pagination,
                                    // relatedTableOrder: null,

                                    orderBy: {
                                        field: value.column,
                                        order: value.state as any,
                                        relation: "userSocialMedias"
                                    }
                                })
                            } else if (value.column === "platform") {
                                setPagination({
                                    ...pagination,
                                    // relatedTableOrder: null,

                                    orderBy: {
                                        field: value.column,
                                        order: value.state as any,
                                        relation: "userSocialMedias"
                                    }
                                })



                            } else {
                                setPagination({
                                    ...pagination,
                                    // relatedTableOrder: null,
                                    orderBy: {
                                        field: value.column,
                                        order: value.state as any,
                                    }

                                })
                            }


                        }}
                    />
                </div>
            )
            }


            {
                pagination && pagination.totalItems > 0 && (
                    <TablePagination
                        component="div"
                        count={pagination.totalItems}
                        page={pagination.page}
                        rowsPerPage={pagination.itemsPerPage}

                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage="Registros por página"
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    />

                )
            }




        </CRow >


    )
}


export default BannedUserPermanentList;

