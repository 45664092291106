import { update } from 'react-spring';
import { Pagination } from '../../../interfaces/pagination';
import { WebAllowedService } from '../../../services/db/web-allowed/webAllowed.service';
import { WebAllowed } from '../../../models/db/web-allowed/webAllowed';
import { ReasonForDeactivationProps } from '../../check-table/useCheckTable';



export const useWebAllowed = () => {

    const service = new WebAllowedService();

    const addWebAllowed = (body: WebAllowed) => {
        return new Promise<any>((res, rej) => {
            service.add(body)
                .then((item) => {
                    if (item && item.data && item.data?.ok) {
                        res(item.data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })
        })
    }

    const updateWebAllowed = (body: WebAllowed) => {
        return new Promise<any>((res, rej) => {
            service.update(body.id, body)
                .then((item) => {
                    if (item && item.data && item.data?.ok) {
                        res(item.data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })
        })
    }

    const getWebAllowed = (pagination: Pagination = { page: 1, itemsPerPage: 5 }) => {
        return new Promise<any>((res, rej) => {
            service.getAll(pagination)
                .then((item) => {

                    if (item && item.data && item.data?.ok) {
                        res(item.data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })
        })
    }


    const getWebAllowedById = (id: any) => {
        return new Promise<any>((res, rej) => {
            service.getById(id)
                .then((item) => {
                    if (item && item.data && item.data?.ok) {
                        res(item.data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })
        })
    }

    const getAllWebAllowedId = (reasonForDesactivation: ReasonForDeactivationProps[] = []) => {
        return new Promise<any>((res, rej) => {
            console.log(reasonForDesactivation)
            service.getAllId(reasonForDesactivation)
                .then((item) => {
                    if (item && item.data && item.data?.ok) {
                        res(item.data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })
        })
    }

    const deleteWebAllowed = (idList: string[] | number[], action: boolean) => {
        return new Promise<any>((res, rej) => {
            service.delete(idList, action)
                .then((item) => {
                    if (item && item.data && item.data?.ok) {
                        res(item.data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })
        })
    }









    return {
        get: getWebAllowed,
        getAllId: getAllWebAllowedId,
        getById: getWebAllowedById,
        add: addWebAllowed,
        delete: deleteWebAllowed,
        update: updateWebAllowed,


        getWebAllowed,
        getWebAllowedById,
        addWebAllowed,
        deleteWebAllowed,
        updateWebAllowed,
        getAllWebAllowedId
    }
}