import { forwardRef } from "react";
import { DropdownItem } from "../../../../../core/interfaces/dropdown/dropdown-item";
import { useNavigate } from "react-router-dom";
import { DropdownItemNotification } from "../../../../../core/interfaces/dropdown/dropdown-item-notification";
import moment from "moment";
import { Tooltip } from "@mui/material";
import { RxCross2 } from 'react-icons/rx';
import { RippleButton } from "../../ripple-button/RippleButton";


interface DropdownItemNotificationProps {
  lastItem: boolean;
  item: DropdownItemNotification;

  handleVisible: (visible: boolean) => void;

  selectItem?: (item: any) => void;
  removeItem?: (item: any) => void;
}

export const DropItemNotification = forwardRef<HTMLDivElement, DropdownItemNotificationProps>((
  {
    item,
    lastItem,
    handleVisible,
    selectItem,
    removeItem
  }, ref) => {

  const { name, icon: IconComponent, to, roles, notification } = item;

  const navivation = useNavigate()

  // Controlador de clic que ignora los clics del botón derecho
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {

    // Verificar si el clic fue hecho con el botón derecho
    if (event.button === 2) {
      // No hacer nada si es clic derecho
      return;
    }
    // Cerrar el menú para clics que no son del botón derecho
    handleVisible(false);
    if (to) {
      selectItem && selectItem(notification);
      navivation(to);
    }
  };






  return (
    <div ref={ref} onClick={handleClick} className="w-full cursor-pointer min-h-[60px] hover:bg-gray-50 ">
      <div className="p-2 flex flex-row justify-between items-center text-black transition duration-300 ease-in-out">
        <div className="flex items-center flex-1 min-w-0">
          {IconComponent && <span className="text-md mr-2 antialiased">{IconComponent}</span>}
          {name && (
            <Tooltip title={name} placement="top" arrow>
              <span className="capitalize block overflow-hidden text-ellipsis whitespace-nowrap flex-1 min-w-0">{name}</span>
            </Tooltip>
          )}
        </div>
        <div className="flex-shrink-0 pl-2">
          {/* Botón con forma de círculo que cambia de estilo al pasar el cursor */}
          <button
            className="rounded-full p-1 hover:bg-gray-200 transition duration-300 ease-in-out"
            onClick={(e) => {
              // Detiene la propagación para evitar que se llame al handleClick del contenedor
              e.stopPropagation();

              removeItem && removeItem(notification);
              // Aquí llamarías a tu función para manejar el evento de cerrar
              console.log("Cerrar notificación");
            }}
          >
            <RxCross2 />
          </button>
        </div>
      </div>
      <div className="px-2 pb-1">
        {notification.createdDate && <span className="text-xs text-slate-400">{moment(notification.createdDate).fromNow()}</span>}
      </div>
      {!lastItem && <div className="border-b border-gray-300"></div>}
    </div>
  );
});

DropItemNotification.displayName = "DropItemNotification";

