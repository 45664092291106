import { create } from 'zustand'


// Definiendo el tipo para el estado de UI
interface UIState {
    sidebarShow: boolean;
    sidebarUnfoldable: boolean;
    asideShow: boolean;
    theme: string;
}

// Definiendo el tipo para las acciones de UI
interface UIActions {
    setUI: (newState: Partial<UIState>) => void;
}


// Creando el store de Zustand
export const useUIStore = create<UIState & UIActions>(set => ({
    sidebarShow: true,
    sidebarUnfoldable: false,
    asideShow: false,
    theme: 'default',

    // Actions
    setUI: (newState) => set(state => ({ ...state, ...newState })),
}))


