import { Chip } from "@mui/material";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { BsChatDots } from "react-icons/bs";
import { GiPayMoney } from "react-icons/gi";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { RiForbidLine } from "react-icons/ri";
import { TbDiscountCheck } from "react-icons/tb";
import { TbMessageOff } from "react-icons/tb";

export const NotificationTypeColumn = ({ item }) => {
    let IconComponent = null;
    let tooltipTitle = ""; // Vamos a almacenar el título del tooltip aquí
    let chipColor: any = "default"; // Color por defecto del Chip

    switch (item.notificationType) {
        case 'BAN':
            IconComponent = RiForbidLine;
            tooltipTitle = "Ban";
            chipColor = "error"; // Rojo para prohibiciones
            break;
        case 'CONVERSATION':
            IconComponent = BsChatDots;
            tooltipTitle = "Conversación";
            chipColor = "primary"; // Azul para conversaciones
            break;
        case 'LEAD':
            IconComponent = TbDiscountCheck;
            tooltipTitle = "Lead";
            chipColor = "success"; // Verde para leads
            break;
        case 'QUOTA_REACHED':
            // IconComponent = GiPayMoney;
            IconComponent = TbMessageOff;
            tooltipTitle = "Cuota alcanzada";
            chipColor = "warning"; // Amarillo para cuotas alcanzadas
            break;
        case 'OTHER':
            IconComponent = AiOutlineExclamationCircle;
            tooltipTitle = "Otros";
            chipColor = "default"; // Gris para otros
            break;
        case 'ADMINISTRATION':
            IconComponent = MdOutlineAdminPanelSettings;
            tooltipTitle = "Administración";
            chipColor = "secondary"; // Púrpura para administración
            break;
        default:
            IconComponent = null;
            tooltipTitle = "";
    }

    try {
        return (
            <td className="text-center align-middle">
                <div className="flex items-center">
                    {IconComponent && (
                        <Chip
                            icon={<IconComponent />}
                            label={tooltipTitle}
                            color={chipColor}
                            size="small"
                            variant="outlined"
                        />
                    )}
                </div>
            </td>
        )
    } catch (e) {
        console.error(e)
        return (
            <td className="text-center align-middle">
                <div className="flex items-center ">
                    {IconComponent && (
                        <Chip
                            icon={<IconComponent />}
                            label={tooltipTitle}
                            color={chipColor}
                            size="small"
                            variant="outlined"
                        />
                    )}
                </div>
            </td>
        )
    }
}