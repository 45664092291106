import React, { useState, ReactNode, forwardRef, useRef, useImperativeHandle, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group'; // Esta es una biblioteca útil para animaciones de entrada/salida
import { RippleButton } from '../ripple-button/RippleButton';
import { SlArrowRight } from "react-icons/sl";
import { SlArrowDown } from "react-icons/sl";
import clsx from 'clsx';
import { getContrastColor } from '../../../../core/util/colors/adjust-color';
import { COLORS } from '../../../../core/constants/colors';
import CIcon from '@coreui/icons-react';
import { useSpring, animated } from 'react-spring';

// Define la interfaz para la propiedad 'group'
export interface NavGroupProps {
    lastGroup?: boolean;

    name: ReactNode;
    icon?: ReactNode;
    children?: ReactNode; // Los elementos NavItemCustom serán pasados como children

    visible?: boolean;
}

type Props = NavGroupProps;

const NavGroupCustom = forwardRef<HTMLAnchorElement, Props>(({ name, icon, visible = false, lastGroup = false, children }) => {

    const [isOpen, setIsOpen] = useState(visible);
    const [contentHeight, setContentHeight] = useState(0);

    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (contentRef.current) {
            setContentHeight(contentRef.current.scrollHeight);
        }
    }, [children]);

    const animatedStyles = useSpring({
        to: {
            opacity: isOpen ? 1 : 0,
            height: isOpen ? contentHeight : 0
        },
        from: {
            //  opacity: 0, 
            height: 0
        },
        config: { tension: 400, friction: 50 }
    });

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };


    return (

        <div className='w-full flex flex-col justify-center items-center pt-1'  >
            <div
                className='w-full flex flex-col justify-center items-center '
                onClick={toggleOpen}
            >

                <RippleButton className='rounded-lg' style={{ width: '90%' }}>
                    {/* El título del grupo, que al hacer clic expandirá o contraerá el grupo */}
                    <div
                        style={{
                            backgroundColor: isOpen ? getContrastColor(COLORS.tertiaryColor) : 'transparent',
                            paddingLeft: '1rem', paddingRight: '1rem'
                        }}
                        className={clsx(`w-full flex items-center no-underline p-2 rounded-lg transition-colors duration-150`, {
                            'text-white': isOpen,
                            'text-gray-400 hover:text-gray-200': !isOpen,
                        })}
                    >
                        {icon && typeof icon === 'string' ? (
                            <CIcon icon={icon} customClassName="text-lg ml-4" className='ml-4' />
                        ) : (
                            <span className="ml-4">{icon}</span>
                        )}
                        <span className="flex-1 min-w-0 pl-3"> {/* Ajusta el padding izquierdo aquí */}
                            <span className="block truncate">{name}</span>
                        </span>


                        {/* Agregar icono de expansión/contracción si es necesario */}
                        <span className="expand-icon-class">{isOpen
                            ? <SlArrowDown fontSize={10} />
                            : <SlArrowRight fontSize={10} />}
                        </span>
                    </div>
                </RippleButton>
            </div>
            {/* La sección que se expande o contrae */}
            <div className='w-full'>
                <animated.div style={animatedStyles} className={clsx(`overflow-hidden`)}>
                    <div ref={contentRef} className={clsx(`pt-1`)}>
                        {children}
                    </div>
                </animated.div>
            </div>
        </div>

    );
});


NavGroupCustom.displayName = 'NavGroupCustom';
export default NavGroupCustom;
