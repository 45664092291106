
import { Pagination } from '../../../interfaces/pagination';
import { ConfigurationBot } from '../../../models/db/configuration-bot/configuration-bot';
import { ConfigurationBotService } from '../../../services/db/configuration/configuration.service';
import { ReasonForDeactivationProps } from '../../check-table/useCheckTable';



export const useConfigurationBot = () => {

    const service = new ConfigurationBotService();

    // const addConfigurationBot = (body: ConfigurationBot) => {
    //     return new Promise<any>((res, rej) => {
    //         service.add(body)
    //             .then((item) => {
    //                 if (item && item.data && item.data?.ok) {
    //                     res(item.data);
    //                 } else {
    //                     res(undefined)
    //                 }
    //             })
    //             .catch((e) => {
    //                 console.log(e)
    //                 rej(e)
    //             })
    //     })
    // }

    const updateConfigurationBot = (body: ConfigurationBot) => {
        return new Promise<any>((res, rej) => {
            service.update(body.id, body)
                .then((item) => {
                    if (item && item.data && item.data?.ok) {
                        res(item.data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })
        })
    }

    const getConfigurationBot = (pagination: Pagination = { page: 1, itemsPerPage: 5 }) => {
        return new Promise<any>((res, rej) => {
            service.getAll(pagination)
                .then((item) => {

                    if (item && item.data && item.data?.ok) {
                        res(item.data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })
        })
    }


    // const getConfigurationBotById = (id: any) => {
    //     return new Promise<any>((res, rej) => {
    //         service.getById(id)
    //             .then((item) => {
    //                 if (item && item.data && item.data?.ok) {
    //                     res(item.data);
    //                 } else {
    //                     res(undefined)
    //                 }
    //             })
    //             .catch((e) => {
    //                 console.log(e)
    //                 rej(e)
    //             })
    //     })
    // }

    

    // const deleteConfigurationBot = (idList: string[] | number[], action: boolean) => {
    //     return new Promise<any>((res, rej) => {
    //         service.delete(idList, action)
    //             .then((item) => {
    //                 if (item && item.data && item.data?.ok) {
    //                     res(item.data);
    //                 } else {
    //                     res(undefined)
    //                 }
    //             })
    //             .catch((e) => {
    //                 console.log(e)
    //                 rej(e)
    //             })
    //     })
    // }









    return {
        get: getConfigurationBot,
        // getById: getConfigurationBotById,
        // add: addConfigurationBot,
        // delete: deleteConfigurationBot,
        update: updateConfigurationBot,


        getConfigurationBot,
        // getConfigurationBotById,
        // addConfigurationBot,
        // deleteConfigurationBot,
        updateConfigurationBot,
  
    }
}