import { cilArrowLeft } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CCard, CCardHeader, CCol, CCardBody, CRow, CContainer } from "@coreui/react-pro";
import { Snackbar } from "@mui/material";
import { useState, useContext, useEffect, useRef } from "react";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import { BASE_ROUTE, ROUTE_NAME } from "../../../../core/constants/route_name";
import { useUser } from "../../../../core/hooks";
import { User } from "../../../../core/models/db/user";
import { LoginService } from "../../../../core/services/login/login.service";
import { LoaderContext } from "../../../components/loader/context/LoaderContext";
import { useSnackbar } from "../../../components_OLD/snackbar/hook/useSnackbar";
import UserForm from "./UserForm";
import { COLORS } from "../../../../core/constants/colors";
import clsx from "clsx";
import { RippleButton } from "../../../components/ui/ripple-button/RippleButton";
import { useIdentityStore } from "../../../../core/store";
import { HiArrowNarrowLeft } from "react-icons/hi";

const LABEL_PAGE = "Perfil"

const UserFormDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const stateLocation = location.state;

  const { id } = useParams();

  const { setIdentity, identity } = useIdentityStore(state => state);

  const formRef = useRef<any>(null);

  const { getUserById, addUser, updateUser, checkPassword, checkUsername } = useUser();


  const [user, setUser] = useState<User>({
    username: "",
    password: "",
    priv_confirmPassword: "",
    image: "",
    email: "",
    blocked: false,
    idRoleFk: undefined,
  })

  const { handleLoading, changeStyle } = useContext(LoaderContext);
  const [loadingButton, setLoadingButton] = useState(false);
  const [typeAction, setTypeAction] = useState<"add" | "update">(null);

  const [setToken] = useState(null);

  const { state, handleSnackbar } = useSnackbar({
    color: "",
    wait: 3000,
    message: "",
    changeState: false,
  })

  /**
* Se usa al comienzo de cargar la página para rellenar la tabla
*/
  useEffect(() => {
    (async () => {
      // changeStyle({ backgroundColor: "rgba(111, 214, 250, 0.5)" })
      handleLoading(true);
      const path = location.pathname;

      console.log(path)
      console.log(path)
      console.log(path)
      console.log(path)


      if (path.includes("detalle") && stateLocation && stateLocation.row) {
        console.log("1111")
        let row: User = { ...stateLocation.row };
        row.password = "";
        row.priv_confirmPassword = "";
        delete row.token;
        delete row.role;
        delete row.priv_role;
        delete row.createdDate;
        delete row.deletedDate;
        delete row.updatedDate;

        setUser(row);
        setTypeAction("update");
      } else if (path.includes("detalle") && id) {
        console.log("2222")

        let result = await getUserById(id)
        if (result && result.ok) {
          console.log("what is the result", result)
          let user = result.item;
          user.password = "";
          user.priv_confirmPassword = "";
          delete user.token;
          delete user.roles;
          delete user.createdDate;
          delete user.deletedDate;
          delete user.updatedDate;
          setUser(user);
          setTypeAction("update");
        } else {
          console.log("333")

          console.warn("No se encontró ningún id");
          navigate(BASE_ROUTE + ROUTE_NAME.usuariosCrear);
        }
      } else {
        console.log("4444")

        setTypeAction("add");
      }

      handleLoading(false);
    })()
  }, []);

  /**
   * Comprueba que el username está libre
   * @param id 
   * @param password 
   * @returns 
   */
  const handleCheckUsername = async (id, username) => {
    const result = await checkUsername(id, username);
    if (result && result.ok) {
      return result.item;
    } else {
      return false;
    }
  }


  const handleCheckPassword = async (id, password) => {
    const result = await checkPassword(id, password);


    if (result && result.ok) {
      return result.item;
    } else {
      return false;
    }
  }


  /**
   * Crea el usuario
   * @param user 
   */
  const add = async (user: User) => {


    try {
      let u = { ...user };
      delete u.priv_confirmPassword;
      setLoadingButton(true);
      if (!u.password || u.password === '') {
        u.password = "rbc1234";
      }

      const result = await addUser(u);
      if (result && result.ok) {
        handleSnackbar("El registro se ha creado", "success");

        setTimeout(() => {
          setLoadingButton(false);
          // setUser({
          //   ...result.item,
          //   password: "",
          //   priv_confirmPassword: ''
          // })
          navigate("/dashboard/usuarios");
        }, 1600)
      }

    } catch (e) {
      handleSnackbar("Ha habido un error al crear el registro", "success");
      setLoadingButton(false);
      console.error(e)
    }
  }

  /**
  * Crea el usuario
  * @param user 
  */
  const update = async (user: User) => {
    try {
      let u = { ...user };
      delete u.priv_confirmPassword;
      setLoadingButton(true);
      if (!u.password || u.password === '') {
        delete u.password;
      }

      u.myId = identity.idUser;

      const result = await updateUser(u);
      if (result && result.ok) {
        console.log(result)
        // handleSnackbar("El registro se ha actualizado", "success");
        console.log("voy a pedir identity")
        // let identity = await LoginService.getIdentityAsync();
        if (u.id === identity.idUser) {

          setIdentity({
            ...identity,
            email: u.email,
            username: u.username,
            role: result?.item?.role?.title,
            avatar: u.image,
          })

          // LoginService.setIdentityAsync({
          //   ...identity,
          //   username: u.username,
          //   role: result?.item?.role?.title,
          //   avatar: u.image,
          // })

        }

        setTimeout(() => {
          setLoadingButton(false);
          // setUser({
          //   ...u,
          //   priv_confirmPassword: ''
          // })
          navigate(`${BASE_ROUTE + ROUTE_NAME.usuarios}`);
        }, 300)
      }

    } catch (e) {
      // handleSnackbar("Ha habido un error al crear el registro", "success");
      setLoadingButton(false);
      console.error(e)
    }
  }

  const toggleFormVisibility = () => setIsEditing(!isEditing);

  const [isEditing, setIsEditing] = useState(false); // Estado para manejar si se está en modo edición

  // Función para manejar el click en el botón de editar/crear
  const handleActionButtonClick = async (value: User) => {
    try {
      if (typeAction === 'add') {
        await add(value);
        setIsEditing(true);
      } else {
        await update(value);
        setIsEditing(!isEditing);
      }
    } catch (e) {
      console.error(e)
    }
  };

  // Función para manejar la cancelación de la edición
  const handleCancelEdit = () => {
    if (formRef.current) {
      formRef.current.createBackup();
    }

    setIsEditing(false);
  };

  const handleCreate = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  }


  return (

    <CContainer >

      <div className="w-full justify-between items-center flex  pt-4 pb-5">
        <div className="flex flex-col">
          <div className="flex items-center hover:underline cursor-pointer" style={{ marginBottom: 20 }} onClick={() => {
            navigate(`${BASE_ROUTE + ROUTE_NAME.usuarios}`)
          }}><HiArrowNarrowLeft size={20} className="mr-1" /> Volver a usuarios</div>
          <span className="text-4xl" style={{ fontWeight: 500 }}>Perfil del usuario</span>
          <span className="small text-medium-emphasis" >{typeAction === "add" ? "Crear" : "Detalle"}</span>


        </div>

        {typeAction === 'update' ? (
          isEditing ? (
            // Mostrar botones de Cancelar y Guardar cuando se esté editando
            <div className="flex justify-between w-[200px]">
              <RippleButton className={clsx(`py-2 px-3 rounded-xl`, {
                'bg-gray-400': !isEditing,
                'bg-gray-600': isEditing
              })}
                style={{
                  backgroundColor: !isEditing ? COLORS.principalColor : COLORS.disabledColor, // Asumiendo que tienes un secondaryColor
                  // Asumiendo que tienes un secondaryColor

                }} onClick={handleCancelEdit} >Cancelar</RippleButton>
              <RippleButton className={clsx(`py-2 px-3 rounded-xl`, {
                'bg-gray-400': !isEditing,
                'bg-gray-600': isEditing
              })}
                style={{
                  // backgroundColor: !isEditing ? COLORS.principalColor : COLORS.disabledColor, 
                  backgroundColor: COLORS.principalColor,

                }} onClick={handleCreate}>Guardar</RippleButton>
            </div>
          ) : (
            // Mostrar botón de Editar cuando no se esté en modo edición
            <RippleButton className={clsx(`py-2 px-3 rounded-xl`, {
              'bg-gray-400': !isEditing,
              'bg-gray-600': isEditing
            })}
              style={{
                backgroundColor: !isEditing ? COLORS.principalColor : COLORS.disabledColor, // Asumiendo que tienes un secondaryColor
                // Asumiendo que tienes un secondaryColor

              }} onClick={() => setIsEditing(true)}>Editar</RippleButton>
          )
        ) : (
          // Mostrar botón de Crear cuando el tipo de acción sea 'add'
          <RippleButton className={clsx(`py-2 px-3 rounded-xl`, {
            'bg-gray-400': !isEditing,
            'bg-gray-600': isEditing
          })}
            style={{
              backgroundColor: !isEditing ? COLORS.principalColor : COLORS.disabledColor, // Asumiendo que tienes un secondaryColor
              // Asumiendo que tienes un secondaryColor

            }} onClick={handleCreate}>Crear</RippleButton>
        )}
      </div>


      <CCard className="">
        <CCardBody>
          <CRow>
            {user && typeAction && (
              <UserForm
                ref={formRef}
                loadingButton={loadingButton}
                user={user}
                typeAction={typeAction}


                handleActionButtonClick={handleActionButtonClick}

                // add={add}
                // update={update}
                checkPassword={handleCheckPassword}
                checkUsername={handleCheckUsername}
                disabled={!isEditing}
              />
            )}

          </CRow>
        </CCardBody>
        {/* <CCardFooter>
          <CRow xs={{ cols: 1 }} md={{ cols: 5 }} className="text-center">
            Footer
          </CRow>
        </CCardFooter> */}
        <CRow>

        </CRow>
      </CCard>
    </CContainer>
  )
}

export default UserFormDetail



