import { CRUDService } from '../crud-service.service';
import { ConfigurationBot } from '../../../models/db/configuration-bot/configuration-bot';


const apiUrl = process.env.REACT_APP_API_URL;
const nameRoute = 'configuration';

export class ConfigurationBotService extends CRUDService<ConfigurationBot> {

    constructor() {
        super(apiUrl, nameRoute);
    }

}
