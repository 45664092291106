import { CRow } from "@coreui/react-pro";
import { UserSocialMedia } from "../../../../core/models/db";
import { Divider } from "../../../components";
import clsx from "clsx";
import moment from "moment";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { IoInformationCircleOutline } from "react-icons/io5";
import { useConversation } from "../../../../core/hooks/db/conversation/useConversation";
import { useConversationStore } from "../../../../core/store";
import { RippleButton } from "../../../components/ui/ripple-button/RippleButton";
import { FaRegCheckCircle } from "react-icons/fa";
import { RiForbidLine } from "react-icons/ri";
interface ConversationInformationProps {
    params: any;
    platform: string;
    userSocialMedia: UserSocialMedia;
}

const ConversationInformation = (props: ConversationInformationProps) => {
    const { params, platform, userSocialMedia } = props;
    const { bannedUsers } = userSocialMedia;

    const { conversationSelected, setConversationSelected, setErrorNotFoundConversation } = useConversationStore();

    const { getConversationByIdSession } = useConversation();


    const existParamInit = (params) => {
        return params && params?.init_bot_web;
    }
    // Función para renderizar los chips de parámetros
    const renderParameterChips = () => {
        // Verifica si params es nulo o un objeto vacío
        if (!params || Object.keys(params).length === 0 || Object.keys(params).length === 1 && existParamInit(params)) {
            return <div className="text-sm">No hay parámetros disponibles</div>;
        }

        // Mapea los parámetros a elementos visuales (chips)
        return Object.entries(params).map(([key, value], index) => {
            if (key === "init_bot_web") return null;
            return (
                <div key={index} className="bg-blue-100 text-blue-800  text-sm font-semibold mr-2 mb-2 px-2.5 py-0.5 rounded-xl ">
                    {key}: {value.toString()}
                </div>
            )
        });
    };


    const getByIdSession = async (idSession: string) => {

        if (conversationSelected.idSession === idSession) return;

        const result = await getConversationByIdSession(idSession);
        console.log(result);
        if (result && result.ok && result.item) {
            setConversationSelected(result.item);
        } else {
            setErrorNotFoundConversation(true);
        }
    }

    return (
        <CRow className="">
            <div className="flex items-center text-2xl">
                <IoInformationCircleOutline className='mr-2' />
                <span>Info. del contacto</span>
            </div>
            <Divider />
            <div className="flex flex-col">
                <div className="text-lg"><b>Contactado en {platform.toLowerCase()}</b></div>
                {platform === "WEB" ? (
                    <div> <b>ID:</b> {userSocialMedia.id}</div>
                ) : (
                    <div> <b>ID:</b> {userSocialMedia.idUserPlatform}</div>
                )}
                <div><b>Cantidad chats:</b> {userSocialMedia.conversations.length}</div>

                <div className="flex items-center"> <b>Lead generado:</b> {Boolean(params?.lead)
                    ? <FaRegCheckCircle className="ml-1 text-green-700" />
                    : <RiForbidLine className="ml-1 text-red-600" size={18} />}
                </div>

                <div className="text-sm mt-2"> <b>Nombre:</b> {params?.name}</div>



                {params?.email && (
                    <a
                        href={`mailto:${params.email}`}
                        className="text-blue-500 hover:text-blue-700 text-sm"
                    >
                        <b>Email:</b> {params.email}
                    </a>
                )}
                {params?.phone && (
                    <a
                        href={`tel:${params.phone}`}
                        className="text-blue-500 hover:text-blue-700 text-sm"
                    >
                        <b>Teléfono:</b> {params.phone}
                    </a>
                )}
                <div className="text-sm"><b>Método de contacto:</b> {params.contact_method}</div>
                {(params?.start_date && params?.end_date) && params?.start_date === params?.end_date && (
                    <div className=" text-gray-700 text-sm">
                        <span className="font-semibold text-gray-800">Fecha de Contacto: </span>
                        {moment(params.start_date).format("DD/MM/YYYY HH:mm")}
                 
                    </div>
                )}


                <>
                    {(params?.start_date !== params?.end_date) && params?.start_date && (
                        <div className="text-sm text-gray-700">
                            <span className="font-semibold text-gray-800">Horario comienzo: </span>
                            {moment(params?.start_date).format("DD/MM/YYYY HH:mm")}
                        </div>
                    )}
                    {(params?.start_date !== params?.end_date) && params?.end_date && (
                        <div className="text-sm text-gray-700">
                            <span className="font-semibold text-gray-800">Horario final: </span>
                            {moment(params?.end_date).format("DD/MM/YYYY HH:mm")}
                        </div>
                    )}
                </>

                {params?.project_idea
                    && (
                        <div className="text-sm mt-2"> <b>Requerimientos:</b> {params?.project_idea}</div>
                    )}




                {/* <span className="text-red-700 font-bold">Ver conversaciones (sin hacer)</span> */}
                {/* <Divider /> */}
                {/* <span className="text-lg" style={{ fontWeight: 500 }}>Parámetros</span>
                <div className="flex flex-wrap mt-2">
                    {renderParameterChips()}
                </div> */}
                <Divider />
                <span className="text-lg" style={{ fontWeight: 500 }}>Historial de expulsiones</span>
                <div className="flex flex-col mt-2">
                    {bannedUsers.map((ban, index) => (
                        <RippleButton className="w-full rounded-xl px-2 my-0.5" key={index} rippleColor="rgba(135, 135, 135, 0.4)" onClick={() => getByIdSession(ban.idSession)}>
                            <div className="flex items-center justify-between w-full  cursor-pointer" >
                                <span className={clsx("font-bold", {
                                    "text-orange-400": ban.banType.startsWith("T"),
                                    "text-red-600": ban.banType.startsWith("P"),
                                })}>({ban.banType.substring(0, 1)}.)</span>
                                <span className="text-sm text-gray-400">{moment(ban.fromDate).format("DD-MM-YYYY")}</span>
                                <div className="flex items-center text-sm text-gray-600" >
                                    <HiMagnifyingGlass size={14} className="antialiased" />
                                </div>
                            </div>
                        </RippleButton>
                    ))}
                </div>
            </div>
        </CRow>
    );
};

export default ConversationInformation;
