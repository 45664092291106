export function isColorDark(color: string) {
    if (!color) {
        return '';
    }
    // Elimina el hash si está presente
    const hex = color.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // Cálculo de luminancia
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // Devuelve true si el color es oscuro
    return luminance < 0.5;
}

export function adjustColor(color: string, amount: number) {
    if (!color) {
        return '';
    }
    return '#' + color.replace(/^#/, '').replace(/../g, (color: string) =>
        ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}

export function getContrastColor(color: string, amount = 15) {
    if (!color) {
        return '';
    }
    // Determina si el color es oscuro o claro
    const dark = isColorDark(color);

    let amountToAdjust = amount;
    // Ajusta el color en la dirección opuesta dependiendo de si es oscuro o claro
    amountToAdjust = dark ? amountToAdjust : -amountToAdjust;
    return adjustColor(color, amount);
}
