
import React, { useState, useEffect } from 'react';
import { CCard } from '@coreui/react-pro';
import { BarAndLineChartCustom } from '../../components/@analitycs';
import { ButtonGroup, Button } from '@mui/material';
import { COLORS } from '../../../core/constants/colors';
import moment from 'moment';
import { CiWarning } from 'react-icons/ci';

interface Analytic3Props {
    data: any[];
    error: boolean;
    loading: boolean;
}

export const Analytic3 = ({ data, error, loading }: Analytic3Props) => {
    const [granularity, setGranularity] = useState('daily');
    const [hideDaily, setHideDaily] = useState(false);
    const [hideWeekly, setHideWeekly] = useState(false);

    // Esta función se ejecutará cada vez que cambie la granularidad
    // y ajustará los datos que se pasan al gráfico
    useEffect(() => {
        // Ajustar los datos aquí si es necesario
    }, [granularity, data]);

    useEffect(() => {
        if (!data || data.length === 0) return;
        const startDate = data[0]?.date;
        const endDate = data[data.length - 1]?.date;

        const monthsDiff = moment(endDate).diff(moment(startDate), 'months', true);

        const shouldShowDaily = monthsDiff <= 3;
        const shouldShowWeekly = monthsDiff <= 12;
        setHideDaily(!shouldShowDaily);
        setHideWeekly(!shouldShowWeekly);

        if (!shouldShowDaily && granularity === 'daily') {
            if (shouldShowWeekly) {
                setGranularity('weekly');
            } else {
                setGranularity('monthly');
            }
        }

        // if (shouldShowDaily && granularity === 'monthly' || granularity === 'quarterly') {
        //     setGranularity('daily');
        // }
    }, [data]);

    const selectDataByGranularity = (data, granularity) => {
        return data.map(d => {
            switch (granularity) {
                case 'daily':
                    return { ...d, date: d.date };
                case 'weekly':
                    return { ...d, date: d.week };
                case 'monthly':
                    return { ...d, date: d.month };
                case 'quarterly':
                    return { ...d, date: d.quarter };
                default:
                    return d;
            }
        });
    };

    const dataForChart = selectDataByGranularity(data, granularity);


    const isActive = (buttonGranularity) => {
        return granularity === buttonGranularity;
    };

    const getButtonStyle = (buttonGranularity) => ({
        backgroundColor: granularity === buttonGranularity ? COLORS.principalColor : undefined,
        color: granularity === buttonGranularity ? 'white' : undefined,
        '&:hover': {
            backgroundColor: granularity === buttonGranularity ? COLORS.principalColor : undefined,
        },
    });

    return (
        <>

            <CCard className="rounded-xl p-3 my-4" style={{ height: 400 }}>

                <div className='flex flex-col md:flex-row justify-between items-center mx-4 my-2'>
                    <h2 className='text-2xl font-semibold'>Mensajes Consumidos</h2>
                    {!loading && !error && (
                        <ButtonGroup size="small" aria-label="small outlined button group">
                            {!hideDaily && <Button onClick={() => setGranularity('daily')} sx={getButtonStyle('daily')}>Diario</Button>}
                            {!hideWeekly && <Button onClick={() => setGranularity('weekly')} sx={getButtonStyle('weekly')}>Semanal</Button>}
                            <Button onClick={() => setGranularity('monthly')} sx={getButtonStyle('monthly')}>Mensual</Button>
                        </ButtonGroup>
                    )}

                </div>

                {loading && (
                    <div className="flex justify-center items-center h-full mt-5">
                        <div className="flex justify-center items-center">
                            <div className="animate-spin rounded-full h-[32px] w-[32px] border-t-2 border-b-2 border-gray-900"></div>
                        </div>
                    </div>
                )}

                {error && (
                    <div className="flex justify-center items-center mx-4 my-2 h-full">
                        <div className="text-center">
                            <CiWarning size={150} className="text-yellow-500 mx-auto" />
                            <p className="mt-4 text-lg font-semibold text-gray-700">
                                No se puede mostrar la información
                            </p>
                        </div>
                    </div>
                )}


                {!loading && !error && (
                    <BarAndLineChartCustom
                        hasLegend={false}
                        dataKeyBar="Mensajes"
                        dataKeyLine=""
                        data={dataForChart}

                        barColor="#ffbe0a"
                        // lineColor="#82ca9d"

                        timeFormat={granularity as any}
                        aggregateType="analytics3"
                    />
                )}
            </CCard>
        </>
    );
};
