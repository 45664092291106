import { update } from 'react-spring';
import { Pagination } from '../../../interfaces/pagination';
import { ReasonForDeactivationProps } from '../../check-table/useCheckTable';
import { NotificationService } from '../../../services/db/notification/notification.service';
import { NotificationRBC } from '../../../models/db';
import { G } from '@react-pdf/renderer';



export const useNotification = () => {

    const service = new NotificationService();


    const markAllAsRevised = () => {
        return new Promise<any>((res, rej) => {
            service.markAllAsRevised()
                .then((item) => {
                    if (item && item.data && item.data?.ok) {
                        res(item.data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })
        })
    }



    const updateNotification = (body: NotificationRBC) => {
        return new Promise<any>((res, rej) => {
            service.update(body.id, body)
                .then((item) => {
                    if (item && item.data && item.data?.ok) {
                        res(item.data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })
        })
    }

    const getNotification = (pagination: Pagination = { page: 1, itemsPerPage: 5 }) => {
        return new Promise<any>((res, rej) => {
            service.getAll(pagination)
                .then((item) => {

                    if (item && item.data && item.data?.ok) {
                        res(item.data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })
        })
    }


    const getNotificationById = (id: any) => {
        return new Promise<any>((res, rej) => {
            service.getById(id)
                .then((item) => {
                    if (item && item.data && item.data?.ok) {
                        res(item.data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })
        })
    }

    const getAllNotificationId = (reasonForDesactivation: ReasonForDeactivationProps[] = []) => {
        return new Promise<any>((res, rej) => {
            console.log(reasonForDesactivation)
            service.getAllId(reasonForDesactivation)
                .then((item) => {
                    if (item && item.data && item.data?.ok) {
                        res(item.data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })
        })
    }

    const deleteNotification = (idList: string[] | number[], action: boolean) => {
        return new Promise<any>((res, rej) => {
            service.delete(idList, action)
                .then((item) => {
                    if (item && item.data && item.data?.ok) {
                        res(item.data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })
        })
    }









    return {
        get: getNotification,
        getAllId: getAllNotificationId,
        getById: getNotificationById,
        delete: deleteNotification,
        update: updateNotification,
        
        markAllAsRevised,
        getNotification,
        getNotificationById,
        deleteNotification,
        updateNotification,
        getAllNotificationId
    }
}