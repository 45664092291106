import { CCard, CCol, CContainer, CRow } from "@coreui/react-pro";
import HandleBotConversationPayload from "./components-show-chat/HandleBotConversationPayload";
import { useConversationStore } from "../../../core/store";
import ModalErrorChat from "./modal-error-chat/ModalErrorChat";
import ConversationSide from "./conversationSide/ConversationSide";
import ConversationInformation from "./conversationInformation/ConversationInformation";
import { BiDotsVerticalRounded } from "react-icons/bi";
import ConversationSideNormalPagination from "./conversationSideNormalPagination/ConversationSideNormalPagination";

export const ConversationPage = () => {
    // const [conversation, setConversation] = useState<Conversation>(undefined);
    const { conversationSelected } = useConversationStore(state => state);

    return (
        <CContainer className="overflow-y">
            <CRow>
                <div className="pt-4 pb-5">
                    <span className="text-4xl" style={{ fontWeight: 500 }}>Conversaciones</span>
                </div>

            </CRow>
            <CCard className="rounded-xl p-3">
                <CRow >
                    <CCol xs={6} md={3} lg={3} className="sidebar-visible ">

                        <div className="sidebar-content ">
                            {/* <ConversationSide /> */}
                            <ConversationSideNormalPagination />
                        </div>
                    </CCol>

                    {conversationSelected && conversationSelected.conversationText && (
                        <>
                            <CCol xs={12} md={6} className="flex flex-col thin-scrollbar rounded-lg no-select bg-gray-100 p-0"
                                style={{
                                    maxHeight: 820,
                                    overflow: 'hidden',
                                }}>

                                {/* <div className="sticky top-0 z-10 flex items-center justify-end w-full bg-gray-400 h-[115px]">

                                    <button className="mx-4" >
                                        <BiDotsVerticalRounded size={30} />
                                    </button>
                                </div> */}

                                <div className="overflow-auto px-10 py-3">
                                    {/* Este div contendrá el contenido desplazable. Asegúrate de que el contenido aquí se pueda desplazar según sea necesario. */}
                                    {HandleBotConversationPayload({
                                        rrss: conversationSelected.platform as any,
                                        botPayload: conversationSelected.conversationText as any
                                    })}
                                </div>

                            </CCol>

                            <CCol className="hidden sm:block py-0" style={{ maxHeight: 820, overflow: 'auto', padding: 20 }}>
                                <ConversationInformation
                                    params={conversationSelected.paramsJson}
                                    platform={conversationSelected.platform}
                                    userSocialMedia={conversationSelected.userSocialMedias}
                                />
                            </CCol>
                        </>


                    )}
                </CRow>
            </CCard>
            {/* Modal error */}
            <ModalErrorChat />
        </CContainer >
    )
}
