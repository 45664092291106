import { create } from 'zustand';


interface ActionBarState {
    isShowActionBar: boolean;
    reset: boolean;
    table?: string; 
    options?: any; 
}


interface ActionBarActions {
    showActionBar: () => void;
    hideActionBar: () => void;
    loadActionBar: (options: any) => void; 
    resetPageActionBar: () => void;
}


export const useActionBarStore = create<ActionBarState & ActionBarActions>(
    (set, get) => ({
        // Estado inicial
        isShowActionBar: false,
        reset: false,
        table: undefined,
        options: undefined,

        // Acciones
        showActionBar: () => set({ isShowActionBar: true, reset: false }),
        hideActionBar: () => set({ isShowActionBar: false }),
        loadActionBar: (options) => set({ options, reset: false }),
        resetPageActionBar: () => set({ isShowActionBar: false, reset: true }),
    }))
