import React, { useState, useEffect, useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { useUser } from '../../../core/hooks/db/user/useUser';
import { useRole } from '../../../core/hooks/db/role/useRole';
import { useDocumentAndorraResidence } from '../../../core/hooks/specialCaseAutocomplete/useDocumentAndorraResidence';
import { TableNamesType } from '../../../core/interfaces';
import { Util } from '../../../core/util/util';


const ChooseHook = (table: TableNamesType) => {
    const userHook = useUser();
    const roleHook = useRole();



    const documentAndorraResidenceHook = useDocumentAndorraResidence();


    let hookFunction;
    switch (table) {
        case "users":
            hookFunction = userHook;
            break;
        case "webAllowed":
            hookFunction = roleHook;
            break;
        case "role":
            hookFunction = roleHook;
            break;
        case "special_case_typeDocumentStep_1" as any:
            hookFunction = documentAndorraResidenceHook;
            break
        case "special_case_typeCompany" as any:
            hookFunction = "No existe hook";
            break;
        case 'special_case_type_welcome_bot' as any:
            hookFunction = "No existe hook";
            break;
    }

    return hookFunction
}

function fillOutObject(table: TableNamesType, rows: any[], defaultRow) {

    return new Promise<any>((res, rej) => {
        let body = [];
        // console.log(rows)

        if (rows.length === 0) {
            res(body);
        }

        for (let [i, row] of rows.entries()) {
            switch (table) {
                case "users":
                    row = { ...row, text: Util.capitalize(row.username), value: row.id }
                    break;
                case "role":
                    let title = "";
                    if (row.title === "ROLE_ADMIN") {
                        title = "Administrador";
                    } else if (row.title === "ROLE_USER") {
                        title = "Usuario";
                    }

                    if (title) {
                        row = { ...row, text: title, value: row.id }
                    };

                    break;
                case "webAllowed":
                    row = { ...row, text: Util.capitalize(row.title), value: row.id }
                    break;
                case "special_case_type_welcome_bot" as any:
                    row = { ...row, text: Util.capitalize(row.title), value: row.id }
                    break;

            }
            if (defaultRow && defaultRow.value === row[defaultRow.key]) {
                row.selected = true;
            } else {
                row.selected = false;
            }

            body.push(row)

            if (rows.length - 1 == i) {
                res(body);
            }
        }
    })
}

interface AutocompleteProps {
    /** Si obtiene los datos a través de esta variable entonces no hará la petición al back */
    defaultData?: any[];
    multiselect?: boolean;

    placeholder?: string;
    disabled?: boolean;
    id?: string;
    name: string;
    defaultValue?: {
        key: string,
        value: any
    };
    table: TableNamesType;
    onChange: (newValue: any) => void;
    // onBlur: (e: React.FocusEvent<HTMLSelectElement>) => void;
    onBlur: (event: React.FocusEvent<HTMLDivElement>) => void;
    styleCustom?: boolean;

    className?: string;
}



export const AutocompleteMui = (props: AutocompleteProps) => {




    const {
        defaultData,
        placeholder,
        disabled,
        id,
        name,
        defaultValue,
        table,
        onChange,
        onBlur,
        multiselect = false
    } = props;

    // Ejemplo de cómo puedes elegir el hook basado en la variable table
    const { autocomplete } = ChooseHook(table);

    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);


    useEffect(() => {
        (async () => {
            setLoading(true);

            let transformedData;
            if (defaultData) {
                transformedData = await fillOutObject(table, defaultData, defaultValue);
            } else {
                const response = await autocomplete();
                if (response.ok) {
                    let rows = response.item;
                    transformedData = await fillOutObject(table, rows, defaultValue);
                }
            }

            setOptions(transformedData);
            setLoading(false);

            // Establecer el valor seleccionado utilizando el valor por defecto.
            if (defaultValue) {
                // Encuentra la opción que corresponde al valor por defecto
                const defaultOption = transformedData.find(option => option.value === defaultValue.value);
                // Establecer el valor seleccionado al estado
                setSelectedValue(defaultOption || null); // Si no encuentra la opción, se establece a null
            }

        })();
    }, []); // Este useEffect solo se ejecuta una vez en el montaje del componente



    const sendUniqueItem = (newValue) => {
        console.log("on change", newValue)

        let value: any;
        if (newValue) {
            value = newValue;

            let item = options.find(item => item.id == value);
            let obj: any = {

                name: name,
                value: value,
                id: value.id,
                // NUEVO, para incluir el objeto entero. name y value son obligatorios por el
                item

            }

            console.log("estoy aqui?", obj)
            onChange(newValue)
        } else {
            if (!name) {
                throw new Error("No existe la propiedad name en el autocomplete");
            } else {
                console.warn("a ver?", newValue)
                // throw new Error("Hubo un error en el autocomplete");
                let obj: any = {
                    target: {
                        name: null,
                        value: null,
                    }
                }
                onChange(obj)


            }
        }
    }

    const sendMultipleItem = (e) => {
        console.log("on change", e)

        let values: any;
        if (e && e.length > 0) {
            values = e;
            let itemList = [];
            itemList = options.filter(itemf => values.find(valuesAutoComp => valuesAutoComp.value == itemf.id))

            // let item = body.find(item => item.id == values);
            let obj: any = itemList
            onChange(obj)
        } else {
            if (!name) {
                throw new Error("No existe la propiedad name en el autocomplete");
            } else {
                console.warn(e)
                // throw new Error("Hubo un error en el autocomplete");
                let obj: any = []
                onChange(obj)


            }
        }
    }

    // const selectedValues = React.useMemo(
    //     () => options.filter((v) => v.selected),
    //     [selectedValue, options],
    // );

    return (
        <Autocomplete

            id={id}
            disabled={disabled}
            options={options}
            getOptionLabel={(option) => option.text}
            loading={loading}
            onChange={(event, newValue) => {
                multiselect ? sendMultipleItem(newValue) : sendUniqueItem(newValue);

                setSelectedValue(newValue);
            }}
            // onBlur={onBlur}
            renderInput={(params) => (
                <TextField
                    variant='standard'
                    {...params}
                    label={placeholder}
                    placeholder={placeholder}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            value={selectedValue}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            multiple={multiselect}
        />
    );
};
