import CIcon from '@coreui/icons-react';
import { CBadge } from '@coreui/react-pro';
import React, { ElementType, ReactNode, forwardRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RippleButton } from '../ripple-button/RippleButton';
import clsx from 'clsx';
import { getContrastColor } from '../../../../core/util/colors/adjust-color';
import { COLORS } from '../../../../core/constants/colors';

export interface Badge {
    text: ReactNode;
    color?: string;
}

export interface NavItemProps {
    name: ReactNode;
    icon?: ReactNode;
    badge?: Badge;
    to: string;
    roles?: ("ROLE_ADMIN" | "ROLE_USER")[];
}

type Props = NavItemProps;

const NavItemCustom = forwardRef<HTMLAnchorElement, Props>((props, ref) => {
    const location = useLocation();
    const isActive = location.pathname === props.to;
    const navigate = useNavigate();

    if (!props) {
        console.error('No props');
        return null;
    }

    const { name, to, icon, badge, roles } = props;

    return (
        <li className='w-full flex justify-center py-1'>
            <RippleButton
                className='rounded-lg w-full'
                style={{ maxWidth: '90%' }}
                onClick={() => navigate(to)} // Usando navigate en el onClick
            >
                <div
                    className={clsx(
                        `flex items-center no-underline p-2 rounded-lg transition-colors duration-150 w-full justify-between`,
                        {
                            'text-white bg-opacity-20': isActive, // Considera ajustar el estilo de background para el estado activo
                            'text-gray-400 hover:text-gray-200': !isActive,
                        }
                    )}
                    style={{
                        paddingLeft: '1rem',
                        paddingRight: '1rem',
                        backgroundColor: isActive ? getContrastColor(COLORS.tertiaryColor) : 'transparent',
                    }}
                >
                    {icon && typeof icon === 'string' ? (
                        <CIcon icon={icon} customClassName="text-lg ml-4" />
                    ) : (
                        <span className="ml-4">{icon}</span>
                    )}
                    <span className="flex-1 min-w-0 pl-3">
                        <span className="block truncate">{name}</span>
                    </span>

                    {badge && (
                        <CBadge color={badge.color} className="ml-auto">
                            {badge.text}
                        </CBadge>
                    )}
                </div>
            </RippleButton>
        </li>
    );
});

NavItemCustom.displayName = 'NavItemCustom';
export default NavItemCustom;
