import { CContainer, CForm, CHeader, CRow } from "@coreui/react-pro";
import { FormControlLabel, Switch } from "@mui/material";
import { ImNotification } from "react-icons/im";
import { Divider } from "..";
import { useEffect, useState } from "react";
import { Identity, LoginService } from "../../../core/services/login/login.service";
import { useUserPushNotificationSettings, usePushNotificationType } from "../../../core/hooks";
import { PushNotificationType } from "../../../core/models/db/push-notification-types";
import { UserPushNotificationSetting } from "../../../core/models/db/user-push-notification-settings";
import { CodePush, usePushNotificationStore, useUIStore } from "../../../core/store";


type action = "update" | "create";
type UserPushNotificationSettingWithAction = UserPushNotificationSetting & {
    action: action;
};


const Setting = () => {

    const { getPushNotificationType } = usePushNotificationType();
    const { getByIdUser, add, update } = useUserPushNotificationSettings();

    const [identity, setIdentity] = useState<Identity>();
    const [pushTypeList, setPushTypeList] = useState<PushNotificationType[]>([]);

    const [userPushSettingList, setUserPushSettingList] = useState<UserPushNotificationSettingWithAction[]>([]);
    const [initialUserPushSettingList, setInitialUserPushSettingList] = useState<UserPushNotificationSettingWithAction[]>([]);

    const [isChanged, setIsChanged] = useState(false);

    const { asideShow } = useUIStore();
    const { setHasBannedUser, setHasConversation, setHasLead, setHasQuotaMessage } = usePushNotificationStore();


    // useEffect que se ejecuta una vez cuando el componente se monta. Se utiliza para inicializar el componente,
    // cargando la identidad del usuario y la configuración de notificaciones push.
    useEffect(() => {
        if (asideShow || !identity) {
            getInit();
        }
    }, [asideShow]);

    // useEffect que observa los cambios en asideShow y isChanged. Si asideShow es false y hubo cambios,
    // se aplican esos cambios en la base de datos.
    useEffect(() => {
        // console.log(asideShow, isChanged);
        const applyChanges = async () => {
            if (isChanged && !asideShow) {
                console.log("Aplicando cambios en la base de datos");
                const changedSettings = getChangedSettings();

                for (const setting of changedSettings) {
                    const { action, ...rest } = setting;
                    if (action === "update") {
                        await update(rest.id, rest);
                    } else if (action === "create") {
                        await add(rest);
                    }
                }

                setIsChanged(false); // Restablecer isChanged después de aplicar los cambios
            }
        };

        applyChanges();
    }, [asideShow, isChanged, userPushSettingList, add, update]);

    // Función asíncrona para inicializar la identidad del usuario y cargar la configuración de notificaciones push.
    const getInit = async () => {
        try {
            let i = await LoginService.getIdentityAsync();
            setIdentity(i);

            let list = await getPushNotificationTypePage();
            await getUserPush(i.idUser, list);
        } catch (e: any) {
            console.error(e);
        }
    };

    // Función asíncrona para obtener la lista de tipos de notificaciones push disponibles.
    const getPushNotificationTypePage = async () => {
        try {
            const result = await getPushNotificationType({ page: 1, itemsPerPage: 10000 });
            if (result && result.ok) {
                let data = result.item.rows.map(item => {
                    return {
                        id: item.id,
                        name: item.name,
                        code: item.code
                    };
                });
                setPushTypeList(data);
                return data;
            } else {
                return {};
            }
        } catch (e) {
            console.error(e);
        }
    };

    // Función asíncrona para cargar la configuración actual de notificaciones push del usuario.
    // const getUserPush = async (idUser, pushTypeList) => {
    //     try {
    //         const result = await getByIdUser(idUser);
    //         if (result && result.ok) {
    //             const userSettingsMap = result.item.reduce((acc, item) => {
    //                 acc[item.idPushNotificationTypeFk] = item;
    //                 return acc;
    //             }, {});

    //             const allUserSettings = pushTypeList.map(type => {





    //                 if (userSettingsMap[type.id]) {
    //                     return {
    //                         ...userSettingsMap[type.id],
    //                         enabled: userSettingsMap[type.id].enabled,
    //                         action: "update"
    //                     };
    //                 } else {
    //                     return {
    //                         idUserFk: idUser,
    //                         idPushNotificationTypeFk: type.id,
    //                         enabled: false,
    //                         action: "create"
    //                     };
    //                 }
    //             });

    //             setUserPushSettingList(allUserSettings);
    //             setInitialUserPushSettingList(JSON.parse(JSON.stringify(allUserSettings))); // Copia inicial para comparar cambios
    //         }
    //         return result;
    //     } catch (e) {
    //         console.error(e);
    //     }
    // };

    const getUserPush = async (idUser, pushTypeList) => {
        try {
            // Suponiendo que `getByIdUser` devuelve todas las configuraciones de notificación del usuario.
            const result = await getByIdUser(idUser);
            if (result && result.ok) {
                // Construye un mapa de configuraciones existentes para acceso rápido.
                const userSettingsMap = result.item.reduce((acc, item) => {
                    acc[item.idPushNotificationTypeFk] = item;
                    return acc;
                }, {});



                // Itera sobre la lista de tipos de notificación para construir la configuración final.
                const allUserSettings = pushTypeList.map(type => {
                    // Encuentra la configuración existente del usuario para este tipo, si la hay.
                    const userSettingForType = userSettingsMap[type.id];
                    const isEnabled = userSettingForType ? userSettingForType.enabled : false;
                    console.log(type.code, isEnabled)
                    // Actualiza los estados temporales basados en los códigos y la configuración del usuario
                    switch (type.code) {
                        case CodePush.BANNED_USER:
                            setHasBannedUser(isEnabled);
                            break;
                        case CodePush.QUOTA_MESSAGE:
                            setHasQuotaMessage(isEnabled);
                            break;
                        case CodePush.LEAD:
                            setHasLead(isEnabled);
                            break;
                        case CodePush.CONVERSATION:
                            setHasConversation(isEnabled);
                            break;
                        default:
                            console.log("Código no reconocido", type.code);
                    }

                    if (userSettingForType) {
                        // Si existe una configuración para este tipo, úsala y actualiza según sea necesario.
                        return {
                            ...userSettingForType,
                            enabled: isEnabled,
                            action: "update" // Marca para actualización si ya existe.
                        };
                    } else {
                        // Si no existe una configuración para este tipo, crea una nueva con valores predeterminados.
                        return {
                            idUserFk: idUser,
                            idPushNotificationTypeFk: type.id,
                            enabled: false, // Predeterminado a 'false', ajusta según la lógica de tu aplicación.
                            action: "create" // Marca para creación ya que es una nueva entrada.
                        };
                    }
                });

                setUserPushSettingList(allUserSettings);
                setInitialUserPushSettingList(JSON.parse(JSON.stringify(allUserSettings))); // Copia profunda para comparaciones futuras.
            }
            return result;
        } catch (e) {
            console.error(e);
        }
    };


    // Función para determinar qué configuraciones han cambiado respecto a su estado inicial.
    const getChangedSettings = () => {
        return userPushSettingList.filter((currentSetting, index) => {
            const initialSetting = initialUserPushSettingList.find(initial => initial.idPushNotificationTypeFk === currentSetting.idPushNotificationTypeFk);
            return !initialSetting || currentSetting.enabled !== initialSetting.enabled;
        });
    };

    // Manejador para los cambios en los interruptores de configuración, marcando el estado como cambiado y actualizando la configuración.
    const handleSwitchChange = (idPushNotificationType, checked) => {
        setIsChanged(true);
        const code = pushTypeList.find(type => type.id === idPushNotificationType)?.code;

        switch (code) {
            case CodePush.BANNED_USER:
                setHasBannedUser(checked);
                break;
            case CodePush.QUOTA_MESSAGE:
                setHasQuotaMessage(checked);
                break;
            case CodePush.LEAD:
                setHasLead(checked);
                break;
            case CodePush.CONVERSATION:
                setHasConversation(checked);
                break;
            default:
                console.log("Código no reconocido", code);
        }





        setUserPushSettingList(prevSettings => {
            const newSettings = prevSettings.map(setting => {
                if (setting.idPushNotificationTypeFk === idPushNotificationType) {
                    return { ...setting, enabled: checked };
                }
                return setting;
            });

            console.log("mira newSetting", newSettings);
            return [...newSettings];
        });
    };


    if (!identity) {
        return null;
    }

    return (
        <>
            <CForm className="mt-3">
                {/* <pre>{JSON.stringify(userPushSettingList, null, 2)}</pre> */}
                <CContainer className="flex flex-col">
                    <CRow>
                        <span className="flex items-center text-lg"><ImNotification className="mr-2" /> Notificaciones Push</span>
                    </CRow>
                    <Divider />

                    {pushTypeList.map((item) => {
                        const userSetting = userPushSettingList.find(setting => setting.idPushNotificationTypeFk === item.id) || { enabled: false };
                        // console.log(`Render switch for ${item.id}:`, userSetting); // Verifica el valor justo antes de renderizar
                        return (
                            <CRow key={item.id} className="flex items-center justify-between">
                                <FormControlLabel
                                    control={<Switch
                                        checked={userSetting.enabled}
                                        onChange={(e) => handleSwitchChange(item.id, e.target.checked)}
                                    />}
                                    label={item.name}
                                />
                            </CRow>
                        );
                    })}


                </CContainer>
            </CForm>


        </>
    )
}


export default Setting;