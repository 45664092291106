import { cilListFilter } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CButton, CCol, CCollapse, CRow } from '@coreui/react-pro'
import { useEffect, useState } from 'react'
import ModalCover from '../../../components/modalCover/ModalCover'

import { Autocomplete, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material'
import moment from 'moment-timezone'
import { Pagination } from '../../../../core/interfaces'
import { DatePickerRange } from '../../../components'
import { END_DATE_DEFAULT, START_DATE_DEFAULT } from '../../../components/datePickerRange/custom-ranges'
import { platform } from 'os'
import { a } from 'react-spring'


const actionOptions = [
  {
    value: 'contact',
    text: 'Contacto',
    selected: false
  },
  // {
  //   value: 'cita',
  //   text: 'Cita',
  //   selected: false
  // },
  // {
  //   value: 'renting',
  //   text: 'Renting',
  //   selected: false
  // },
  // {
  //   value: 'alquiler diario',
  //   text: 'Alquiler diario',
  //   selected: false
  // },
  // {
  //   value: 'horario y contacto',
  //   text: 'Horario y Contacto',
  //   selected: false
  // },
  // {
  //   value: 'tasar',
  //   text: 'Tasar vehículo',
  //   selected: false
  // }
]




const attClientOptions = [
  {
    value: 'llamada',
    text: 'Llamada',
    selected: false
  },
  {
    value: 'videollamada',
    text: 'Videollamada',
    selected: false
  },
  {
    value: 'whatsapp',
    text: 'Whatsapp',
    selected: false
  }
]



const optionsSocialMedia = [
  {
    value: 'INSTAGRAM',
    text: 'Instagram',
    selected: false
  },
  {
    value: 'WHATSAPP',
    text: 'Whatsapp',
    selected: false
  },
  {
    value: 'FACEBOOK',
    text: 'Facebook',
    selected: false
  },
  {
    value: 'WEB',
    text: 'Web',
    selected: false
  },
  {
    value: 'TELEGRAM',
    text: 'Telegram',
    selected: false
  }
]



export interface ConversationFilterProps {
  search: (pagination2: Pagination, reset: boolean) => void;
}

const ConversationFilter = (props: ConversationFilterProps) => {

  const { search } = props;

  const [visibleA, setVisibleA] = useState(false);
  const [showModal, setShowModal] = useState(false);

  // const [date, setDate] = useState<{ startDate: string, endDate: string }>(undefined);
  const [date, setDate] = useState<{ startDate: any, endDate: any }>({
    // startDate: new Date(new Date(new Date().setDate(new Date().getDate() - 29)).setHours(0, 0, 0, 0)),
    // endDate: new Date(new Date().setHours(23, 59, 59, 999))
    startDate: START_DATE_DEFAULT.format("YYYY-MM-DD HH:mm:ss"),
    endDate: END_DATE_DEFAULT.format("YYYY-MM-DD HH:mm:ss")
  });

  const [userSocialList, setUserSocialList] = useState<{ value: any, text: any, platform: string, selected: boolean, disabled: boolean }[]>([]);

  // const [socialMediaList, setSocialMediaList] = useState<{ value: any, text: any, selected: boolean }[]>(optionsSocialMedia);
  // const [actionList, setActionList] = useState<{ value: any, text: any, selected: boolean }[]>(actionOptions);
  // const [attClientList, setAttClientList] = useState<{ value: any, text: any, selected: boolean }[]>(attClientOptions);

  const [socialMediaList, setSocialMediaList] = useState<{ value: any, text: any, selected: boolean }[]>([]);
  const [actionList, setActionList] = useState<{ value: any, text: any, selected: boolean }[]>([]);
  const [attClientList, setAttClientList] = useState<{ value: any, text: any, selected: boolean }[]>([]);

  const [text, setText] = useState<string>("");


  const [hasNotification, setHasNotification] = useState<boolean>(false);

  /**
   * Todos los usuarios
   */
  const [usmCompleteList, setUSMCompleteList] = useState<{ value: any, text: any, platform: string, selected: boolean, disabled: boolean }[]>([]);


  // const { autocomplete: userSocialAutocomplete } = useUserSocialMedia();

  useEffect(() => {
    (async () => {
      // let token = await LoginService.getTokenAsync();
      let token = ""
      // let result = await userSocialAutocomplete(token, { order: "createdDate", orderBy: "asc", page: 1, itemsPerPage: 10000 });
      let result: any = {};
      if (result && result.ok) {
        // Este array no se puede modificar
        setUSMCompleteList(result.item.map(usm => {
          let textLabel = usm.platform ? usm.platform.substring(0, 3) + " - " + usm.idUserPlatform : usm?.idUserPlatform;
          return {
            value: usm.id,
            text: textLabel,
            platform: usm.platform,
            selected: false,
            disabled: false
          }
        }))

        // Este array es el que se modificará
        setUserSocialList(result.item.map(usm => {
          let textLabel = usm.platform ? usm.platform.substring(0, 3) + " - " + usm.idUserPlatform : usm?.idUserPlatform;
          return {
            value: usm.id,
            text: textLabel,
            platform: usm.platform,
            selected: false,
            disabled: false
          }
        }))
      } else {
        setUSMCompleteList([]);
        setUserSocialList([]);
      }

    })();
  }, [])

  function handleModal(value?: boolean): void {
    setShowModal(value);
  }

  const handleChangeSocialMedia = (event, newValue) => {
    setSocialMediaList(newValue);
  };


  // const handleChangeSocialMedia = (selectedSocialMedias) => {

  //   console.log(selectedSocialMedias)
  //   console.log(selectedSocialMedias)
  //   console.log(selectedSocialMedias)
  //   console.log(selectedSocialMedias)

  //   // Nuevo
  //   // setSocialMedia(selectedSocialMedias)
  //   setSocialMediaList(prevList => {
  //     return prevList.map(rrss => {
  //       const isSelected = selectedSocialMedias.some(selected => selected.value === rrss.value);
  //       return {
  //         ...rrss,
  //         selected: isSelected
  //       }
  //     });
  //   });

  //   const selectedSocialMediaValues = selectedSocialMedias.map(socialMedia => socialMedia.value);

  //   setUserSocialList(prevList => {
  //     // Si no hay ninguna red social seleccionada, todos los usuarios están habilitados
  //     if (selectedSocialMedias.length === 0) {
  //       return prevList.map(userSocial => {
  //         return { ...userSocial, disabled: false };
  //       });
  //     }

  //     // Si hay redes sociales seleccionadas, solo los usuarios con esa plataforma están habilitados
  //     return prevList.map(userSocial => {
  //       if (selectedSocialMediaValues.includes(userSocial.platform.toLowerCase())) {
  //         return { ...userSocial, selected: userSocial.selected, disabled: false };
  //       } else {
  //         return { ...userSocial, selected: false, disabled: true };
  //       }
  //     });
  //   });
  // }


  const handleChangeUserSocialMedia = (event) => {

    setUserSocialList(prevList => {
      return prevList.map(userSocial => {
        if (event.find(e => e.value === userSocial.value)) {
          return { ...userSocial, selected: true };
        } else {
          return { ...userSocial, selected: false };
        }
      });
    });
  }


  const handleChangeAction = (event, newValue) => {
    setActionList(newValue);
  };


  const handleChangeAttClient = (event, newValue) => {
    setAttClientList(newValue);
  };



  const handleDate = (startDate, endDate) => {
    if (startDate && endDate) {
      setDate({ startDate, endDate })
    } else {
      setDate(undefined)
    }
  }

  const [typingTimeout, setTypingTimeout] = useState(null);
  const handleChangeText = (event) => {
    // Si ya hay un timeout, se borra
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    // Se establece un nuevo timeout
    setTypingTimeout(
      setTimeout(() => {
        // save(event.target.value);
        setText(event.target.value)
      }, 250) // Se ejecuta tras 400 milisegundos de haber dejado de escribir
    );

  }

  useEffect(() => {
    save();
  }, [text])


  const save = () => {

    const selectedUsers = userSocialList.filter(item => item);
    const selectedMedias = socialMediaList.filter(item => item);
    const selectedActions = actionList.filter(item => item);
    const selectedAttClients = attClientList.filter(item => item);
    const esUuidValido = (valor) => /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(valor);

    let textFilter: any = {};
    let valueText = {
      value: text && Array.isArray(text) && text.length > 0
        ? text[0]
        : text && text.length > 0
          ? text
          : undefined,
    }

    let socialMedia: any = {};

    console.log(valueText, "valueText")

    textFilter = {
      conversationText: !esUuidValido(valueText.value) ? valueText : undefined,
      idSession: esUuidValido(valueText.value) ? valueText : undefined,
    }



    socialMedia = {
      platform: {
        value: socialMediaList.length > 0 ? socialMediaList.map(s => s.value) : undefined,
        isEnum: true,
      }
    }


    let filterJsons = {
      paramsJson: [
        // Condición 'not' si hasNotification es verdadero
        ...(hasNotification ? [{
          path: ["lead"],
          value: true,
          isJson: true
        }] : []),

        // Condición 'attClient' si attClientList tiene elementos
        ...attClientList.map(s => ({
          path: ["contact_method"],
          value: s.value,
          isJson: true
        })),

        ...actionList.map(s => ({
          path: ["action"],
          value: s.value,
          isJson: true
        }))



      ]
    };

    /**
     * De esta mnanera te asegura de mandar la hora correcta al backend sin importar la zona horaria
     */
    const startDate = moment(date.startDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ') as any;
    const endDate = moment(date.endDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ') as any;


    const searchParams: Pagination = {
      page: 1,
      itemsPerPage: 15,
      startDate,
      endDate,

      filters: { ...textFilter, ...socialMedia },
      filtersJson: filterJsons
    };


    console.log("ENTRO AQUI", searchParams)
    search(searchParams as any, true); // ejecuta la búsqueda con los parámetros creados

    setShowModal(false);
  }






  return (
    <CRow className='m-1' >

      <div className='flex justify-around '>
        <CCol xs={8} className='flex items-center'>
          <TextField
            id="searchtable"
            label="Pon un id de sessión o un palabra"
            type="text"
            variant="standard"
            onKeyUp={handleChangeText}
            fullWidth // Esto hace que el TextField ocupe el ancho completo del contenedor
            margin="normal"
          />

        </CCol>
        {/* <CCol xs={1}><CButton color="light" onClick={() => setVisibleA(!visibleA)} ><CIcon icon={cilListFilter} size='lg' /></CButton></CCol> */}
        <CCol xs={1} className='flex  items-center'>
          <CButton color="light" onClick={() => setShowModal(!showModal)} >
            <CIcon icon={cilListFilter} size='lg' />
          </CButton>
        </CCol>
      </div>

      <CCol xs={10}>

        <CCollapse visible={visibleA}>


          {/* <CCard className="mt-3">
            <CCardBody>
              Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson
              ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt
              sapiente ea proident.
            </CCardBody>
          </CCard> */}
        </CCollapse>
      </CCol>



      <ModalCover
        size='xl'
        title={'Filtrar chat'}
        hasSuccessButton={true}
        hasCloseButton={false}
        handleModal={handleModal}
        show={showModal}
        fnSuccess={save}
      >
        {/* <pre>{JSON.stringify(usmCompleteList, null, 2)}</pre>*/}
        {/* <pre>{JSON.stringify(socialMediaList, null, 2)}</pre> */}


        <div className='flex mt-10'>
          <CCol xs={12} md={6} className="flex flex-col justify-center items-center">
            <div className='w-[80%]'>
              {/* <label style={{ marginBottom: 8 }}>Rango fechas</label> */}
              <Typography>Rango fechas</Typography>
              <DatePickerRange
                defaultStartDate={date?.startDate}
                defaultEndDate={date?.endDate}
                handleDate={handleDate} />
            </div>
          </CCol>

          <CCol xs={12} md={6} className="flex flex-col justify-center items-center">
            <div className='w-[80%]'>
              <Typography>Selecciona Redes Sociales</Typography>
              <Autocomplete
                multiple
                id="tags-outlined"
                defaultValue={socialMediaList.filter(item => item)}
                options={optionsSocialMedia}
                getOptionLabel={(option) => option.text}
                filterSelectedOptions
                onChange={handleChangeSocialMedia}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Redes Sociales"
                    placeholder="Añadir"
                  />
                )}
              />
            </div>
          </CCol>


          {/* <CCol>
            <CMultiSelect
              placeholder="Seleccionar..."
              selectAllLabel="Seleccionar todos"
              options={actionList}
              label="Rama principal"
              onChange={handleChangeAction}
            />
          </CCol> */}


        </div>

        <div className='flex mt-10'>
          <CCol xs={12} md={6} className="flex flex-col justify-center items-center">
            <div className='w-[80%]'>
              <Typography>Selecciona el Método de Contacto</Typography>
              <Autocomplete
                multiple
                id="tags-outlined"
                defaultValue={attClientList.filter(item => item)}
                options={attClientOptions}
                getOptionLabel={(option) => option.text}
                filterSelectedOptions
                onChange={handleChangeAttClient}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Métodos de contacto"
                    placeholder="Añadir"
                  />
                )}
              />
            </div>
          </CCol>

          <CCol xs={12} md={6} className="flex flex-col justify-center items-center">
            <div className='w-[80%]'>
              <Typography>Selecciona la Rama de Conversación</Typography>
              <Autocomplete
                multiple
                id="tags-outlined"
                defaultValue={actionList.filter(item => item)}
                options={actionOptions}
                getOptionLabel={(option) => option.text}
                filterSelectedOptions
                onChange={handleChangeAction}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Rama principal"
                    placeholder="Añadir"
                  />
                )}
              />
            </div>
          </CCol>
        </div>


        <div style={{ marginTop: 30 }}></div>
        <div className='flex mt-10'>

          <CCol xs={12} md={6} className="flex flex-col  justify-center items-center">
            <FormControlLabel
              className='w-[80%]'
              control={
                <Checkbox
                  checked={hasNotification}
                  onChange={(e: any) => setHasNotification(e.target.checked)}
                  name="hasNotification"
                  color="primary"
                />
              }
              label="Conversaciones con notificación"
            />
          </CCol>
        </div>
      </ModalCover>
    </CRow>

  )
}

export default ConversationFilter

