


import React from 'react'
import { CRow } from '@coreui/react-pro';


export class QuickReplyPayload {


    message?: string;
    title?: string | string[];
    url?: string;
    fileType?: FileType;
    quickReplyLinkMultimedia: {
        isLink?: boolean;
        title?: string;
        value?: string;
        shadowMessage?: string;
    }[];
}

type FileType = "image-cloud" | "video-cloud" | "image-local" | "video-local";



export const QuickReplyBot = (quickreply: QuickReplyPayload & { userType: "bot" | "human", bannerColor: string }) => {
    const { title, quickReplyLinkMultimedia, userType, bannerColor } = quickreply;
    const styles = {
        botBubble: {
            margin: "5px",

            alignSelf: userType === 'bot' ? 'flex-start' : 'flex-end',
        }
    };

    const createMarkup = (htmlContent) => {
        return { __html: htmlContent };
      };

    return (
        <div style={styles.botBubble} dangerouslySetInnerHTML={createMarkup(title[0])} >
        </div>
    );
}

