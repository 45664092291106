import { create } from 'zustand';
import { NotificationRBC } from '../../../models/db';

interface NotificationState {
    isUpdateList: boolean;

    notificationsList: NotificationRBC[];
    notificationSelected: NotificationRBC | undefined;
    lastNotificationRevised: NotificationRBC | undefined;
    allRevised: boolean;

    setNotificationsList: (notifications: NotificationRBC[]) => void;
    setNotificationSelected: (notification: NotificationRBC) => void;
    setLastNotificationRevised: (notification: NotificationRBC) => void;
    setAllRevised: (allRevised: boolean) => void;
    setIsUpdateList: (isUpdateList: boolean) => void;


}

export const useNotificationStore = create<NotificationState>((set, get) => ({
    notificationsList: [],
    notificationSelected: undefined,
    lastNotificationRevised: undefined,
    allRevised: false,
    isUpdateList: false,

    setNotificationsList: (notifications) => set(() => ({ notificationsList: notifications })),
    setNotificationSelected: (notification) => set(() => ({ notificationSelected: notification })),
    setLastNotificationRevised: (notification) => set(() => ({ lastNotificationRevised: notification })),
    setAllRevised: (allRevised) => set(() => ({ allRevised: allRevised })),
    setIsUpdateList: (isUpdateList) => set(() => ({ isUpdateList: isUpdateList })),

}));
