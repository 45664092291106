import React from 'react';
import { CCard, CCardBody, CCardHeader } from "@coreui/react-pro";
import { ResponsiveContainer, AreaChart, Area, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';
import { adjustColor, isColorDark } from '../../../../core/util/colors/adjust-color';

const LineChartWidget = ({ title, data, bgColor }) => {
    return (
        <CCard className="mb-4" style={{ backgroundColor: bgColor }}>
            <CCardHeader className="text-white" style={{ backgroundColor: bgColor }}>
                <span style={{ color: isColorDark(bgColor) ? "white" : "black" }}>{title}</span>
            </CCardHeader>
            <div>
                <ResponsiveContainer width="100%" height={150}>
                    <AreaChart data={data} margin={{ top: 10, right: 0, left: 0, bottom: 0 }}>
                        <defs>
                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                                <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                            </linearGradient>
                        </defs>
                        {/* Quitar la cuadrícula de fondo */}
                        <CartesianGrid stroke="none" />
                        <Tooltip />
                        {/* Áreas sin puntos */}
                        <Area type="monotone" dataKey="uv" stroke="none" fillOpacity={1} fill="url(#colorUv)" dot={false} />
                        {/* <Area type="monotone" dataKey="pv" stroke="none" fillOpacity={1} fill="url(#colorUv)" dot={false} /> */}
                        {/* Oculta los ejes */}
                        <XAxis dataKey="name" hide />
                        <YAxis hide />
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        </CCard>
    );
};

export default LineChartWidget;
