import React, { useEffect, useState } from 'react';
import {
    ResponsiveContainer, ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Line
} from 'recharts';


const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        // console.log('payload', payload);

        const total = payload
            .filter(f => f.name !== "Leads")
            .reduce((result, entry) => {
                return result + entry.value
            }, 0);
        return (
            <div className="custom-tooltip bg-white p-2 border">
                <p className="label">{`${label}`}</p>

                <p key={`item-001`} className='font-bold' style={{ color: "#" }}>
                    {`Conversaciones : ${total}`}
                </p>
                {payload.map((entry, index) => (
                    <p key={`item-${index}`} style={{ color: entry.color }}>
                        {`${entry.name} : ${entry.value}`}
                    </p>
                ))}
            </div>
        );
    }

    return null;
};

export const BarHourTimeChartCustom = ({
    timeFormat,
    data,
    xAxisKey,
    barDataKeys,
    dataKeyLine,
    lineDataKeys,
    lineColor,
    barColors = [],
    lineColors = [],
}) => {
    // No es necesario procesar los datos en el useEffect ya que asumimos que
    // viene ya procesado y listo para usar directamente en el gráfico
    return (
        <div style={{ width: '100%', height: 320 }}> {/* Ajusta la altura según sea necesario */}
            <ResponsiveContainer width="100%" height="100%">
                <ComposedChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
                    <CartesianGrid stroke="#f1f1f1" vertical={false} />
                    <XAxis dataKey={xAxisKey} angle={-45} textAnchor="end" fontSize={10} tickMargin={5} />
                    <YAxis />
                    {/* <Tooltip /> */}
                    <Tooltip content={<CustomTooltip active={undefined} payload={undefined} label={undefined} />} />
                    <Legend
                        layout="horizontal" // Puede ser 'horizontal' o 'vertical'
                        align="center" // Puede ser 'left', 'center', o 'right'
                        verticalAlign="top" // Puede ser 'top', 'middle', o 'bottom'
                        wrapperStyle={{
                            scale: 0.3, // Ajusta el tamaño de la leyenda
                            paddingTop: 0, // Ajusta el padding para bajar la leyenda
                            paddingBottom: 0, // Ajusta si necesitas más espacio debajo
                            // Puedes añadir cualquier otro estilo CSS aquí
                        }}
                    />
                    {barDataKeys.map((key, index) => (
                        <Bar key={key} dataKey={key} barSize={20} stackId="a" fill={barColors[index % barColors.length]} />

                    ))}
                    {/* {dataKeyLine && <Line type="monotone" dataKey={dataKeyLine} stroke={lineColor} strokeWidth={2} dot={false} />} */}

                    {lineDataKeys.map((key, index) => (
                        <Line key={key} type="monotone" dataKey={key} stroke={lineColors[index % lineColors.length]} strokeWidth={2} dot={false} />
                    ))}
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    );
};

export default BarHourTimeChartCustom;
