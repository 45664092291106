import axios, { AxiosProgressEvent } from "axios";
import { UploadFile } from "../../../app/components/drag-drop/model/UploadFile";



const apiUrl = process.env.REACT_APP_API_URL;


export class FileService {


    static add(object: UploadFile, onUploadProgress: (progressEvent: AxiosProgressEvent) => void) {

        if (object.file == undefined) throw new Error("No existe fichero para ser guardado");
        let formData: FormData = new FormData();
        let directList = JSON.stringify(object.directoryNameList);
        formData.append("directories", directList);
        formData.append("file", object.file)
        // let headers = {
        //     'Authorization': `Bearer ${token}`
        // };
        return axios.post(
            `${apiUrl}/api/upload-file`,
            formData,
            {
                // headers,
                withCredentials: true,
                onUploadProgress
            },
        );
    }

    static addToBot(object: UploadFile, onUploadProgress: (progressEvent: AxiosProgressEvent) => void) {

        if (object.file == undefined) throw new Error("No existe fichero para ser guardado");
        let formData: FormData = new FormData();
        let directList = JSON.stringify(object.directoryNameList);
        formData.append("directories", directList);
        formData.append("file", object.file)
   
        return axios.post(
            `${apiUrl}/api/bot/upload-file-bot`,
            formData,
            {
                withCredentials: true,
                onUploadProgress
            },
        );
    }
}