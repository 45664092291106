import { useContext, useEffect, useState } from 'react';
import { COLORS } from '../../../core/constants/colors';
import { RippleButton } from '../../components/ui/ripple-button/RippleButton';
import { CContainer, CRow, CCard, CModal, CModalBody, CFooter } from "@coreui/react-pro";
import BannedUserPermanentList from './bannedUserParmentList/BannedUserParmentList';
import BannedUserTemporaryList from './bannedUserTemporaryList/BannedUserTemporaryList';
import { Tab, Tabs, createTheme, ThemeProvider } from '@mui/material';
import { RxCross2 } from 'react-icons/rx';
import { Divider } from '../../components';
import { BannedUser } from '../../../core/models/db';
import clsx from 'clsx';
import { useBannedUser } from '../../../core/hooks/db/banned-user/useBannedUser';
import { LoginService } from '../../../core/services/login/login.service';
import moment from 'moment';
import { useLoader } from '../../components/loader/hook/useLoader';
import { LoaderContext } from '../../components/loader/context/LoaderContext';



function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const BannedUserPage = () => {

    const [identity, setIdentity] = useState(undefined);
    useEffect(() => {
        (async () => {
            const i = await LoginService.getIdentityAsync();
            setIdentity(i);
        })();
    }, [])


    const [loading, setLoading] = useState<boolean>(false);

    const [visible, setVisible] = useState(false);
    const [bannedUserSelected, setBannedUserSelected] = useState<BannedUser>(null);
    const [typeActionModal, setTypeActionModal] = useState<'to-permanent' | 'remove-ban'>(undefined);

    const [tab, setTab] = useState(0);

    const { deleteBannedUser, addBannerUser, updateBannerUser } = useBannedUser();


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };


    const handleModal = (bannedUser: any, typeActionModal: 'to-permanent' | 'remove-ban') => {
        setTypeActionModal(typeActionModal);
        setBannedUserSelected(bannedUser);
        setVisible(true);
    }


    const closeModal = () => {
        setVisible(false);
        setTimeout(() => {
            setTypeActionModal(undefined);
            setBannedUserSelected(null);
        }, 300)
    }

    const handleAction = async (bannedUser: BannedUser, action: 'to-permanent' | 'remove-ban') => {
        try {
            setLoading(true);

            if (action === 'to-permanent') {
                await updateBannerUser({
                    id: bannedUser.id,
                    toDate: moment().add(100, 'years').toDate(),
                    banType: 'PERMANENT',
                    reason: `Baneo permanente desde la plataforma. Creado por el usuario ${identity.idUser}`,
                });
            } else if (action === 'remove-ban') {
                await deleteBannedUser([bannedUser.id], true);
            }

        } catch (e: any) {

            console.error('Error al realizar la acción: ', e.message);
        } finally {
            const backup = tab;
            setTab(undefined);
            setTimeout(() => {
                setTab(backup);
            }, 100)
            setLoading(false);

            closeModal();
        }
    }



    return (
        <CContainer>
            <CRow>
                <div className="pt-4 pb-5">
                    <span className="text-4xl" style={{ fontWeight: 500 }}>Baneos de usuarios</span>
                </div>
            </CRow>

            <CCard className="rounded-xl p-3">

                <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">

                    <Tab label="Temporal" {...a11yProps(0)} />
                    <Tab label="Permanente" {...a11yProps(1)} />
                </Tabs>



                {/* <CRow>
                    <SearchTable nameColumn={["email"]} label={["Email"]} />
                </CRow>
                <Divider color="" marginX={-16} /> */}
                {/* <CRow>
                    <AppliedFilters />
                </CRow>
                <Divider marginX={-16} />
                <CRow className="flex justify-center align-middle items-center">
                    <RoleCheck multiSelect={false} nameTable="role" nameColumn="title" label="Rol" />
                </CRow>
                <Divider marginX={-16} /> */}

                <div className='mt-2'>
                    {tab === 0 && <BannedUserTemporaryList handleModal={handleModal} loading={loading} setLoading={setLoading} />}
                    {tab === 1 && <BannedUserPermanentList handleModal={handleModal} loading={loading} setLoading={setLoading} />}

                </div>

                {/* Modal */}

                <CModal
                    alignment="center"
                    visible={visible}
                    onClose={() => {
                        closeModal();
                    }}
                >
                    <div className="mt-2 flex items-center justify-between bg-transparent px-3">
                        <div className='flex items-center text-lg float-start'>
                            {typeActionModal === 'to-permanent' ?
                                (
                                    <span>¿Desea banear permanentemente al usuario <span className='font-bold'>{bannedUserSelected?.userSocialMedias?.idUserPlatform}</span>?</span>
                                )
                                : (
                                    <span>¿Desea quitar el baneo del usuario <span className='font-bold'>{bannedUserSelected?.userSocialMedias?.idUserPlatform}</span></span>
                                )
                            }
                        </div>
                        <div
                            className="float-end cursor-pointer text-2xl"
                            onClick={() => closeModal()}
                        >
                            <RxCross2 />
                        </div>
                    </div>
                    <Divider />
                    <CModalBody>
                        {typeActionModal === 'to-permanent' && (<span>Al confirmar, el usuario quedará baneado de manera indefinida y perderá todo acceso a las funcionalidades del chatbot.</span>)}
                        {typeActionModal === 'remove-ban' && (<span>Al confirmar, el usuario recuperará el acceso completo al chatbot y sus funciones.</span>)}
                    </CModalBody>


                    <div className="w-full flex justify-end items-center gap-2 mt-2 mb-3">
                        {/* Botón Cancelar */}
                        <RippleButton
                            className="rounded-lg"
                            rippleColor="rgba(135, 135, 135, 0.4)"
                            onClick={() => closeModal()}
                        >
                            <div
                                className="px-3 py-1.5 rounded-lg inline-block hover:bg-gray-100 transition duration-300 ease-in-out"
                            >
                                <span className="text-black no-select" style={{ fontWeight: 600, fontSize: 15 }}>
                                    Cancelar
                                </span>
                            </div>
                        </RippleButton>
                        {/* Botón Confirmar */}
                        <RippleButton
                            className={clsx(`rounded-lg pr-3`)}
                            rippleColor="rgba(135, 135, 135, 0.4)"
                            onClick={() => {
                                handleAction(bannedUserSelected, typeActionModal)
                            }}
                        >
                            <div

                                className="px-3 py-1.5  rounded-lg inline-block hover:bg-red-100 transition duration-300 ease-in-out"
                            >
                                <span className="text-red-700 no-select" style={{ fontWeight: 600, fontSize: 15 }}>
                                    Confirmar
                                </span>
                            </div>
                        </RippleButton>
                    </div>

                </CModal>
            </CCard>
        </CContainer >
    )
}


export default BannedUserPage;

