import { create } from 'zustand';
import { Conversation } from '../../../models/db';



interface ConversationState {
    conversationsList: Conversation[];
    conversationSelected: Conversation;
    errorNotFoundConversation: boolean;

    setConversationsList: (conversations: Conversation[]) => void;
    setConversationSelected: (conversation: Conversation) => void;
    setErrorNotFoundConversation: (error: boolean) => void;
}

export const useConversationStore = create<ConversationState>((set) => ({
    conversationsList: [],
    conversationSelected: undefined,
    errorNotFoundConversation: false,
    setConversationsList: (conversations) => set((state) => ({ conversationsList: conversations })),
    setConversationSelected: (conversation) => set((state) => ({ conversationSelected: conversation })),
    setErrorNotFoundConversation: (error) => set((state) => ({ errorNotFoundConversation: error })),

}));
