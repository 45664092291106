import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Auth } from '../interfaces/routes/interfaces';
import { LoginService } from '../services/login/login.service';
import { first } from 'rxjs';

interface AuthProps {
    isAuth?: boolean;
}


export const useAuth = ({ isAuth }: AuthProps) => {
    const [auth, setAuth] = useState<boolean>(isAuth);
    const [identity, setIdentity] = useState<any>();


    useEffect(() => {

        (async () => {
            try {
                // let identity = localStorage.getItem("identity");
                let identity = await LoginService.getIdentityAsync();
                if (identity) {
                    // setIdentity(JSON.parse(identity))
                    setIdentity(identity)
                } else {
                    logout()
                }
            } catch (e) {
                if (e && e === "Sin permiso i") {
                    console.log(e);
                }
            }
        })();
    }, [])



    const logout = () => {
        setAuth(false);
        localStorage.clear();
    }

    const checkIdentity = async () => {
        return new Promise<any>(async (res, rej) => {
            const identity = await LoginService.getIdentityAsync();
            const exist = (!!identity);

            if (exist) {
                setAuth(true);
                res("");
            } else {
                logout();
                res("");
            }
        })

    }



    return {
        auth,
        identity,

        checkIdentity,
        logout
    }
}