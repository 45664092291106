import React, { useEffect, useState } from 'react';
import {
    ResponsiveContainer,
    ComposedChart,
    Line,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
} from 'recharts';
import _ from 'lodash';

const aggregateData = (data, timeKey, aggregateType) => {
    if (aggregateType === 'default') {
        // La lógica actual para la agregación por Conversaciones, Leads y SessionsEnded
        const groupedData = _.groupBy(data, timeKey);
        return _.map(groupedData, (group, key) => ({
            timeLabel: key,
            Conversaciones: _.sumBy(group, 'Conversaciones'),
            Leads: _.sumBy(group, 'Leads'),
            SessionsEnded: _.sumBy(group, 'SessionsEnded'),
        }));
    } else if (aggregateType === 'analytics3') {
        const groupedData = _.groupBy(data, timeKey);
        return _.map(groupedData, (group, key) => ({
            timeLabel: key,
            Mensajes: _.sumBy(group, 'Mensajes'),
            messageByHuman: _.sumBy(group, 'messageByHuman'),
        }));
    }
};

export const BarAndLineChartCustom = ({
    data,
    timeFormat,
    barColor = '#413ea0',
    lineColor = '#ff7300',
    dataKeyBar,
    dataKeyLine,
    aggregateType = 'default',
    hasLegend = true,
}) => {
    const [processedData, setProcessedData] = useState([]);

    useEffect(() => {
        let timeKey;
        switch (timeFormat) {
            case 'week':
                timeKey = 'week';
                break;
            case 'month':
                timeKey = 'month';
                break;
            case 'quarter':
                timeKey = 'quarter';
                break;
            default:
                timeKey = 'date';
                break;
        }

        const aggregatedData = aggregateData(data, timeKey, aggregateType);
        setProcessedData(aggregatedData);
    }, [data, timeFormat]);

    return (
        <div style={{ width: '100%', height: 320 }}>
            <ResponsiveContainer>
                <ComposedChart data={processedData} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
                    <CartesianGrid stroke="#f1f1f1" vertical={false} />
                    <XAxis dataKey="timeLabel" angle={-45} textAnchor="end" fontSize={10} tickMargin={5} />
                    <YAxis />
                    <Tooltip />
                    {hasLegend && <Legend verticalAlign="top" align="center" />}


                    <Bar dataKey={dataKeyBar} barSize={20} fill={barColor} />
                    {
                        (dataKeyLine) && (
                            <Line type="monotone" dataKey={dataKeyLine} stroke={lineColor} />
                        )
                    }

                </ComposedChart>
            </ResponsiveContainer>
        </div>
    );
};
