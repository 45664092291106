export class DataBasicEncoder {

    private static secretKey = 'rbc_1!';

    /**
     * Codifica un objeto en una cadena especial para ocultar su contenido.
     * Utiliza un cifrado XOR para alterar los datos antes de la codificación Base64.
     * @param obj Objeto a codificar.
     * @returns Cadena codificada.
     */
    static encode(obj: object): string | null {
        if (!obj) return null;

        const jsonString = JSON.stringify(obj);
        const xorString = this.xorCipher(jsonString, this.secretKey);

        const base64Url = btoa(xorString)
            .replace(/\+/g, '-')
            .replace(/\//g, '_')
            .replace(/=+$/, '');

        return base64Url;
    }

    /**
     * Decodifica una cadena especial para obtener el objeto original.
     * Invierte el proceso de cifrado XOR después de decodificar de Base64.
     * @param encoded Cadena codificada.
     * @returns Objeto decodificado.
     */
    static decode(encoded: string): object | null {
        if (!encoded) return null;

        const base64String = atob(encoded.replace(/-/g, '+').replace(/_/g, '/'));
        const xorString = this.xorCipher(base64String, this.secretKey);

        try {
            return JSON.parse(xorString);
        } catch (e) {
            console.error("Error al decodificar el objeto:", e);
            return null;
        }
    }

    /**
     * Realiza un cifrado XOR de una cadena con una clave.
     * @private
     * @param s Cadena a cifrar.
     * @param key Clave para el cifrado XOR.
     * @returns Cadena cifrada.
     */
    private static xorCipher(s: string, key: string): string {
        return s.split('').map((char, index) =>
            String.fromCharCode(char.charCodeAt(0) ^ key.charCodeAt(index % key.length))
        ).join('');
    }
}




// export class DataBasicEncoder {

//     /**
//      * Codifica un objeto en una cadena especial para ocultar su contenido.
//      * @param obj Objeto a codificar.
//      * @returns Cadena codificada.
//      */
//     static encode(obj: object): string {
//         if (!obj) return;

//         const jsonString = JSON.stringify(obj);
//         const encodedString = this.rotateString(jsonString);

//         const base64Url = btoa(encodedString)
//             .replace('+', '-')
//             .replace('/', '_')
//             .replace(/=+$/, '');

//         return base64Url;
//     }

//     /**
//      * Decodifica una cadena especial para obtener el objeto original.
//      * @param encoded Cadena codificada.
//      * @returns Objeto decodificado.
//      */
//     static decode(encoded: string): object {
//         if (!encoded) return;

//         const base64String = atob(encoded.replace('-', '+').replace('_', '/'));
//         const decodedString = this.reverseRotateString(base64String);
//         return JSON.parse(decodedString);
//     }

//     /**
//      * Rota una cadena de texto n posiciones.
//      * @private
//      * @param s Cadena a rotar.
//      * @param n Número de posiciones para rotar (por defecto 5).
//      * @returns Cadena rotada.
//      */
//     private static rotateString(s: string, n = 5): string {
//         let rotated = "";
//         for (let i = 0; i < s.length; i++) {
//             rotated += String.fromCharCode(s.charCodeAt(i) + n);
//         }
//         return rotated;
//     }

//     /**
//      * Deshace la rotación de una cadena de texto.
//      * @private
//      * @param s Cadena a revertir.
//      * @param n Número de posiciones para revertir la rotación (por defecto 5).
//      * @returns Cadena original.
//      */
//     private static reverseRotateString(s: string, n = 5): string {
//         let original = "";
//         for (let i = 0; i < s.length; i++) {
//             original += String.fromCharCode(s.charCodeAt(i) - n);
//         }
//         return original;
//     }
// }
