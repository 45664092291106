import { ToastContainer, ToastPosition, toast } from 'react-toastify';
import styled from 'styled-components';
import { useToastAppStore } from '../../../core/store';
import { useEffect } from 'react';

/**
 * Crear diferentes y agregar imagenes si fuera necesario
 * https://stackoverflow.com/questions/60849448/how-can-i-change-the-styles-of-the-react-toastify-popup-message
 */
const StyledStoasContainer = styled(ToastContainer)`

  &&&.Toastify__toast-container {
    /* Estilos personalizados para el contenedor de toasts */
    margin-left: 25px !important;
  }
  
  .Toastify__toast {
  
    border-radius: 60px;
    border: 1px solid #789f9d;
  }

  .Toastify__toast-body {
    color: #444C63    ;
    font-size: 16px;
    padding-left: 20px;
    line-height: 20px;
    padding: 0px;
    width: 100%;
    font-weight: 400;
    margin-left: 25px !important;
  } 
  .Toastify__progress-bar {
    background: ${props => props.theme.progressBarColor};
  }

  .Toastify__progress-bar--bg {
    background: ${props => props.theme.progressBarBackground || 'rgba(255,255,255,0.7)'};
  }
`;

export const ToastApp = () => {

  const { message } = useToastAppStore((state) => state);

  useEffect(() => {
    if (!message) return;
    toast(message.message, {
      // className: "toast-success-container toast-success-container-after",
      closeButton: false,
      position: message.position || "top-right",
      autoClose: message.autoClose || 3000,
      // hideProgressBar: message.progressColor ? false : true,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: { backgroundColor: message.backgroundColor, color: message.textColor, position: "relative", top: 40 },
      // progressStyle: { background: message.progressColor }
    });
  }, [message]);

  return <StyledStoasContainer limit={3} />
}


