import React from 'react';
import { PieChartCustom } from '../../components/@analitycs';
import { CCard } from '@coreui/react-pro';
import { CiWarning } from "react-icons/ci";

interface Analytic2Props {
    data: any[];
    error: boolean;
    loading: boolean;
}

const Analytic2 = ({ data, error, loading }: Analytic2Props) => {

    // const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];
    const PLATFORM_COLORS = {
        FACEBOOK: '#3b6ad1',
        INSTAGRAM: '#de2a7b',
        WEB: '#d91821',
        WHATSAPP: '#22b322',
        TELEGRAM: '#5f82d9',
    };

    const getColorByPlatform = (platformName: string) => {
        return PLATFORM_COLORS[platformName.toUpperCase()] || '#ccc'; // Color por defecto
    };

    const dataWithColors = data.map((item) => ({
        ...item,
        color: getColorByPlatform(item.name), // Aquí se asigna el color
    }));

    return (
        <CCard className="rounded-xl p-3 my-4" style={{ height: 400, maxWidth: 380 }}>


            <div className='flex justify-center mx-4 my-2'>
                <h2 className='text-2xl font-semibold'>Conversaciones Totales</h2>
            </div>
            {error && (
                <div className="flex justify-center items-center mx-4 my-2 h-full">
                    <div className="text-center">
                        <CiWarning size={150} className="text-yellow-500 mx-auto" />
                        <p className="mt-4 text-lg font-semibold text-gray-700">
                            No se puede mostrar la información
                        </p>
                    </div>
                </div>

            )}

            {loading && (
                <div className="flex justify-center items-center h-full mt-5">
                    <div className="flex justify-center items-center">
                        <div className="animate-spin rounded-full h-[32px] w-[32px] border-t-2 border-b-2 border-gray-900"></div>
                    </div>
                </div>
            )}

            {!loading && !error && (
                <>


                    <PieChartCustom
                        data={dataWithColors}
                        dataKey="value"
                        nameKey="name"
                    />
                </>
            )}



        </CCard>
    );
};

export default Analytic2;
