import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export class RoleService {

  constructor() { }

  static getAutocomplete() {
    return axios.get(`${apiUrl}/api/role/autocomplete`, { withCredentials: true });
  }

}
