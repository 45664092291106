import Navigation from './app/routes/Navigation';
import { ToastApp } from './app/components';
import { useSocketIoClient } from './core/hooks/@io-socket/useSocketIoClient';
import { COLORS } from './core/constants/colors';
import { Box, ThemeProvider, autocompleteClasses, createTheme } from '@mui/material';
import { useIdentityStore, useSnackbarStore } from './core/store';
import { useEffect } from 'react';

import { SnackbarProvider } from 'notistack';

// Estilos
import './core/assets/styles/style.scss';
import './core/assets/styles/tailwind.scss';
import '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';




// Moment en ES
import moment from 'moment';
import 'moment/locale/es';
import CustomSnackbar from './app/components/custom-snackbar/CustomSnackbar';
import SnackbarManager from './app/components/snackbar-manager/SnackbarManager';
moment.locale('es');



LicenseInfo.setLicenseKey('f4cefc7a2d6a667effc50569bad83c53Tz04NTkwMixFPTE3NDEzNjg5MzUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.principalColor,
    }
  },
  components: {
    MuiPopover: { // El DateRangePicker utiliza internamente un Popover para desplegar el calendario
      styleOverrides: {
        root: {
          zIndex: 2000,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          // Estilos para la raíz del Checkbox aquí
          color: 'rgba(0, 0, 0, 0.54)', // Color por defecto, cambia según necesites
        },
        colorPrimary: {
          '&.Mui-checked': {
            color: COLORS.principalColor, // Color cuando está seleccionado (checked)
          },
          '&:hover': {
            backgroundColor: 'transparent', // Puedes definir un color de fondo en hover si es necesario
          },
        },
        // Aplica estilos adicionales si es necesario
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          // Aquí puedes agregar estilos globales para el componente Autocomplete
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'rgba(0, 0, 0, 0.23)', // Color del borde por defecto
            },
            '&:hover fieldset': {
              borderColor: COLORS.principalColor, // Color del borde en hover
            },
            '&.Mui-focused fieldset': {
              borderColor: COLORS.principalColor, // Color del borde en foco
            },
            // Estilos adicionales según sea necesario
          },
        },
        // Puedes agregar estilos específicos para los subcomponentes aquí
        // Por ejemplo, para el input del Autocomplete:
        inputRoot: {
          '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
            // Ajustar el padding si es necesario
          },
          '& .MuiAutocomplete-endAdornment': {
            // Ajustar los adornos finales como los iconos de clear o dropdown
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          // Estilos aplicados a la raíz del TextField
          '& .MuiOutlinedInput-root': {
            // Estilos aplicados al borde cuando el TextField está en su estado normal
            '& fieldset': {
              borderColor: 'rgba(0, 0, 0, 0.23)', // Cambia esto según tus necesidades
            },
            // Estilos aplicados al borde en hover (sin el estado de focus)
            '&:hover fieldset': {
              borderColor: COLORS.principalColor, // Cambia esto para el color en hover
            },
            // Estilos aplicados al borde cuando el TextField está enfocado
            '&.Mui-focused fieldset': {
              borderColor: COLORS.principalColor, // Cambia esto para el color en focus
            },
            // Puedes agregar más estilos específicos para otros estados como error, disabled, etc.
          },
        },
      },
    },


    MuiSlider: {
      styleOverrides: {
        root: {
          // Estilo para el root del Slider si es necesario
        },
        thumb: {
          // Esto cambia el color del pulgar (la parte que arrastras)
          backgroundColor: COLORS.principalColor,
        },
        track: {
          // Esto cambia el color de la parte del track (pista) que muestra el valor seleccionado
          backgroundColor: COLORS.principalColor,
        },
        rail: {
          // Esto cambia el color de la parte del track (pista) que no muestra el valor seleccionado
          backgroundColor: 'rgba(0, 0, 0, 0.26)', // Cambia esto según tus necesidades
        },
        // Puedes agregar más estilos específicos para otros subcomponentes del Slider aquí
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          // color: COLORS.principalColor, // Color del botón (thumb) cuando está apagado
          '&.Mui-checked': {
            color: COLORS.principalColor, // Color del botón (thumb) cuando está encendido
          },
          '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: COLORS.principalColor, // Color de la pista cuando está encendido
          },
        },
        track: {
          backgroundColor: COLORS.principalColor, // Puedes ajustar el color de la pista cuando está apagado si es necesario
        },
      },
    },
    // Estilo para los Tabs
    MuiTabs: {
      styleOverrides: {
        // Aplica estilos al indicador de los Tabs
        indicator: {
          backgroundColor: COLORS.principalColor, // Reemplaza con tu color
        },
      },
    },
    // Estilo para el Tab individual
    MuiTab: {
      styleOverrides: {
        // Estilos para el texto del Tab
        textColorPrimary: {
          color: COLORS.principalColor, // Reemplaza con tu color para el texto no seleccionado
          '&.Mui-selected': {
            color: COLORS.principalColor, // Reemplaza con tu color para el texto seleccionado
          },
        },
      },
    },
  },
});


// TODO: Quita console.log en production
if (process.env.NODE_ENV === 'production') {
  console.log = function () { };
}

function App() {
  const loadIdentity = useIdentityStore(state => state.loadIdentity);
  const maxSnackbars = useSnackbarStore(state => state.maxSnackbars);
  useEffect(() => {
    // Carga la identidad al iniciar la app
    loadIdentity();
  }, [loadIdentity]);

  useSocketIoClient();

  console.log("API APP: ", process.env.REACT_APP_API_URL);
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={maxSnackbars}>
        <SnackbarManager />
        {/* <ToastApp /> */}
        <Navigation />
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default App
