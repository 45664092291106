import React, { useEffect, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import { COLORS } from '../../../core/constants/colors';

interface CheckPasswordProps {
    placeholder?: string;
    handlePassword: (value: string) => void;
    disabled: boolean;
    blockWriting: boolean;
    clean?: boolean;
    differentColor?: boolean;
}

const CheckPassword = (props: CheckPasswordProps) => {
    const { disabled, blockWriting, placeholder, handlePassword, differentColor } = props;
    const [password, setPassword] = useState("");
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (disabled) {
            setPassword("");
            if (inputRef.current) {
                inputRef.current.value = "";
            }
        }
    }, [disabled]);

    useEffect(() => {
        const typingTimeout = setTimeout(() => {
            handlePassword(password);
        }, 300);
        return () => clearTimeout(typingTimeout);
    }, [password, handlePassword]);

    const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
        const target = e.target as HTMLInputElement;
        setPassword(target.value);
    };

    return (
        <TextField
            fullWidth
            inputRef={inputRef}
            label="Contraseña actual"
            variant="standard"
            placeholder={placeholder || "Buscar"}
            onKeyUp={handleKeyUp}
            disabled={disabled || blockWriting}
            style={differentColor ? { borderColor: COLORS.principalColor } : {}}
        />
    );
};

export default CheckPassword;
