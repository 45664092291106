import { CSSProperties, useEffect, useState } from "react";



interface UserTableSearch {
    multiSelect?: boolean
}


export const useTableSearch = ({ multiSelect = false }: UserTableSearch) => {

    const [message, setMessage] = useState('');
    const [updated, setUpdated] = useState<string[]>([]);


    const handleChange = (event) => {
        setMessage(event.target.value);
    }

    const handleKeyDown = (event, multiWord?: boolean) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            let array = multiSelect ? [...updated] : [];

            array.push(event.target.value)
            setUpdated(array);
            setMessage("")
        }

    }

    const handleDelete = (ind) => {
        let array = [...updated];
        array.splice(ind, 1);
        setUpdated(array);
    }

    return {
        message,
        updated,

        handleChange,
        handleKeyDown,
        handleDelete
    }
}