

import { CModal, CModalBody } from '@coreui/react-pro';
import React from 'react'
import { Divider } from '../../../components';
import { RxCross2 } from 'react-icons/rx';
import { useConversationStore } from '../../../../core/store';

const ModalErrorChat = () => {

    const { errorNotFoundConversation, setErrorNotFoundConversation } = useConversationStore(state => state);
    return (
        <CModal
            alignment="center"
            visible={errorNotFoundConversation}
            onClose={() => {
                setErrorNotFoundConversation(false);
            }}


        >
            <div className="mt-2 flex items-center justify-between bg-transparent px-3">

                <div className='flex items-center text-lg float-start'><span>No se encontró conversación</span></div>
                <div
                    className="float-end cursor-pointer text-2xl"
                    onClick={() => setErrorNotFoundConversation(false)}
                >
                    <RxCross2 />
                </div>

            </div>
            <Divider />
            <CModalBody>
                <span>La conversación que intenta buscar no se encuentra disponible en estos momentos.</span>
            </CModalBody>

        </CModal>
    )
}

export default ModalErrorChat