

import React from 'react'
import { CRow } from '@coreui/react-pro';


export class TextPayload {

    message?: string;
    text?: string[];

}


export const TextBot = (textpayload: TextPayload & { userType: "bot" | "human", bannerColor: string }) => {
    const { text, userType, bannerColor } = textpayload;
    const styles = {
        botBubble: {
            margin: "5px",

            alignSelf: userType === 'bot' ? 'flex-start' : 'flex-end',
        }
    };

    const createMarkup = (htmlContent) => {
        return { __html: htmlContent };
    };

    const t = text[0];

    if (t === undefined || t === "000RBCStart") {
        return <></>;
    }

    return (
        <div style={styles.botBubble} dangerouslySetInnerHTML={createMarkup(text[0])}>
        </div>
    );
}
