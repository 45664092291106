import { create } from 'zustand';
import { Pagination } from '../../interfaces';
import { transformCheckDataToFilter } from '../../util/transform-checkdata-to-filter';

// Definimos una interfaz para los filtros
export interface FilterRaw {
    nameTable: string;
    nameColumn: string;
    label: string;
    title: string;
    value: any;
}



// Combinamos los estados de filtros y paginación en un solo estado
interface PaginationAndFilterRawState {
    pagination: Pagination;
    filtersRaw: FilterRaw[];
}

// Combinamos las acciones de filtros y paginación en un solo conjunto de acciones
interface PaginationAndFilterRawActions {
    addFilterRaw: (filter: FilterRaw) => void;
    addFilterRawExclusive: (filter: FilterRaw) => void;
    removeFilterRaw: (nameTable: string, nameColumn: string, title: any) => void;
    removeFilterRawMassive: (filtersRawToRemove: FilterRaw[]) => void;
    resetFilterRaws: () => void;

    setPagination: (pagination: Pagination) => void;
}

export const usePaginationStore = create<PaginationAndFilterRawState & PaginationAndFilterRawActions>((set) => ({
    // Valores iniciales para paginación
    pagination: undefined,
    filtersRaw: [],

    // Acción para establecer cualquier parte del estado de paginación
    setPagination: (pagination: Pagination) => set((state) => {
        console.log("que es pagination", pagination)
        return {
            pagination: { ...state.pagination, ...pagination }
        }
    }),

    addFilterRaw: (filter) => set((state) => {
        const updatedFiltersRaw = [...state.filtersRaw, filter];
        const updatedFilters = transformCheckDataToFilter(updatedFiltersRaw);
        return {
            filtersRaw: updatedFiltersRaw,
            pagination: { ...state.pagination, filters: updatedFilters, page: 0 },
        };
    }),
    addFilterRawExclusive: (filter) => set((state) => {
        const updatedFiltersRaw = [...state.filtersRaw.filter(f => !(f.nameTable === filter.nameTable && f.nameColumn === filter.nameColumn)), filter];
        const updatedFilters = transformCheckDataToFilter(updatedFiltersRaw);
        return {
            filtersRaw: updatedFiltersRaw,
            pagination: { ...state.pagination, filters: updatedFilters, page: 0},
        };
    }),
    removeFilterRaw: (nameTable, nameColumn, title) => set((state) => {
        const updatedFiltersRaw = state.filtersRaw.filter(f => !(f.nameTable === nameTable && f.nameColumn === nameColumn && f.title === title));
        const updatedFilters = transformCheckDataToFilter(updatedFiltersRaw);
        return {
            filtersRaw: updatedFiltersRaw,
            pagination: { ...state.pagination, filters: updatedFilters, page: 0 },
        };
    }),
    removeFilterRawMassive: (filtersRawToRemove) => set((state) => {
        const updatedFiltersRaw = state.filtersRaw.filter(f =>
            !filtersRawToRemove.some(fr =>
                f.nameTable === fr.nameTable &&
                f.nameColumn === fr.nameColumn &&
                f.title === fr.title
            )
        );
        const updatedFilters = transformCheckDataToFilter(updatedFiltersRaw);
        return {
            filtersRaw: updatedFiltersRaw,
            pagination: { ...state.pagination, filters: updatedFilters, page: 0 },
        };
    }),
    resetFilterRaws: () => set((state) => {
        return {
            filtersRaw: [],
            pagination: { ...state.pagination, filters: {}, page: 0 },
        };
    }),




}));
