import { CMultiSelect } from '@coreui/react-pro';
import styled from 'styled-components';
import { COLORS } from '../../../core/constants/colors';


const StyledCMultiSelect = styled(CMultiSelect)`
background-color: ${COLORS.principalColor};
border: none;

&:focus {
  border-color: #f08a3b;
  box-shadow: 0 0 0 0.2rem rgba(240, 138, 59, 0.25);
}

&:blur {
  border-color: transparent;
  box-shadow: none;
}

div, span, input {
  color: white;
}

.form-multi-select-search {
  background-color: ${COLORS.principalColor};
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  color: orange;

  &::placeholder {
    color: #fff;
  }
}

.dropdown-menu {
  background-color: #1d1d26;
}

&.form-multi-select{
    padding: 0px;
}

&.form-multi-select.show {
  background-color: ${COLORS.principalColor};
  box-shadow: 0 0 0 0.25rem rgba(240, 138, 59, 0.25);
}

.virtual-scroller-content {
  background-color: #1d1d26;
}

.form-multi-select-option {
  background-color: #1d1d26;
  color: white;
  border-top: 1px solid #323343;
  padding: 5px 10px;
  border-radius: 5px;

  &.elegida {
    background-color: #f08a3b;
  }

  &:first-child {
    border-top: none;
  }
}

.virtual-scroller {
  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
    padding: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #4f6f88;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: transparent;
  }
}
`;


export default StyledCMultiSelect;