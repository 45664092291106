
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { useSpring, animated } from 'react-spring';



interface DropdownProps {
    showModal: boolean;
    delay?: number;
    className?: string;
    children?: JSX.Element | JSX.Element[];
    position?: {
        top?: string | number;
        left?: string | number;
        right?: string | number;
        bottom?: string | number;
    };

    setShowModal: (showModal: boolean) => void;

}



export const Dropdown = (props: DropdownProps) => {

    const { showModal, className = "", delay = 180, position, children, setShowModal } = props;
    const ref = useRef(null);
    const [visibilityClass, setVisibilityClass] = useState<boolean>(showModal)

    // Anima la opacidad y la transformación al mostrar y ocultar el dropdown
    const transitions = useSpring({
        opacity: showModal && visibilityClass ? 1 : 0,
        transform: showModal && visibilityClass ? 'translateY(0px)' : 'translateY(-10px)',
        config: { duration: delay }, // Establece la duración de la transición
        onRest: () => {
            // Esto se llama cuando la transición termina
            if (!showModal) {

                // Si el dropdown debe estar oculto, entonces ajusta cualquier estado adicional si es necesario aquí
            }
        },
    });

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setTimeout(() => {
                setShowModal(false); // Simplemente oculta el modal
            }, delay)
        }
    };

    useEffect(() => {
        if (!showModal) return;
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, []);

    useEffect(() => {
        if (showModal) {
            setVisibilityClass(true);
        }
    }, [showModal]);

    useEffect(() => {
        if (showModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
    }, [showModal]);


    if (!showModal) {
        return null;
    }


    return (
        // fixed inset-0 z-40
        <div className={`z-40`} onClick={() => {
            setVisibilityClass(false);
            setTimeout(() => {
                setShowModal(false);
            }, delay)
        }}>
            {/* Se ha puesto z-30 por las tablas */}
            <div className="fixed  inset-0 z-30 bg-transparent" aria-hidden="true" style={{ width: "100vw", height: "100vh" }}></div>

            <animated.div
                ref={ref}
                style={{ ...transitions, ...position }}
                className={`absolute z-50 ${className}`}
                onClick={(e) => e.stopPropagation()}>

                {children}
            </animated.div>
        </div>


    )

}
