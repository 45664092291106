import { CBadge, CCol, CRow, CSpinner, CVirtualScroller } from '@coreui/react-pro'
import React, { useEffect, useState } from 'react'
import { cibTelegram, cibFacebook, cibWhatsapp, cibInstagram, cibProbot } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { classNames } from '../../../utils/classes-css';
import moment from 'moment';
import { Util } from '../../../../core/util/util';

import { Conversation, UserSocialMedia } from '../../../../core/models/db';
import { LoginService } from '../../../../core/services/login/login.service';
import { Divider } from '../../../components';
import { useConversation } from '../../../../core/hooks/db/conversation/useConversation';
import { Pagination } from '../../../../core/interfaces';

import { TbWorldWww } from "react-icons/tb";
import { useConversationStore } from '../../../../core/store';
import ConversationFilter from '../conversation-filter/ConversationFilter';
import { Box } from '@mui/material';
import { Pagination as MuiPagination } from '@mui/material';




interface DateRange {
  startDate: string;
  endDate: string;
}

export interface ConversationSearch {
  location?: string[];

  actionParams?: string[];
  locationParams?: string[];
  brandParams?: string[];
  // typeVehicleParams?: string[];
  vehicleTypeParams?: string[];
  typeAppointmentParams?: string[];

  attClientParams?: string[];

  conversation?: string[];
  idUserSocialMediaFk?: string[];
  createdDate?: DateRange;
  searchText?: string;
  page?: number;
  itemsPerPage?: number;
  orderBy?: string;
  order?: 'ASC' | 'DESC';

  totalItems?: number;
  totalPages?: number;
}


// const INIT_PAGINATION: Pagination = { page: 1, itemsPerPage: 15 };
const INIT_PAGINATION: Pagination = { page: 1, itemsPerPage: 10 };


const NUM_FINAL = 3;


const choosePlatformIcon = (platform: string, userSocialMedia: UserSocialMedia, webLocation: string) => {
  // console.log(platform)
  switch (platform) {
    case "TELEGRAM":
      return (
        <div className="d-flex align-items-center">
          <div className='mx-2'>
            <CIcon icon={cibTelegram} className="mr-2" size="lg" style={{ color: '#3b9eeb' }} />
          </div>
          <span style={{ fontSize: "13px" }}># {userSocialMedia.idUserPlatform}</span>
        </div>
      )
    case "FACEBOOK":
      return (
        <div className="d-flex align-items-center">
          <div className='mx-2'>
            <CIcon icon={cibFacebook} className="mr-2" size="lg" style={{ color: '#10588f' }} />
          </div>
          <span style={{ fontSize: "13px" }}># {userSocialMedia.idUserPlatform}</span>


        </div>
      )
    case "WEB":
      return (
        <div className="d-flex align-items-center">
          <div className='mx-2'>
            {/* <CIcon icon={cibProbot} size="lg" style={{ color: '#e9111f' }} /> */}
            <TbWorldWww className='text-lg' style={{ color: '#e9111f' }} />
          </div>
          {/* <span style={{ fontSize: "13px" }}># {userSocialMedia.idUserPlatform} - {location}</span> */}
          <span style={{ fontSize: "11px" }}>{webLocation}</span>
        </div>
      )
    case "WHATSAPP":
      return (
        <div className="d-flex align-items-center">
          <div className='mx-2'>
            <CIcon icon={cibWhatsapp} className="mr-2" size="lg" style={{ color: '#1dbf22' }} />
          </div>
          <span style={{ fontSize: "13px" }}># {userSocialMedia.idUserPlatform}</span>
        </div>
      )
    case "INSTAGRAM":
      return (
        <div className="d-flex align-items-center">
          <div className='mx-2'>
            <CIcon icon={cibInstagram} className="mr-2" size="lg" style={{ color: '#de124f' }} />
          </div>
          <span style={{ fontSize: "13px" }}># {userSocialMedia.idUserPlatform}</span>

        </div>
      )
    default:
      return (
        <div className="d-flex align-items-center">
          <div className='mx-2'>
            {/* <CIcon icon={cibProbot} size="lg" style={{ color: '#e9111f' }} /> */}
          </div>
          <span style={{ fontSize: "13px" }}># {userSocialMedia.idUserPlatform}</span>
        </div>
      )
  }
}



const ConversationSideNormalPagination = () => {

  const { conversationSelected, setConversationSelected, conversationsList, setConversationsList, setErrorNotFoundConversation } = useConversationStore(state => state);

  const { getMinified, getCompleted, getById } = useConversation();
  // const [pagination, setPagination] = useState<Pagination>(INIT_PAGINATION);
  const [pagination, setPagination] = useState<Pagination>(INIT_PAGINATION);


  // const [conversations, setConversations] = useState<Conversation[]>([]);
  const [limitScroll, setLimitScroll] = useState<number>(0);


  const [isScrollBlocked, setScrollBlocked] = useState(false);

  useEffect(() => {
    // TODO: Por defecto tenia que buscase, pero no hace falta debido a que ya se activa el buscador
    // desde el componente filtro
    (async () => {
      // Comentar esto si se empieza a usar el filtro
      paginateConversation2();
    })();
  }, [])


  const paginateConversation2 = async (pagination: Pagination = INIT_PAGINATION, reset = false) => {

    try {

      if (reset) {
        // Reinicio la conversación mostrada
        setConversationSelected(undefined);
      }

      setScrollBlocked(true);

      // const response = await getCompleted(pagination);
      const response = await getMinified(pagination);


      console.log("Response sidebar chats", response);
      if (response && response.ok) {
        // console.log("what is result - conversation", response);
        if (conversationsList.length === 0 || reset) {
          setConversationsList([...response.item.rows]);
        } else {
          setConversationsList([...response.item.rows]);

          // setConversationsList([...conversationsList, ...response.item.rows]);
        }

        setPagination({
          ...pagination,
          totalItems: response.item.pagination.totalItems,
          totalPages: response.item.pagination.totalPages,
        })
      } else {
        setErrorNotFoundConversation(true);
      }
    } catch (e) {
      setErrorNotFoundConversation(true);
      console.error(e);
    } finally {

      setScrollBlocked(false);

    }
  }

  const handleConversation = async (conversation: Conversation) => {

    try {

      const response = await getById(conversation.id);
      console.log("response unico", response);
      if (response && response.ok) {
        setConversationSelected(response.item);
      } else {
        setConversationSelected(undefined);
      }
    } catch (e) {
      setConversationSelected(undefined);

      console.error(e);
    }

  }


  const infoBoxItem = (conversation: Conversation) => {

    let userSocialMedia: UserSocialMedia = conversation.userSocialMedias;

    let hasLead = false;
    let brand = undefined;
    let action = undefined;

    // console.log("mira que es conversation", conversation)

    if (conversation && conversation.paramsJson) {

      const params = conversation.paramsJson;
      brand = Util.capitalize(params?.brand);
      action = Util.capitalize(params?.action);
      hasLead = params?.lead;
    }

    // console.log(conversation)

    return (
      <div style={{ padding: '10px 0', display: 'flex', justifyContent: 'flex-start', width: "95%", }}
        onClick={() =>
          handleConversation(conversation)}
      >
        {userSocialMedia && (
          <div style={{ display: 'flex', flexDirection: "column", width: "100%", }}>
            <div style={{ display: 'flex', justifyContent: "space-between", width: "100%" }}>

              {choosePlatformIcon(conversation.platform, userSocialMedia, conversation.webLocation)}
              <span style={{ fontSize: "11px", marginRight: "7px" }}>
                {conversation.createdDate ? moment(conversation.createdDate).format("DD/MM/YYYY HH:mm") : 'Fecha no registrada'}
              </span>
            </div>
            <div className='flex'>
              {action && (
                <span className='flex m-1 items-center'><CBadge color="info" size={'sm'} shape="rounded-pill">{action}</CBadge></span>
              )}
              {brand && (
                <span className='flex m-1 items-center'><CBadge color="dark" size={'sm'} shape="rounded-pill">{brand}</CBadge></span>
              )}
              {hasLead && (
                <span className='flex m-1 items-center'><CBadge color="success" size={'sm'} shape="rounded-pill"><span>Notificación</span></CBadge></span>
              )}


              {!action && !hasLead && (
                <span className='m-1'>
                  <CBadge color="secondary" shape="rounded-pill" size={'sm'}>Sin información</CBadge>
                </span>
              )}
            </div>
          </div>
        )}
      </div>
    )
  }


  const renderConversations = () => {
    return conversationsList.map((conversation, index) => (
      <div
        key={index}
        className={classNames(`box-conversation-single ${conversationSelected?.id === conversation.id ? 'bg-slate-100 transition-all' : ''}`)}
        >
        {infoBoxItem(conversation)}
      </div>
    ));
  };

  const handleChangePage = (event, newPage) => {
    const newPagination = { ...pagination, page: newPage };
    setPagination(newPagination);
    paginateConversation2(newPagination);
  };

  return (
    // conversation-side-content
    <div className="" style={isScrollBlocked ? { overflowY: 'hidden' } : {}}>

      {isScrollBlocked && (
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <CSpinner color="dark" />
        </div>
      )}

      <CRow >
        <CCol >

          {/* <ConversationFilter search={paginateConversation2}></ConversationFilter> */}
          <div >
            <ConversationFilter search={(value, isReset) => paginateConversation2(value, isReset)} />
          </div>
          {/* Separador */}
          <Divider />

          <CRow className='flex justify-center items-center'>
            {renderConversations()}
          </CRow>
        </CCol>

        {/* Componente de paginación de MUI */}
        <Box className="flex justify-center items-center" my={4}>
          <MuiPagination
            count={pagination.totalPages}
            page={pagination.page}
            color="primary"
            onChange={handleChangePage}
            showFirstButton
            showLastButton
            size="small" // Ajusta el tamaño del paginador.
            siblingCount={0} boundaryCount={1}
          />
        </Box>
      </CRow>


    </div>
  )
}

export default ConversationSideNormalPagination


