export enum RouteName {
    login = "login",
    home = "home",

    usuarios = "usuarios",
    usuariosCrear = "usuariosCrear",
    usuariosActualizar = "usuariosActualizar",
    usuariosEliminar = "usuariosEliminar",

    chats = "chats",
    bannedUsers = "bannedUsers",
    webAllowed = "webAllowed",
    profileCrear = "profileCrear",
    profile = "profileDetalle",
    notification = "notification",
    configuration = "configuration",



    // documentAccessesActualizar = "documentAccessesActualizar",
}

export const BASE_ROUTE = "/dashboard"

export const ROUTE_NAME: { [key in RouteName]: string } = {

    login: "/login",
    home: "/home",

    usuarios: "/usuarios",
    usuariosCrear: "/usuarios/crear",
    usuariosActualizar: "/usuarios/actualizar",
    usuariosEliminar: "/usuarios/eliminar",

    chats: "/chats",

    bannedUsers: "/usuarios-baneados",
    webAllowed: "/urls-permitidas",
    notification: "/notificaciones",
    profileCrear: "/perfil/nuevo",
    profileDetalle: "/perfil/:id/detalle",
    configuration: "/configuracion",


}

export const ROUTE_NAME_ID = (id: any, name: RouteName): string | void => {
    const routeChoosed = ROUTE_NAME[name];

    if (!routeChoosed || !id) {
        console.error(id)
        console.error("La ruta no existe", routeChoosed);
        return "/dashboard/home";
    }

    return routeChoosed.replace(/:id/, id.toString());
};