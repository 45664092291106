import axios from "axios";
import { User } from "../../../models/db/user";
import { Pagination } from "../../../interfaces/pagination";
import { ReasonForDeactivationProps } from "../../../hooks/check-table/useCheckTable";


const apiUrl = process.env.REACT_APP_API_URL;

export class AnalyticService {

    constructor() { }

    static getAnalytics(startDate, endDate, platforms) {
        return axios.post(`${apiUrl}/api/bot/analytics`, { startDate, endDate, platforms }, { withCredentials: true });
    }

    static getAnalyticsPerTime(startDate, endDate, platforms) {
        return axios.post(`${apiUrl}/api/bot/analytics-per-time`, { startDate, endDate, platforms }, { withCredentials: true });
    }

    static getCounterMessagePerTime(startDate, endDate, platforms) {
        return axios.post(`${apiUrl}/api/bot/analytics-count-messages-by-time`, { startDate, endDate, platforms }, { withCredentials: true });
    }

    static getFlowPercentage(startDate, endDate, platforms) {
        return axios.post(`${apiUrl}/api/bot/analytics-per-flow`, { startDate, endDate, platforms }, { withCredentials: true });
    }

    static getMonthlyMessages() {
        return axios.get(`${apiUrl}/api/bot/analytics-limit-monthly-messages`, { withCredentials: true });
    }

}
