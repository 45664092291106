import { LazyExoticComponent } from "react";

export type JSXComponent = () => JSX.Element;

export interface Route {
    to: string;
    path: string;
    name: string;
    Component: LazyExoticComponent<JSXComponent> | JSXComponent;
    redirectPath?: string;
    role?: Auth[];
}

export enum Auth {
    ROLE_ADMIN = "ROLE_ADMIN",
    ROLE_USER = "ROLE_USER",
}

