import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useSnackbarStore } from '../../../core/store/snackbar-custom-store/SnackBarCustomStore';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const SnackbarManager = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const snackbar = useSnackbarStore((state) => state.snackbar);

    useEffect(() => {
        if (snackbar) {
            const { title, color, duration, position, closeable, icon: Icon } = snackbar;
            // Aquí asumimos que `position` es un string como 'top-right', necesitas convertirlo a un objeto
            const anchorOrigin = snackbar.position ? {
                vertical: snackbar.position.split('-')[0],
                horizontal: snackbar.position.split('-')[1],
            } : {
                vertical: 'top',
                horizontal: 'right',
            };



            const action = closeable ? (key) => (
                <IconButton size="small" aria-label="close" color="inherit" onClick={() => closeSnackbar(key)}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            ) : undefined;


            // Asegúrate de pasar `anchorOrigin` correctamente a `enqueueSnackbar`
            enqueueSnackbar(title, {
                variant: color, // Notistack utiliza 'variant' para el color
                autoHideDuration: duration,
                anchorOrigin: anchorOrigin as any,
                action,

            });
        }
    }, [snackbar, enqueueSnackbar]);

    return null;
};

export default SnackbarManager;
