import { combineReducers, createStore } from 'redux';

// import { uiReducer } from '../../app/components/ui/template/store';



const rootReducer = combineReducers({
    // actionBar: actionBarReducer,
    // ui: uiReducer,
    // myComponent: myReducer,
});


const store = createStore(rootReducer);

export type RootState = ReturnType<typeof rootReducer>;
export { store };
export default store;