import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';

// Definición de las props del componente
interface BanTimeActiveProps {
    startDate: Date;
    endDate: Date;
}

// Componente BanTimeActive
export const BanTimeActive: React.FC<BanTimeActiveProps> = ({ startDate, endDate }) => {
    const isActive = new Date() < new Date(endDate);
    const formattedStartDate = moment(startDate).format('DD-MM-YYYY HH:mm');
    const formattedEndDate = moment(endDate).format('DD-MM-YYYY HH:mm');

    // console.log(isActive, startDate, endDate)
    return (
        <Tooltip title={`Inicio: ${formattedStartDate} - Fin: ${formattedEndDate}`}>
            <div className="cursor-pointer">
                <div
                    className={`h-3 w-3 rounded-full shadow-md ${isActive ? 'bg-green-600' : 'bg-red-600'}`}
                />
            </div>
        </Tooltip>
    );
};
