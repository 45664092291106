import { useEffect, useRef, useState } from "react";
import { useConfigurationBot } from "../../../core/hooks/db/configuration-bot/useConfigurationBot"
import { ConfigurationBot, User } from "../../../core/models/db";
import { CCard, CContainer, CRow, CSmartTable } from "@coreui/react-pro";
import ConfigurationForm from "./configuration-form/ConfigurationForm";
import { Item } from "@coreui/react-pro/dist/components/table/types";
import { SearchTable, AppliedFilters, RoleCheck, Divider } from "../../components";
import { COLORS } from "../../../core/constants/colors";
import { RippleButton } from "../../components/ui/ripple-button/RippleButton";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { JsonConfig } from "../../../core/models/db/configuration-bot/json-config";
import { IframeBot } from "./iframe-bot/IframeBot";

export const ConfigurationPage = () => {

    const navigate = useNavigate();

    const { getConfigurationBot, updateConfigurationBot } = useConfigurationBot();
    const [configurationBot, setConfigurationBot] = useState<ConfigurationBot>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    // const [resetPreview, setResetPreview] = useState<boolean>(false);
    const [previewConfig, setPreviewConfig] = useState<{ jsonConfig: JsonConfig, systemUnavailableMessage: string } | null>(null);

    const [isEditing, setIsEditing] = useState(false); // Estado para manejar si se está en modo edición
    const formRef = useRef<any>(null);


    useEffect(() => {
        getData();
    }, [])

    const update = async (value: ConfigurationBot) => {
        try {
            await updateConfigurationBot(value);
        } catch (e) {
            console.error(e)
        }
    }

    // Función para manejar el click en el botón de editar/crear
    const handleActionButtonClick = async (value: ConfigurationBot) => {
        try {
            console.log(value);

            await update({
                ...value,
                id: configurationBot.id
            });
            setIsEditing(!isEditing);

        } catch (e) {
            console.error(e)
        }


    };

    // Función para manejar la cancelación de la edición
    const handleCancelEdit = () => {
        if (formRef.current) {
            formRef.current.createBackup();
        }

        setIsEditing(false);
    };

    const handleCreate = () => {
        if (formRef.current) {
            formRef.current.submitForm();
        }
    }



    const getData = async () => {
        try {
            const result = await getConfigurationBot({
                page: 1,
                itemsPerPage: 1,
                filters: {
                    id: {
                        value: 1
                    }
                }
            });



            if (result && result.ok) {
                setConfigurationBot(result.item.rows[0]);
            } else {
                throw new Error('Error al obtener la configuración');
            }

        } catch (e) {
            navigate('/login');
            console.error(e)
        }
    }

    // const handleResetPreview = () => {
    //     setResetPreview(!resetPreview);
    // }

    const handlePreview = (jsonConfig, systemUnavailableMessage) => {
        // Aquí debes determinar cómo generar la configuración para el `iframe`
        // Por ejemplo, podrías tomar la configuración actual del formulario o la última guardada
        const currentConfig = configurationBot?.systemUnavailableMessage; // Asegúrate de obtener la configuración correcta
        if (currentConfig) {
            jsonConfig.environment = "test-bot";
            // setPreviewConfig({ jsonConfig, systemUnavailableMessage });
            setPreviewConfig({ jsonConfig, systemUnavailableMessage });

        }
    };

    return (

        <CContainer>
            <div className="w-full justify-between items-center flex  pt-4 pb-5">
                <div className="flex flex-col">
                    <span className="text-4xl" style={{ fontWeight: 500 }}>Configuración</span>
                </div>


                {isEditing ? (
                    // Mostrar botones de Cancelar y Guardar cuando se esté editando
                    <div className="flex justify-between w-[200px]">
                        <RippleButton className={clsx(`py-2 px-3 rounded-xl`, {
                            'bg-gray-400': !isEditing,
                            'bg-gray-600': isEditing
                        })}
                            style={{
                                backgroundColor: !isEditing ? COLORS.principalColor : COLORS.disabledColor, 
                                // Asumiendo que tienes un secondaryColor

                            }} onClick={handleCancelEdit} >Cancelar</RippleButton>
                        <RippleButton className={clsx(`py-2 px-3 rounded-xl`, {
                            'bg-gray-400': !isEditing,
                            'bg-gray-600': isEditing
                        })}
                            style={{
                                // backgroundColor: !isEditing ? COLORS.principalColor : COLORS.disabledColor, 
                                backgroundColor: COLORS.principalColor,

                            }} onClick={handleCreate}>Guardar</RippleButton>
                    </div>
                ) : (
                    // Mostrar botón de Editar cuando no se esté en modo edición
                    <RippleButton className={clsx(`py-2 px-3 rounded-xl`, {
                        'bg-gray-400': !isEditing,
                        'bg-gray-600': isEditing
                    })}
                        style={{
                            backgroundColor: !isEditing ? COLORS.principalColor : COLORS.disabledColor, // Asumiendo que tienes un secondaryColor
                            // Asumiendo que tienes un secondaryColor

                        }} onClick={() => setIsEditing(true)}>Editar</RippleButton>

                )}
            </div>
            <CCard className="rounded-xl p-3">


                <CRow>

                    {loading && (
                        <div className="flex justify-center items-center h-full mt-5">
                            <div className="flex justify-center items-center">
                                <div className="animate-spin rounded-full h-[32px] w-[32px] border-t-2 border-b-2 border-gray-900"></div>
                            </div>
                        </div>
                    )}

                    {configurationBot && (
                        <ConfigurationForm
                            ref={formRef}
                            disabled={!isEditing}
                            configurationBot={configurationBot}
                            handleActionButtonClick={handleActionButtonClick}

                            handlePreview={handlePreview}
                        />
                    )}



                </CRow>
            </CCard>
            <div className="md:block hidden">
                {previewConfig && (
                    <IframeBot
                        configBot={previewConfig.jsonConfig}
                        systemUnavailableMessage={previewConfig.systemUnavailableMessage}
                    />
                )}
            </div>
        </CContainer >
    )
}
