import { create } from 'zustand';

export enum CodePush {
    BANNED_USER = '001',
    QUOTA_MESSAGE = '002',
    LEAD = '301',
    CONVERSATION = '302',
}


interface PaginationAndFilterRawState {
    hasConversation: boolean;
    hasBannedUser: boolean;
    hasQuotaMessage: boolean;
    hasLead: boolean;

    setHasConversation: (hasConversation: boolean) => void;
    setHasBannedUser: (hasBannedUser: boolean) => void;
    setHasQuotaMessage: (hasQuotaMessage: boolean) => void;
    setHasLead: (hasLead: boolean) => void;
}


export const usePushNotificationStore = create<PaginationAndFilterRawState>((set) => ({
    hasConversation: false,
    hasBannedUser: false,
    hasQuotaMessage: false,
    hasLead: false,

    setHasConversation: (hasConversation) => set({ hasConversation }),
    setHasBannedUser: (hasBannedUser) => set({ hasBannedUser }),
    setHasQuotaMessage: (hasQuotaMessage) => set({ hasQuotaMessage }),
    setHasLead: (hasLead) => set({ hasLead }),

}));
