import axios, { AxiosResponse } from 'axios';
import { Pagination } from '../../../interfaces';
import { BannedUser } from '../../../models/db';
import { CRUDService } from '../crud-service.service';


const apiUrl = process.env.REACT_APP_API_URL;
const nameRoute = 'bannedUsers';

export class BannedUserService extends CRUDService<BannedUser> {

    constructor() {
        super(apiUrl, nameRoute);
    }

}
