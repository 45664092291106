import { create } from 'zustand';

export interface Filter {
    nameTable: string;
    nameColumn: string;
    label: string;
    title: string;
    value: any;
}

interface TableFilterState {
    items: Filter[];
}

interface TableFilterActions {
    addFilter: (filter: { nameTable: string, nameColumn: string, label: string, title: any, value: any }) => void;
    addFilterExclusive: (filter: Filter) => void;
    removeFilter: (nameTable: string, nameColumn: string, title: any) => void;
    removeFilterMassive: (filtersToRemove: Array<{ nameTable: string, nameColumn: string, title: any }>) => void;
    resetFilters: () => void;
}


export const useTableFilterStore = create<TableFilterState & TableFilterActions>((set) => ({
    items: [],

    addFilter: (filter) => set((state) => ({
        items: [...state.items, filter],
    })),

    addFilterExclusive: (filter) => set((state) => ({
        items: [...state.items.filter(item => !(item.nameTable === filter.nameTable && item.nameColumn === filter.nameColumn)), filter],
    })),

    removeFilter: (nameTable, nameColumn, title) => set((state) => ({
        items: state.items.filter((item) => !(item.nameTable === nameTable && item.nameColumn === nameColumn && item.title === title)),
    })),

    removeFilterMassive: (filtersToRemove: { nameTable: string, nameColumn: string, title: any }[]) => set((state) => ({
        items: state.items.filter(item =>
            !filtersToRemove.some(filterToRemove =>
                item.nameTable === filterToRemove.nameTable &&
                item.nameColumn === filterToRemove.nameColumn &&
                item.title === filterToRemove.title
            )
        ),
    })),

    resetFilters: () => set(() => ({
        items: [],
    })),
}));
