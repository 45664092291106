import { CCol, CForm, CFormInput, CFormLabel, CFormText, CRow } from '@coreui/react-pro';
import { FormControlLabel, FormGroup, FormLabel, Slider, Switch, TextField, Tooltip } from '@mui/material';
import { useFormik } from 'formik';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import * as Yup from 'yup';
import { ConfigurationBot } from '../../../../core/models/db';
import { Autocomplete, AutocompleteMui, CFormTextareaCustom, Divider, MyRichTextEditor } from '../../../components';
import { MdAppBlocking, MdOutlineSecurityUpdateWarning } from 'react-icons/md';
import { CgHello } from "react-icons/cg";
import DragDrop from '../../../components/drag-drop/DragDrop';
import { FileService } from '../../../../core/services/file/file.service';
import { FileItemObservableService } from '../../../components/drag-drop/services/rxJS/fileitem-observable.service';
import React from 'react';
import { JsonConfig } from '../../../../core/models/db/configuration-bot/json-config';
import { useUIStore } from '../../../../core/store';
import clsx from 'clsx';



const validationSchema = Yup.object({
    style: Yup.string().oneOf(['basic', 'crystal']),
    color1: Yup.string(),
    color2: Yup.string(),
    color3: Yup.string(),
    name: Yup.string().max(30, 'El nombre no debe exceder los 30 caracteres.'),
    welcomeMessageEnabled: Yup.boolean(),
    isBlocked: Yup.boolean(),
    // welcomeMessage: Yup.object({
    //     type: Yup.string().oneOf(['slide', 'bubble']),
    //     text: Yup.string()
    //         .required('El mensaje de bienvenida es requerido.')
    //         .when('type', (type, schema) => {
    //             const typeValue = Array.isArray(type) ? type[0] : type;
    //             return typeValue === 'bubble'
    //                 ? schema.max(120, 'El mensaje no debe exceder los 120 caracteres.')
    //                 : schema.max(28, 'El mensaje no debe exceder los 28 caracteres.');
    //         }),
    //     delay: Yup.number(),
    //     hasSound: Yup.boolean(),
    // }),
    welcomeMessage: Yup.object()
        .when('welcomeMessageEnabled', (welcomeMessageEnabled, schema) => {

            const bool = Array.isArray(welcomeMessageEnabled) ? welcomeMessageEnabled[0] : welcomeMessageEnabled;
            return bool
                ? schema.shape({
                    type: Yup.string().oneOf(['slide', 'bubble']),
                    text: Yup.string()
                        .required('El mensaje de bienvenida es requerido.')
                        .when('type', (type, schema) => {
                            console.log("que es type", type);
                            const typeValue = Array.isArray(type) ? type[0] : type;
                            return typeValue === 'bubble'
                                ? schema.max(120, 'El mensaje no debe exceder los 120 caracteres.')
                                : schema.max(28, 'El mensaje no debe exceder los 28 caracteres.');
                        }),
                    delay: Yup.number(),
                    hasSound: Yup.boolean(),
                })
                : schema.shape({
                    type: Yup.string().notRequired(),
                    text: Yup.string().notRequired(),
                    delay: Yup.number().notRequired(),
                    hasSound: Yup.boolean().notRequired(),
                })
        }

        ),
    logo: Yup.string(),
    environment: Yup.string(),
});

interface ConfigurationFormProps {
    configurationBot: ConfigurationBot;
    disabled?: boolean;
    handleActionButtonClick?: (configurationBot: ConfigurationBot) => Promise<any>;
    handlePreview?: (configJson: JsonConfig, systemUnavailableMessage: string, isBlocked: boolean) => void;
}


const apiBotImagesUrl = process.env.REACT_APP_BOT_API_URL;

const ConfigurationForm = forwardRef((props: ConfigurationFormProps, ref) => {

    const { configurationBot, disabled, handleActionButtonClick, handlePreview } = props;
    // const [imageLogo, setImageLogo] = useState<string>(user && user.image ? user.image : "");
    const [imageLogo, setImageLogo] = useState<string>(configurationBot?.configurationJson?.logo || "");


    const { sidebarShow } = useUIStore(state => state)


    const formik = useFormik({
        initialValues: {
            style: configurationBot?.configurationJson?.style,
            color1: configurationBot?.configurationJson?.color1,
            color2: configurationBot?.configurationJson?.color2,
            color3: configurationBot?.configurationJson?.color3,
            name: configurationBot?.configurationJson?.name,
            welcomeMessage: {
                text: configurationBot?.configurationJson?.welcomeMessage.text,
                type: configurationBot?.configurationJson?.welcomeMessage.type,
                delay: configurationBot?.configurationJson?.welcomeMessage.delay,
                hasSound: configurationBot?.configurationJson?.welcomeMessage.hasSound,
            },
            logo: configurationBot?.configurationJson?.logo,
            environment: configurationBot.configurationJson.environment || "",
            // 
            //
            welcomeMessageEnabled: configurationBot?.configurationJson?.welcomeMessage?.text ? true : false,
            systemUnavailableMessage: configurationBot?.systemUnavailableMessage,
            isBlocked: configurationBot?.isBlocked ? true : false,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {

            if (values.welcomeMessageEnabled === false) {
                values.welcomeMessage = {
                    text: "",
                    type: "slide",
                    delay: 0,
                    hasSound: false,
                }
            }


            handleActionButtonClick({
                systemUnavailableMessage: values.systemUnavailableMessage,
                isBlocked: values.isBlocked,
                configurationJson: {
                    style: values.style,
                    color1: values.color1,
                    color2: values.color2,
                    color3: values.color3,
                    name: values.name,
                    welcomeMessage: {
                        text: values.welcomeMessage.text,
                        type: values.welcomeMessage.type,
                        delay: values.welcomeMessage.delay,
                        hasSound: values.welcomeMessage.hasSound,
                    },
                    logo: values.logo,
                    environment: "production",

                },
            });

            handleFormatPreview();

        },
    });

    useImperativeHandle(ref, () => ({
        submitForm: () => {
            formik.handleSubmit();
        },
        createBackup: () => {
            changeToBackup(true);
        }
    }));

    useEffect(() => {
        handleFormatPreview();
    }, [])

    const handleFormatPreview = () => {
        let values = formik.values;
        if (values.welcomeMessageEnabled === false) {
            values.welcomeMessage = {
                text: "",
                type: "slide",
                delay: 0,
                hasSound: false,
            }
        }

        handlePreview(
            {
                style: values.style,
                color1: values.color1,
                color2: values.color2,
                color3: values.color3,
                name: values.name,
                welcomeMessage: {
                    text: values.welcomeMessage.text,
                    type: values.welcomeMessage.type,
                    delay: values.welcomeMessage.delay,
                    hasSound: values.welcomeMessage.hasSound,
                },
                logo: values.logo,
                environment: "test-bot",

            },
            values.systemUnavailableMessage,
            values.isBlocked
        )

    }


    const changeToBackup = (value: boolean) => {
        if (value) {
            // Asegurándonos de que configurationJson está definido correctamente
            const configJson = configurationBot.configurationJson ? configurationBot.configurationJson : {};
            const welcomeMessage = configJson.welcomeMessage ? { ...configJson.welcomeMessage } : {};

            // Asumiendo que messageWelcome debe ser welcomeMessage basado en tu estructura
            // Y corrigiendo el acceso y establecimiento de hasSound
            if (welcomeMessage.hasSound !== undefined) {
                welcomeMessage.hasSound = Boolean(welcomeMessage.hasSound);
            }

            // Actualiza los valores de Formik correctamente
            formik.setValues({
                style: configurationBot?.configurationJson?.style,
                color1: configurationBot?.configurationJson?.color1,
                color2: configurationBot?.configurationJson?.color2,
                color3: configurationBot?.configurationJson?.color3,
                name: configurationBot?.configurationJson?.name,
                welcomeMessage: {
                    text: configurationBot?.configurationJson?.welcomeMessage.text,
                    type: configurationBot?.configurationJson?.welcomeMessage.type,
                    delay: configurationBot?.configurationJson?.welcomeMessage.delay,
                    hasSound: configurationBot?.configurationJson?.welcomeMessage.hasSound,
                },
                logo: configurationBot?.configurationJson?.logo,
                environment: configurationBot.configurationJson.environment || "",
                // 
                //
                welcomeMessageEnabled: configurationBot?.configurationJson?.welcomeMessage ? true : false,
                systemUnavailableMessage: configurationBot?.systemUnavailableMessage,

                isBlocked: configurationBot?.isBlocked ? true : false,
            },);

            // Manejo de la imagen del usuario con retardo
            if (configurationBot?.configurationJson && configurationBot.configurationJson.logo) {
                let backImage = configurationBot.configurationJson.logo;
                setImageLogo(null); // Asumiendo que setImageLogo es una función de estado para actualizar la imagen del usuario
                setTimeout(() => {
                    setImageLogo(backImage);
                }, 400);
            }
        }
    };

    // Cojo la referencia del observable para saber cuando ha habido un cambio
    const controlFile$ = React.useRef(FileItemObservableService.instance.control$);

    /** Cuando existe un cambio en el observable actializo el listado de items */
    useEffect(() => {
        const subscription = FileItemObservableService.instance
            .control$.subscribe(
                item => {
                    if (item && item.fileItemList && item.fileItemList.length > 0) {
                        formik.values.logo = item.fileItemList[0].pathRelative;
                        formik.setFieldValue('logo', item.fileItemList[0].pathRelative);
                    } else {
                        formik.setFieldValue('logo', "");
                    }
                }
            )
        return () => {
            subscription.unsubscribe();
        }

    }, [controlFile$]);



    // Función que pasas como prop `onChange`
    const handleEmojiChange = (e) => {
        // Tu lógica personalizada, si es necesario
        formik.setFieldValue(e.target.name, e.target.value);
    };

    const handleRichTextEditorChange = (content, delta, source, editor) => {
        formik.setFieldValue("systemUnavailableMessage", editor.getHTML());
    };

    const handleAutocompleteChange = (name, value) => {
        if (value === undefined || value === null) {
            console.warn("No hay una selección");
        } else {
            console.log("aqui, no?", name, value);
            formik.setFieldValue(name, value);
        }
    };

    return (
        <CForm className='relative' onSubmit={formik.handleSubmit}>


            <div className={clsx(`fixed bottom-5 left-5 z-50 transition-all duration-150 ease-in-out md:block hidden`, {
                'left-[277px]': sidebarShow
            })}>
                <Tooltip title="Cargar datos en el ChatBot" placement="top" arrow
                    enterDelay={700}
                    leaveDelay={200}
                >
                    <span>
                        <button
                            type='button'
                            onClick={handleFormatPreview}
                            className={clsx(`flex items-center justify-center p-3 bg-blue-500 hover:bg-blue-700 text-white font-bold rounded-full shadow-lg hover:shadow-xl transition duration-150 ease-in-out`, {
                                'bg-slate-400 hover:bg-slate-400': disabled
                            })}
                            disabled={disabled}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                            </svg>
                        </button>
                    </span>
                </Tooltip>
            </div>

            <div className='flex flex-col md:flex-row'>
                <div className='flex-1'>
                    <TextField
                        className='w-full'
                        disabled={disabled}
                        variant='standard'
                        id="name"
                        name="name"
                        label="Nombre"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name ? formik.errors.name.toString() : ''}
                        margin="normal"
                    />
                </div>

                <div className='flex items-center justify-between flex-1 text-2xl mt-4 mb-2 sm:mt-0 md:ml-4 p-2'>
                    <div className='flex items-center'>
                        <span className='mr-2'><MdAppBlocking /></span>
                        {formik.values.isBlocked ? "El bot está Desactivado" : "El bot está Activado"}

                    </div>
                    <Tooltip title="Impide el uso del bot." placement="top">
                        <FormControlLabel
                            control={
                                <Switch
                                    disabled={disabled}
                                    checked={formik.values.isBlocked}
                                    onChange={() => formik.setFieldValue('isBlocked', !formik.values.isBlocked)}
                                    name="isBlocked"
                                    color="primary"
                                />
                            }
                            label=""
                        />
                    </Tooltip>
                </div>
            </div>


            <div className="row bg-slate-0 mt-4">

                <CCol xs={12} md={6} className=''>
                    {/* <span>{identity && (
                            <span>{JSON.stringify(identity)}</span>
                        )}</span> */}
                    <CFormLabel className='text-xl mt-2' htmlFor="name">Avatar</CFormLabel>

                    <DragDrop
                        id={"id-dd-config"}

                        serviceAddFile={FileService.addToBot}
                        apiUrl={apiBotImagesUrl}

                        styleDD={{ maxWidth: "auto", height: 160, marginTop: 0 }}
                        valueDefault={imageLogo}
                        isOnlyOneFile={true}
                        disabled={disabled}
                        directoryNameList={["images", "logo"]}
                        maxSizeMB={19}
                        typeAllowed={[
                            "image/*",
                            "image/gif",
                            "image/jpeg",
                            "image/png",
                            "image/jpg"
                        ]}
                    />


                </CCol>

                {/* Colores */}
                {/* Repite este patrón para color1, color2, y color3 */}
                <CCol xs={12} md={6} className=''>
                    <div className='flex flex-col items-center'>
                        <CFormLabel className='text-xl mt-2' htmlFor="name">Colores del bot</CFormLabel>
                        {
                            ['color1', 'color2', 'color3'].map((color, index) => (
                                <div key={color} className='flex items-center my-2'>
                                    <span className='mr-2 font-semibold'>Color {index + 1}</span>
                                    <CFormInput
                                        type="color"

                                        id={color}
                                        className='input-light-picker-color'

                                        disabled={disabled}
                                        name={color}
                                        onChange={handleEmojiChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values[color]}
                                        defaultValue={formik.values[color]}
                                    />
                                    <span className='ml-2 text-sm font-semibold w-[60px]'>{formik.values[color]}</span>
                                </div>
                            ))
                        }
                    </div>
                </CCol>

            </div>
            <div className='flex text-2xl mt-4 mb-2'>
                <div className='flex items-center'><span className='mr-2'><CgHello /></span> Mensaje de Bienvenida</div>
                <div className='ml-4'>
                    <Tooltip title="Activa el mensaje de bienvenida del chatbot" placement="top">
                        <FormControlLabel
                            control={
                                <Switch
                                    disabled={disabled}
                                    checked={formik.values.welcomeMessageEnabled}
                                    onChange={() => formik.setFieldValue('welcomeMessageEnabled', !formik.values.welcomeMessageEnabled)}
                                    name="welcomeMessageEnabled"
                                    color="primary"
                                />
                            }
                            label=""
                        />
                    </Tooltip>
                </div>
            </div>
            <Divider />





            {
                formik.values.welcomeMessageEnabled && (
                    <>
                        <div className='flex flex-wrap justify-evenly' style={{ marginTop: 40 }}>

                            {/* Mensaje de Bienvenida - Texto */}

                            <div className='xs:w-full md:w-[24%]'>
                                {/* Mensaje de Bienvenida - Tipo */}
                                <CFormLabel className='text-xl' htmlFor="welcomeMessage.delay">Tipo</CFormLabel>

                                {/* <Autocomplete
                                    className="cmulti-select-light mt-2"
                                    id="multi-select-configuration"
                                    // table='role'
                                    disabled={disabled}
                                    name={'welcomeMessage.type'}
                                    defaultValue={{ key: "id", value: formik.values.welcomeMessage.type }}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}



                                    defaultData={[{ id: 'slide', title: 'slide' }, { id: 'bubble', title: 'bubble' }]}
                                    table={'special_case_type_welcome_bot' as any}
                                /> */}


                                <AutocompleteMui
                                    className="cmulti-select-light mt-2"
                                    id="multi-select-configuration"
                                    // table='role'
                                    disabled={disabled}
                                    name={'welcomeMessage.type'}
                                    defaultValue={{ key: "id", value: formik.values.welcomeMessage.type }}
                                    onChange={(newValue) => handleAutocompleteChange("welcomeMessage.type", newValue?.id)}
                                    onBlur={formik.handleBlur}



                                    defaultData={[{ id: 'slide', title: 'slide' }, { id: 'bubble', title: 'bubble' }]}
                                    table={'special_case_type_welcome_bot' as any}
                                />
                            </div>



                            <div className='w-ful md:w-[40%]'>
                                <CFormLabel className='text-xl' htmlFor="welcomeMessage.delay">Texto</CFormLabel>
                                <CFormTextareaCustom
                                    id="welcomeMessage.text"
                                    name="welcomeMessage.text"
                                    value={formik.values.welcomeMessage.text}
                                    placeholder="Texto del mensaje de bienvenida"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    disabled={disabled}
                                    // invalid={formik.touched.welcomeMessage?.text && Boolean(formik.errors.welcomeMessage?.text)}
                                    errorMessage={formik.errors.welcomeMessage?.text as string}
                                // touched={formik.touched.welcomeMessage?.text}
                                />

                                {formik.touched.welcomeMessage?.text && typeof formik.errors.welcomeMessage?.text === 'string' && (
                                    <CFormText className="text-danger">{formik.errors.welcomeMessage.text}</CFormText>
                                )}
                            </div>


                        </div>



                        {/* Mensaje de Bienvenida - Retraso */}
                        <div className='flex flex-col items-center justify-center' style={{ marginTop: 70 }}>

                            <CFormLabel className='text-xl' htmlFor="welcomeMessage.delay">Delay</CFormLabel>
                            <span className='text-sm'>Tiempo que tardará en mostrar el mensaje una vez cargada la página</span>
                            <div className='w-[80%]'>
                                <Slider
                                    min={0}
                                    max={4000}
                                    disabled={disabled}
                                    aria-label="Delay"
                                    value={formik.values.welcomeMessage.delay || 0} // Asegúrate de que siempre haya un valor
                                    onChange={(event, newValue) => {
                                        formik.setFieldValue("welcomeMessage.delay", newValue);
                                    }}
                                    onBlur={formik.handleBlur}
                                    // valueLabelDisplay="auto"
                                    valueLabelDisplay="off"
                                    id="welcomeMessage.delay"
                                    name="welcomeMessage.delay"
                                    marks={[
                                        {
                                            value: 0,
                                            label: '0 segundos',
                                        },
                                        {
                                            value: 1000,
                                            label: '1 segundo',
                                        },
                                        {
                                            value: 2000,
                                            label: '2 segundos',
                                        },
                                        {
                                            value: 3000,
                                            label: '3 segundos',
                                        },
                                        {
                                            value: 4000,
                                            label: '4 segundos',
                                        },
                                    ]}
                                    step={1000} // Define el mínimo cambio entre valores, ajusta según necesites
                                />
                            </div>


                        </div>
                        {/* 
            
                    {/* Mensaje de Bienvenida - Tiene Sonido */}
                        {/* <CFormLabel htmlFor="welcomeMessage.hasSound">Mensaje de Bienvenida - Tiene Sonido</CFormLabel> */}

                        <div className='flex flex-col items-center justify-center' style={{ marginTop: 70 }}>
                            <CFormLabel className='text-xl' htmlFor="welcomeMessage.delay">Sonido</CFormLabel>
                            <span className='text-sm'>Alerta sonora tras cargar la página</span>
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={disabled}
                                        checked={formik.values.welcomeMessage.hasSound}
                                        onChange={formik.handleChange}
                                        name="welcomeMessage.hasSound"
                                        color="primary"
                                    />
                                }
                                label=""
                            />

                        </div>
                    </>
                )
            }




            <div className='text-2xl mt-4 mb-2'>
                <div className='flex items-center'><span className='mr-2'><MdOutlineSecurityUpdateWarning />
                </span> Texto de Inactividad</div>
            </div>
            <Divider />

            <div className='m-4'>
                <MyRichTextEditor
                    disabled={disabled}
                    value={formik.values.systemUnavailableMessage}
                    onChange={handleRichTextEditorChange}
                />
            </div>

            <div className='h-[20px]'></div>




            {/* <pre dangerouslySetInnerHTML={{ __html: formik.values.systemUnavailableMessage }}></pre> */}

        </CForm >
    );
});


ConfigurationForm.displayName = "ConfigurationForm";
export default ConfigurationForm;
