// Analytic4.tsx
import React from 'react';
import { TreeMapChartCustom } from '../../components/@analitycs';
import { CCard } from '@coreui/react-pro';
import { CiWarning } from 'react-icons/ci';

interface Analytic4Props {
    data: any;
    error: boolean;
    loading: boolean;
}

export const Analytic4: React.FC<Analytic4Props> = ({ data, error, loading }) => {
    // El contenedor debe tener un tamaño definido, prueba con un tamaño específico o ajusta según tu layout
    const containerStyle = {
        width: '100%',
        height: '300px', // Este es un ejemplo, ajusta a lo que necesites
    };

    return (
        <CCard className="rounded-xl p-3 my-4" style={{ height: 380 }}>
            <div className='flex flex-col md:flex-row justify-between items-center mx-4 my-2'>
                <h2 className='text-2xl font-semibold'>Mapa de Interacciones</h2>
            </div>

            {loading && (
                <div className="flex justify-center items-center h-full mt-5">
                    <div className="flex justify-center items-center">
                        <div className="animate-spin rounded-full h-[32px] w-[32px] border-t-2 border-b-2 border-gray-900"></div>
                    </div>
                </div>
            )}

            {error && (
                <div className="flex justify-center items-center mx-4 my-2 h-full">
                    <div className="text-center">
                        <CiWarning size={150} className="text-yellow-500 mx-auto" />
                        <p className="mt-4 text-lg font-semibold text-gray-700">
                            No se puede mostrar la información
                        </p>
                    </div>
                </div>
            )}

            {!loading && !error && (
                <TreeMapChartCustom data={data} />

            )}

        </CCard>
    );
};
