// import { ChangeEvent, useEffect, useRef, useState } from 'react';
// import { CFormTextarea, CFormText } from '@coreui/react-pro';

// // import 'emoji-mart/css/emoji-mart.css';
// import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon'; // Puedes usar el ícono que prefieras
// import data from '@emoji-mart/data'
// import Picker from '@emoji-mart/react'
// import { ref } from 'yup';

// interface CFormTextareaCustomProps {
//     id: string;
//     name: string;
//     value: string;
//     placeholder?: string;
//     disabled?: boolean;
//     invalid?: boolean;
//     rows?: number;
//     onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
//     onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
//     errorMessage?: string;
//     touched?: boolean;
// }

// export const CFormTextareaCustom = ({
//     id,
//     name,
//     value,
//     placeholder,
//     disabled,
//     rows = 3,
//     invalid,
//     onChange,
//     onBlur,
//     errorMessage,
//     touched,
// }: CFormTextareaCustomProps) => {
//     const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);

//     const insertEmoji = (emoji) => {
//         const textarea = document.getElementById(id) as HTMLTextAreaElement;
//         const start = textarea.selectionStart;
//         const end = textarea.selectionEnd;
//         const before = value.substring(0, start);
//         const after = value.substring(end, value.length);

//         // Construye el nuevo valor
//         const newValue = `${before}${emoji.native}${after}`;

//         // Crea un evento falso para pasar a onChange
//         const event = {
//             target: {
//                 id,
//                 name,
//                 value: newValue,
//             },
//         } as unknown as ChangeEvent<HTMLTextAreaElement>;

//         onChange(event);

//         // Coloca el cursor después del emoji insertado
//         setTimeout(() => {
//             textarea.selectionStart = textarea.selectionEnd = start + emoji.native.length;
//             textarea.focus();
//         }, 0);
//     };


//     const wrapperRef = useRef(null);
//     useOutsideAlerter(wrapperRef, () => setIsEmojiPickerOpen(false));

//     function useOutsideAlerter(ref, onClose) {
//         useEffect(() => {
//             function handleClickOutside(event) {
//                 if (ref.current && !ref.current.contains(event.target)) {
//                     onClose();
//                 }
//             }

//             document.addEventListener("mousedown", handleClickOutside);
//             return () => {
//                 document.removeEventListener("mousedown", handleClickOutside);
//             };
//         }, [ref, onClose]);
//     }


//     return (
//         <div className='relative'>
//             <CFormTextarea
//                 disabled={disabled}
//                 className='input-light'
//                 id={id}
//                 name={name}
//                 placeholder={placeholder}
//                 onChange={onChange}
//                 onBlur={onBlur}
//                 value={value}
//                 invalid={invalid}
//                 rows={rows}
//             />
//             {!disabled && (
//                 <>
//                     <InsertEmoticonIcon
//                         className='emoji-icon' // Asegúrate de que los estilos posicionen correctamente el ícono
//                         onClick={() => setIsEmojiPickerOpen(isEmojiPickerOpen ? false : true)}
//                     />
//                     {isEmojiPickerOpen && (
//                         <div ref={wrapperRef} className="emoji-picker-container"> {/* Envuelve el Picker para un mejor control de estilos */}
//                             <Picker
//                                 theme='light'
//                                 maxFrequentRows={0}
//                                 locale='es'
//                                 emojiSiz={10}
//                                 data={data}
//                                 onEmojiSelect={(e) => {
//                                     insertEmoji(e);
//                                     setIsEmojiPickerOpen(false);
//                                 }}
//                                 style={{ position: 'absolute', bottom: '0', right: 40, zIndex: 2 }}
//                             />
//                         </div>
//                     )}
//                 </>
//             )}
//             {touched && invalid && errorMessage && (
//                 <CFormText className="text-danger">{errorMessage}</CFormText>
//             )}
//         </div>
//     );
// };


import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { TextField, IconButton, Box } from '@mui/material';

// import 'emoji-mart/css/emoji-mart.css';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon'; // Puedes usar el ícono que prefieras
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { ref } from 'yup';



interface CFormTextareaCustomProps {
    id: string;
    name: string;
    value: string;
    placeholder?: string;
    disabled?: boolean;
    rows?: number;
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    onBlur?: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
    errorMessage?: string;
    touched?: boolean;
}

export const CFormTextareaCustom = ({
    id,
    name,
    value,
    placeholder,
    disabled,
    rows = 3,
    onChange,
    onBlur,
    errorMessage,
    touched,
}: CFormTextareaCustomProps) => {
    const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);

    const insertEmoji = (emoji: any) => {
        // Accede al elemento input/textarea a través del ID
        const textInput = document.getElementById(id) as HTMLInputElement | HTMLTextAreaElement;

        // Guarda la posición actual del cursor o la selección
        const start = textInput.selectionStart;
        const end = textInput.selectionEnd;

        // Construye el nuevo valor con el emoji insertado en la posición correcta
        const textBefore = value.substring(0, start);
        const textAfter = value.substring(end, value.length);
        const newValue = textBefore + emoji.native + textAfter;

        // Actualiza el valor del campo utilizando el evento onChange
        onChange({
            target: { id, name, value: newValue },
        } as ChangeEvent<HTMLTextAreaElement | HTMLInputElement>);

        // Cierra el selector de emojis después de insertar el emoji
        setIsEmojiPickerOpen(false);

        // Establece un breve retraso para asegurar que el estado del componente se actualice correctamente
        setTimeout(() => {
            // Coloca el cursor inmediatamente después del emoji insertado
            textInput.focus(); // Asegura que el campo de texto tenga el foco
            const cursorPosition = start + emoji.native.length; // Calcula la nueva posición del cursor
            textInput.setSelectionRange(cursorPosition, cursorPosition); // Mueve el cursor a la nueva posición
        }, 0);
    };

    const wrapperRef = useRef(null);
    // useOutsideAlerter(wrapperRef, () => setIsEmojiPickerOpen(false));

    useEffect(() => {
        // This function will close the emoji picker if clicked outside
        function handleClickOutside(event: MouseEvent) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
                setIsEmojiPickerOpen(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);


    return (
        <div className="relative"  >
            <TextField

                fullWidth
                multiline
                minRows={rows}
                disabled={disabled}
                variant="standard"
                id={id}
                name={name}
                placeholder={placeholder}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                error={touched && !!errorMessage}
                helperText={touched && errorMessage ? errorMessage : ' '}
            />
            {!disabled && (
                <>
                    <InsertEmoticonIcon
                        className='emoji-icon' // Asegúrate de que los estilos posicionen correctamente el ícono
                        onClick={() => setIsEmojiPickerOpen(isEmojiPickerOpen ? false : true)}
                    />
                    {isEmojiPickerOpen && (
                        <div className="emoji-picker-container" ref={wrapperRef}>
                            <Picker
                                theme='light'
                                data={data}
                                // onEmojiSelect={insertEmoji}
                                locale='es'
                                onEmojiSelect={(e) => {
                                    insertEmoji(e);
                                    setIsEmojiPickerOpen(false);
                                }}

                                style={{ position: 'absolute', bottom: '0', right: 40, zIndex: 2 }}
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    );

}