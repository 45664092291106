import { forwardRef } from "react";
import { DropdownItem } from "../../../../../core/interfaces/dropdown/dropdown-item";
import { useNavigate } from "react-router-dom";

interface DropdownItemProps {
    item: DropdownItem;
    handleVisible: (visible: boolean) => void;
}

export const DropItemStandard = forwardRef<HTMLDivElement, DropdownItemProps>(({ item, handleVisible }, ref) => {

    const { name, icon: IconComponent, to, fn, roles } = item;

    const navivation = useNavigate()

    // Controlador de clic que ignora los clics del botón derecho
    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {

        // Verificar si el clic fue hecho con el botón derecho
        if (event.button === 2) {
            // No hacer nada si es clic derecho
            return;
        }
        // Cerrar el menú para clics que no son del botón derecho
        handleVisible(false);
        if (to) {
            navivation(to);
        } else if (fn) {
            fn();
        }
    };






    return (
        <div ref={ref} onClick={handleClick} className="w-full cursor-pointer">
            <div className="flex items-center text-black pl-3 pr-2 h-[40px] rounded-lg hover:bg-gray-100 transition duration-300 ease-in-out">
                {IconComponent && <span className="text-md mr-2 antialiased">{IconComponent}</span>}
                {name && <span className="capitalize">{name}</span>}
            </div>
        </div>
    );
});

DropItemStandard.displayName = "DropItemStandard";

