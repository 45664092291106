import moment from "moment";
import { FlowData } from "../../../app/components/@analitycs/sankey-chart-custom/interfaces";
import * as d3 from 'd3-color';

const TIME_SEGMENTS = {
    morning: { start: 6, end: 12 },
    afternoon: { start: 12, end: 18 },
    night: { start: 18, end: 5 }
};

export class AnalyticUtil {

    static clasificarConversacionesConRango(data, startDate, endDate) {
        const resultado = {
            daily: {},
            weekly: {},
            monthly: {},
            quarterly: {}
        };

        // Rellenar rangos con ceros para conversaciones, leads y sessionsEnded
        let currentDate = moment(startDate);
        const end = moment(endDate);

        while (currentDate <= end) {
            const dia = currentDate.format('YYYY-MM-DD');
            const semana = `S${currentDate.isoWeek()} ${currentDate.year()}`;
            const mes = currentDate.format('MM-YYYY');
            const trimestre = `T${currentDate.quarter()} ${currentDate.year()}`;

            resultado.daily[dia] = { mañana: 0, tarde: 0, noche: 0, Leads: 0, SessionsEnded: 0 };

            if (!resultado.weekly[semana]) {
                resultado.weekly[semana] = { mañana: 0, tarde: 0, noche: 0, Leads: 0, SessionsEnded: 0 };
            }
            if (!resultado.monthly[mes]) {
                resultado.monthly[mes] = { mañana: 0, tarde: 0, noche: 0, Leads: 0, SessionsEnded: 0 };
            }
            if (!resultado.quarterly[trimestre]) {
                resultado.quarterly[trimestre] = { mañana: 0, tarde: 0, noche: 0, Leads: 0, SessionsEnded: 0 };
            }

            currentDate.add(1, 'days');
        }

        // Procesar las fechas de conversación
        data.total.dailyWithTime.forEach(fechaStr => {
            const fecha = moment(fechaStr);
            const dia = fecha.format('YYYY-MM-DD');
            const semana = `S${fecha.isoWeek()} ${fecha.year()}`;
            const mes = fecha.format('MM-YYYY');
            const trimestre = `T${fecha.quarter()} ${fecha.year()}`;

            const hora = fecha.hour();

            // Determinar la franja horaria y actualizar contadores de conversaciones
            const franja = hora >= 6 && hora < 12 ? 'mañana' :
                hora >= 12 && hora < 18 ? 'tarde' : 'noche';

            resultado.daily[dia][franja]++;
            resultado.weekly[semana][franja]++;
            resultado.monthly[mes][franja]++;
            resultado.quarterly[trimestre][franja]++;
        });

        // Procesar leads y sessionsEnded
        ['leads', 'sessionsEnded'].forEach(tipo => {
            console.log("tipo", tipo)
            Object.entries(data[tipo].daily).forEach(([fecha, cantidad]) => {
                const momentFecha = moment(fecha);
                const semana = `S${momentFecha.isoWeek()} ${momentFecha.year()}`;
                const mes = momentFecha.format('MM-YYYY');
                const trimestre = `T${momentFecha.quarter()} ${momentFecha.year()}`;

                if (!resultado.daily[fecha]) resultado.daily[fecha] = { mañana: 0, tarde: 0, noche: 0, Leads: 0, SessionsEnded: 0 };
                if (!resultado.weekly[semana]) resultado.weekly[semana] = { mañana: 0, tarde: 0, noche: 0, Leads: 0, SessionsEnded: 0 };
                if (!resultado.monthly[mes]) resultado.monthly[mes] = { mañana: 0, tarde: 0, noche: 0, Leads: 0, SessionsEnded: 0 };
                if (!resultado.quarterly[trimestre]) resultado.quarterly[trimestre] = { mañana: 0, tarde: 0, noche: 0, Leads: 0, SessionsEnded: 0 };


                if (!resultado.daily[fecha][tipo]) {
                    resultado.daily[fecha][tipo] = 0;
                }
                if (!resultado.weekly[semana][tipo]) {
                    resultado.weekly[semana][tipo] = 0;
                }
                if (!resultado.monthly[mes][tipo]) {
                    resultado.monthly[mes][tipo] = 0;
                }
                if (!resultado.quarterly[trimestre][tipo]) {
                    resultado.quarterly[trimestre][tipo] = 0;
                }

                if (tipo === 'leads') tipo = 'Leads';
                if (tipo === 'sessionsEnded') tipo = 'SessionsEnded';

                if (tipo === 'Leads' || tipo === 'SessionsEnded') {
                    resultado.daily[fecha][tipo] += cantidad;
                    resultado.weekly[semana][tipo] += cantidad;
                    resultado.monthly[mes][tipo] += cantidad;
                    resultado.quarterly[trimestre][tipo] += cantidad;
                } else {
                    console.log("ALGO VA MAL\n\nLa fecha es: ", fecha, "\ny el tipo es: ", tipo, "\nLa cantidad es: ", cantidad)

                }

            });
        });

        // Convertir los contadores a totales de conversaciones
        const convertirATotalesConversaciones = (obj) => {
            Object.keys(obj).forEach(key => {
                const { mañana, tarde, noche } = obj[key];
                obj[key].Conversaciones = mañana + tarde + noche;
            });
        };

        convertirATotalesConversaciones(resultado.daily);
        convertirATotalesConversaciones(resultado.weekly);
        convertirATotalesConversaciones(resultado.monthly);
        convertirATotalesConversaciones(resultado.quarterly);

        return resultado;
    }


    // static clasificarConversacionesConRango(data, startDate, endDate) {
    //     const resultado = {
    //         daily: {},
    //         weekly: {},
    //         monthly: {},
    //         quarterly: {}
    //     };

    //     // Rellenar rangos con ceros
    //     let currentDate = moment(startDate);
    //     const end = moment(endDate);

    //     while (currentDate <= end) {
    //         const dia = currentDate.format('YYYY-MM-DD');
    //         const semana = `S${currentDate.isoWeek()} ${currentDate.year()}`;
    //         const mes = currentDate.format('MM-YYYY');
    //         const trimestre = `T${currentDate.quarter()} ${currentDate.year()}`;

    //         resultado.daily[dia] = { mañana: 0, tarde: 0, noche: 0 };

    //         if (!resultado.weekly[semana]) {
    //             resultado.weekly[semana] = { mañana: 0, tarde: 0, noche: 0 };
    //         }
    //         if (!resultado.monthly[mes]) {
    //             resultado.monthly[mes] = { mañana: 0, tarde: 0, noche: 0 };
    //         }
    //         if (!resultado.quarterly[trimestre]) {
    //             resultado.quarterly[trimestre] = { mañana: 0, tarde: 0, noche: 0 };
    //         }

    //         currentDate.add(1, 'days');
    //     }

    //     // Procesar las fechas de conversación
    //     data.total.dailyWithTime.forEach(fechaStr => {
    //         const fecha = moment(fechaStr);
    //         const dia = fecha.format('YYYY-MM-DD');
    //         const semana = `S${fecha.isoWeek()} ${fecha.year()}`;
    //         const mes = fecha.format('MM-YYYY');
    //         const trimestre = `T${fecha.quarter()} ${fecha.year()}`;

    //         const hora = fecha.hour();

    //         // Determinar la franja horaria y actualizar todos los contadores
    //         const franja = hora >= 6 && hora < 12 ? 'mañana' :
    //             hora >= 12 && hora < 18 ? 'tarde' : 'noche';

    //         resultado.daily[dia][franja]++;
    //         resultado.weekly[semana][franja]++;
    //         resultado.monthly[mes][franja]++;
    //         resultado.quarterly[trimestre][franja]++;
    //     });

    //     // Convertir los contadores a totales
    //     const convertirATotales = (obj) => {
    //         Object.keys(obj).forEach(key => {
    //             const { mañana, tarde, noche } = obj[key];
    //             obj[key].Conversaciones = mañana + tarde + noche;
    //         });
    //     };

    //     convertirATotales(resultado.daily);
    //     convertirATotales(resultado.weekly);
    //     convertirATotales(resultado.monthly);
    //     convertirATotales(resultado.quarterly);

    //     return resultado;
    // }

    // static clasificarConversacionesConRango(data, startDate, endDate) {
    //     const resultado = {
    //         daily: {},
    //         weekly: {},
    //         monthly: {},
    //         quarterly: {}
    //     };

    //     // Rellenar rangos con ceros
    //     let currentDate = moment(startDate);
    //     const end = moment(endDate);

    //     while (currentDate <= end) {
    //         const dia = currentDate.format('YYYY-MM-DD');
    //         const semana = `S${currentDate.isoWeek()} ${currentDate.year()}`;
    //         const mes = currentDate.format('MM-YYYY');
    //         const trimestre = `T${currentDate.quarter()} ${currentDate.year()}`;

    //         resultado.daily[dia] = { mañana: 0, tarde: 0, noche: 0 };

    //         if (!resultado.weekly[semana]) {
    //             resultado.weekly[semana] = 0;
    //         }
    //         if (!resultado.monthly[mes]) {
    //             resultado.monthly[mes] = 0;
    //         }
    //         if (!resultado.quarterly[trimestre]) {
    //             resultado.quarterly[trimestre] = 0;
    //         }

    //         currentDate.add(1, 'days');
    //     }

    //     // Procesar las fechas de conversación
    //     data.total.dailyWithTime.forEach(fechaStr => {
    //         const fecha = moment(fechaStr);
    //         const dia = fecha.format('YYYY-MM-DD');
    //         const semana = `S${fecha.isoWeek()} ${fecha.year()}`;
    //         const mes = fecha.format('MM-YYYY');
    //         const trimestre = `T${fecha.quarter()} ${fecha.year()}`;

    //         const hora = fecha.hour();

    //         // Conteo diario por franjas horarias
    //         if (hora >= 6 && hora < 12) {
    //             resultado.daily[dia].mañana++;
    //         } else if (hora >= 12 && hora < 18) {
    //             resultado.daily[dia].tarde++;
    //         } else {
    //             resultado.daily[dia].noche++;
    //         }

    //         // Incrementar contadores de semana, mes y trimestre
    //         resultado.weekly[semana]++;
    //         resultado.monthly[mes]++;
    //         resultado.quarterly[trimestre]++;
    //     });

    //     // Agregar el total diario de conversaciones
    //     Object.keys(resultado.daily).forEach(dia => {
    //         const { mañana, tarde, noche } = resultado.daily[dia];
    //         resultado.daily[dia].Conversaciones = mañana + tarde + noche;
    //     });

    //     return resultado;
    // }

    // static clasificarConversaciones(data) {
    //     const resultado = {
    //         daily: {},
    //         weekly: {},
    //         monthly: {},
    //         quarterly: {}
    //     };

    //     data.total.dailyWithTime.forEach(fechaStr => {
    //         const fecha = moment(fechaStr);
    //         const dia = fecha.format('YYYY-MM-DD');
    //         const semana = `S${fecha.isoWeek()} ${fecha.year()}`;
    //         const mes = fecha.format('MM-YYYY');
    //         const trimestre = `T${fecha.quarter()} ${fecha.year()}`;

    //         // Inicializar contadores si aún no existen
    //         if (!resultado.daily[dia]) {
    //             resultado.daily[dia] = { mañana: 0, tarde: 0, noche: 0 };
    //         }
    //         if (!resultado.weekly[semana]) {
    //             resultado.weekly[semana] = 0;
    //         }
    //         if (!resultado.monthly[mes]) {
    //             resultado.monthly[mes] = 0;
    //         }
    //         if (!resultado.quarterly[trimestre]) {
    //             resultado.quarterly[trimestre] = 0;
    //         }

    //         const hora = fecha.hour();

    //         // Conteo diario por franjas horarias
    //         if (hora >= 6 && hora < 12) { // 6am a 11:59am
    //             resultado.daily[dia].mañana++;
    //         } else if (hora >= 12 && hora < 18) { // 12pm a 5:59pm
    //             resultado.daily[dia].tarde++;
    //         } else { // 6pm a 5:59am
    //             resultado.daily[dia].noche++;
    //         }

    //         // Incrementar contadores de semana, mes y trimestre
    //         resultado.weekly[semana]++;
    //         resultado.monthly[mes]++;
    //         resultado.quarterly[trimestre]++;
    //     });

    //     // Agregar el total diario de conversaciones
    //     Object.keys(resultado.daily).forEach(dia => {
    //         const { mañana, tarde, noche } = resultado.daily[dia];
    //         resultado.daily[dia].Conversaciones = mañana + tarde + noche;
    //     });

    //     return resultado;
    // }


    static transformDataForBarAndLineChart(item) {
        const data = [];

        // Obtenemos todas las fechas disponibles en el rango
        const dates = Object.keys(item?.total?.daily);

        // Iteramos sobre cada fecha para construir el objeto de datos para el gráfico
        dates.forEach(date => {
            const totalConversations = item.total.daily[date] || 0;
            const leads = item.leads.daily[date] || 0;
            const sessionsEnded = item.sessionsEnded.daily[date] || 0;
            const momentDate = moment(date);

            // Asegúrate de que sumas 1 al resultado del método `month()` antes de dividir por 3
            // Esto es porque `month()` devuelve un valor de 0 a 11, donde 0 es enero.
            const quarter = Math.ceil((momentDate.month() + 1) / 3);

            data.push({
                date: momentDate.format('DD-MM-YY'), // día
                week: `S${momentDate.isoWeek()} ${momentDate.year()}`, // semana
                month: momentDate.format('MM-YYYY'), // mes
                quarter: `T${quarter} ${momentDate.year()}`, // trimestre
                Conversaciones: totalConversations,
                Leads: leads,
                SessionsEnded: sessionsEnded,
            });
        });

        return data;
    }


    // 
    // 
    // 
    // 
    // 

    // static getSegment(hour) {
    //     if (hour >= TIME_SEGMENTS.morning.start && hour < TIME_SEGMENTS.morning.end) {
    //         return 'morning';
    //     } else if (hour >= TIME_SEGMENTS.afternoon.start && hour < TIME_SEGMENTS.afternoon.end) {
    //         return 'afternoon';
    //     } else {
    //         return 'night';
    //     }
    // }

    // static getTimeKey(dateTime, granularity) {
    //     const momentDate = moment(dateTime);
    //     switch (granularity) {
    //         case 'weekly':
    //             return `S${momentDate.isoWeek()} ${momentDate.year()}`;
    //         case 'monthly':
    //             return momentDate.format('MM-YYYY');
    //         case 'quarterly':
    //             const quarter = Math.ceil((momentDate.month() + 1) / 3);
    //             return `T${quarter} ${momentDate.year()}`;
    //         default: // 'daily'
    //             return momentDate.format('YYYY-MM-DD');
    //     }
    // }

    // static addConversationsToSegments(dateTimeString, segments) {
    //     const dateTime = moment(dateTimeString);
    //     const segment = this.getSegment(dateTime.hour());
    //     segments[segment]++;
    // }


    // static transformDataForBarAndLineChart2(item) {
    //     const data = {
    //         daily: {},
    //         weekly: {},
    //         monthly: {},
    //         // quarterly: {}
    //     };

    //     // Obtenemos todas las fechas con tiempo y las procesamos
    //     item.total.dailyWithTime.forEach(dateTimeString => {
    //         const date = moment(dateTimeString).format('YYYY-MM-DD');
    //         const week = `W${moment(dateTimeString).isoWeek()} ${moment(dateTimeString).year()}`;
    //         const month = moment(dateTimeString).format('MM-YYYY');
    //         // const quarter = `Q${Math.ceil((moment(dateTimeString).month() + 1) / 3)} ${moment(dateTimeString).year()}`;

    //         // Inicializa o acumula los contadores por segmento para cada granularidad
    //         data.daily[date] = data.daily[date] || { morning: 0, afternoon: 0, night: 0 };
    //         this.addConversationsToSegments(dateTimeString, data.daily[date]);

    //         data.weekly[week] = data.weekly[week] || { morning: 0, afternoon: 0, night: 0 };
    //         this.addConversationsToSegments(dateTimeString, data.weekly[week]);

    //         data.monthly[month] = data.monthly[month] || { morning: 0, afternoon: 0, night: 0 };
    //         this.addConversationsToSegments(dateTimeString, data.monthly[month]);

    //         // data.quarterly[quarter] = data.quarterly[quarter] || { morning: 0, afternoon: 0, night: 0 };
    //         // this.addConversationsToSegments(dateTimeString, data.quarterly[quarter]);
    //     });

    //     // Transforma los datos de totales, leads y sesiones terminadas a la estructura necesaria
    //     ['daily', 'weekly', 'monthly'].forEach(granularity => {
    //         console.log(granularity)
    //         Object.keys(item.total[granularity]).forEach(timeKey => {
    //             const totalConversations = item.total[granularity][timeKey] || 0;
    //             const leads = item.leads[granularity][timeKey] || 0;
    //             const sessionsEnded = item.sessionsEnded[granularity][timeKey] || 0;

    //             data[granularity][timeKey] = {
    //                 ...data[granularity][timeKey],
    //                 totalConversations,
    //                 leads,
    //                 sessionsEnded
    //             };
    //         });
    //     });

    //     return data;
    // }


    static transformDataForPieChart(analyticsData, selectedSocialMedia) {

        // console.log("analyticsData", analyticsData)
        // console.log("selectedSocialMedia", selectedSocialMedia)
        let platformsToShow = Object.keys(analyticsData);

        // Si se seleccionaron redes sociales específicas, filtrar solo esas
        if (selectedSocialMedia && selectedSocialMedia.length > 0) {
            platformsToShow = platformsToShow.filter((platform) => {
                return selectedSocialMedia.some((item) => item?.value.includes(platform))
            }
                // selectedSocialMedia.includes(platform)
            );
        }




        return platformsToShow.map((key) => {
            const { totalConversations, leadsGenerated, sessionsEnded } = analyticsData[key];
            return {
                name: key,
                value: totalConversations, // o elige entre leadsGenerated o sessionsEnded si es necesario
            };
        });
    }





    // analitica 3

    // static transformDataForBarChartAnalytic3(totals) {

    //     console.log(totals)
    //     console.log(totals)
    //     console.log(totals)
    //     console.log(totals)

    //     // Inicializar los arrays de datos para cada tipo de granularidad
    //     const dailyData = [];
    //     const weeklyData = [];
    //     const monthlyData = [];
    //     // Suponiendo que no tienes datos trimestrales directamente, no incluiremos esa parte aquí

    //     // Procesar datos diarios
    //     Object.keys(totals.daily).forEach(date => {
    //         dailyData.push({
    //             date,
    //             messageByHuman: totals.daily[date].messagesByHuman,
    //             Mensajes: totals.daily[date].messagesByBot,
    //         });
    //     });

    //     // Procesar datos semanales
    //     Object.keys(totals.weekly).forEach(week => {
    //         weeklyData.push({
    //             week,
    //             messageByHuman: totals.weekly[week].messagesByHuman,
    //             Mensajes: totals.weekly[week].messagesByBot,
    //         });
    //     });

    //     // Procesar datos mensuales
    //     Object.keys(totals.monthly).forEach(month => {
    //         monthlyData.push({
    //             month,
    //             messageByHuman: totals.monthly[month].messagesByHuman,
    //             Mensajes: totals.monthly[month].messagesByBot,
    //         });
    //     });

    //     // Como ejemplo, devolveremos solo los datos diarios, pero puedes ajustar para devolver lo que necesites
    //     return {dailyData, weeklyData, monthlyData};
    // }


    static transformDataForBarChartAnalytic3(totals) {
        const data = [];
        // Procesar datos diarios
        Object.keys(totals.daily).forEach(date => {
            const momentDate = moment(date);
            const quarter = Math.ceil((momentDate.month() + 1) / 3);
            data.push({
                date: momentDate.format('YYYY-MM-DD'), // Formato más estándar para fechas
                week: `S${momentDate.isoWeek()} ${momentDate.year()}`,
                month: momentDate.format('YYYY-MM'),
                quarter: `T${quarter} ${momentDate.year()}`,
                MensajesHumanos: totals.daily[date].messagesByHuman,
                Mensajes: totals.daily[date].messagesByBot,
            });
        });


        return data;
    }


    // Analitica 4


    static transformToTreeMapChartAnalytic4 = (item: FlowData[]) => {

        const colorScheme = {
            "Default Start Flow": "hsl(14, 90%, 65%)", // naranja en HSL
            "Contact Flow": "hsl(250, 60%, 70%)", // violeta en HSL

        };


        const children = Object.entries(item).map(([flowId, flowData]) => {
            // Usa el color del esquema o uno por defecto
            const baseColor = colorScheme[flowData.name] || "hsl(0, 0%, 50%)"; // gris por defecto en HSL

            // Ajusta el brillo del color base dependiendo del porcentaje total del flujo
            const flowColor = d3.hsl(baseColor);
            flowColor.l *= (flowData.percentage / 100);

            const pages = Object.values(flowData.pages).map(page => {
                // Ajusta el brillo del color base dependiendo del porcentaje de la página
                const pageColor = d3.hsl(flowColor);
                pageColor.l *= (page.percentage / 100);
                return {
                    name: page.name,
                    loc: page.percentage * (flowData.percentage / 100), // Usa directamente el porcentaje de la página
                    color: pageColor.toString() // Convierte el color HSL a string para usarlo en el gráfico
                };
            });

            return {
                // name: flowData.name,
                name: flowData?.alias,
                color: flowColor.toString(), // Convierte el color HSL a string para usarlo en el gráfico
                children: pages
            };
        });

        return {
            name: 'bot', // Raíz necesaria para el treemap
            children: children
        };

    }


}