import { useCallback, useEffect, useRef, useState } from "react"
import { Identity, LoginService } from "../../../../../core/services/login/login.service";
import { CDropdown, CDropdownDivider, CDropdownItem, CDropdownMenu, CDropdownToggle, CRow } from "@coreui/react-pro";
import { COLORS } from "../../../../../core/constants/colors";
import { DropdownItem } from "../../../../../core/interfaces";
import { DropItemStandard } from "../../dropdown/drop-item-standard/DropItemStandard";
import { Dropdown } from "../../dropdown/Dropdown";
import { RippleButton } from "../../ripple-button/RippleButton";

import { FaRegUser } from "react-icons/fa";
import { LuSettings2 } from "react-icons/lu";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { useIdentityStore, useUIStore } from "../../../../../core/store";
import { BASE_ROUTE, ROUTE_NAME_ID, RouteName } from "../../../../../core/constants/route_name";

const items: DropdownItem[] = [
    {
        component: DropItemStandard,
        name: "Perfil",
        icon: <FaRegUser />,

    },
    {
        component: DropItemStandard,
        name: "Configuración",
        icon: <LuSettings2 />,
        // to: "/settings",
        fn: () => {
            useUIStore.getState().setUI({ asideShow: true });
        }
    }
]

const itemExit = {
    component: DropItemStandard,
    name: "Logout",
    icon: <RiLogoutCircleRLine />,

    to: "/login"
}

export const NavButtonAvatar = (props) => {

    const apiUrl = process.env.REACT_APP_API_URL;
    const { setIdentity, identity } = useIdentityStore(state => state);


    // const [identity, setIdentity] = useState<Identity>(undefined);
    const [showModal, setShowModal] = useState<boolean>(undefined);

    const toggleShowModal = () => {
        setShowModal(prevShowModal => !prevShowModal);
    };

    // useEffect(() => {
    //     (async () => {
    //         let identityAux = await LoginService.getIdentityAsync();
    //         if (!identity) {
    //             setIdentity(identityAux);
    //         } else if (identityAux.username != identity.username || identityAux.role != identity.role || identityAux.avatar != identity.avatar) {
    //             setIdentity(identityAux);
    //         }
    //     })();
    // }, [])

    // const toggleDropdown = (e) => {

    //     setShowModal(showModal => !showModal);
    // }


    return (
        <>
            <div className="relative">
                <RippleButton className="rounded-full w-[45px] h-[45px]" onClick={(e) => toggleShowModal()}>

                    {identity && identity.avatar && (
                        //     <div
                        //         className="no-select"

                        //         style={{
                        //             borderRadius: '50%',
                        //             overflow: 'hidden',
                        //             height: '40px',
                        //             width: '40px',
                        //             display: 'flex',
                        //             justifyContent: 'center',
                        //             alignItems: 'center',
                        //             backgroundImage: `url(${apiUrl}${identity.avatar})`,
                        //             backgroundSize: 'cover',
                        //             backgroundPosition: 'center'
                        //         }} />
                        // )}

                        <div
                            className="no-select"
                            style={{
                                border: '2px solid rgba(0, 0, 0, 0.5)',
                                borderRadius: '50%',
                                overflow: 'hidden',
                                height: '45px',
                                width: '45px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundImage: `url(${apiUrl}${identity.avatar})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center'
                            }} />
                    )}

                    {identity && !identity.avatar && identity.username && (
                        <div
                            onClick={(e) => toggleShowModal()}
                            className="no-select"

                            style={{
                                // backgroundColor: generateAvatarColor(identity.username),
                                // color: generateTextColor(generateAvatarColor(identity.username)),

                                backgroundColor: COLORS.tertiaryColor,
                                borderWidth: 2,
                                borderColor: COLORS.quaternaryColor,
                                borderStyle: "solid",
                                fontSize: 16,
                                borderRadius: '50%',
                                width: '45px',
                                height: '45px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                fontWeight: 'bold',
                            }}
                        >
                            {identity.username.substring(0, 2).toUpperCase()}
                        </div>
                    )}
                </RippleButton>
            </div>



            <Dropdown
                position={{ top: 60, right: 20 }}
                showModal={showModal}
                setShowModal={setShowModal}
            >

                <div className={`rounded-xl bg-white w-auto min-w-[180px] max-w-xs shadow-lg px-2 py-3`}>

                    <div className="flex flex-col items-start px-2">
                        <div className="text-black font-semibold text-md break-words">{identity?.username}</div>
                        <div className="text-slate-400 text-sm break-words">{identity?.email}</div>
                    </div>

                    <div className="border-b border-gray-300 py-1"></div>
                    <div className="py-1"></div>
                    {
                        items.map((item, index) => {
                            if (item.name === "Perfil") {

                                return (
                                    <DropItemStandard key={index}
                                        item={{
                                            ...item,
                                            to: BASE_ROUTE + ROUTE_NAME_ID(identity?.idUser, RouteName.profile)
                                        }} handleVisible={setShowModal} />
                                )
                            } else {
                                return (
                                    <DropItemStandard key={index} item={item} handleVisible={setShowModal} />
                                )
                            }

                        })
                    }
                    <div className="border-b border-gray-300 py-1"></div>
                    <div className="py-1"></div>

                    <DropItemStandard item={itemExit} handleVisible={setShowModal} />

                </div>


            </Dropdown>


        </>
    )
}


