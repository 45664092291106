
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ActionBarOptions } from './interfaces';
import { cilLoopCircular } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { useLocation } from 'react-router-dom';
import DeleteModal from './components/DeleteModal';
import AddPdfModal from './components/AddPdfModal';
import { useActionBarStore } from '../../../core/store';


// interface ActionBarProps { }

const ActionBar: React.FC<any> = () => {

    // const show = useSelector((state: RootState) => state.actionBar.showActionBar);
    // const options: ActionBarOptions = useSelector((state: RootState) => state.actionBar.options);


    const { isShowActionBar, options, loadActionBar, hideActionBar } = useActionBarStore(state => state);


    const [visible, setVisible] = useState(false);


    const location = useLocation();


    const [showDelete, setShowDelete] = useState(false);
    const [showAddPdf, setShowAddPdf] = useState(false);

    useEffect(() => {
        setVisible(isShowActionBar);
    }, [isShowActionBar]);
    /**
     * Muestra o coulta el modal
     * @param value 
     */
    const handleModalDelete = (value: boolean) => {
        setShowDelete(value);
    }

    const handleModalAddPdf = (value: boolean) => {
        setShowAddPdf(value);
    }

    useEffect(() => {
        /**
         * Fuerza a esconder el ActionBar tras cambiar de pantalla
         * y limpia las opciones
         */
        loadActionBar({});
        hideActionBar();
    }, [location]);

    const generateButtons = (options: ActionBarOptions) => {
        const buttons = [];


        // if (options?.addPdf) {
        //     buttons.push(<button key={'addPdf'} onClick={() => {
        //         setShowAddPdf(true)
        //     }} style={buttonStyle}><svg style={{ fill: "black", height: 19 }} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V304H176c-35.3 0-64 28.7-64 64V512H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zM176 352h32c30.9 0 56 25.1 56 56s-25.1 56-56 56H192v32c0 8.8-7.2 16-16 16s-16-7.2-16-16V448 368c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24H192v48h16zm96-80h32c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48H304c-8.8 0-16-7.2-16-16V368c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H320v96h16zm80-112c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16s-7.2 16-16 16H448v32h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H448v48c0 8.8-7.2 16-16 16s-16-7.2-16-16V432 368z" /></svg></button>);
        // }

        if (options?.addPdf) {
            buttons.push(<button key={'addPdf'} onClick={() => {
                setShowAddPdf(true)
            }} style={{ ...buttonStyle, color: "black" }}>IMPRIMIR</button>);
        }


        if (options?.delete) {
            buttons.push(<button key={'delete'} onClick={() => {
                setShowDelete(true)
            }} style={buttonStyle}><svg style={{ fill: "red", height: 19 }} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z" /></svg></button>);
        }

        if (options?.change?.show) {
            buttons.push(<button key={'change'} style={buttonStyle}><CIcon icon={cilLoopCircular} size='lg' /> {options.change.text}</button>);
        }
        return buttons;
    };

    if (!isShowActionBar) {
        return null;
    }







    const actionBarStyle: React.CSSProperties = {
        // visibility: visible ? "visible" : "hidden",
        animation: isShowActionBar ? "fadeIn 0.5s ease forwards" : "fadeOut 0.5s ease forwards",
        // position: "absolute",
        marginTop: 20,
        position: "sticky",
        bottom: "8px",
        left: "20px",
        right: "20px",
        height: "60px",
        borderRadius: "10px",
        backgroundColor: "white",
        boxShadow: "0px -1px 5px rgba(0,0,0,0.2)",
        padding: "10px",
        zIndex: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between" // updated to space-between to place items on the extremes
    };

    const buttonStyle: React.CSSProperties = {
        backgroundColor: "transparent", // transparent background
        border: "none",
        color: "red", // color of the icon
        padding: "10px",
        textAlign: "center",
        textDecoration: "none",
        display: "inline-block",
        fontSize: 16,
        margin: "4px 2px",
        cursor: "pointer"
    };

    const countLabelStyle: React.CSSProperties = {
        padding: "10px",
        fontSize: "20px",
        color: "#333"
    };

    return (

        <div style={actionBarStyle}>
            {
                options && options?.count && (
                    <div style={countLabelStyle}><span>{options?.count}</span>
                        {options.labelCount
                            ? <span style={{ fontSize: 14, marginLeft: "3px" }}> {options.labelCount}</span>
                            : <span style={{ fontSize: 14, marginLeft: "3px" }}> {options.count >= 2 ? "Registros seleccionados" : "Registro seleccionado"}</span>}
                    </div>)
            }
            <div>
                {generateButtons(options)}
            </div>
            {options?.delete && (
                <DeleteModal
                    table={options?.table}
                    items={options?.items}
                    count={options.count}
                    show={showDelete}
                    handleModalDelete={handleModalDelete}
                    title={'Delete Modal from ActionBar'} />
            )}

            {options?.addPdf && (
                <AddPdfModal
                    table={options?.table}
                    items={options?.items}
                    count={options.count}
                    show={showAddPdf}
                    handleModalAddPdf={handleModalAddPdf}
                    title={'Delete Modal from ActionBar'} />
            )}


        </div>

    );
};

export default ActionBar;




