import axios from "axios";
import { User } from "../../models/db/user";
import { DataBasicEncoder } from "../../util/data-basic-encoder";
import { UserAuthData } from "../../models/db";


export interface Identity {
    username: string;
    email: string;
    idUser: number;
    role: string;
    avatar?: string;
}


const apiUrl = process.env.REACT_APP_API_URL;

export class LoginService {



    constructor() { }

    /**
     * Acceso a la app
     */
    static login = (auth: User) => {
        return axios.post(`${apiUrl}/api/web/login`, auth, { withCredentials: true });
    }

    /**
     * Registro a la app
     */
    // static register = ({ username, password, email }: User) => {
    //     const headers = {
    //         'Content-Type': 'application/json',
    //     }
    //     return axios.post(`${environment.urlApi}/register`, { username, password, email }, { headers });
    // }
    static register = (user: User) => {
        const headers = {
            'Content-Type': 'application/json',
        }
        return axios.post(`${apiUrl}/register`, user, { headers });
    }

    /**
     * Cerrar sessión y vuelta al login
     */
    // static logOut = () => {
    //     localStorage.clear();
    // }

    /**
     * Recibir token
     */
    static getTokenAsync = () => {
        return new Promise<any>((res, rej) => {
            let token = localStorage.getItem("token");
            if (!token) rej("Sin permiso ttete");
            res(token);
        });
    }

    /**
     * Recibir identity
     */
    // static getIdentityAsync = (): Promise<any> => {
    //     return new Promise<any>((res, rej) => {
    //         let identity = localStorage.getItem("identity");
    //         if (!identity) rej("Sin permiso i");
    //         res(JSON.parse(identity!));
    //     });
    // }


    static getIdentityAsync = (): Promise<Identity> => {
        return new Promise<any>((res, rej) => {
            // Este se queda tal como está
            // Este se queda tal como está
            // Este se queda tal como está
            let identity = localStorage.getItem("identity");
            // console.log(identity)

            if (!identity) {
                rej("Sin permiso i");
                // return;
            }
            const decode = DataBasicEncoder.decode(identity);


            const referenceIdentity: Partial<Identity> = {
                username: '',
                email: '',
                role: '',
                idUser: 0,
                avatar: ''
            };

            if (!this._conformsToInterface(decode, referenceIdentity)) {
                rej("Objeto inexistente");
                return;
            }
            res(decode);
        });
    }

    static _conformsToInterface<T>(obj: any, reference: T): obj is T {
        for (const key in reference) {
            if (!obj.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }

    static setIdentityAsync = (identity: Identity) => {
        try {
            
            console.log("Este es el identity para el set", identity)
            const encode = DataBasicEncoder.encode(identity)
            console.log("estoy en set, toma el encode", encode)
            localStorage.setItem("identity", encode);
            return { identity, identityEncoded: encode };
        } catch (e) {
            throw new Error("No se ha podido guardar la identidad");
            console.error(e);
        }
    }


}