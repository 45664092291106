

import { CSpinner } from '@coreui/react-pro';
import React, { CSSProperties } from 'react'

interface LoaderProps {
  className?: string;
  style?: CSSProperties;
  styleSpinner?: CSSProperties;
}


export const Loader = ({ style, className, styleSpinner }: LoaderProps) => {
  return (
    // <div style={style} className={`${className} loader-item`} >
    //   <CSpinner color={styleSpinner ? "" : "dark"} style={styleSpinner} />
    // </div >
    <div style={style} className={`${className} loader-item`} >
      <div className="flex justify-center items-center">
        <div style={styleSpinner} className="animate-spin rounded-full h-[32px] w-[32px] border-t-2 border-b-2 border-gray-900"></div>
      </div>
    </div>

  )
}

