import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Login } from '../../../../core/models/login/login';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { useNavigate } from 'react-router-dom';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Lock from '@mui/icons-material/Lock';
import Warning from '@mui/icons-material/Warning';

import logoAPP from "../../../../core/assets/img/logo_cotymse.jpg";
import { COLORS } from '../../../../core/constants/colors';
import { RippleButton } from '../../../components/ui/ripple-button/RippleButton';
import clsx from 'clsx';

const LoginForm = ({ access, loadingButton }) => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      username: "",
      password: ""
    },
    validationSchema: Yup.object({
      username: Yup.string().max(40, "Debe de tener 40 caracteres o menos").required("Requerido"),
      password: Yup.string().max(12, "Debe de tener 12 caracteres o menos").required("Requerido"),
    }),
    onSubmit: values => access(values),
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <form noValidate onSubmit={formik.handleSubmit} style={{ width: '100%', maxWidth: 360 }}>
        <div style={{ textAlign: 'center', marginBottom: 20 }}>
          {/* <img src={logoAPP} alt="Logo Empresa" width={50} height={30} /> */}
        </div>

        <TextField
          className='input-autofill'


          variant="standard"

          margin="normal"
          required
          fullWidth
          id="username"
          label="Nombre de usuario"
          name="username"
          autoComplete="username"
          autoFocus
          {...formik.getFieldProps('username')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          error={formik.touched.username && Boolean(formik.errors.username)}
          helperText={formik.touched.username && formik.errors.username}
        />

        <TextField
          className='input-autofill'

          variant="standard"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Contraseña"
          type="password"
          id="password"
          autoComplete="current-password"
          {...formik.getFieldProps('password')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Lock />
              </InputAdornment>
            ),
          }}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />



        <div className='flex justify-center mt-4'>
          <RippleButton className={clsx(`py-2 px-4 rounded-xl `, {
            'bg-gray-400': !loadingButton,
            'bg-gray-600': loadingButton
          })}
            style={{
              backgroundColor: !loadingButton ? COLORS.principalColor : COLORS.disabledColor,
              // Asumiendo que tienes un secondaryColor

            }} onClick={() => formik.handleSubmit()} >Entrar</RippleButton>
        </div>


        {/* <div
          style={{
            textAlign: 'center',
            color: COLORS.principalColor,
            fontSize: 12,
            marginTop: 25,
            cursor: 'pointer',
            textDecoration: 'none'
          }}
          onClick={() => navigate('/registro')}
        >
          ¿No tienes cuenta? Regístrate aquí
        </div> */}
      </form>
    </div>
  );
};

export default LoginForm;
