import React from 'react';
import { ResponsiveContainer, PieChart, Pie, Tooltip, Legend, Cell } from 'recharts';
import styled from 'styled-components';

interface PieChartProps {
    data: any[];
    dataKey: string;
    nameKey: string;
    colors?: string[];
}

const StyledPieChartContainer = styled.div`
  .recharts-wrapper:focus {
    outline: none;
  }
`;

export const PieChartCustom: React.FC<PieChartProps> = ({ data, dataKey, nameKey, colors = [] }) => {

    console.log("Data entrante en pie chart", data);
    return (
        <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer>
                <PieChart>
                    <Pie
                        data={data}
                        dataKey={dataKey}
                        nameKey={nameKey}
                        cx="50%"
                        cy="50%"
                        outerRadius={90}
                        label
                        isAnimationActive={true}
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color || '#8884d8'} />
                        ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                </PieChart>
            </ResponsiveContainer>
        </div >

    );
};