// services/db/pushNotification/userPushNotificationSetting.service.js

import axios from 'axios';
import { Pagination } from '../../../interfaces';
import { UserPushNotificationSetting } from '../../../models/db/user-push-notification-settings';

const apiUrl = process.env.REACT_APP_API_URL;

export class UserPushNotificationSettingsService {
    static add(body: UserPushNotificationSetting) {
        return axios.post(`${apiUrl}/api/userPushNotificationSetting/add`, body, { withCredentials: true });
    }

    static getAll(pagination?: Pagination) {
        return axios.post(`${apiUrl}/api/userPushNotificationSettings`, { pagination }, { withCredentials: true });
    }
    
    static getByIdUser(idUser: any) {
        return axios.get(`${apiUrl}/api/userPushNotificationSetting/user/${idUser}`, { withCredentials: true });

    }

    static update(id, body: UserPushNotificationSetting) {
        return axios.post(`${apiUrl}/api/userPushNotificationSetting/${id}`, body, { withCredentials: true });
    }


    static delete(idList: string[] | number[], action: boolean) {
        let params = { list: idList, action };
        return axios.post(`${apiUrl}/api/userPushNotificationSetting/delete`, params, { withCredentials: true });
    }
}
