

import { useState } from 'react';

import { Pagination } from '../../../interfaces';
import { UserPushNotificationSettingsService } from '../../../services/db/push-notification/userPushNotificationSettings.service';
import { UserPushNotificationSetting } from '../../../models/db/user-push-notification-settings';


export const useUserPushNotificationSettings = () => {
    const [error, setError] = useState(null);

    const addUserPushNotificationSettings = async (body: UserPushNotificationSetting) => {
        try {
            const response = await UserPushNotificationSettingsService.add(body);
            return response.data;
        } catch (e) {
            setError(e);
            return null;
        }
    };

    const getUserPushNotificationSettings = async (pagination: Pagination = { page: 1, itemsPerPage: 3 }) => {
        try {
            const response = await UserPushNotificationSettingsService.getAll(pagination);
            return response.data;
        } catch (e) {
            setError(e);
            return null;
        }
    };

    const getUserPushNotificationSettingsByIdUser = async (idUser: any) => {
        try {
            const response = await UserPushNotificationSettingsService.getByIdUser(idUser);
            return response.data;
        } catch (e) {
            setError(e);
            return null;
        }
    };

    const updateUserPushNotificationSettings = async (id, body: UserPushNotificationSetting) => {
        try {
            const response = await UserPushNotificationSettingsService.update(id, body);
            return response.data;
        } catch (e) {
            setError(e);
            return null;
        }
    };

    const deleteUserPushNotificationSettings = async (idList: string[] | number[], action: boolean) => {
        try {
            const response = await UserPushNotificationSettingsService.delete(idList, action);
            return response.data;
        } catch (e) {
            setError(e);
            return null;
        }
    };

    return {
        add: addUserPushNotificationSettings,
        update: updateUserPushNotificationSettings,
        delete: deleteUserPushNotificationSettings,
        get: getUserPushNotificationSettings,
        getByIdUser: getUserPushNotificationSettingsByIdUser,


        addUserPushNotificationSettings,
        getUserPushNotificationSettings,
        updateUserPushNotificationSettings,
        deleteUserPushNotificationSettings,
        getUserPushNotificationSettingsByIdUser,
        error,
    };
};
