import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CForm, CFormLabel, CFormInput, CFormText } from '@coreui/react-pro';
import { useWebAllowed } from '../../../../core/hooks/db/web-allowed/useWebAllowed';
import { RippleButton } from '../../../components/ui/ripple-button/RippleButton';
import clsx from 'clsx';
import { COLORS } from '../../../../core/constants/colors';
import { getContrastColor } from '../../../../core/util/colors/adjust-color';
import { FormControl, TextField, Typography } from '@mui/material';


// Esquema de validación con Yup
const validationSchema = Yup.object({
    url: Yup.string()
        .url("Debe ser una URL válida")
        .required("El campo URL es obligatorio"),
    description: Yup.string().max(100, "La descripción debe tener menos de 100 caracteres"),
});


interface WebAllowedFormProps {
    resetList: () => void;
}

export const WebAllowedForm = ({ resetList }: WebAllowedFormProps) => {

    const { add } = useWebAllowed();

    const formik = useFormik({
        initialValues: {
            url: '',
            description: '',
        },
        validationSchema,
        onSubmit: async values => {
            console.log(values);
            try {
                await add({
                    ...values,

                });
            } catch (e) {
                console.log(e);
            } finally {
                resetList();
            }
        },
    });

    return (
        <form>
            <FormControl fullWidth margin="normal">
                <TextField
                    id="url"
                    name="url"
                    label="URL"
                    variant="standard"
                    placeholder="https://ejemplo-de-url.com"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.url}
                    error={formik.touched.url && Boolean(formik.errors.url)}
                    helperText={formik.touched.url && formik.errors.url}
                />
            </FormControl>

            <FormControl fullWidth margin="normal">
                <TextField
                    id="description"
                    name="description"
                    label="Descripción opcional"
                    variant="standard"
                    placeholder="Descripción opcional"
                    multiline
                    rows={1}
                    inputProps={{ maxLength: 100 }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.description}
                    error={formik.touched.description && Boolean(formik.errors.description)}
                    helperText={formik.touched.description && formik.errors.description}
                />
                <Typography variant="caption">
                    {formik.values.description.length}/100
                </Typography>
            </FormControl>


            <div className='flex justify-center pt-4'>
                <RippleButton
                    disabled={!formik.isValid}
                    className={clsx(`py-2 px-3 rounded-xl`)}
                    style={{ backgroundColor: formik.isValid ? COLORS.principalColor : COLORS.disabledColor }}
                    onClick={() => formik.handleSubmit()}>
                    <span className={clsx(`text-white`)} >
                        Enviar
                    </span>
                </RippleButton>
            </div>
        </form>
    );
};
