import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Pagination } from '../../../interfaces/pagination';
import { RoleService } from '../../../services/db/role/role.service';
import { ReasonForDeactivationProps } from '../../check-table/useCheckTable';
import { Role } from '../../../models/db/role';





export const useRole = () => {

    const getRoleAutocomplete = () => {
        return new Promise<any>((res, rej) => {
            RoleService.getAutocomplete()
                .then(({ data }) => {
                    // console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }




    return {
      
        getRoleAutocomplete,
        
        autocomplete: getRoleAutocomplete,
       
    }
}