

import { Dropdown } from '../../ui/dropdown/Dropdown';
import { DropdownItemCheck } from '../../../../core/interfaces/dropdown/dropdown-item-check';
import { DropItemCheck } from '../../ui/dropdown/drop-item-check/DropITemCheck';
import { IoIosArrowDown } from "react-icons/io";
import { useEffect, useState } from 'react';
import { RippleButton } from '../../ui/ripple-button/RippleButton';
import { usePaginationStore } from '../../../../core/store';




// const options: DropdownItemCheck[] = [
//     {
//         title: "Administrador",
//         value: "ROLE_ADMIN",
//         nameColumn: NAME_COLUMN,
//         nameColumnES: NAME_COLUMN_ES,
//         checked: false,
//         component: DropItemCheck
//     },
//     {
//         title: "Usuario",
//         value: "ROLE_USER",
//         nameColumn: NAME_COLUMN,
//         nameColumnES: NAME_COLUMN_ES,
//         checked: false,
//         component: DropItemCheck
//     },
// ]

interface RoleCheckProps {
    multiSelect?: boolean;
    nameTable?: string;
    nameColumn: string;
    label: string;
}

export const RoleCheck = ({ multiSelect = true, nameTable = '', nameColumn, label }: RoleCheckProps) => {


    const [showModal, setShowModal] = useState<boolean>(undefined);
    const [dropdownItems, setDropdownItems] = useState<DropdownItemCheck[]>([
        {
            checked: false,
            component: DropItemCheck,

            title: "Administrador",
            value: "ROLE_ADMIN",
            nameTable: nameTable,
            nameColumn: nameColumn,
            label: label

        },
        {
            checked: false,
            component: DropItemCheck,

            title: "Usuario",
            value: "ROLE_USER",
            nameTable: nameTable,
            nameColumn: nameColumn,
            label: label

        },
    ]);

    const {
        filtersRaw,
        addFilterRaw,
        removeFilterRaw,
        addFilterRawExclusive } = usePaginationStore((state) => state);

    const toggleShowModal = () => {
        setShowModal(prevShowModal => !prevShowModal);
    };


    const handleSelection = (itemCheck: { title: string, value: any }, valueCheck) => {


        if (valueCheck) {

            if (multiSelect) {
                addFilterRaw({
                    nameTable,
                    nameColumn,
                    label,
                    title: itemCheck.title,
                    value: itemCheck.value
                });
            } else {
                addFilterRawExclusive({
                    nameTable,
                    nameColumn,
                    label,
                    title: itemCheck.title,
                    value: itemCheck.value
                });
            }
        } else {
            removeFilterRaw(nameTable, nameColumn, itemCheck.title);
        }


        // let itemFounded = items.find(item => item.title === title);
        // itemFounded.checked = value;
        // También actualiza el estado local para reflejar el cambio inmediatamente
        setDropdownItems(prevItems => prevItems.map(item => {
            if (itemCheck.title === item.title) {
                return { ...item, checked: valueCheck };
            }
            return item;
        }));


    }


    useEffect(() => {

        // Parte para desmarcar un filtro si fue eliminado de filtersRaw
        const itemCheckFounded = dropdownItems.find(itemDropdown => itemDropdown.checked
            && !filtersRaw.some(item => itemDropdown.title === item.title && nameTable === item.nameTable && nameColumn === item.nameColumn));

        if (itemCheckFounded) {
            setDropdownItems(prevItems => prevItems.map(item => {
                if (itemCheckFounded.title === item.title) {
                    return { ...item, checked: false };
                }
                return item;
            }));
        }

        // Parte para manejar la lógica de seleccionar solo el último filtro en modo no multiSelect
        if (!multiSelect) {
            // Obtén los filtros de la categoría 'Role' del store de Zustand.
            const roleFilters = filtersRaw.filter(item => item.nameTable === nameTable && item.nameColumn === nameColumn);


            // Si hay más de un filtro seleccionado para 'Role',
            // debemos asegurarnos de mantener solo el último seleccionado.
            if (roleFilters.length > 1) {
                // Encuentra el último filtro seleccionado
                // const lastSelectedFilter = roleFilters[roleFilters.length - 1];
                const lastSelectedFilter = roleFilters.pop()

                // Actualiza el estado local para reflejar que solo el último filtro está seleccionado.
                setDropdownItems(prevItems => prevItems.map(item => ({
                    ...item,
                    checked: item.value === lastSelectedFilter.value,
                })));


                roleFilters.map(r => removeFilterRaw(nameTable, nameColumn, r.title))

            } else if (roleFilters.length === 1) {
                // Si solo hay un filtro seleccionado, asegúrate de que esté reflejado en el estado local.
                setDropdownItems(prevItems => prevItems.map(item => ({
                    ...item,
                    checked: item.value === roleFilters[0].value,
                })));
            }
        }

    }, [filtersRaw]);



    return (
        <div className='relative'>
            <RippleButton className="rounded-lg" rippleColor="rgba(135, 135, 135, 0.4)" onClick={(e) => toggleShowModal()}>
                <div
                    className='px-3 py-1.5 rounded-lg inline-block  hover:bg-gray-100 transition duration-300 ease-in-out'
                >
                    <div className='flex justify-center items-center'>
                        <span className='text-black no-select' style={{ fontWeight: 600, fontSize: 15 }}>
                            {label}
                        </span>
                        <span className='ml-2 text-black no-select' >
                            <IoIosArrowDown size={18} />
                        </span>
                    </div>
                </div>
            </RippleButton>
            <Dropdown
                position={{ top: 40, left: 0 }}
                showModal={showModal}
                setShowModal={setShowModal} >
                <div className={`rounded-xl bg-white w-auto min-w-[180px] max-w-xs shadow-lg px-2 py-3`}>

                    {
                        dropdownItems.map((item, index) => {
                            return (
                                <DropItemCheck
                                    key={index}

                                    multiSelect={multiSelect}
                                    item={item}
                                    handleSelection={handleSelection}
                                    handleVisible={setShowModal}
                                />
                            )
                        })
                    }

                </div>
            </Dropdown>
        </div>


    )
}
