import axios from "axios";
import { User } from "../../../models/db/user";
import { Pagination } from "../../../interfaces/pagination";
import { ReasonForDeactivationProps } from "../../../hooks/check-table/useCheckTable";


const apiUrl = process.env.REACT_APP_API_URL;

export class UserService {

  constructor() { }

  static get(id: any) {
    return axios.get(`${apiUrl}/api/user/${id}`, { withCredentials: true });
  }

  static getAll(pagination?: Pagination) {
    return axios.post(`${apiUrl}/api/user`, { pagination }, { withCredentials: true });
  }

  static getAllId(reasonForDesactivation: ReasonForDeactivationProps[] = []) {
    let object = { reasonForDesactivation };
    return axios.post(`${apiUrl}/api/user/all-id`, object, { withCredentials: true });
  }


  static add(body: User) {
    // let json = JSON.stringify(body);
    // let params = `json=${json}`;
    return axios.post(`${apiUrl}/api/user/add`, body, { withCredentials: true });
  }

  /**
   * Añade un usuario sin necesidad del 
   * @param body 
   * @returns 
   */
  // addPublic(body: User) {
  //   let json = JSON.stringify(body);
  //   let params = `json=${json}`;
  //   let headers = {
  //     'Content-Type': 'application/x-www-form-urlencoded',
  //   });
  //   return axios.post(`${apiUrl}/public/user`, params, { withCredentials: true });
  // }

  static update(body: User) {
    return axios.post(`${apiUrl}/api/user/update`, body, { withCredentials: true });
  }

  static delete(idList: string[] | number[], action: boolean) {

    let params = { idList, action };
    return axios.post(`${apiUrl}/api/user/delete`, params, { withCredentials: true });
  }

  static checkAdmin() {
    return axios.get(`${apiUrl}/api/user-admin`, { withCredentials: true });
  }

  static checkPassword(id, password) {
    let params = { id, password };
    return axios.post(`${apiUrl}/api/user/check-password`, params, { withCredentials: true });
  }

  static checkUsername(id, username) {
    let params = { id, username };
    return axios.post(`${apiUrl}/api/user/check-username`, params, { withCredentials: true });
  }

  static checkEmail(id, email) {
    let params = { id, email };
    return axios.post(`${apiUrl}/api/user/check-email`, params, { withCredentials: true });
  }

  // Public = Sin necesidad de 
  // Public = Sin necesidad de 
  // Public = Sin necesidad de 
  // Public = Sin necesidad de 
  // Public = Sin necesidad de 

  static checkPasswordPublic(id, password) {

    let params = { id, password };
    return axios.post(`${apiUrl}/api/user/public-check-password`, params);
  }

  static checkUsernamePublic(id, username) {

    let params = { id, username };
    return axios.post(`${apiUrl}/api/user/public-check-username`, params);
  }

  static checkEmailPublic(id, email) {
    let params = { id, email };
    return axios.post(`${apiUrl}/api/user/public-check-email`, params);
  }


}
