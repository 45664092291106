import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import { IconButton } from '@mui/material';


import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';


import 'react-quill/dist/quill.snow.css';



const Link = Quill.import('formats/link');
class MyLink extends Link {
    static create(value) {
        let node = super.create(value);
        // Añade target="_blank" a todos los enlaces
        node.setAttribute('target', '_blank');
        node.setAttribute('class', 'ext-link-bot');

        // Añade rel="noopener noreferrer" por seguridad
        node.setAttribute('rel', 'noopener noreferrer');
        return node;
    }
}

// 2. Registra el formato personalizado
Quill.register(MyLink, true);


export const MyRichTextEditor = ({ disabled, value, onChange }) => {

    const quillRef = useRef(null);
    const [emojiPickerVisible, setEmojiPickerVisible] = useState(false);


    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => setEmojiPickerVisible(false));

    function useOutsideAlerter(ref, onClose) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    onClose();
                }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref, onClose]);
    }

    useEffect(() => {
        const toolbar = document.querySelector('.ql-toolbar');
        if (!toolbar) {
            console.error("La barra de herramientas no se encuentra.");
            return;
        }

        let button: any = toolbar.querySelector('.ql-emoji2');
        if (!button) {
            button = document.createElement('span');
            button.type = 'button';
            button.className = 'ql-emoji2';
            button.innerHTML = '<button class="flex items-center text-sm align-middle mt-[9px] mr-6"><span class="flex font-semibold">Emojis</span></button>';
            button.style.fontSize = '24px'; // Asegúrate de que el botón sea visible
            button.style.cursor = 'pointer';
            button.style.background = 'none';
            button.style.border = 'none';

        } else { }



        // Define la función de manejo del clic
        const handleClick = () => {
            if (disabled) return;
            setEmojiPickerVisible(prev => !prev);
        };

        // Añade el evento de clic al botón
        button.addEventListener('click', handleClick);

        toolbar.appendChild(button);

        return () => {
            button.removeEventListener('click', handleClick);
        };
    }, [disabled, emojiPickerVisible]);



    const insertEmoji = (emojiObject) => {
        const editor = quillRef.current.getEditor();
        const range = editor.getSelection(true);
        if (range && emojiObject.native) {
            // Usamos el string del emoji
            editor.insertText(range.index, emojiObject.native);
            setEmojiPickerVisible(false);

        }
    };


    // Función para manejar la subida de imágenes si es necesario
    const handleImageUpload = (file) => {
        // Aquí deberías implementar la lógica para subir la imagen
        // a tu servidor o a un servicio de almacenamiento como S3 y luego
        // devolver la URL de la imagen subida.
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('image', file);
            const apiUrl = process.env.REACT_APP_API_URL;
            fetch(apiUrl, {
                method: 'POST',
                body: formData,
            })
                .then(response => response.json())
                .then(result => {
                    resolve(result.url); // Debes devolver la URL de la imagen subida
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    };

    // Configuración de los módulos para react-quill
    const modules = {
        toolbar: {
            container: [
                [{ 'header': [1, 2, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                ['link'],
                // ['link', 'image'],

                ['clean']
            ],
            // Aquí añades la función para la subida de imágenes
            handlers: {
                // 'emoji': function () { }
                // image: imageHandler
            }
        },

    };

    const insertImage = () => {
        const editor = quillRef.current.getEditor();
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
            const file = input.files[0];
            if (file) {
                const url = await handleImageUpload(file);
                const range = editor.getSelection(true);
                if (range) {
                    // Insertar la imagen en la posición actual del cursor
                    editor.insertEmbed(range.index, 'image', url);
                }
            }
        };
    };

    // Asegúrate de que el manejador de imágenes se inicialice correctamente
    useEffect(() => {
        if (quillRef.current) {
            const quill = quillRef.current.getEditor();
            quill.getModule('toolbar').addHandler('image', insertImage);
        }
    }, []);


    return (
        <div style={{ position: 'relative' }}>
            {/* <pre>{JSON.stringify(disabled, null, 2)}</pre> */}
            <ReactQuill
                readOnly={disabled}
                ref={quillRef}
                theme="snow"
                value={value}
                onChange={onChange}
                modules={modules}
                style={{ minHeight: '150px', fontSize: '1rem' }}
            />
            {emojiPickerVisible && (
                <div ref={wrapperRef} style={{ position: 'absolute', top: '50px', right: '0', zIndex: 2 }}>
                    <Picker
                        theme='light'
                        maxFrequentRows={0}
                        locale='es'
                        emojiSize={20}
                        data={data}
                        onEmojiSelect={(emojiObject) => insertEmoji(emojiObject)}
                    />
                </div>
            )}
        </div>


    );
}