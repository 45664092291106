// snackbarStore.ts
import { create } from 'zustand';

// Definimos el tipo para las posiciones permitidas de los Snackbars, como lo hicimos anteriormente
type SnackbarPosition = 'top-right' | 'top-center' | 'top-left' | 'bottom-right' | 'bottom-center' | 'bottom-left';

// Definimos la interfaz para la configuración de un mensaje Snackbar
interface SnackbarConfig {
    id: string;
    title: string;
    duration?: number;
    closeable?: boolean;
    color?: 'default' | 'error' | 'warning' | 'info' | 'success'; // Alineado con los VariantType de notistack/MUI
    position?: SnackbarPosition;
    icon?: React.ElementType; // Opcional, en caso de que quieras usar un ícono específico
}

// La interfaz del estado de la aplicación para el manejo de Snackbars
interface SnackbarAppState {
    snackbar: SnackbarConfig | undefined;
    maxSnackbars: number;
    addSnackbar: (newSnackbar: Omit<SnackbarConfig, 'id'>) => void;
    setMaxSnackbars: (maxSnackbars: number) => void;
}

// Creación del store con Zustand
export const useSnackbarStore = create<SnackbarAppState>((set) => ({
    snackbar: undefined,
    maxSnackbars: 3,
    addSnackbar: (newSnackbar) =>
        set(() => ({
            snackbar: { ...newSnackbar, id: Math.random().toString(36).substr(2, 9) }, // Generamos un ID único para cada Snackbar
        })),
    setMaxSnackbars: (maxSnackbars) => set(() => ({ maxSnackbars })),
}));
