import { update } from 'react-spring';
import { Pagination } from '../../../interfaces/pagination';
import { BannedUser } from '../../../models/db';
import { BannedUserService } from '../../../services/db/banned-user/bannedUser.service';


export const useBannedUser = () => {

    const bannedUserService = new BannedUserService();

    const addBannerUser = (body: BannedUser) => {
        return new Promise<any>((res, rej) => {
            bannedUserService.add(body)
                .then((item) => {
                    if (item && item.data && item.data?.ok) {
                        res(item.data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })
        })
    }

    const updateBannerUser = (body: BannedUser) => {
        return new Promise<any>((res, rej) => {
            bannedUserService.update(body.id, body)
                .then((item) => {
                    if (item && item.data && item.data?.ok) {
                        res(item.data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })
        })
    }

    const getBannedUser = (pagination: Pagination = { page: 1, itemsPerPage: 5 }) => {
        return new Promise<any>((res, rej) => {
            bannedUserService.getAll(pagination)
                .then((item) => {

                    if (item && item.data && item.data?.ok) {
                        res(item.data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })
        })
    }


    const getBannedUserById = (id: any) => {
        return new Promise<any>((res, rej) => {
            bannedUserService.getById(id)
                .then((item) => {
                    if (item && item.data && item.data?.ok) {
                        res(item.data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })
        })
    }

    const deleteBannedUser = (idList: string[] | number[], action: boolean) => {
        return new Promise<any>((res, rej) => {
            bannedUserService.delete(idList, action)
                .then((item) => {
                    if (item && item.data && item.data?.ok) {
                        res(item.data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })
        })
    }









    return {
        get: getBannedUser,
        getById: getBannedUserById,
        add: addBannerUser,
        delete: deleteBannedUser,
        update: updateBannerUser,


        getBannedUser,
        getBannedUserById,
        addBannerUser,
        deleteBannedUser,
        updateBannerUser
    }
}