import { create } from 'zustand';
import { ToastPosition } from 'react-toastify';

interface ToastConfig {
    id: any;
    message: string;
    backgroundColor?: string;
    progressColor?: string;
    textColor?: string;
    position?: ToastPosition;
    autoClose?: number;
}



interface ToastAppState {
  message: ToastConfig;
  addMessage: (newMessage: ToastConfig) => void;
}

export const useToastAppStore = create<ToastAppState>((set) => ({
  message: undefined,
  addMessage: (newMessage) =>
    set((state) => ({
      message: { ...newMessage, id: Math.random().toString(36).substr(2, 9) },
    })),

}));
