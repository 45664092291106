import React from 'react';
import { CardPayload, CardBot } from './card-bot';
import { QuickReplyPayload, QuickReplyBot } from './quickreply-bot';
import { TextPayload, TextBot } from './text-bot';
import moment from 'moment';



interface BotPayload {
    item: TextPayload | CardPayload | QuickReplyPayload;
    user: {
        userType: "bot" | "human";
    };
    notification: {
        notificationType: any,
        hour: string
    };
}

const getBannerColor = (rrss: string) => {
    rrss = rrss.substring(0, 4) === "http" ? "web" : rrss;
    switch (rrss) {
        case "facebook":
            return "#3b6ad1";
        case "instagram":
            return "#de2a7b";
        case "web":
            return "#d91821";
        case "whatsapp":
            return "#22b322";
        case "telegram":
            return "#5f82d9";
        default:
            return "#ccc";
    }
}


// Componente BotMessage
const BotMessage = ({ item, user, notification, rrss }: BotPayload & { rrss: string }) => {
    const date = new Date(notification.hour);
    const timeStyle = { fontSize: "0.8rem", color: "#999", textAlign: user.userType === 'bot' ? 'left' : 'right' };
    const bannerColor = getBannerColor(rrss);

    const styles = {
        messageContainer: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: user.userType === 'bot' ? 'flex-start' : 'flex-end',
        },
        bubbleContainer: {
            maxWidth: '60%',
            backgroundColor: user.userType === 'bot' ? '#f0f0f0' : "#fff",
            // backgroundColor: user.userType === 'bot' ? '#E1E0E0' : "#f0f0f0",
            borderRadius: "15px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
            padding: "10px",
            marginBottom: "10px",
            position: 'relative', // Se añade para que el banner pueda posicionarse de manera absoluta dentro de este contenedor
        },
        banner: {
            backgroundColor: bannerColor,
            height: "10px",
            width: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            borderTopLeftRadius: '15px',
            borderTopRightRadius: '15px',
        },
    };

    let itemAux: any = item;

    if (item.message === 'text' && itemAux?.text && itemAux?.text[0] === "000RBCStart") {
        return <></>;
    }

    return (
        <>
            {(item.message === 'text' || item.message === 'card' || item.message === 'quickreply') && (
                <div style={styles.messageContainer as any}>
                    <div style={styles.bubbleContainer as any}>
                        <div style={styles.banner as any}></div>
                        {item.message === 'text' && <TextBot {...(item as TextPayload)} userType={user.userType} bannerColor={bannerColor} />}
                        {item.message === 'card' && <CardBot {...(item as CardPayload)} userType={user.userType} bannerColor={bannerColor} />}
                        {item.message === 'quickreply' && <QuickReplyBot {...(item as QuickReplyPayload)} userType={user.userType} bannerColor={bannerColor} />}
                        <div style={timeStyle as any}>{moment(date).format("HH:mm")}</div>
                    </div>
                </div>
            )}
        </>
    );
};


export default BotMessage;
export { BotPayload };
