import CIcon from '@coreui/icons-react'
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderNav,
  CHeaderToggler
} from '@coreui/react-pro'
import { useLocation } from 'react-router-dom'
import { NavButtonAvatar } from '../..'
import { useUIStore } from '../../../../core/store'
import { NavButtonNotification } from '../icons-nav/nav-button-notification/NavButtonNotification'
import { cilUser, cilBook, cilBan, cilLink, cilBell, cilSettings, cilHome, cilMenu } from '@coreui/icons'; // Importa los iconos necesarios

const getRouteObject = (path: string) => {
  switch (true) {
    case /^\/dashboard\/home$/.test(path):
      return {
        component: () => <div>Inicio</div>,
        name: 'Inicio',
        icon: <CIcon icon={cilHome} />,
        to: path,
      };
    case /^\/dashboard\/usuarios$/.test(path):
      return {
        component: () => <div>Usuarios</div>,
        name: 'Usuarios',
        icon: <CIcon icon={cilUser} />,
        to: path,
      };
    case /^\/dashboard\/chats$/.test(path):
      return {
        component: () => <div>Chats</div>,
        name: 'Chats',
        icon: <CIcon icon={cilBook} />,
        to: path,
      };
    case /^\/dashboard\/usuarios-baneados$/.test(path):
      return {
        component: () => <div>Baneos</div>,
        name: 'Baneos',
        icon: <CIcon icon={cilBan} />,
        to: path,
      };
    case /^\/dashboard\/urls-permitidas$/.test(path):
      return {
        component: () => <div>URLs Permitidas</div>,
        name: 'URLs Permitidas',
        icon: <CIcon icon={cilLink} />,
        to: path,
      };
    case /^\/dashboard\/notificaciones$/.test(path):
      return {
        component: () => <div>Notificaciones</div>,
        name: 'Notificaciones',
        icon: <CIcon icon={cilBell} />,
        to: path,
      };
    case /^\/dashboard\/configuracion$/.test(path):
      return {
        component: () => <div>Configuración</div>,
        name: 'Configuración',
        icon: <CIcon icon={cilSettings} />,
        to: path,
      };
    default:
      console.log("Ruta no definida en el sistema: " + path);
      return null;
  }
};


const AppHeader = (): JSX.Element => {

  const location = useLocation();

  const { sidebarShow, asideShow, setUI } = useUIStore(state => state)


  // useEffect(() => {
  // }, [location])


  const routeObject = getRouteObject(location.pathname);

  return (
    // <CHeader position="sticky" className="mb-4">
    <CHeader position="sticky" className="cHeaderWithColor">

      <CContainer fluid>
        <CHeaderToggler
          style={{ color: "black" }}
          className="ps-1"

          onClick={() => {
            setUI({ sidebarShow: !sidebarShow })
            // dispatch({ type: 'set', sidebarShow: !sidebarShow })
          }}
        >
          <CIcon icon={cilMenu} size="lg" style={{ color: "black" }} />
        </CHeaderToggler>

        <CHeaderBrand className="mx-auto d-md-none" >
          {/* Aquí podría ir un logo también */}
          {/* <CIcon icon={logo} height={48} /> */}
        </CHeaderBrand>
        <CHeaderNav className="d-none d-md-flex me-auto" >
          {/* <CNavItem >
            <CNavLink to="/dashboard" component={NavLink} style={{ color: "white" }}>
              Dashboard
            </CNavLink>
          </CNavItem> */}

          {/* {routeObject && (
            <>
              {routeObject.name &&
                <div style={{ fontFamily: "Roboto", fontSize: 18, fontWeight: 500, color: "black" }}>
                  {routeObject.name}
                </div>}
            </>
          )} */}

        </CHeaderNav>
        <div className="px-md-0 me-md-3">
          <NavButtonNotification />
        </div>
        <div className="px-md-0 me-md-3">
          <NavButtonAvatar />
          {/* <UserNav /> */}
        </div>
        {/* <CHeaderToggler
          className="px-md-0 me-md-3"
          onClick={() => {
            setUI({ asideShow: !asideShow })
          }}
        >
          <CIcon icon={cilApplicationsSettings} size="lg" />
        </CHeaderToggler> */}
      </CContainer>
      {/* <CHeaderDivider />
      <CContainer fluid>
        <AppBreadcrumb />
      </CContainer> */}
    </CHeader>
  )
}

export default AppHeader
