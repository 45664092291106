
// import './button-ripple.scss'; // Asegúrate de crear este archivo CSS

// import React, { CSSProperties, useState } from 'react';

// let rippleId = 0;

// interface RippleButtonProps {
//     children: React.ReactNode;
//     className?: string;
//     style?: CSSProperties;
//     rippleColor?: string;
//     onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
// }

// export const RippleButton = ({ children, className = '', style, rippleColor, onClick }: RippleButtonProps) => {
//     const [ripples, setRipples] = useState([]);

//     const createRipple = (event) => {
//         const button = event.currentTarget;
//         const rect = button.getBoundingClientRect();
//         const size = Math.max(button.clientWidth, button.clientHeight);
//         const x = event.clientX - rect.left - size / 2;
//         const y = event.clientY - rect.top - size / 2;
//         const newRipple = {
//             id: rippleId++,
//             style: {
//                 top: `${y}px`,
//                 left: `${x}px`,
//                 width: `${size}px`,
//                 height: `${size}px`,
//                 backgroundColor: rippleColor,

//             },
//         };

//         setRipples((prevRipples) => [...prevRipples, newRipple]);

//         // Elimina la onda después de que la animación haya terminado
//         setTimeout(() => {
//             setRipples((prevRipples) => prevRipples.filter((r) => r.id !== newRipple.id));
//         }, 600); // Ajusta este tiempo según la duración de tu animación

//         if (onClick) {
//             onClick(event);
//         }
//     };

//     return (
//         <div className={`ripple-button ${className}`} onClick={createRipple} style={style}>
//             {ripples.map((ripple) => (
//                 <span key={ripple.id} className="ripple" style={ripple.style} />
//             ))}
//              <div className="flex items-center justify-center h-full">
//                 {children} 
//             </div>
//         </div>
//     );
// };

import React, { CSSProperties, useState, forwardRef } from 'react';
import './button-ripple.scss'; // Asegúrate de que este archivo CSS esté creado y correctamente importado

let rippleId = 0;

interface RippleButtonProps {
    children: React.ReactNode;
    className?: string;
    style?: CSSProperties;
    rippleColor?: string;

    disabled?: boolean;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const RippleButton = forwardRef<HTMLDivElement, RippleButtonProps>(
    ({ children, disabled = false, className = '', style, rippleColor, onClick }, ref) => {
        const [ripples, setRipples] = useState([]);

        const createRipple = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

            if (disabled) return;

            const button = event.currentTarget;
            const rect = button.getBoundingClientRect();
            const size = Math.max(button.clientWidth, button.clientHeight);
            const x = event.clientX - rect.left - size / 2;
            const y = event.clientY - rect.top - size / 2;
            const newRipple = {
                id: rippleId++,
                style: {
                    top: `${y}px`,
                    left: `${x}px`,
                    width: `${size}px`,
                    height: `${size}px`,
                    backgroundColor: rippleColor,
                },
            };

            setRipples((prevRipples) => [...prevRipples, newRipple]);

            setTimeout(() => {
                setRipples((prevRipples) => prevRipples.filter((r) => r.id !== newRipple.id));
            }, 600);

            if (onClick) {
                onClick(event);
            }


        };


        return (
            // 
            // <div ref={ref} className={`ripple-button ${className}`} onMouseDown={createRipple} style={style}>
            <div ref={ref} className={`ripple-button ${className}`} onMouseDown={createRipple} style={style}>

                {ripples.map((ripple) => (
                    <span key={ripple.id} className="ripple" style={ripple.style} />
                ))}
                <div className="flex items-center justify-center h-full">
                    {children}
                </div>
            </div>
        );
    }
);

RippleButton.displayName = 'RippleButton';
