import axios from 'axios';
import { CRUDService } from '../crud-service.service';
import { ReasonForDeactivationProps } from '../../../hooks';
import { NotificationRBC } from '../../../models/db';


const apiUrl = process.env.REACT_APP_API_URL;
const nameRoute = 'notification';

export class NotificationService extends CRUDService<NotificationRBC> {

    constructor() {
        super(apiUrl, nameRoute);
    }

    getAllId(reasonForDesactivation: ReasonForDeactivationProps[]) {
        return axios.post(`${apiUrl}/api/bot/${nameRoute}-all-id`, { reasonForDesactivation }, { withCredentials: true });
    }

    markAllAsRevised() {
        return axios.get(`${apiUrl}/api/bot/${nameRoute}-update-multiple-revised`, { withCredentials: true });
    }

}
