import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../../../core/contexts/AuthContext'
import { Login } from '../../../../core/models/login/login'
import { LoginService } from '../../../../core/services/login/login.service'
import LoginForm from './LoginForm'
import { useIdentityStore, useSnackbarStore, useUIStore } from '../../../../core/store'




const image = false


const LoginPage = () => {


  const navigate = useNavigate();
  const { checkIdentity } = useContext(AuthContext);

  const { setIdentity } = useIdentityStore(state => state);

  const [loadingButton, setLoadingButton] = useState(false);
  const [error, setError] = useState(false);

  const { setUI } = useUIStore(state => state)


  const { addSnackbar, setMaxSnackbars } = useSnackbarStore();




  useEffect(() => {
    localStorage.removeItem("identity");
    localStorage.removeItem("token");

    setUI({ asideShow: false })

    // localStorage.clear();
    // handleLogin();
  }, [])

  const handleLogin = async () => {
    try {
      if (!localStorage.getItem('reloaded')) {
        localStorage.setItem('reloaded', 'true');
        window.location.reload();
      } else {
        localStorage.clear();
      }
    } catch (error) {
      console.error(error)
    }
  };


  const access = (values: Login) => {

    console.log(values);

    return new Promise<any>((res, rej) => {


      setError(false);
      console.log("que es values", values)
      // handleLoading(true);
      setLoadingButton(true);
      LoginService.login(
        {
          username: values.username,
          password: values.password,
        }
      )
        .then(async ({ data }) => {
          console.log(data);


          if (data.ok) {
            if (data.item?.user) {
              console.log("que llega aqui?222")
              console.log(data.item?.user)
              setIdentity(data.item.user);
              // const encode = DataBasicEncoder.encode(data.item.user);
              // localStorage.setItem("identity", encode)

              await checkIdentity();
              // handleLoading(false);

              setMaxSnackbars(1);

              addSnackbar({

                duration: 1500,
                title: "Inicio de sesión exitoso",
                position: "bottom-center",
                color: "success",
              })

              setMaxSnackbars(3);

              setLoadingButton(false);
              navigate(`/dashboard`);
            } else {
              // handleLoading(false);
              setLoadingButton(false);

              throw new Error("Error desde response.ok", data);
            }
          } else {
            // handleLoading(false);
            setLoadingButton(false);

          }
        })
        .catch(e => {
          // handleLoading(false);
          setLoadingButton(false);
          setMaxSnackbars(2);

          addSnackbar({

            duration: 1000,
            title: "Error al intentar iniciar sesión",
            position: "bottom-center",
            color: "error",
          })
          


          // setError(true);
          console.error(e);

        })
    });

  }



  //   return (
  //     <div className="container-fluid">
  //       <section className="login">
  //         <div className="row">

  //           <div className="col-sm-12  col-lg-3">
  //             <div className="form-wp">
  //               <LoginForm access={access} loadingButton={loadingButton} />
  //             </div>
  //             {error && <div className="" role="alert"> Error al iniciar sesión </div>}
  //           </div>
  // {/* 
  //           <div className="col-sm-12 col-lg-9 px-0 login-right">
  //             <div className="login-right_image"></div>
  //           </div> */}
  //         </div>

  //       </section>
  //     </div>
  //   )

  return (
    <div className={`flex justify-center items-center min-h-screen ${image ? 'login-background' : 'bg-[#22272e]'}`}>
      <div className="flex justify-center items-center bg-[#22272e]">
        <div className="w-full px-4 sm:px-0">
          <div className="flex items-center bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 min-h-[300px]">
            <LoginForm access={access} loadingButton={loadingButton} />
            {error && <div className="text-red-500 text-sm mt-6" role="alert">* Error al intentar iniciar sesión</div>}
          </div>
        </div>
      </div>

    </div>
  )
}

export default LoginPage;