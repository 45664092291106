import React, { useEffect, useState } from 'react';

export const ProgressWheel = ({ totalInteractions, totalPurchasedMessages }) => {
    const radius = 130; // Radio del círculo
    const stroke = 20; // Grosor de la línea
    const normalizedRadius = radius - stroke * 2;
    const circumference = normalizedRadius * 2 * Math.PI;

    const [progress, setProgress] = useState(0);
    const [percentage, setPercentage] = useState(0);

    useEffect(() => {
        // Asegúrate de que el porcentaje no supere el 100%
        const newPercentage = Math.min(totalInteractions / totalPurchasedMessages * 100, 100);
        const newProgress = newPercentage / 100 * circumference;
        setProgress(newProgress);
        setPercentage(newPercentage);
    }, [totalInteractions, totalPurchasedMessages, circumference]);

    const getStrokeColor = () => {
        if (percentage < 50) return '#10b981'; // green-500
        if (percentage < 75) return '#facc15'; // yellow-500
        return '#ef4444'; // red-500
    };

    return (
        <div className="flex justify-center items-center relative group">
            <svg
                height={radius * 2}
                width={radius * 2}
                className="transform -rotate-90"
            >
                <circle
                    stroke="#d1d5db" // gray-300
                    fill="transparent"
                    strokeWidth={stroke}
                    strokeDasharray={circumference + ' ' + circumference}
                    style={{ strokeDashoffset: 0 }}
                    r={normalizedRadius}
                    cx={radius}
                    cy={radius}
                />
                <circle
                    stroke={getStrokeColor()} // Dinamically change color
                    fill="transparent"
                    strokeWidth={stroke}
                    strokeDasharray={circumference + ' ' + circumference}
                    style={{ strokeDashoffset: circumference - progress }}
                    r={normalizedRadius}
                    cx={radius}
                    cy={radius}
                />
            </svg>
            <div className="absolute text-2xl font-semibold">{`${Math.round(
                percentage
            )}%`}</div>

        </div>
    );
};
