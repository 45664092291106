import { ROUTE_NAME } from "../../core/constants/route_name"
import { Auth, Route } from "../../core/interfaces/routes/interfaces"
import BannedUserPage from "./bannedUsers/BannedUserPage"
import { ConfigurationPage } from "./configuration/ConfigurationPage"
import { ConversationPage } from "./conversation/ConversationPage"
import HomePage from "./home/HomePage"
import NotificationPage from "./notification/NotificationPage"

import UserFormDetail from "./user/userForm/UserFormDetail"
import UserPage from "./user/UserPage"
import WebAllowedPage from "./webAllowed/WebAllowedPage"


// import Conversation from "./conversation/Conversation"
// const Conversation = React.lazy(() => import('./conversation/ConversationPage'));

const PagesRoute: Route[] = [
    { path: ROUTE_NAME.home, to: ROUTE_NAME.home, name: "Home", Component: HomePage, role: [Auth.ROLE_ADMIN, Auth.ROLE_USER] },
    { path: ROUTE_NAME.usuarios, to: ROUTE_NAME.usuarios, name: "Usuarios", Component: UserPage, role: [Auth.ROLE_ADMIN, Auth.ROLE_USER] },
    { path: ROUTE_NAME.chats, to: ROUTE_NAME.chats, name: "Chats", Component: ConversationPage, role: [Auth.ROLE_ADMIN, Auth.ROLE_USER] },
    { path: ROUTE_NAME.bannedUsers, to: ROUTE_NAME.bannedUsers, name: "Baneos", Component: BannedUserPage, role: [Auth.ROLE_ADMIN, Auth.ROLE_USER] },
    { path: ROUTE_NAME.webAllowed, to: ROUTE_NAME.webAllowed, name: "Baneos", Component: WebAllowedPage, role: [Auth.ROLE_ADMIN, Auth.ROLE_USER] },
    { path: ROUTE_NAME.profileCrear, to: ROUTE_NAME.profileCrear, name: "Perfil crear", Component: UserFormDetail, role: [Auth.ROLE_ADMIN, Auth.ROLE_USER] },
    { path: ROUTE_NAME.profileDetalle, to: ROUTE_NAME.profileDetalle, name: "Perfil detalle", Component: UserFormDetail, role: [Auth.ROLE_ADMIN, Auth.ROLE_USER] },
    { path: ROUTE_NAME.notification, to: ROUTE_NAME.notification, name: "Notificación", Component: NotificationPage, role: [Auth.ROLE_ADMIN, Auth.ROLE_USER] },
    { path: ROUTE_NAME.configuration, to: ROUTE_NAME.configuration, name: "Configuración", Component: ConfigurationPage, role: [Auth.ROLE_ADMIN, Auth.ROLE_USER] },
 
]


export default PagesRoute