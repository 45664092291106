import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';


export interface SnackbarState {
    color?: string;
    colorAction?: "success" | "error";
    wait?: number;
    message: string
    changeState: boolean
}

export const useSnackbar = (props: SnackbarState) => {

    const [state, setState] = useState(props)

    const handleSnackbar = (msg, colorAction: "success" | "error") => {

        setState({
            ...state,
            message: msg,
            colorAction,
            changeState: true
        })
        setTimeout(() =>{
            setState({
                ...state,
                message: msg,
                colorAction,
                changeState: false
            })
        }, state.wait)
    }

    return {

        state,

        handleSnackbar,
    }
}