import React, { useState, useEffect } from 'react';
import { CCard } from '@coreui/react-pro';

import { ButtonGroup, Button } from '@mui/material';
import { COLORS } from '../../../core/constants/colors';
import { BarHourTimeChartCustom } from '../../components/@analitycs';
import moment from 'moment';
import { CiWarning } from 'react-icons/ci';

interface Analytic1Props {
    data: any;
    error: boolean;
    loading: boolean;
}

const processDataForChart = (data, granularity) => {
    if (!data || data.length === 0 || !granularity) return [];
    console.log('data', data);
    console.log('granularity', granularity);
    let processedData = [];
    const timeUnitData: any = data[granularity];
    console.log('timeUnitData', timeUnitData);
    Object.entries(timeUnitData).forEach(([timeUnit, values]: any) => {
        processedData.push({
            date: timeUnit, // El eje X utilizará esta clave
            Mañana: values.mañana,
            Tarde: values.tarde,
            Noche: values.noche,
            Leads: values.Leads,
            SessionsEnded: values.SessionsEnded,
            Conversaciones: values.Conversaciones
        });
    });
    return processedData;
};

export const Analytic1 = ({ data, error, loading }: Analytic1Props) => {
    const [granularity, setGranularity] = useState('daily');
    const [chartData, setChartData] = useState([]);
    const [hideDaily, setHideDaily] = useState(false);
    const [hideWeekly, setHideWeekly] = useState(false);

    // Esta función se ejecutará cada vez que cambie la granularidad
    // y ajustará los datos que se pasan al gráfico
    useEffect(() => {
        console.log('Granularity changed to:', data);
        // Ajustar los datos aquí si es necesario
    }, [granularity, data]);

    useEffect(() => {
        if (!data || !data.daily) return;
        const processedData = processDataForChart(data, granularity);
        setChartData(processedData);

    }, [granularity, data]);


    useEffect(() => {
        if (!data || !data.daily) return;

        const dailyDates = Object.keys(data.daily);
        if (dailyDates.length === 0) return;

        const startDate = dailyDates[0];
        const endDate = dailyDates[dailyDates.length - 1];
        const monthsDiff = moment(endDate).diff(moment(startDate), 'months', true);

        const shouldShowDaily = monthsDiff <= 3;
        const shouldShowWeekly = monthsDiff <= 12;
        setHideDaily(!shouldShowDaily);
        setHideWeekly(!shouldShowWeekly);
        if (!shouldShowDaily && granularity === 'daily') {
            if (shouldShowWeekly) {
                setGranularity('weekly');
            } else {
                setGranularity('monthly'); // O 'quarterly' si esa es la siguiente granularidad relevante
            }
        }
        // if (shouldShowDaily && granularity === 'monthly' || granularity === 'quarterly') {
        //     setGranularity('daily');
        // }
    }, [data]);



    const isActive = (buttonGranularity) => {
        return granularity === buttonGranularity;
    };

    const getButtonStyle = (buttonGranularity) => ({
        backgroundColor: granularity === buttonGranularity ? COLORS.principalColor : undefined,
        color: granularity === buttonGranularity ? 'white' : undefined,
        '&:hover': {
            backgroundColor: granularity === buttonGranularity ? COLORS.principalColor : undefined,
        },
    });





    return (
        <>
            <CCard className="rounded-xl p-3 my-4" style={{ height: 400 }}>

                <div className='flex flex-col md:flex-row justify-between items-center mx-4 my-2'>
                    <h2 className='text-2xl font-semibold'>Actividad Diaria</h2>

                    {!loading && !error && (
                        <ButtonGroup size="small" aria-label="small outlined button group">
                            {!hideDaily && <Button onClick={() => setGranularity('daily')} sx={getButtonStyle('daily')}>Diario</Button>}
                            {!hideWeekly && <Button onClick={() => setGranularity('weekly')} sx={getButtonStyle('weekly')}>Semanal</Button>}
                            <Button onClick={() => setGranularity('monthly')} sx={getButtonStyle('monthly')}>Mensual</Button>
                            <Button onClick={() => setGranularity('quarterly')} sx={getButtonStyle('quarterly')}>Trimestral</Button>
                        </ButtonGroup>
                    )}
                </div>

                {loading && (
                    <div className="flex justify-center items-center h-full mt-5">
                        <div className="flex justify-center items-center">
                            <div className="animate-spin rounded-full h-[32px] w-[32px] border-t-2 border-b-2 border-gray-900"></div>
                        </div>
                    </div>
                )}


                {error && (
                    <div className="flex justify-center items-center mx-4 my-2 h-full">
                        <div className="text-center">
                            <CiWarning size={150} className="text-yellow-500 mx-auto" />
                            <p className="mt-4 text-lg font-semibold text-gray-700">
                                No se puede mostrar la información
                            </p>
                        </div>
                    </div>
                )}


                {!loading && !error && data && granularity && (
                    <BarHourTimeChartCustom
                        data={chartData}
                        xAxisKey="date"
                        barDataKeys={['Mañana', 'Tarde', 'Noche']}
                        barColors={['#c0d62f', '#d17732', '#153273']} // Colores profesionales
                        dataKeyLine="Leads"
                        lineColor="#6bbfbf" // Color de la línea para Leads, por ejemplo dorado
                        timeFormat={granularity}
                        lineDataKeys={["Leads"]}
                        lineColors={["#6bbfbf"]}
                    />
                )}

            </CCard>
        </>
    );
};


