export const useDocumentAndorraResidence = () => {


    const autocomplete = () => {
        Promise.resolve(
            {
                ok: true,
                item: [
                    { id: 1, title: "Pasaporte" },
                    { id: 2, title: "Certificado de antecedentes penales" },
                    { id: 3, title: "Certificado Médico válido" },
                    { id: 4, title: "Documento de prueba de recursos financieros" },
                    { id: 5, title: "Seguro médico en AND" },
                    { id: 6, title: "Justificante de inversión extranjera o contrato de trabajo" },
                    { id: 7, title: "Contrato de alquiler o vivienda en propiedad" },
                    { id: 8, title: "Fotografías de carnet" },
                ]
            }
        )
    }



    return {
        autocomplete,
    }
}