import { Pagination } from '../../../interfaces/pagination';
import { ConversationService } from '../../../services/db/conversation/conversation.service';




export const useConversation = () => {

    const getConversationMinified = (pagination: Pagination = { page: 1, itemsPerPage: 5 }) => {
        return new Promise<any>((res, rej) => {
            ConversationService.getAllMinified(pagination)
                .then((item) => {

                    if (item && item.data && item.data?.ok) {
                        res(item.data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })
        })
    }

    const getConversationCompleted = (pagination: Pagination = { page: 1, itemsPerPage: 5 }) => {
        return new Promise<any>((res, rej) => {
            ConversationService.getAllCompleted(pagination)
                .then((item) => {

                    if (item && item.data && item.data?.ok) {
                        res(item.data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })
        })
    }


    const getConversationById = (id: any) => {
        return new Promise<any>((res, rej) => {
            ConversationService.getById(id)
                .then((item) => {

                    if (item && item.data && item.data?.ok) {
                        res(item.data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })
        })
    }

    const getConversationByIdSession = (id: any) => {
        return new Promise<any>((res, rej) => {
            ConversationService.getByIdSession(id)
                .then((item) => {

                    if (item && item.data && item.data?.ok) {
                        res(item.data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })
        })
    }

    // const getAnalytics = (startDate: string, endDate: string, platforms: string[]) => {
    //     return new Promise<any>((res, rej) => {
    //         ConversationService.getAnalytics(startDate, endDate, platforms)
    //             .then((item) => {

    //                 if (item && item.data && item.data?.ok) {
    //                     res(item.data);
    //                 } else {
    //                     res(undefined)
    //                 }
    //             })
    //             .catch((e) => {
    //                 console.log(e)
    //                 rej(e)
    //             })
    //     })
    // }

    // const getAnalyticsPerTime = (startDate: string, endDate: string, platforms: string[]) => {
    //     return new Promise<any>((res, rej) => {
    //         ConversationService.getAnalyticsPerTime(startDate, endDate, platforms)
    //             .then((item) => {

    //                 if (item && item.data && item.data?.ok) {
    //                     res(item.data);
    //                 } else {
    //                     res(undefined)
    //                 }
    //             })
    //             .catch((e) => {
    //                 console.log(e)
    //                 rej(e)
    //             })
    //     })
    // }

    


    return {
        getMinified: getConversationMinified,
        getCompleted: getConversationCompleted,
        getById: getConversationById,
        getByIdSession: getConversationByIdSession,
        // getAnalytics,
        // getAnalyticsPerTime,

        getConversationMinified,
        getConversationCompleted,
        getConversationById,
        getConversationByIdSession,

    }
}