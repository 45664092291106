// useSocketIoClient.js
import { useEffect } from 'react';

import { CodePush, useNotificationStore, usePushNotificationStore, useSnackbarStore } from '../../store';
import { SocketIoClientService } from '../../services/socketio/socketio-client.service';
import { useNavigate } from 'react-router-dom';
import { LoginService } from '../../services/login/login.service';



export const useSocketIoClient = () => {

    // const addMessage = useToastAppStore(state => state.addMessage);
    const addSnackbar = useSnackbarStore(state => state.addSnackbar);



    const { hasBannedUser, hasConversation, hasLead, hasQuotaMessage } = usePushNotificationStore();
    const { setIsUpdateList } = useNotificationStore();

    useEffect(() => {
        const socketService = SocketIoClientService.instance;

        // Define una función para manejar los eventos de plataforma
        const handlePlatformBotEvent = async (data) => {
            console.log('Evento platformBot recibido:', data);
            const code = data?.code;

            console.log('Código de evento:', code);
            console.log(hasConversation)

            let identity: any = await LoginService.getIdentityAsync();
            if (identity && identity.idUser) {
                // Dependiendo del código del evento y si el usuario ha habilitado las notificaciones,
                // procesa el evento específico.
                if (code === CodePush.BANNED_USER && hasBannedUser) {
                    // Procesa evento de usuario baneado
                    AddSnackbarForEvent(data, 'Nuevo usuario baneado', 'default');
                } else if (code === CodePush.QUOTA_MESSAGE && hasQuotaMessage) {
                    // Procesa evento de cuota de mensajes alcanzada
                    AddSnackbarForEvent(data, 'Cuota de mensajes alcanzada. Por favor, contacte al administrador.', 'warning', 5000, false);
                } else if (code === CodePush.LEAD && hasLead) {
                    // Procesa evento de nuevo lead
                    AddSnackbarForEvent(data, 'Se ha creado un nuevo lead', 'success');
                } else if (code === CodePush.CONVERSATION && hasConversation) {
                    // Procesa evento de conversación creada
                    AddSnackbarForEvent(data, 'Nueva conversación creada', 'info');
                } else {
                    console.warn("Código no reconocido o notificaciones desactivadas", code);
                }
            }
        };

        const AddSnackbarForEvent = (
            data,
            defaultMessage,
            color: 'default' | 'error' | 'warning' | 'info' | 'success',
            duration?: number,
            closeable?: boolean
        ) => {
            const message = data.message || defaultMessage;
            addSnackbar({
                title: message,
                color: color || 'default',
                duration: duration || 2500,
                position: 'bottom-left',
                closeable: closeable,
            });

            setIsUpdateList(true);
            setTimeout(() => {
                setIsUpdateList(false);
            }, 100);
        }

        // Función auxiliar para mostrar toasts
        // const addToastForEvent = (data, defaultMessage) => {
        //     const message = data.message || defaultMessage;
        //     addMessage({
        //         id: Math.random().toString(36).substr(2, 9),
        //         message,
        //         position: 'top-right',
        //         autoClose: 2500,

        //         // progressColor: 'limegreen',
        //         backgroundColor: '#eff9fa',
        //         textColor: '#070807',
        //         progressColor: '#fce988'
        //     });


        //     setIsUpdateList(true);
        //     setTimeout(() => {
        //         setIsUpdateList(false);
        //     }, 100);
        // };

        // Escucha eventos y actualiza el estado con Zustand
        socketService.on('platformBot', handlePlatformBotEvent);

        // Limpieza al desmontar
        // return () => {
        //     socketService.disconnect();
        // };
    }, [hasBannedUser, hasConversation, hasLead, hasQuotaMessage, addSnackbar]);
}
