import React from 'react';
import { PieChartCustom, ProgressWheel } from '../../components/@analitycs';
import { CCard } from '@coreui/react-pro';
import { CiWarning } from 'react-icons/ci';
import { ImNotification } from 'react-icons/im';
import { Tooltip } from '@mui/material';
import { RiInformationFill } from 'react-icons/ri';

interface Analytic2Props {
    totalInteractions: number;
    totalPurchasedMessages: number;
    error: boolean;
    loading: boolean;
}

const Analytic5 = ({ totalInteractions, totalPurchasedMessages, error, loading }: Analytic2Props) => {

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];


    return (
        <CCard className="rounded-xl p-3 my-4" style={{ height: 400, maxWidth: 380 }}>
            <div className='flex justify-center items-center mx-4 my-2'>
                <h2 className='text-2xl font-semibold'>Balance mensual</h2>
                <Tooltip title="Los datos presentados reflejan una vista general y no están directamente asociados con las especificaciones de fechas o plataformas seleccionadas en los filtros.">
                    <span className='cursor-pointer'>
                        <RiInformationFill size={18} className="ml-2" />
                    </span>
                </Tooltip>
            </div>

            {loading && (
                <div className="flex justify-center items-center h-full mt-5">
                    <div className="flex justify-center items-center">
                        <div className="animate-spin rounded-full h-[32px] w-[32px] border-t-2 border-b-2 border-gray-900"></div>
                    </div>
                </div>
            )}

            {error && (
                <div className="flex justify-center items-center mx-4 my-2 h-full">
                    <div className="text-center">
                        <CiWarning size={150} className="text-yellow-500 mx-auto" />
                        <p className="mt-4 text-lg font-semibold text-gray-700">
                            No se puede mostrar la información
                        </p>
                    </div>
                </div>

            )}

            {!loading && !error && (
                <>
                    <ProgressWheel
                        totalInteractions={totalInteractions}
                        totalPurchasedMessages={totalPurchasedMessages}
                    />


                    <div className='flex flex-col justify-between mx-4'>
                        <div className='flex justify-between'>
                            <h3 className='text-xl font-semibold'>Cantidad Total</h3><span className='font-normal text-xl'>{totalPurchasedMessages}</span>
                        </div>
                        <div className='flex justify-between'>
                            <h3 className='text-xl font-semibold'>Interacciones</h3><span className='font-normal text-xl'>{totalInteractions}</span>
                        </div>
                    </div>

                </>
            )}

        </CCard>
    );
};

export default Analytic5;
