import { CFormInput } from '@coreui/react-pro';
import styled from 'styled-components';
import { COLORS } from '../../../../../core/constants/colors';



const StyledCFormInput = styled(CFormInput)`
background-color: none;
border: none;

&:focus {
  border-color: #f08a3b;
  box-shadow: none;
}

&:blur {
  border-color: transparent;
  box-shadow: none;
}

div, span, input {
  color: white;
}

.virtual-scroller {
  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
    padding: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #4f6f88;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: transparent;
  }
}
`;


export default StyledCFormInput;