import { Filter, usePaginationStore  } from '../../../../core/store';
import { TiDelete } from "react-icons/ti";

interface FilterChipProps {
    filter: Filter;
    onRemove: (nameTable: string, nameColumn: string, title: string) => void;
}


const FilterChip = ({ filter, onRemove }: FilterChipProps) => {
    return (
        <div className="chip">
            <span className='chip-title'>{filter.label}: </span><span className='chip-value'>{filter.title}</span>
            <button onClick={() => onRemove(filter.nameTable, filter.nameColumn, filter.title)}>
                <TiDelete className='text-gray-400 hover:text-gray-600' size={28} />
            </button>
        </div>
    );
};

export const AppliedFilters = () => {
    const { filtersRaw, removeFilterRaw } = usePaginationStore((state) => state);

    return (
        <div className="applied-filters">
            {filtersRaw && filtersRaw.length ? (
                <>
                    {
                        filtersRaw.map((filter, index) => (
                            <FilterChip key={index} filter={filter} onRemove={removeFilterRaw} />
                        ))
                    }
                </>
            ) : (
                <div className='flex items-center text-sm mx-1'>
                    <span>No hay filtros aplicados</span>
                </div>
            )}
        </div>
    );
};


