
import { Route } from '../../../core/interfaces/routes/interfaces';
import LoginPage from './login/Login';




const ExternalPagesRoute: Route[] = [
    { path: "/login", to: "/login", name: "Login", Component: LoginPage },
    // { path: "/pago/:data", to: "/pago/:data", name: "Pago", Component: PaymentPage },
    // { path: "/pago", to: "/pago", name: "Pago", Component: PaymentPage },





]

export default ExternalPagesRoute;
