// Importamos useEffect y useRef de React
import React, { useEffect, useRef, useState } from 'react';
import { JsonConfig } from '../../../../core/models/db/configuration-bot/json-config';

// Definimos los props del componente
interface IframeBotProps {
    configBot: JsonConfig; // La configuración del bot
    systemUnavailableMessage: string; // Mensaje de sistema no disponible
}

export const IframeBot: React.FC<IframeBotProps> = ({ configBot, systemUnavailableMessage }) => {
    const iframeRef = useRef<HTMLIFrameElement>(null); // Referencia al iframe

    // Manejador para eventos postMessage recibidos del iframe
    useEffect(() => {
        const handleMessage = (event) => {
            if (event.data.action === 'rbc-open-chat') {
                const payload = event.data.payload;
                if (iframeRef.current) {
                    if (payload?.isOpen) {
                        iframeRef.current.className = 'rbc-iframe-open';
                        iframeRef.current.style.width = ''; // Asumiendo este es el ancho por defecto cuando está abierto
                        iframeRef.current.style.height = ''; // Asumiendo esta es la altura por defecto cuando está abierto
                    } else {
                        if (payload?.messageType === 'slide') {
                            iframeRef.current.className = 'rbc-iframe-slide-close';
                            iframeRef.current.style.width = payload?.size?.width ? `${payload.size.width}px` : ''; // Ajusta según el ancho de slide
                        } else if (payload?.messageType === 'bubble') {
                            iframeRef.current.className = 'rbc-iframe-bubble-close';
                            iframeRef.current.style.height = payload?.size?.height ? `${payload.size.height}px` : ''; // Ajusta según la altura de bubble
                        } else {
                            iframeRef.current.className = 'rbc-iframe-close';
                            iframeRef.current.style.width = ''; // Asumiendo este es el ancho por defecto cuando está cerrado
                            iframeRef.current.style.height = ''; // Asumiendo esta es la altura por defecto cuando está cerrado
                        }
                    }
                }
            } else {
                iframeRef.current.className = iframeRef.current.className || 'rbc-iframe-close';
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    // Función para crear la URL del bot
    const createBotUrl = (config: JsonConfig, baseUrl: string): string => {
        const params = new URLSearchParams();
        Object.keys(config).forEach(key => {
            let value = config[key];
            // console.log((key === "welcomeMessage" && config[key]?.text && config[key]?.text !== ""))
            if (key !== "welcomeMessage" || (key === "welcomeMessage" && config[key]?.text && config[key]?.text !== "")) {
                if (typeof value === 'object') {
                    params.set(key, JSON.stringify(value));
                } else {
                    params.set(key, encodeURIComponent(value));
                }
            }


        });
        return `${baseUrl}?${params.toString()}`;
    };

    // Efecto para actualizar el src del iframe cuando cambie la configuración del bot o el mensaje de inactividad
    useEffect(() => {
        const routeBot = process.env.REACT_APP_ROUTE_BOT || "http://localhost:3000/#/bot";
        const botUrl = createBotUrl(configBot, routeBot);
        const uniqueSuffix = new Date().getTime();

        if (iframeRef.current) {
            iframeRef.current.src = botUrl + `&iframeUrl=${window.location.origin}`;
        }
    }, [configBot, systemUnavailableMessage]);



    return (
        <iframe
            ref={iframeRef}
            className={``} // Usamos la clase dinámica
            title="Bot Preview"
        />
    );
};
