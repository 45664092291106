import { Filter } from "../store";

interface TransformedFilters {
    [key: string]: {
        value: any | any[];
        relation?: string;
    };
}

export const transformCheckDataToFilter = (filtersArray: Filter[]): TransformedFilters => {
    return filtersArray.reduce((acc: TransformedFilters, filter: Filter) => {
        const { nameColumn, value, nameTable } = filter;

        // Verifica si la columna ya existe y si tiene la misma relación
        if (acc[nameColumn] && acc[nameColumn].relation === nameTable) {
            // Si ya existe y es la misma relación, maneja el valor como array
            if (!Array.isArray(acc[nameColumn].value)) {
                acc[nameColumn].value = [acc[nameColumn].value];
            }
            if (!(acc[nameColumn].value as any[]).includes(value)) {
                (acc[nameColumn].value as any[]).push(value);
            }
        } else {
            // Si la columna no existe o tiene una relación diferente, crea o sobrescribe el filtro
            acc[nameColumn] = { value: value, ...(nameTable !== nameColumn && { relation: nameTable }) };
        }

        return acc;
    }, {});
};


