import { cilUser, cilEnvelopeClosed, cilLockLocked, cilInfo } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CRow, CCol, CInputGroup, CInputGroupText, CFormInput, CAlert, CFormCheck, CFormLabel, CContainer, CFormText } from "@coreui/react-pro";
import { useFormik } from "formik";
import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import { User } from "../../../../core/models/db/user";
import { LoginService } from "../../../../core/services/login/login.service";
import DragDrop from "../../../components/drag-drop/DragDrop";
import { FileItemObservableService } from "../../../components/drag-drop/services/rxJS/fileitem-observable.service";
import CheckPassword from "../../../components_OLD/checkPassword/CheckPassword";
import * as Yup from 'yup';
import validationUserSchema from './uf_validator_yup';
import { Autocomplete, AutocompleteMui } from "../../../components";
import { InputLabel, TextField, FormHelperText, Box, FormControlLabel, Checkbox, Alert } from "@mui/material";




interface UserFormProps {
    user: User;
    typeAction: "add" | "update";
    loadingButton?: boolean;

    isModal?: boolean

    disabled?: boolean;

    add?: (user: User) => Promise<any>;
    update?: (user: User) => Promise<any>;
    checkPassword?: (id: any, password: any) => Promise<any>
    checkUsername?: (id: any, username: string) => Promise<any>

    handleActionButtonClick?: (user: User) => Promise<any>


}



const UserForm = forwardRef((props: UserFormProps, ref) => {

    /**
     * Este fragmento es necesario porque el navegador rellena de manera
     * automática los inputs username y password. Después de intentarlo mediante
     * otros métodos se ha optado por el siguiente
     */
    const autocompleteOff = () => setTimeout(() => setAutoOff(false), 100)
    const [autoOff, setAutoOff] = useState(null);

    const [identity, setIdentity] = useState(undefined);

    useEffect(() => {
        (async () => {
            autocompleteOff()
            let id = await LoginService.getIdentityAsync();
            setIdentity(id);
        })();

    }, [])


    // Comienzo de la página
    // Comienzo de la página
    // Comienzo de la página

    const {
        user,
        typeAction,
        loadingButton,
        disabled,

        isModal = false,

        // add,
        // update,
        checkPassword,
        checkUsername,
        handleActionButtonClick
    } = props;


    const [showPassword, setShowPassword] = useState(false);
    const [imageUser, setImageUser] = useState<string>(user && user.image ? user.image : "");
    // const [disabled, setDisabled] = useState(true);

    const { values, errors, touched, isValid,

        handleChange,
        handleSubmit,
        handleBlur,
        getFieldProps,
        setFieldValue,
        setValues
    } = useFormik({
        initialValues: {
            ...user,
            blocked: Boolean(user.blocked)
        },
        validateOnBlur: false,
        onSubmit: (value) => {
            console.log(value)
            handleActionButtonClick(value);
        },
        validationSchema: Yup.lazy(values => validationUserSchema(values, typeAction, touched, checkUsername, identity && identity.idRole == 1))
    })


    useImperativeHandle(ref, () => ({
        submitForm: () => {
            handleSubmit();
        },
        createBackup: () => {
            changeToBackup(true);
        }
    }));


    // Cojo la referencia del observable para saber cuando ha habido un cambio
    const controlFile$ = React.useRef(FileItemObservableService.instance.control$);

    /** Cuando existe un cambio en el observable actializo el listado de items */
    useEffect(() => {
        const subscription = FileItemObservableService.instance
            .control$.subscribe(
                item => {
                    if (item && item.fileItemList && item.fileItemList.length > 0) {
                        values.image = item.fileItemList[0].pathRelative;
                        setFieldValue('image', item.fileItemList[0].pathRelative);
                    } else {
                        setFieldValue('image', "");
                    }
                }
            )
        return () => {
            subscription.unsubscribe();
        }

    }, [controlFile$]);

    /**
     * Si hay que mantener la pagina tras haber creado el registro, 
     * descomenta este trozo de código
     * @param value 
     */
    // useEffect(() => {
    //     console.log("Activating user update");
    //     setValues({
    //         ...user,
    //         blocked: Boolean(user.blocked)
    //     })
    // }, [user])




    const changeToBackup = (value: boolean) => {
        if (value) {
            setValues(
                {
                    ...user,
                    blocked: Boolean(user.blocked)
                }
            );


            if (value) {
                let backImage = user.image;
                setImageUser(null)
                setTimeout(() => {
                    setImageUser(backImage)
                }, 400)
            }
        }
    }

    // const isEdit = (value: boolean) => {
    //     setDisabled(value);
    // }


    const handleAutocompleteChange = (name, value) => {
        if (value === undefined || value === null) {
            console.warn("No hay una selección");
        } else {
            setFieldValue(name, value);
        }
    };

    
    return (

        <CRow>
            {/* <h4>Original</h4>
            <pre>
                {JSON.stringify(user, null, 2)}
            </pre>
            <h4> Modificado</h4>
            <pre>
                {JSON.stringify(values, null, 2)}
            </pre> */}

            <form onSubmit={handleSubmit}>
                <CRow>
                    <CCol sm={6}>
                        {/* <span>{identity && (
                            <span>{JSON.stringify(identity)}</span>
                        )}</span> */}
                        <DragDrop
                            styleDD={{ maxWidth: "auto", height: 250, marginTop: identity && identity.idRole == 1 ? 50 : 0 }}

                            id={"test-id"}
                            valueDefault={imageUser}
                            isOnlyOneFile={true}
                            disabled={(disabled && typeAction !== "add") || loadingButton}
                            directoryNameList={["fromReact"]}
                            maxSizeMB={19}
                            typeAllowed={[
                                "image/*",
                                "image/gif",
                                "image/jpeg",
                                "image/png",
                                "image/jpg"
                            ]} />


                    </CCol>

                    <CCol sm={6}>

                        <Box className="mb-3">
                            <TextField

                                label="Username"
                                placeholder='Username'
                                disabled={(disabled && typeAction !== "add") || loadingButton}
                                {...getFieldProps("username")}
                                error={touched.username && Boolean(errors.username)}
                                // readOnly={autoOff}
                                autoComplete="new-username"
                                variant="standard"
                                fullWidth
                                id="username"
                            />
                            {touched.username && errors.username && (
                                <FormHelperText error>{errors.username}</FormHelperText>
                            )}
                        </Box>



                        <CCol>



                            <Box className="mb-4">
                                <TextField

                                    label="Email"
                                    placeholder='Email'
                                    disabled={(disabled && typeAction !== "add") || loadingButton}
                                    {...getFieldProps("email")}
                                    error={touched.email && Boolean(errors.email)}

                                    variant="standard"
                                    fullWidth

                                />
                                {touched.email && errors.email && (
                                    <FormHelperText error>{errors.email}</FormHelperText>
                                )}
                            </Box>
                        </CCol>


                        {typeAction === "update" && (

                            <CheckPassword
                                // clean={disabled}
                                placeholder='Introduce la contraseña'
                                handlePassword={async function (text: string) {
                                    let result = await checkPassword(user.id, text);
                                    setShowPassword(result);
                                }}
                                disabled={disabled || loadingButton}
                                blockWriting={showPassword}
                            />
                        )}


                        <CInputGroup className="my-3">
                            {/* <CInputGroupText>
                                <CIcon icon={cilLockLocked} />
                            </CInputGroupText> */}
                            <CFormInput
                                className='input-light'
                                placeholder='Nueva Contraseña'
                                type="password"
                                {...getFieldProps("password")}
                                readOnly={autoOff}
                                disabled={(!showPassword && typeAction === "update") || (disabled && typeAction !== "add") || loadingButton}
                                autoComplete="new-password"
                                invalid={touched.password && Boolean(errors.password)} // Agregar esta línea
                            />



                            <CFormInput
                                className='input-light'
                                placeholder='Repetir nueva contraseña'
                                type="password"
                                {...getFieldProps("priv_confirmPassword")}
                                readOnly={autoOff}
                                disabled={(!showPassword && typeAction === "update") || (disabled && typeAction !== "add") || loadingButton}
                                invalid={touched.priv_confirmPassword && Boolean(errors.priv_confirmPassword)} // Agregar esta línea
                            />

                        </CInputGroup>

                        <div style={{ height: "auto" }}>
                            {/* Este fragmento es solo para cuando vayas a editar */}
                            {showPassword && (
                                <CAlert color="primary" className="d-flex align-items-center" style={{ height: identity && identity.idRole == 1 ? "25px" : "50px" }}>
                                    <CIcon icon={cilInfo} className="flex-shrink-0 me-2" width={18} height={18} />
                                    <div className='small'>Deja los campos en blanco para no cambiar la contraseña</div>
                                </CAlert>

                            )}

                            {touched.password && errors.password && (
                                <CFormText className="text-danger" style={{ height: "25px" }}>{errors.password}</CFormText>

                            )}
                            {touched.priv_confirmPassword && errors.priv_confirmPassword && (
                                <CFormText className="text-danger" style={{ height: "25px" }}>{errors.priv_confirmPassword}</CFormText>

                            )}
                        </div>




                        {identity && identity.role === "ROLE_ADMIN" && !isModal && (

                            // <CCol className="mt-4">
                            //     {/* <CFormCheck className="mb-4" {...getFieldProps("blocked")} /> */}
                            //     <CFormCheck
                            //         disabled={(disabled && typeAction !== "add") || loadingButton}
                            //         className="mb-4"
                            //         {...getFieldProps("blocked")}
                            //         // defaultChecked={values.blocked || false}
                            //         checked={values.blocked || false}
                            //         label="Bloquear usuario"
                            //     />

                            //     {touched.blocked && errors.blocked && <CAlert color="danger">{errors.blocked}</CAlert>}
                            // </CCol>

                            <Box className="mt-4">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled={(disabled && typeAction !== "add") || loadingButton}
                                            {...getFieldProps("blocked")}
                                            checked={values.blocked || false}
                                        />
                                    }
                                    label="Bloquear usuario"
                                />
                                {touched.blocked && errors.blocked && (
                                    <Alert severity="error">{errors.blocked}</Alert>
                                )}
                            </Box>
                        )}


                        {identity && identity.role === "ROLE_ADMIN" && !isModal && (
                            <>
                                <CCol>
                                    {/* <Autocomplete
                                        className="cmulti-select-light mt-2"
                                        id="basic-url"
                                        table='role'
                                        disabled={(disabled && typeAction !== "add") || loadingButton}
                                        name={'idRoleFk'}
                                        defaultValue={{ key: "id", value: user.idRoleFk }}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    /> */}


                                    <div className="mt-6">
                                        <AutocompleteMui
                                            placeholder="Selecciona un rol"
                                            // className="cmulti-select-light mt-2"
                                            id="basic-url"
                                            table='role'
                                            disabled={(disabled && typeAction !== "add") || loadingButton}
                                            name={'idRoleFk'}
                                            defaultValue={{ key: "id", value: user.idRoleFk }}
                                            // onChange={handleChange}
                                            onChange={(newValue) => handleAutocompleteChange("idRoleFk", newValue?.id)}
                                            onBlur={handleBlur}
                                        />
                                    </div>

                                </CCol>
                                <div style={{ height: "auto", marginTop: 15 }}>
                                    {touched.idRoleFk && errors.idRoleFk && (
                                        <CAlert color="danger" className='d-flex align-items-center' style={{ height: "25px" }}>
                                            <div className='small'>{errors.idRoleFk}</div>
                                        </CAlert>
                                    )}
                                </div>
                            </>
                        )}

                    </CCol>
                </CRow>
            </form >
        </CRow >


    )
});

UserForm.displayName = "UserForm";
export default UserForm



