// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/treemap
import { ResponsiveTreeMap, TreeMap } from '@nivo/treemap'
import { useState } from 'react';


export const TreeMapChartCustom = ({ data }) => {

    console.log("que es data", data)

    if (!data) return null;

    const CustomLabel = ({ x, y, width, height, value }) => {
        // Asegúrate de que el label se ajuste dentro del cuadrante y solo se muestre si hay suficiente espacio
        const isLabelVisible = width > 30 && height > 30;

        return isLabelVisible ? (
            <text x={x + width / 2} y={y + height / 2} textAnchor="middle" fill="#fff">
                {value}
            </text>
        ) : null;
    };

    return (
        <ResponsiveTreeMap
            // tooltip={(value: any) => (
            //     <strong >
            //         {/* {id}: {value} */}
            //         {JSON.stringify(value)}
            //     </strong>
            // )}
            // height={300}
            // width={700}

            colors={{ scheme: 'category10' }}

            data={data}
            identity="name"
            value="loc"
            valueFormat=".02s"
            margin={{ top: 10, right: 30, bottom: 10, left: 30 }}
            labelSkipSize={12}
            labelTextColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        1.2
                    ]
                ]
            }}
            parentLabelPosition="left"
            parentLabelTextColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        2
                    ]
                ]
            }}
            nodeOpacity={0.2}
            borderColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        0.1
                    ]
                ]
            }}


        />
    )
}


// const [data2, setData] = useState({
//     "name": "nivo",
//     "color": "hsl(72, 70%, 50%)",
//     "children": [
//         {
//             "name": "viz",
//             "color": "hsl(143, 70%, 50%)",
//             "children": [
//                 {
//                     "name": "stack",
//                     "color": "hsl(27, 70%, 50%)",
//                     "children": [
//                         {
//                             "name": "cchart",
//                             "color": "hsl(330, 70%, 50%)",
//                             "loc": 155423
//                         },
//                         {
//                             "name": "xAxis",
//                             "color": "hsl(155, 70%, 50%)",
//                             "loc": 41419
//                         },
//                         {
//                             "name": "yAxis",
//                             "color": "hsl(345, 70%, 50%)",
//                             "loc": 45426
//                         },
//                         {
//                             "name": "layers",
//                             "color": "hsl(165, 70%, 50%)",
//                             "loc": 174513
//                         }
//                     ]
//                 },
//                 {
//                     "name": "ppie",
//                     "color": "hsl(192, 70%, 50%)",
//                     "children": [
//                         {
//                             "name": "chart",
//                             "color": "hsl(150, 70%, 50%)",
//                             "children": [
//                                 {
//                                     "name": "pie",
//                                     "color": "hsl(17, 70%, 50%)",
//                                     "children": [
//                                         {
//                                             "name": "outline",
//                                             "color": "hsl(305, 70%, 50%)",
//                                             "loc": 51461
//                                         },
//                                         {
//                                             "name": "slices",
//                                             "color": "hsl(85, 70%, 50%)",
//                                             "loc": 152083
//                                         },
//                                         {
//                                             "name": "bbox",
//                                             "color": "hsl(223, 70%, 50%)",
//                                             "loc": 77456
//                                         }
//                                     ]
//                                 },
//                                 {
//                                     "name": "donut",
//                                     "color": "hsl(245, 70%, 50%)",
//                                     "loc": 66494
//                                 },
//                                 {
//                                     "name": "gauge",
//                                     "color": "hsl(64, 70%, 50%)",
//                                     "loc": 131856
//                                 }
//                             ]
//                         },
//                         {
//                             "name": "legends",
//                             "color": "hsl(357, 70%, 50%)",
//                             "loc": 56386
//                         }
//                     ]
//                 }
//             ]
//         },
//         {
//             "name": "colors",
//             "color": "hsl(308, 70%, 50%)",
//             "children": [
//                 {
//                     "name": "rgb",
//                     "color": "hsl(312, 70%, 50%)",
//                     "loc": 49797
//                 },
//                 {
//                     "name": "hsl",
//                     "color": "hsl(356, 70%, 50%)",
//                     "loc": 99284
//                 }
//             ]
//         },
//         {
//             "name": "utils",
//             "color": "hsl(280, 70%, 50%)",
//             "children": [
//                 {
//                     "name": "randomize",
//                     "color": "hsl(343, 70%, 50%)",
//                     "loc": 119244
//                 },
//                 {
//                     "name": "resetClock",
//                     "color": "hsl(147, 70%, 50%)",
//                     "loc": 35239
//                 },
//                 {
//                     "name": "noop",
//                     "color": "hsl(85, 70%, 50%)",
//                     "loc": 7380
//                 },
//                 {
//                     "name": "tick",
//                     "color": "hsl(305, 70%, 50%)",
//                     "loc": 21573
//                 },
//                 {
//                     "name": "forceGC",
//                     "color": "hsl(336, 70%, 50%)",
//                     "loc": 199387
//                 },
//                 {
//                     "name": "stackTrace",
//                     "color": "hsl(219, 70%, 50%)",
//                     "loc": 146035
//                 },
//                 {
//                     "name": "dbg",
//                     "color": "hsl(285, 70%, 50%)",
//                     "loc": 56022
//                 }
//             ]
//         },
//         {
//             "name": "generators",
//             "color": "hsl(351, 70%, 50%)",
//             "children": [
//                 {
//                     "name": "address",
//                     "color": "hsl(162, 70%, 50%)",
//                     "loc": 131696
//                 },
//                 {
//                     "name": "city",
//                     "color": "hsl(308, 70%, 50%)",
//                     "loc": 170721
//                 },
//                 {
//                     "name": "animal",
//                     "color": "hsl(5, 70%, 50%)",
//                     "loc": 161925
//                 },
//                 {
//                     "name": "movie",
//                     "color": "hsl(130, 70%, 50%)",
//                     "loc": 138864
//                 },
//                 {
//                     "name": "user",
//                     "color": "hsl(344, 70%, 50%)",
//                     "loc": 73727
//                 }
//             ]
//         },
//         {
//             "name": "set",
//             "color": "hsl(352, 70%, 50%)",
//             "children": [
//                 {
//                     "name": "clone",
//                     "color": "hsl(283, 70%, 50%)",
//                     "loc": 86753
//                 },
//                 {
//                     "name": "intersect",
//                     "color": "hsl(155, 70%, 50%)",
//                     "loc": 85471
//                 },
//                 {
//                     "name": "merge",
//                     "color": "hsl(43, 70%, 50%)",
//                     "loc": 103038
//                 },
//                 {
//                     "name": "reverse",
//                     "color": "hsl(98, 70%, 50%)",
//                     "loc": 98043
//                 },
//                 {
//                     "name": "toArray",
//                     "color": "hsl(233, 70%, 50%)",
//                     "loc": 153380
//                 },
//                 {
//                     "name": "toObject",
//                     "color": "hsl(336, 70%, 50%)",
//                     "loc": 136306
//                 },
//                 {
//                     "name": "fromCSV",
//                     "color": "hsl(148, 70%, 50%)",
//                     "loc": 98576
//                 },
//                 {
//                     "name": "slice",
//                     "color": "hsl(69, 70%, 50%)",
//                     "loc": 184043
//                 },
//                 {
//                     "name": "append",
//                     "color": "hsl(308, 70%, 50%)",
//                     "loc": 191526
//                 },
//                 {
//                     "name": "prepend",
//                     "color": "hsl(228, 70%, 50%)",
//                     "loc": 148427
//                 },
//                 {
//                     "name": "shuffle",
//                     "color": "hsl(289, 70%, 50%)",
//                     "loc": 103936
//                 },
//                 {
//                     "name": "pick",
//                     "color": "hsl(76, 70%, 50%)",
//                     "loc": 42128
//                 },
//                 {
//                     "name": "plouc",
//                     "color": "hsl(90, 70%, 50%)",
//                     "loc": 15835
//                 }
//             ]
//         },
//         {
//             "name": "text",
//             "color": "hsl(221, 70%, 50%)",
//             "children": [
//                 {
//                     "name": "trim",
//                     "color": "hsl(35, 70%, 50%)",
//                     "loc": 15086
//                 },
//                 {
//                     "name": "slugify",
//                     "color": "hsl(270, 70%, 50%)",
//                     "loc": 52283
//                 },
//                 {
//                     "name": "snakeCase",
//                     "color": "hsl(249, 70%, 50%)",
//                     "loc": 10359
//                 },
//                 {
//                     "name": "camelCase",
//                     "color": "hsl(347, 70%, 50%)",
//                     "loc": 37969
//                 },
//                 {
//                     "name": "repeat",
//                     "color": "hsl(227, 70%, 50%)",
//                     "loc": 152319
//                 },
//                 {
//                     "name": "padLeft",
//                     "color": "hsl(312, 70%, 50%)",
//                     "loc": 83562
//                 },
//                 {
//                     "name": "padRight",
//                     "color": "hsl(56, 70%, 50%)",
//                     "loc": 170160
//                 },
//                 {
//                     "name": "sanitize",
//                     "color": "hsl(249, 70%, 50%)",
//                     "loc": 165251
//                 },
//                 {
//                     "name": "ploucify",
//                     "color": "hsl(122, 70%, 50%)",
//                     "loc": 60350
//                 }
//             ]
//         },
//         {
//             "name": "misc",
//             "color": "hsl(205, 70%, 50%)",
//             "children": [
//                 {
//                     "name": "greetings",
//                     "color": "hsl(65, 70%, 50%)",
//                     "children": [
//                         {
//                             "name": "hey",
//                             "color": "hsl(27, 70%, 50%)",
//                             "loc": 51807
//                         },
//                         {
//                             "name": "HOWDY",
//                             "color": "hsl(19, 70%, 50%)",
//                             "loc": 6857
//                         },
//                         {
//                             "name": "aloha",
//                             "color": "hsl(260, 70%, 50%)",
//                             "loc": 67684
//                         },
//                         {
//                             "name": "AHOY",
//                             "color": "hsl(317, 70%, 50%)",
//                             "loc": 61299
//                         }
//                     ]
//                 },
//                 {
//                     "name": "other",
//                     "color": "hsl(261, 70%, 50%)",
//                     "loc": 196854
//                 },
//                 {
//                     "name": "path",
//                     "color": "hsl(9, 70%, 50%)",
//                     "children": [
//                         {
//                             "name": "pathA",
//                             "color": "hsl(43, 70%, 50%)",
//                             "loc": 65807
//                         },
//                         {
//                             "name": "pathB",
//                             "color": "hsl(153, 70%, 50%)",
//                             "children": [
//                                 {
//                                     "name": "pathB1",
//                                     "color": "hsl(182, 70%, 50%)",
//                                     "loc": 115934
//                                 },
//                                 {
//                                     "name": "pathB2",
//                                     "color": "hsl(16, 70%, 50%)",
//                                     "loc": 78147
//                                 },
//                                 {
//                                     "name": "pathB3",
//                                     "color": "hsl(75, 70%, 50%)",
//                                     "loc": 82915
//                                 },
//                                 {
//                                     "name": "pathB4",
//                                     "color": "hsl(169, 70%, 50%)",
//                                     "loc": 46766
//                                 }
//                             ]
//                         },
//                         {
//                             "name": "pathC",
//                             "color": "hsl(218, 70%, 50%)",
//                             "children": [
//                                 {
//                                     "name": "pathC1",
//                                     "color": "hsl(231, 70%, 50%)",
//                                     "loc": 191192
//                                 },
//                                 {
//                                     "name": "pathC2",
//                                     "color": "hsl(203, 70%, 50%)",
//                                     "loc": 139100
//                                 },
//                                 {
//                                     "name": "pathC3",
//                                     "color": "hsl(289, 70%, 50%)",
//                                     "loc": 119958
//                                 },
//                                 {
//                                     "name": "pathC4",
//                                     "color": "hsl(306, 70%, 50%)",
//                                     "loc": 192107
//                                 },
//                                 {
//                                     "name": "pathC5",
//                                     "color": "hsl(61, 70%, 50%)",
//                                     "loc": 87561
//                                 },
//                                 {
//                                     "name": "pathC6",
//                                     "color": "hsl(59, 70%, 50%)",
//                                     "loc": 1961
//                                 },
//                                 {
//                                     "name": "pathC7",
//                                     "color": "hsl(21, 70%, 50%)",
//                                     "loc": 115570
//                                 },
//                                 {
//                                     "name": "pathC8",
//                                     "color": "hsl(100, 70%, 50%)",
//                                     "loc": 182599
//                                 },
//                                 {
//                                     "name": "pathC9",
//                                     "color": "hsl(228, 70%, 50%)",
//                                     "loc": 117889
//                                 }
//                             ]
//                         }
//                     ]
//                 }
//             ]
//         }
//     ]
// });