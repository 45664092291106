// import { useContext, useEffect, useState } from 'react';
// import { CButton, CCol, CContainer, CRow } from '@coreui/react-pro';
// import { AxiosResponse } from 'axios';
// import { useDispatch } from 'react-redux';
// import { useVoucher } from '../../../../core/hooks/db/voucher/useVoucher';
// import { LoaderContext } from '../../loader/context/LoaderContext';
// import ModalCover from '../../modalCover/ModalCover';
// import { resetCurrentPageActionBar } from '../actions/actionBar-action';
// import { TableNamesType } from '../../../../core/models/interfaces/interfaces';
// import { COLORS } from '../../../../core/constants/colors';

import { TableNamesType } from "../../../../core/interfaces/table-name-type";

// import {
//     BlobProvider,
//     Document,
//     PDFViewer,
//     Page,
//     Text,
//     View
// } from '@react-pdf/renderer';

// import { AddTableInvoicePDF } from '../../@pdf/addTableInvoicePDF/AddTableInvoicePDF';
// import { HomeService } from '../../../../core/services/db/home/home.service';
// import { LoginService } from '../../../../core/services/login/login-service';
// import MyDocumentInvoice from '../../@pdf/myDocumentInvoice/MyDocumentInvoice';
// import { data } from '../../TestChart';
// import moment from 'moment';

// import { useSnackbar } from '../../snackbar/hook/useSnackbar';
// import Snackbar from '../../snackbar/Snackbar';

interface AddPdfModalProps {
    table: TableNamesType;
    items: any[],
    handleModalAddPdf: (value?: boolean) => void;


    // interface ModalCover
    count?: number;
    show?: any;
    children?: any;
    title?: any;
    hasCloseButton?: any;
    hasSuccessButton?: any;
    hasCloseHeaderButton?: any;

}

const AddPdfModal = (props: AddPdfModalProps) => { return (<div>Comentado</div>)}
export default AddPdfModal


// const AddPdfModal = (props: AddPdfModalProps) => {

//     const {
//         table,
//         handleModalAddPdf,
//         items,

//         // interface ModalCover
//         count,
//         show,
//         children,
//         title,
//         hasCloseButton = true,
//         hasSuccessButton = false,
//         hasCloseHeaderButton = true,

//     } = props;


//     const dispatch = useDispatch();

//     const [fnAddPdf, setFnAddPdf] =
//         useState<(token: any, idList: string[] | number[], action: boolean) => Promise<AxiosResponse<any, any>> | null>(null);

//     const [hasError, setHasError] = useState(false);
//     const [labels, setLabels] = useState<{
//         labelSigle: string,
//         labelMany: string,
//         titleModal: string
//     }>({
//         labelMany: "Sin hacer",
//         labelSigle: "Sin hacer",
//         titleModal: "Previsualización de la factura"
//     });

//     const [invoiceData, setInvoiceData] = useState(undefined);
//     const [data, setData] = useState(null);

//     // Crear PDF
//     const [print, setPrint] = useState(null);
//     const [pdfBlob, setPdfBlob] = useState(null);
//     const [pdfError, setPdfError] = useState(null);

//     const { state, handleSnackbar } = useSnackbar({
//         color: "",
//         wait: 3000,
//         message: "",
//         changeState: false,
//     })


//     const { handleLoading } = useContext(LoaderContext);


//     // const { deleteUser } = useUser()
//     // const { deleteActivity } = useActivity()
//     // const { deleteUnit } = useUnit()
//     // const { deleteTax } = useTax()
//     // const { deleteWithholding } = useWithholding()
//     // const { deleteCompany } = useCompany()
//     // const { deleteWork } = useWork()
//     // const { deleteCustomer } = useCustomer()
//     // const { deletePartner } = usePartner()
//     const { getAllVoucherLiquidationByPagePartner } = useVoucher()


//     useEffect(() => {
//         if (!fnAddPdf) {
//             switch (table) {

//                 case "vouchers":
//                     // setLabels({
//                     //     labelMany: "¿Quieres eliminar los vales elegidos?",
//                     //     labelSigle: "¿Quieres eliminar el vale elegido?",
//                     //     titleModal: "Eliminar vale"
//                     // })
//                     setFnAddPdf(() => getAllVoucherLiquidationByPagePartner);
//                     break;
//                 default:
//                     setHasError(true);
//                     console.error("Mal uso del modal DELETE. Tabla recibida", table)
//             }
//         }
//     }, [table])


//     useEffect(() => {

//         if (fnAddPdf) {
//             (async () => {
//                 await successButton();
//             })()
//         }

//     }, [fnAddPdf])



//     const successButton = async () => {

//         try {
//             // handleLoading(true);
//             const token = localStorage.getItem("token");

//             let result: any = await fnAddPdf(token, items, true);
//             if (result.ok) {
//                 await getDataInvoice(result.item.rows[0].idWorkFk);
//                 const invoiceData = _processInvoiceData(result.item.rows);
//                 invoiceData.lines.push({
//                     IVA: undefined,
//                     code: undefined,
//                     count: undefined,
//                     quantity: undefined,
//                     total: invoiceData.linesTotal,
//                     unitName: undefined,
//                     unitPrice: undefined,
//                 })

//                 setInvoiceData(invoiceData);
//                 console.log(invoiceData)
//             } else {
//                 setInvoiceData(undefined)
//             }

//         } catch (e) {
//             console.error(e)
//             setInvoiceData(undefined)

//         } finally {

//             // handleModalAddPdf(false);
//             // setTimeout(() => {
//             //     dispatch(resetCurrentPageActionBar());
//             // }, 100)
//         }
//     }

//     const getDataInvoice = (idWork) => {
//         return new Promise<any>(async (res, rej) => {
//             const ID_WORK = idWork
//             let token = await LoginService.getTokenAsync();
//             HomeService.getDataInvoice(token, ID_WORK)
//                 .then(({ data }) => {
//                     console.log(data)
//                     if (data.ok) {
//                         setData(data.item);  // Guardamos los conteos en el estado
//                         res("");
//                     } else {
//                         rej("No encontrado");
//                     }

//                 })
//                 .catch(e => {
//                     console.log(e);
//                     rej(e);
//                 });
//         });
//     };


//     const handlePrint = () => {
//         setPrint(true);

//     }

//     useEffect(() => {
//         if (pdfBlob) {
//             // setItem([]);
//             // setData(undefined);

//             setPrint(false);


//             let date = new Date();
//             const DAY = date.getDate();
//             const MONTH = date.getMonth() + 1;
//             const YEAR = date.getFullYear()


//             const blobURL = URL.createObjectURL(pdfBlob);
//             const link = document.createElement('a');
//             link.href = blobURL;
//             link.download = `vale_facturacion_REF${data.work.code}_${DAY}_${MONTH}_${YEAR}.pdf`;
//             document.body.appendChild(link);
//             link.click();
//             link.remove();

//             handleSnackbar("PDF generado y descargado con éxito", "success");

//             (async () => {
//                 let invoice = await createInvoice()
//                 Promise.all([
//                     updateVouchers(items, invoice.id),
//                     updateCompany()
//                 ]);
//             })()

//             // handleLoading(false);
//             setPdfBlob(undefined);

//             handleModalAddPdf(false);
//             setTimeout(() => {
//                 dispatch(resetCurrentPageActionBar());
//             }, 100)


//         }

//         if (pdfError) {
//             // setData(undefined);
//             handleSnackbar("Ocurrió un error al generar el PDF", "error");

//             setPrint(false);
//             // setItem([]);

//             console.error(pdfError);
//             setPdfError(undefined);
//         }
//     }, [pdfBlob, pdfError]);

//     const createInvoice = () => {
//         return new Promise<any>(async (res, rej) => {

//             let token = await LoginService.getTokenAsync();
//             let invoice: Invoice = new Invoice();
//             invoice = {
//                 idWorkFk: data.work.id,
//                 invoiceNumber: `${data.company.invoiceCounter + 1}`,
//                 month: new Date().getMonth() + 1,
//                 year: new Date().getFullYear(),
//                 customerName: data.work.customer,
//                 commercialName: data.work.customer,
//                 cif: data.work.cif,
//                 address: data.work.address,
//                 province: data.work.province,
//                 zipCode: data.work.zipCode,
//                 town: data.work.town,
//                 issueDate: moment().format("YYYY-MM-DD") as any,
//                 dueDate: moment().format("YYYY-MM-DD") as any,
//                 paymentDate: null,
//                 subTotal: invoiceData.linesTotal,
//                 fee10: invoiceData.IVAtotals[10]?.base || 0,
//                 vat10: invoiceData.IVAtotals[10]?.quota || 0,
//                 fee21: invoiceData.IVAtotals[21].base,
//                 vat21: invoiceData.IVAtotals[21].quota,
//                 irpf: 0,
//                 total: invoiceData.totalInvoice,
//             }

//             InvoiceService.add(token, invoice)
//                 .then(({ data }) => {
//                     console.log(data)
//                     if (data.ok) {
//                         res(data.item);
//                     } else {
//                         rej("Fallo al crear la factura");
//                     }
//                 })
//                 .catch(e => {
//                     console.log(e);
//                     rej(e);
//                 });

//         })
//     }


//     const updateVouchers = (idVoucherList: any[], idInvoice: any) => {
//         return new Promise<any>(async (res, rej) => {

//             let token = await LoginService.getTokenAsync();

//             VoucherService.updateInvoiceMassive(token,
//                 idVoucherList,
//                 idInvoice,
//                 undefined
//             )
//                 .then(({ data }) => {
//                     console.log(data)
//                     if (data.ok) {
//                         res("");
//                     } else {
//                         rej("Fallo al crear la factura");
//                     }
//                 })
//                 .catch(e => {
//                     console.log(e);
//                     rej(e);
//                 });


//         })
//     }

//     const updateCompany = () => {
//         return new Promise<any>(async (res, rej) => {
//             const token = await LoginService.getTokenAsync();
//             let company = { ...data.company };
//             CompanyService.update(token, {
//                 id: company.id,
//                 invoiceCounter: company.invoiceCounter + 1
//             })
//                 .then(({ data }) => {
//                     console.log(data)
//                     if (data.ok) {
//                         res(data.item);
//                     } else {
//                         rej("Fallo al actualizar la compañía");
//                     }
//                 })
//                 .catch(e => {
//                     console.log(e);
//                     rej(e);
//                 });
//         })
//     }


//     const _processInvoiceData = (vouchers) => {

//         const unitCounts = vouchers.reduce((acc, voucher) => {
//             if (acc[voucher.idUnitFk]) {
//                 acc[voucher.idUnitFk] += 1;
//             } else {
//                 acc[voucher.idUnitFk] = 1;
//             }
//             return acc;
//         }, {});

//         // 1. Crear líneas de factura
//         const invoiceLines = vouchers.map(voucher => ({
//             quantity: parseFloat(voucher.quantity).toFixed(2),
//             unitPrice: parseFloat(voucher.customerPrice).toFixed(2),
//             total: (parseFloat(voucher.quantity) * parseFloat(voucher.customerPrice)).toFixed(2),
//             unitName: voucher.units.name,
//             code: voucher.units.code,
//             IVA: voucher.units.vat,
//             count: unitCounts[voucher.idUnitFk]
//         }));

//         // 2. Agrupar por IVA y calcular la base y la cuota
//         let IVAgroups = {};

//         invoiceLines.forEach(line => {
//             const IVA = line.IVA || "0";

//             if (!IVAgroups[IVA]) {
//                 IVAgroups[IVA] = {
//                     base: 0,
//                     quota: 0
//                 };
//             }

//             IVAgroups[IVA].base = +(IVAgroups[IVA].base + parseFloat(line.total)).toFixed(2);
//             IVAgroups[IVA].quota = +(IVAgroups[IVA].quota + parseFloat(line.total) * IVA / 100).toFixed(2);
//         });

//         // 3. Calcular el total sumando todas las bases y todas las cuotas
//         let totalInvoice = 0;

//         for (let IVA in IVAgroups) {
//             totalInvoice = +(totalInvoice + IVAgroups[IVA].base + IVAgroups[IVA].quota).toFixed(2);
//         }

//         const linesTotal = invoiceLines.reduce((acc, line) => acc + parseFloat(line.total), 0).toFixed(2);

//         return {
//             lines: invoiceLines,
//             IVAtotals: IVAgroups,
//             totalInvoice: totalInvoice.toFixed(2),
//             linesTotal
//         };
//     }

//     return (
//         <ModalCover
//             size='lg'
//             show={!hasError ? show : false}

//             title={labels.titleModal}
//             hasCloseButton={false}
//             hasSuccessButton={false}

//             handleModal={handleModalAddPdf}
//             fnSuccess={successButton}
//         >
//             <div>
//                 {/* <CButton style={{ backgroundColor: COLORS.NIMO_MAS_CLARO }} onClick={() => {
//                     handleSnackbar("PDF generado y descargado con éxito", "success");

//                 }}>Test Snackbar [programador]</CButton> */}

//                 {/* 
               
//                No funciona este componente aqui.
//                Lo que ocurre, sale detrás del modal y además una vez se cierra el modal
//                desaparece, por lo tanto el usuario nunca verá el snackbar

//                */}
//                 {/* <Snackbar {...state} /> */}

//                 {invoiceData && data && (
//                     <>

//                         <div className="row">
//                             {/* <CCol className="d-flex justify-content-end m-2"> */}
//                             <CCol className="d-flex justify-content-start m-2">
//                                 <CButton style={{ backgroundColor: COLORS.NIMO_MAS_CLARO }} onClick={handlePrint}>Crear e Imprimir</CButton>
//                             </CCol>
//                         </div>

//                         <div style={{ marginBottom: 30 }}></div>

//                         <PDFViewer
//                             width={765}
//                             height={600}
//                             showToolbar={false}>
//                             <MyDocumentInvoice
//                                 company={data.company}
//                                 work={data.work}
//                                 IVAtotals={invoiceData.IVAtotals}
//                                 totalInvoice={invoiceData.totalInvoice}

//                                 tablePdfData={{
//                                     body: invoiceData.lines,
//                                     header: [
//                                         {
//                                             nameTableList: { nameHeaderCell: "REF", nameParameter: "code" },
//                                             colWidth: 8
//                                         },
//                                         {
//                                             nameTableList: { nameHeaderCell: "Denominación", nameParameter: "unitName" },
//                                             colWidth: 50,
//                                             align: 'left'
//                                         },
//                                         {
//                                             nameTableList: { nameHeaderCell: "Cantidad", nameParameter: "quantity" },
//                                         },
//                                         {
//                                             nameTableList: { nameHeaderCell: "Precio", nameParameter: "unitPrice" },
//                                             align: 'right',
//                                             type: "number",
//                                             symbol: "€"
//                                         },
//                                         {
//                                             nameTableList: { nameHeaderCell: "Total", nameParameter: "total" },
//                                             align: 'right',
//                                             type: "number",
//                                             symbol: "€"
//                                         },

//                                     ],
//                                     headerColor: "#fcba03"
//                                 }}
//                                 itemsPerPage={10} />
//                         </PDFViewer>

//                         {print && (

//                             <BlobProvider document={
//                                 <MyDocumentInvoice
//                                     company={{
//                                         ...data.company,
//                                     }}
//                                     work={data.work}
//                                     IVAtotals={invoiceData.IVAtotals}
//                                     totalInvoice={invoiceData.totalInvoice}

//                                     tablePdfData={{
//                                         body: invoiceData.lines,
//                                         header: [
//                                             {
//                                                 nameTableList: { nameHeaderCell: "REF", nameParameter: "code" },
//                                                 colWidth: 8
//                                             },
//                                             {
//                                                 nameTableList: { nameHeaderCell: "Denominación", nameParameter: "unitName" },
//                                                 colWidth: 50,
//                                                 align: 'left'
//                                             },
//                                             {
//                                                 nameTableList: { nameHeaderCell: "Cantidad", nameParameter: "quantity" },
//                                             },
//                                             {
//                                                 nameTableList: { nameHeaderCell: "Precio", nameParameter: "unitPrice" },
//                                                 align: 'right',
//                                                 type: "number",
//                                                 symbol: "€"
//                                             },
//                                             {
//                                                 nameTableList: { nameHeaderCell: "Total", nameParameter: "total" },
//                                                 align: 'right',
//                                                 type: "number",
//                                                 symbol: "€"
//                                             },

//                                         ],
//                                         headerColor: "#fcba03"
//                                     }}
//                                     itemsPerPage={10} />
//                             }>
//                                 {({ blob, url, loading, error }) => {
//                                     if (blob) {
//                                         setPdfBlob(blob);
//                                     }
//                                     if (error) {
//                                         setPdfError(error);
//                                     }
//                                     if (loading) {
//                                         // return <div>Generando PDF...</div>;
//                                     }
//                                     return null;
//                                 }}
//                             </BlobProvider>
//                         )}
//                     </>
//                 )}

//                 {/* <CButton style={{ backgroundColor: COLORS.NIMO_MAS_CLARO }} onClick={successButton}>Test [programador]</CButton> */}
//             </div>
//         </ModalCover>
//     )
// }

// export default AddPdfModal
