import { create } from 'zustand';
import { DataBasicEncoder } from "../../util/data-basic-encoder";

export interface Identity {
    username: string;
    email: string;
    idUser: number;
    role: string;
    avatar?: string;
}

interface IdentityStore {
    identity: Identity | null;
    setIdentity: (identity: Identity) => void;
    clearIdentity: () => void;
    loadIdentity: () => void;
}

const identityLocalStorageKey = 'identity';

// Utilidad para verificar si un objeto cumple con la interfaz Identity
const conformsToInterface = <T>(obj: any, reference: T): obj is T => {
    for (const key in reference) {
        if (!obj.hasOwnProperty(key)) {
            return false;
        }
    }
    return true;
};

export const useIdentityStore = create<IdentityStore>((set, get) => ({
    identity: null, // Estado inicial nulo, se carga después desde localStorage

    setIdentity: (identity: Identity) => {
        // Codifica y guarda la identidad en localStorage
        const encodedIdentity = DataBasicEncoder.encode(identity);
        localStorage.setItem(identityLocalStorageKey, encodedIdentity);
        set({ identity });
    },

    clearIdentity: () => {
        // Limpia la identidad de localStorage y del estado
        localStorage.removeItem(identityLocalStorageKey);
        set({ identity: null });
    },

    loadIdentity: () => {
        // Carga la identidad desde localStorage si está disponible
        const storedIdentity = localStorage.getItem(identityLocalStorageKey);
        if (storedIdentity) {
            const decodedIdentity = DataBasicEncoder.decode(storedIdentity);
            // Verifica si el objeto decodificado cumple con la interfaz Identity
            if (conformsToInterface(decodedIdentity, { username: '', email: '', role: '', idUser: 0 })) {
                set({ identity: decodedIdentity });
            } else {
                console.error("Objeto de identidad almacenado no cumple con la interfaz esperada.");
            }
        }
    }
}));

// En el componente principal o en el punto de entrada de tu aplicación, puedes cargar la identidad:
// useIdentityStore.getState().loadIdentity();
 