import { CCard, CContainer, CRow, CSmartTable } from "@coreui/react-pro";
import { Item } from "@coreui/react-pro/dist/components/table/types";
import { TablePagination } from "@mui/material";
import clsx from "clsx";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { animated, useSpring } from "react-spring";
import { COLORS } from "../../../core/constants/colors";
import { useCheckTable } from "../../../core/hooks";
import { useWebAllowed } from "../../../core/hooks/db/web-allowed/useWebAllowed";
import { User } from "../../../core/models/db/user";
import { LoginService } from "../../../core/services/login/login.service";
import { useActionBarStore, usePaginationStore } from "../../../core/store";
import { Util } from "../../../core/util/util";
import { AppliedFilters, Divider, Loader, SearchTable } from "../../components";
import { RippleButton } from "../../components/ui/ripple-button/RippleButton";
import { WebAllowedForm } from "./webAllowedForm/WebAllowedForm";
import { LoaderContext } from "../../components/loader/context/LoaderContext";


const CLASS_REGULAR_ROW = "check-reg-" + Util.getUniqueId(1);
const CLASS_PRINCIPAL_ROW = "check-prin-" + Util.getUniqueId(1);


const WebAllowedPage = () => {

    // Estado para controlar la visibilidad del formulario
    const [showForm, setShowForm] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);
    const formRef = useRef(null);


    const { reset, loadActionBar } = useActionBarStore(state => state);
    const { loading: isLoading, handleLoading, changeStyle } = useContext(LoaderContext);

    // Usado cuando se cree un nuevo registro
    const [reset2, setReset2] = useState<boolean>(false);
    // const [loading, handleLoading] = useState<boolean>(false);


    const initialPaginationConfig = useMemo(() => ({
        page: 0,
        itemsPerPage: 10,
        filters: {},
        orderBy: null,
    }), []);

    const { pagination, setPagination, resetFilterRaws } = usePaginationStore();
    const [init, setInit] = useState<boolean>(false);

    useEffect(() => {
        setPagination(initialPaginationConfig);
        resetFilterRaws();
        setInit(true);
    }, [initialPaginationConfig]);


    const [identity, setIdentity] = useState(undefined);

    const { getWebAllowed, getAllWebAllowedId } = useWebAllowed();

    const [body, setBody] = useState<User[]>([]);
    const [reload, setReload] = useState<boolean>(true);

    const { selected, handleSingleCheck, cleanSelected } = useCheckTable(
        {
            body,
            pagination: pagination,
            reasonForDesactivation: [],
            classToFindPrincipalRow: CLASS_PRINCIPAL_ROW,
            classToFindRegularRow: CLASS_REGULAR_ROW,
            getAllId: getAllWebAllowedId
        });


    useEffect(() => {
        if (!pagination || !init) return;
        initPage({
            ...pagination,
        })
    }, [!!init, pagination?.page, pagination?.itemsPerPage, JSON.stringify(pagination?.orderBy), JSON.stringify(pagination?.filters)]);


    useEffect(() => {
        cleanSelected();
    }, [pagination?.itemsPerPage, JSON.stringify(pagination?.filters)]);

    useEffect(() => {
        if (reset || reset2) {
            initPage(initialPaginationConfig);
            setShowForm(false);
            cleanSelected();
        }
    }, [reset, reset2]);


    const initPage = (paginationDefault = pagination) => {
        (async () => {
            changeStyle({ display: "none" });
            handleLoading(true);
            const id = await LoginService.getIdentityAsync();
            setIdentity(id);
            const response = await getWebAllowed({ ...paginationDefault, page: paginationDefault.page + 1 });
            console.log("response web allowed", response)
            if (response && response.ok) {
                let rows = response.item.rows;

                for (let i = 0; i < response.item.rows.length; i++) {
                    const row = rows[i];
                    row.priv_check = "";
                    row._props = { className: CLASS_REGULAR_ROW };
                }

                setBody(rows);
                setPagination({
                    ...paginationDefault,
                    totalItems: response.item.pagination.totalItems,
                    totalPages: response.item.pagination.totalPages,
                })

                setTimeout(() => {
                    loadActionBar({ delete: id.role === "ROLE_ADMIN", table: "webAllowed", count: selected.length, items: selected });
                    handleLoading(false);
                    handleLoading(false);
                }, 200)
            } else {
                console.warn("Ha ocurrido un problema al devolver los usuarios")
            }
        })()
    }

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPagination({
            ...pagination,
            page: newPage
        })

    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setPagination({
            ...pagination,
            page: 0,
            itemsPerPage: parseInt(event.target.value, 10)
        })

        // Se limpia los checks seleccionados
        cleanSelected();
    };

    const toggleFormVisibility = () => setShowForm(!showForm);


    const expandAnimation = useSpring({
        from: { height: 0, visible: false },
        to: { height: showForm ? contentHeight : 0 },
        config: { tension: 250, friction: 30 }
    });

    useEffect(() => {
        if (formRef.current) {
            setContentHeight(formRef.current.clientHeight);
        }
    }, [showForm]);


    const handleReset = () => {
        setReset2(true);
        setTimeout(() => {
            setReset2(false);
        }, 300)
    }

    return (
        <CContainer>
            <div className="w-full justify-between items-center flex  pt-4 pb-5">
                <div className="">
                    <span className="text-4xl" style={{ fontWeight: 500 }}>URLs Permitidas</span>
                </div>
                {/* Botón para alternar la visibilidad del formulario */}
                <RippleButton
                    className={clsx(`py-2 px-3 rounded-xl`, {
                        'bg-gray-400': !showForm,
                        'bg-gray-600': showForm
                    })}
                    style={{
                        backgroundColor: !showForm ? COLORS.principalColor : COLORS.disabledColor, // Asumiendo que tienes un secondaryColor
                        // Asumiendo que tienes un secondaryColor

                    }}

                    onClick={toggleFormVisibility}>
                    <span
                        className={clsx(`text-white`)}
                    >
                        {showForm ? 'Ocultar' : 'Crear'}
                    </span>
                </RippleButton>

            </div>

            {/* Renderizado condicional del formulario basado en el estado showForm */}
            <animated.div style={expandAnimation}>
                <div ref={formRef}>
                    {showForm && (
                        <CCard className="mb-4">
                            <div className="p-3">
                                <WebAllowedForm resetList={handleReset} />
                            </div>
                        </CCard>
                    )}
                </div>
            </animated.div>


            <div className="h-[50px]"></div>



            <CCard className="rounded-xl p-3">
                <CRow>
                    <SearchTable nameColumn={["url"]} label={["URL"]} />
                </CRow>
                <Divider color="" marginX={-16} />
                <CRow>
                    <AppliedFilters />
                </CRow>
                <Divider marginX={-16} />
                {/* <CRow className="flex justify-center align-middle items-center">
                    <RoleCheck multiSelect={false} nameTable="role" nameColumn="title" label="Rol" />
                </CRow>
                <Divider marginX={-16} /> */}
                <CRow>
                    {isLoading && (
                        <div className="flex justify-center items-center h-full mt-5">
                            <div className="flex justify-center items-center">
                                <div className="animate-spin rounded-full h-[32px] w-[32px] border-t-2 border-b-2 border-gray-900"></div>
                            </div>
                        </div>
                    )}

                    {/* LISTADO */}
                    {body && body.length > 0 && pagination && pagination.totalPages && (
                        //   className={width < 780 ? 'table-responsive' : ''}
                        <div style={{ visibility: isLoading ? 'hidden' : 'visible' }}>
                            <CSmartTable
                                tableHeadProps={{
                                    color: "#dedede",

                                }}
                                tableProps={{

                                    responsive: "md",
                                    bordered: false,
                                    borderless: true,
                                    // striped: true,
                                    hover: true,
                                    // color: "dark",

                                }}
                                items={body}

                                columns={[
                                    {
                                        key: "priv_check",
                                        label: "",
                                        sorter: false,
                                        filter: false,
                                        _props: { className: CLASS_PRINCIPAL_ROW },
                                        _style: { backgroundColor: '#f1f1f1' },

                                    },
                                    {
                                        key: "url",
                                        label: "URL",
                                        _style: { backgroundColor: '#f1f1f1' },
                                    },
                                    {
                                        key: "description",
                                        label: "Descripción",
                                        _style: { backgroundColor: '#f1f1f1' },

                                    },
                                    // {
                                    //     key: 'show_details',
                                    //     label: '',
                                    //     _style: { width: '5%' },
                                    //     filter: false,
                                    //     sorter: false,
                                    //     _props: { className: 'fw-semibold' },
                                    // },

                                ]}

                                columnSorter={{
                                    external: true
                                }}

                                // pagination={false}
                                // itemsPerPageSelect={false}
                                // itemsPerPageLabel={"Registros por página"}
                                itemsPerPage={pagination.itemsPerPage}

                                clickableRows

                                onRowClick={(item: Item, index: number, columnName: string, event) => {
                                    let target = typeof event !== 'boolean' ? event.nativeEvent.target as HTMLElement : event;
                                    let value = typeof target === 'boolean' ? target : target.tagName;

                                    if (typeof target !== 'boolean' && value === "INPUT") {
                                        //    Clica al check

                                    } else if (typeof target !== 'boolean' && value === "TD") {
                                        //    Console ir al detalle
                                        if (selected && selected.length > 0) {
                                            handleSingleCheck(null, item.id, item);
                                        } else {
                                            // goToUpdate(item as User)
                                        }
                                    }
                                }}

                                scopedColumns={{
                                    email: (item) => {
                                        let value = "No hay email registrado";
                                        try {
                                            if (item.email) {
                                                value = item.email;
                                            }
                                            return (
                                                <td> {value} </td>
                                            )
                                        } catch (e) {
                                            console.error(e)
                                            return (
                                                <td> {value}* </td>
                                            )
                                        }
                                    },

                                }}

                                onSelectAll={() => {
                                    // console.log("calling all of ids")
                                }}
                                onSelectedItemsChange={(items) => {
                                    // console.log("items that you selected", items)
                                }}


                                onSorterChange={(value) => {
                                    // console.log("what is value?", value)
                                    if (value.state === 0) {
                                        setPagination({
                                            ...pagination,
                                            //   relatedTableOrder: null,
                                            orderBy: null
                                        })
                                    } else if (value.column === "priv_role") {
                                        // setPagination({
                                        //   ...pagination,
                                        //   relatedTableOrder: "roles",
                                        //   order: value.state,
                                        //   orderBy: "title"
                                        // })


                                    } else {
                                        setPagination({
                                            ...pagination,
                                            // relatedTableOrder: null,
                                            orderBy: {
                                                field: value.column,
                                                order: value.state as any
                                            }
                                        })
                                    }


                                }}
                            />

                            {pagination && pagination.totalItems > 0 && (
                                <TablePagination
                                    component="div"
                                    count={pagination.totalItems}
                                    page={pagination.page}
                                    rowsPerPage={pagination.itemsPerPage}

                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    labelRowsPerPage="Registros por página"
                                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                />

                            )}
                        </div>
                    )}




                </CRow>
            </CCard>
        </CContainer >
    )
}


export default WebAllowedPage;

