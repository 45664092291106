

import { Box, Button } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { DateRange } from '@mui/x-date-pickers-pro/internals/models';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import React, { useState } from 'react'
import { CustomRangeShortcuts } from './components/CustomRangeShortcuts';
import { END_DATE_DEFAULT, START_DATE_DEFAULT } from './custom-ranges';

interface DateRangeProps {
  showRanges?: boolean
  disabled?: boolean;
  handleDate?: (startDate: string, endDate: string) => void;

  defaultStartDate?: Date | string,
  defaultEndDate?: Date | string
}




export const DatePickerRange = (props: DateRangeProps) => {

  const {
    disabled,
    showRanges = true,
    defaultStartDate,
    defaultEndDate,
    handleDate } = props;

  const [dateRange, setDateRange] = useState<[moment.Moment, moment.Moment]>([
    moment(defaultStartDate) || moment(START_DATE_DEFAULT),
    moment(defaultEndDate) || moment(END_DATE_DEFAULT)
  ]);



  // const handleChangeDateRange = (newValue: [moment.Moment | null, moment.Moment | null]) => {
  //   setDateRange(newValue); // Esto establecerá las fechas en el estado, incluso si son null
  //   // Comprobando y formateando cada fecha individualmente para manejar nulls adecuadamente
  //   const startDate = newValue[0] ? newValue[0].format('YYYY-MM-DD HH:mm:ss') : null;
  //   const endDate = newValue[1] ? newValue[1].format('YYYY-MM-DD HH:mm:ss') : null;

  //   // Llama a handleDate con las fechas formateadas, permitiendo que sean null
  //   if (handleDate) {
  //     console.log('startDate', startDate);
  //     console.log('endDate', endDate);
  //     handleDate(startDate, endDate);
  //   }
  // };

  const handleChangeDateRange = (newValue: [moment.Moment | null, moment.Moment | null]) => {
    setDateRange(newValue); // Esto establecerá las fechas en el estado, incluso si son null

    // Ajustando la fecha de inicio para que comience a las 00:00:00
    const startDate = newValue[0] ? newValue[0].startOf('day').format('YYYY-MM-DD HH:mm:ss') : null;

    // Ajustando la fecha de finalización para que termine a las 23:59:59
    const endDate = newValue[1] ? newValue[1].endOf('day').format('YYYY-MM-DD HH:mm:ss') : null;

    // Llama a handleDate con las fechas formateadas, permitiendo que sean null
    if (handleDate) {
      handleDate(startDate, endDate);
    }
  };


  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>

      <DateRangePicker

        disabled={disabled}
        defaultValue={[moment(defaultStartDate), moment(defaultEndDate)]}

        value={dateRange}
        onChange={(newValue) => {
          // setDateRange(newValue);
          handleChangeDateRange(newValue);

        }}

        slots={{
          shortcuts: () => (
            <CustomRangeShortcuts setDateRange={handleChangeDateRange} />
          ),
          actionBar: ({ onAccept, onCancel }) => (
            <Box
              sx={{
                display: 'flex',
                gridRow: 3,
                gridColumn: 2,
                // paddingTop: 4,
                justifyContent: 'flex-end',
                marginRight: '1rem',
                marginBottom: '1rem'
              }}
            >
              {/* <Button onClick={onCancel}>Cancelar</Button> */}
              <Button onClick={onAccept}>Aceptar</Button>
            </Box>
          ),
        }}
        slotProps={{
          textField: ({ position }) => ({
            label: position === 'start' ? 'Fecha Inicial' : 'Fecha Final',
            variant: 'standard',
          }),

        }}

      />

    </LocalizationProvider>
  )
}
