import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarToggler,

} from '@coreui/react-pro'
import { AppSidebarNav } from './AppSidebarNav'

import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'


import useNavSideBar from '../../../routes/hook/useNavSideBar'
import { COLORS } from '../../../../core/constants/colors'
import { useUIStore } from '../../../../core/store'

import logoAPP from "../../../../core/assets/img/logo_rbc_negative.png"


const AppSidebar = () => {
  const dispatch = useDispatch()
  // const unfoldable = useTypedSelector((state) => state.sidebarUnfoldable)
  // const sidebarShow = useTypedSelector((state) => state.sidebarShow)

  // const { sidebarUnfoldable: unfoldable, sidebarShow } = useSelector((state: RootState) => state.ui);

  const { sidebarShow, sidebarUnfoldable, setUI } = useUIStore(state => state)


  const navigation = useNavSideBar();

  return (
    <CSidebar
      position="fixed"
      unfoldable={sidebarUnfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        setUI({ sidebarShow: visible })
      }}
    >
      <CSidebarBrand className="d-none d-md-flex" style={{ backgroundColor: COLORS.tertiaryColor }}>

        {/* <img className="sidebar-brand-full" src={logoAPP} alt="My Cool Image" height={90} />
        <img className="sidebar-brand-narrow" src={logoAPP} alt="My Cool Image" height={30} /> */}


        {/* Estos eran los buenos */}
        <img className="sidebar-brand-full" src={logoAPP} alt="logo company" width={300} height={20} />
        {/* <img className="sidebar-brand-narrow" src={logoAPP} alt="My Cool Image" height={30} /> */}
        {/* <span style={{ fontFamily: "monospace", fontSize: 26 }}><span className='text-red-600'>Red</span><span className="text-blue-400">Blue</span>Code</span> */}
     
     
      </CSidebarBrand>

      <CSidebarNav>
        <SimpleBar>
          <div className='mb-4'>
            <AppSidebarNav items={navigation} />
          </div>
        </SimpleBar>
      </CSidebarNav>


      {/* TODO: Impide que se achique el sidebar principal */}
      {/* <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() =>
          setUI({ sidebarUnfoldable: !sidebarUnfoldable })
        }
      /> */}
    </CSidebar>
  )
}

export default React.memo(AppSidebar)
