import { CFormCheck } from '@coreui/react-pro';
import React, { useLayoutEffect, useRef, useState } from 'react'
import { createRoot } from 'react-dom/client';
import { Util } from '../../util/util';
import { Pagination } from '../../interfaces/pagination';
import { useActionBarStore } from '../../store';


export interface ReasonForDeactivationProps {
    key: string,
    typeField: 'number' | 'string' | 'boolean'
    value: string
}

/**
 * @param {boolean} show Muestra o no los checks
 * @param {string} classToFindRegularRow Clase para indicar donde se encuentran los checks secundarios
 * @param {string} classToFindPrincipalRow Clase para encontrar el check principal
 * @param {any[]} body Cuerpo de la tabla
 * @param {number} itemsPerPage Cantidad de elementos por página. Necesario para limpiar referencias
 * @param {Function} getAllId Función que llama todos los id de la tabla
 * @param {ReasonForDeactivationProps[]} reasonForDesactivation objeto para indicar que checks no pueden ser clicados
 */
interface CheckTableProps {
    showCheck?: boolean;
    classToFindRegularRow: string;
    pagination?: Pagination
    classToFindPrincipalRow: string;
    body: any[];
    reasonForDesactivation?: ReasonForDeactivationProps[]
    getAllId: (reasonForDesactivation: ReasonForDeactivationProps[]) => Promise<any>;


    // Especial para la tabla socio liquidación
    getAllIdLiquidation?: (token: any, paginated: Pagination) => Promise<any>;
}


export const useCheckTable = (props: CheckTableProps) => {

    // const dispatch = useDispatch();
    // const show = useSelector((state: RootState) => state.actionBar.showActionBar);
    // const options = useSelector((state: RootState) => state.actionBar.options);

    const { isShowActionBar: show, options, showActionBar, loadActionBar, hideActionBar } = useActionBarStore(state => state);


    const {
        body,
        reasonForDesactivation = [],
        pagination,
        classToFindPrincipalRow,
        classToFindRegularRow,
        showCheck = true,

        getAllId,
        getAllIdLiquidation,
    } = props;




    /**
     * El check principal está marcado
     */
    const [isAllId, setIsAllId] = useState<boolean>(false);
    /**
     * Los id que están marcados
     */
    const [selected, setSelected] = useState<number[]>([]);
    const rootRefs = useRef([]);
    const principalRootRef = useRef(null);

    const isSelected = (row: any) => selected.findIndex(id => id === row) !== -1;

    const cleanSelected = () => {
        setIsAllId(false);
        setSelected([])
    };

    // const createAndRenderFormCheck = (container, formCheckComponent, rootRef) => {
    //     if (rootRef.current) {
    //         rootRef.current.render(formCheckComponent);
    //     } else {
    //         rootRef.current = createRoot(container);
    //         rootRef.current.render(formCheckComponent);
    //     }
    // };

    const createAndRenderFormCheck = (container, formCheckComponent, rootRef) => {
        // Si ya existe un árbol de React para este contenedor, desmóntalo primero
        if (rootRef.current) {
            rootRef.current.unmount();
        }
        // Luego, crea un nuevo árbol de React y renderiza el componente
        rootRef.current = createRoot(container);
        rootRef.current.render(formCheckComponent);
    };

    useLayoutEffect(() => {
        // Limpiar las referencias de React cuando cambia el cuerpo de la tabla
        rootRefs.current = body.map(() => ({ current: null }));
    }, [pagination?.itemsPerPage, (body && (pagination?.itemsPerPage != body.length))])


    const disabledCheck = (row) => {
        if (!reasonForDesactivation || reasonForDesactivation.length === 0) {
            return false;
        }

        let isDisabled = false;
        for (const [i, reason] of reasonForDesactivation.entries()) {

            const { key, value, typeField } = reason;
            if (typeField === "boolean") {
                isDisabled = !!row[key] === Boolean(value);
            } else if (typeField === "number") {
                isDisabled = row[key] === Number(value);
            } else if (typeField === "string") {
                // Elimina los acentos y convierte las cadenas a minúsculas antes de compararlas
                let rowValueNormalized = row[key].normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                let valueNormalized = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                isDisabled = rowValueNormalized.localeCompare(valueNormalized) === 0;
            }

            if (reasonForDesactivation.length - 1 === i) {
                return isDisabled;
            } else if (isDisabled) {
                return true;
            }
        }
    }


    /**
     * This useEffect is in charge of creating each table check
     */
    useLayoutEffect(() => {

        if (body && body.length === 0) {
            return;
        }

        setTimeout(() => {
            if (selected.length > 0) {
                loadActionBar({ ...options, items: selected, count: selected.length });
                showActionBar();
            } else {
                hideActionBar();
            }
        }, 100)

        const timeoutId1 = setTimeout(addFormCheckToFirstRow, 50);
        const timeoutId2 = setTimeout(addFormCheckToEveryRow, 50);
        return () => {
            clearTimeout(timeoutId1);
            clearTimeout(timeoutId2);
        };

    }, [body, selected]);


    /**
     * it searchs the class to create a principal check
     */
    // Dentro de tu componente de React

    const addFormCheckToFirstRow = () => {
        if (!getAllId) {
            console.warn("getAllId is not defined");
            return;
        }
        const rows = document.querySelectorAll(`th[class^="${classToFindPrincipalRow}"]`);
        for (const row of rows) {
            if (row) {
                const cFormCheckComponent = (
                    <CFormCheck
                        id={`principalCheck_${Util.getUniqueId(1)}`}
                        onChange={(e: any) => getAllIdLiquidation
                            ? handlePrincipalCheckSpecialLiquidation(e)
                            : handlePrincipalCheck(e)}
                        checked={isAllId}
                        indeterminate={(pagination?.totalItems !== selected.length) && (selected.length > 0)}
                    />
                );
                createAndRenderFormCheck(row, cFormCheckComponent, principalRootRef);
            }
        }
    };





    /**
    * it searchs the class to create every regular check
    */
    const addFormCheckToEveryRow = async () => {

        const rows = document.querySelectorAll(`tr[class^="${classToFindRegularRow}"]`);

        for (const [i, row] of rows.entries()) {
            const firstTd = row.querySelector('td');
            if (!rootRefs.current[i]) {
                rootRefs.current[i] = { current: null };
            }
            if (body[i]) {
                firstTd.id = `container_row_${body[i].id}`;
                if (firstTd && body[i]) {
                    const cFormCheckComponent = (
                        <CFormCheck
                            id={`check_${i} id_row_${body[i].id}`}
                            disabled={disabledCheck(body[i])}
                            onChange={(e: any) => handleSingleCheck(e, body[i].id, body[i])}
                            checked={!disabledCheck(body[i]) ? isSelected(body[i].id) : null}
                        />
                    );

                    // console.log("entro aqui?", firstTd, body[i].id, cFormCheckComponent)
                    // debugger;

                    createAndRenderFormCheck(firstTd, cFormCheckComponent, rootRefs.current[i]);
                }
            } else {
                // Limpiar las referencias de React cuando cambia el cuerpo de la tabla
                // rootRefs.current = body.map(() => ({ current: null }));
            }
        }

    };





    const handlePrincipalCheck = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsAllId(event.target.checked)
        if (event.target.checked) {
            let result = await getAllId(reasonForDesactivation)
            if (result && result.ok) {
                setSelected(result.item.map(item => item.id))
            }
        } else {
            setSelected([]);
        }
    };

    /**
     * Especial para socios liquidación
     * Especial para socios liquidación
     *  Especial para socios liquidación
     * @param event 
     */
    const handlePrincipalCheckSpecialLiquidation = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsAllId(event.target.checked);
        if (event.target.checked) {
            let token = localStorage.getItem("token");
            let result = await getAllIdLiquidation(token, pagination);
            if (result && result.ok) {
                console.log(result.item)
                setSelected(result.item.vouchers.map(item => item.id));
            }
        } else {
            setSelected([]);
        }
    };

    const handleSingleCheck = (event: React.ChangeEvent<HTMLInputElement>, id: any, item: any) => {
        const selectedIndex = selected.findIndex(rows => rows === id);
        if (!disabledCheck(item)) {
            let newSelected: any[] = [];

            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selected.slice(0, selectedIndex),
                    selected.slice(selectedIndex + 1),
                );
            }

            setSelected(newSelected);
            if (newSelected.length === 0 && isAllId) {
                setIsAllId(false);
            } else if (!isAllId && newSelected.length === pagination?.totalItems) {
                setIsAllId(true);
            }


        }
    };


    return {
        selected,

        handleSingleCheck,
        cleanSelected

    }
}